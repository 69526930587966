export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  DateTime: any
}

export type AbstractUser = {
  __typename?: 'AbstractUser'
  active: Scalars['Boolean']
  cognitoId?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['DateTime']>
  devices: Array<Device>
  email: Scalars['String']
  id: Scalars['ID']
  lastSeenAt?: Maybe<Scalars['DateTime']>
  name: Scalars['String']
  profileImage?: Maybe<Image>
  role: UserRole
  status: Status
  type: UserType
  updatedAt: Scalars['DateTime']
}

export type Admin = {
  __typename?: 'Admin'
  active: Scalars['Boolean']
  cognitoId?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['DateTime']>
  devices: Array<Device>
  email: Scalars['String']
  id: Scalars['ID']
  lastSeenAt?: Maybe<Scalars['DateTime']>
  name: Scalars['String']
  phone: Scalars['String']
  profileImage?: Maybe<Image>
  role: UserRole
  status: Status
  type: UserType
  updatedAt: Scalars['DateTime']
}

export type AdminTopUpInput = {
  amount: Scalars['Int']
  note?: InputMaybe<Scalars['String']>
  partnerId: Scalars['ID']
}

export type AreaLocation = {
  __typename?: 'AreaLocation'
  address: Scalars['String']
  availableNationwide: Scalars['Boolean']
  city: Scalars['String']
  radius: Scalars['Int']
}

export type AreaLocationInput = {
  address: Scalars['String']
  availableNationwide: Scalars['Boolean']
  city: Scalars['String']
  radius: Scalars['Int']
}

export type AttachmentInput = {
  name?: InputMaybe<Scalars['String']>
  payload: Scalars['String']
  type: Scalars['String']
}

export type AvailabilitiesFilterInput = {
  filter?: InputMaybe<Scalars['String']>
  limit: Scalars['Int']
  offset?: InputMaybe<Scalars['Int']>
  partner?: InputMaybe<Scalars['ID']>
  sortBy?: InputMaybe<Scalars['String']>
  sortDirection?: InputMaybe<SortDirection>
  status?: InputMaybe<AvailabilityStatus>
}

export type Availability = {
  __typename?: 'Availability'
  createdAt?: Maybe<Scalars['DateTime']>
  date: Scalars['DateTime']
  id: Scalars['ID']
  partner: Partner
  status: AvailabilityStatus
  updatedAt?: Maybe<Scalars['DateTime']>
  user: User
}

export enum AvailabilityStatus {
  Accepted = 'ACCEPTED',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
}

export type Blog = {
  __typename?: 'Blog'
  author: Scalars['String']
  content: Scalars['String']
  coverImage: Image
  createdAt?: Maybe<Scalars['DateTime']>
  id: Scalars['ID']
  name: Scalars['String']
  ordering: Scalars['Int']
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type BlogFilter = {
  filter?: InputMaybe<Scalars['String']>
  limit: Scalars['Int']
  offset?: InputMaybe<Scalars['Int']>
  searchByName?: InputMaybe<Scalars['String']>
  sortBy?: InputMaybe<Scalars['String']>
  sortDirection?: InputMaybe<SortDirection>
}

export type Budget = {
  __typename?: 'Budget'
  costs: Array<Cost>
  coupleId: Scalars['ID']
  createdAt?: Maybe<Scalars['DateTime']>
  id: Scalars['ID']
  updatedAt?: Maybe<Scalars['DateTime']>
}

export enum CacheControlScope {
  Private = 'PRIVATE',
  Public = 'PUBLIC',
}

export type Category = {
  __typename?: 'Category'
  createdAt: Scalars['DateTime']
  extraFields: Array<Scalars['String']>
  hide: Scalars['Boolean']
  id: Scalars['ID']
  image: Image
  name: Scalars['String']
  ordering: Scalars['Int']
  partnersCount: Scalars['Int']
  tags: Array<Tag>
  updatedAt: Scalars['DateTime']
}

export type CategoryFilter = {
  filter?: InputMaybe<Scalars['String']>
  limit: Scalars['Int']
  offset?: InputMaybe<Scalars['Int']>
  searchByName?: InputMaybe<Scalars['String']>
  sortBy?: InputMaybe<Scalars['String']>
  sortDirection?: InputMaybe<SortDirection>
}

export type Complain = {
  __typename?: 'Complain'
  createdAt?: Maybe<Scalars['DateTime']>
  id: Scalars['ID']
  message: Scalars['String']
  status: Status
  updatedAt?: Maybe<Scalars['DateTime']>
  userId: Scalars['ID']
}

export type ComplainInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>
  id?: InputMaybe<Scalars['ID']>
  message?: InputMaybe<Scalars['String']>
  status?: InputMaybe<Status>
  updatedAt?: InputMaybe<Scalars['DateTime']>
  userId?: InputMaybe<Scalars['ID']>
}

export type Conversation = {
  __typename?: 'Conversation'
  createdAt: Scalars['DateTime']
  id: Scalars['ID']
  lastMessageDate: Scalars['DateTime']
  messages: PaginatedMessage
  participants: Array<User>
  updatedAt: Scalars['DateTime']
}

export type ConversationsFilter = {
  all?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Scalars['String']>
  limit: Scalars['Int']
  messagesLimit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  participants?: InputMaybe<Array<Scalars['ID']>>
  searchByUserName?: InputMaybe<Scalars['String']>
  sortBy?: InputMaybe<Scalars['String']>
  sortDirection?: InputMaybe<SortDirection>
}

export type Coordinate = {
  __typename?: 'Coordinate'
  lat: Scalars['Float']
  lng: Scalars['Float']
}

export type CoordinateInput = {
  lat: Scalars['Float']
  lng: Scalars['Float']
}

export type Cost = {
  __typename?: 'Cost'
  amount: Scalars['Int']
  icon?: Maybe<Scalars['String']>
  id: Scalars['ID']
  name: Scalars['String']
}

export type Couple = {
  __typename?: 'Couple'
  active: Scalars['Boolean']
  adminNote: Scalars['String']
  cognitoId?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['DateTime']>
  devices: Array<Device>
  email: Scalars['String']
  emailFragment: Scalars['String']
  id: Scalars['ID']
  lastSeenAt?: Maybe<Scalars['DateTime']>
  likedItems: Array<Maybe<Item>>
  likedPartners: Array<Maybe<Partner>>
  myName: Scalars['String']
  name: Scalars['String']
  needs: Array<Need>
  partnersName: Scalars['String']
  profileImage?: Maybe<Image>
  role: UserRole
  status: Status
  type: UserType
  updatedAt: Scalars['DateTime']
  userNeeds: Array<UserNeed>
  wedding: Wedding
  whoAreYou: CoupleRole
}

export type CoupleFilter = {
  active?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Scalars['String']>
  limit: Scalars['Int']
  offset?: InputMaybe<Scalars['Int']>
  searchByName?: InputMaybe<Scalars['String']>
  sortBy?: InputMaybe<Scalars['String']>
  sortDirection?: InputMaybe<SortDirection>
  status?: InputMaybe<Scalars['String']>
}

export enum CoupleRole {
  Bride = 'BRIDE',
  Fiance = 'FIANCE',
}

export type Cover = {
  __typename?: 'Cover'
  id: Scalars['ID']
  image: Image
  name: Scalars['String']
}

export type CoverInput = {
  id?: InputMaybe<Scalars['ID']>
  image: Scalars['String']
  name?: InputMaybe<Scalars['String']>
}

export type Device = {
  __typename?: 'Device'
  id: Scalars['String']
  notificationPreferences?: Maybe<NotificationPreferences>
  signedIn: Scalars['Boolean']
}

export type DeviceInput = {
  id: Scalars['String']
  notificationPreferences: NotificationPreferencesInput
}

export enum Direction {
  Down = 'DOWN',
  Up = 'UP',
}

export type EmailPreferences = {
  __typename?: 'EmailPreferences'
  approveUserNeed: Scalars['Boolean']
}

export type EmailPreferencesInput = {
  approveUserNeed?: InputMaybe<Scalars['Boolean']>
}

export type FeedbackQuoteResponseInput = {
  option: Scalars['ID']
  quoteResponseId: Scalars['ID']
}

export type FilterPartnerInput = {
  active?: InputMaybe<Scalars['Boolean']>
  category?: InputMaybe<Scalars['ID']>
  coordinate?: InputMaybe<CoordinateInput>
  guestMax?: InputMaybe<Scalars['Int']>
  need?: InputMaybe<Scalars['ID']>
  searchByName?: InputMaybe<Scalars['String']>
  sortByClass?: InputMaybe<Scalars['Boolean']>
  tagIds?: InputMaybe<Array<Scalars['ID']>>
}

export type FindAvailabilityInput = {
  id: Scalars['ID']
  partner?: InputMaybe<Scalars['ID']>
}

export type FixLocation = {
  __typename?: 'FixLocation'
  address: Scalars['String']
  city: Scalars['String']
  coordinate: Coordinate
  street?: Maybe<Scalars['String']>
  streetNumber?: Maybe<Scalars['String']>
  zip?: Maybe<Scalars['String']>
}

export type FixLocationInput = {
  address: Scalars['String']
  city: Scalars['String']
  coordinate: CoordinateInput
  placeId?: InputMaybe<Scalars['String']>
  street?: InputMaybe<Scalars['String']>
  streetNumber?: InputMaybe<Scalars['String']>
  zip?: InputMaybe<Scalars['String']>
}

export type ForYou = {
  __typename?: 'ForYou'
  createdAt: Scalars['DateTime']
  id: Scalars['ID']
  partner: Partner
  partnerId: Scalars['ID']
  status: QuoteRequestStatus
  updatedAt: Scalars['DateTime']
}

export type ForYouInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>
  partnerId?: InputMaybe<Scalars['ID']>
  status?: InputMaybe<QuoteRequestStatus>
  updatedAt?: InputMaybe<Scalars['DateTime']>
}

export type GalleryItem = {
  __typename?: 'GalleryItem'
  id: Scalars['String']
  image: Image
  name?: Maybe<Scalars['String']>
}

export type GalleryItemInput = {
  image?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
}

export type Guest = {
  __typename?: 'Guest'
  createdAt?: Maybe<Scalars['DateTime']>
  email: Scalars['String']
  icon?: Maybe<Scalars['String']>
  id: Scalars['ID']
  invitation: Invitation
  name: Scalars['String']
  updatedAt: Scalars['DateTime']
}

export type Highlight = {
  __typename?: 'Highlight'
  createdAt?: Maybe<Scalars['DateTime']>
  description: Scalars['String']
  elements: Array<OrderedHighlightElement>
  id: Scalars['ID']
  name: Scalars['String']
  ordering: Scalars['Int']
  randomized?: Maybe<Scalars['Boolean']>
  type: HighlightTypes
  updatedAt?: Maybe<Scalars['DateTime']>
  variant: HighlightVariants
}

export type HighlightElement = Blog | Category | Partner | TagGroup

export type HighlightInput = {
  description?: InputMaybe<Scalars['String']>
  elements: Array<OrderedHighlightElementInput>
  id?: InputMaybe<Scalars['ID']>
  name?: InputMaybe<Scalars['String']>
  ordering?: InputMaybe<Scalars['Int']>
  randomized?: InputMaybe<Scalars['Boolean']>
  type?: InputMaybe<HighlightTypes>
  variant?: InputMaybe<HighlightVariants>
}

export enum HighlightTypes {
  Blog = 'BLOG',
  Category = 'CATEGORY',
  Partner = 'PARTNER',
  TagGroup = 'TAG_GROUP',
}

export enum HighlightVariants {
  Image = 'IMAGE',
  Primary = 'PRIMARY',
  PrimaryWide = 'PRIMARY_WIDE',
  Secondary = 'SECONDARY',
  SecondaryAlt = 'SECONDARY_ALT',
  Tag = 'TAG',
  Wide = 'WIDE',
  WideAlt = 'WIDE_ALT',
}

/** Image */
export type Image = {
  __typename?: 'Image'
  level: Scalars['String']
  meta: ImageMeta
  name: Scalars['String']
}

export type ImageMeta = {
  __typename?: 'ImageMeta'
  height: Scalars['Float']
  width: Scalars['Float']
}

export type InspirationItem = {
  __typename?: 'InspirationItem'
  active: Scalars['Boolean']
  category: Category
  createdAt: Scalars['DateTime']
  description?: Maybe<Scalars['String']>
  galleryId?: Maybe<Scalars['String']>
  id: Scalars['String']
  image: Image
  itemId: Scalars['ID']
  name: Scalars['String']
  partnerId: Scalars['ID']
  partnerName: Scalars['String']
  tags: Array<Scalars['ID']>
  tagsText: Scalars['String']
  updatedAt: Scalars['DateTime']
}

export type InspirationItemFiltersInput = {
  categoryId?: InputMaybe<Scalars['ID']>
  partnerId?: InputMaybe<Scalars['ID']>
  tagIds?: InputMaybe<Array<Scalars['ID']>>
  text?: InputMaybe<Scalars['String']>
}

export type InspirationItemPaginatedInput = {
  filters?: InputMaybe<InspirationItemFiltersInput>
  limit: Scalars['Int']
  random?: InputMaybe<Scalars['Boolean']>
  shownItemIds?: InputMaybe<Array<Scalars['String']>>
}

export type Invitation = {
  __typename?: 'Invitation'
  createdAt?: Maybe<Scalars['DateTime']>
  error?: Maybe<Scalars['String']>
  guestId: Scalars['ID']
  id: Scalars['ID']
  status: InvitationStatus
  updatedAt: Scalars['DateTime']
  weddingId: Scalars['ID']
}

export enum InvitationStatus {
  Failed = 'FAILED',
  Initialized = 'INITIALIZED',
  Pending = 'PENDING',
  Sent = 'SENT',
}

export type Invoice = {
  __typename?: 'Invoice'
  address: Scalars['String']
  city: Scalars['String']
  company: Scalars['String']
  country: Scalars['String']
  name: Scalars['String']
  phone: Scalars['String']
  state: Scalars['String']
  taxNumber: Scalars['String']
  type: InvoiceType
  zip: Scalars['String']
}

export type InvoiceInput = {
  address?: InputMaybe<Scalars['String']>
  city?: InputMaybe<Scalars['String']>
  company?: InputMaybe<Scalars['String']>
  country?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  phone?: InputMaybe<Scalars['String']>
  state?: InputMaybe<Scalars['String']>
  taxNumber?: InputMaybe<Scalars['String']>
  type?: InputMaybe<InvoiceType>
  zip?: InputMaybe<Scalars['String']>
}

export enum InvoiceType {
  Company = 'COMPANY',
  Individual = 'INDIVIDUAL',
}

export type Item = {
  __typename?: 'Item'
  active?: Maybe<Scalars['Boolean']>
  coverImage?: Maybe<Image>
  createdAt?: Maybe<Scalars['DateTime']>
  deletedAt?: Maybe<Scalars['DateTime']>
  description?: Maybe<Scalars['String']>
  gallery: Array<GalleryItem>
  id: Scalars['ID']
  likes: Scalars['Int']
  name: Scalars['String']
  /** An item's partner might be deleted, so it can be null */
  partner?: Maybe<Partner>
  status: Status
  tags?: Maybe<Array<Tag>>
  updatedAt?: Maybe<Scalars['DateTime']>
  views?: Maybe<Scalars['Int']>
}

export type ItemFilter = {
  filter?: InputMaybe<Scalars['String']>
  limit: Scalars['Int']
  offset?: InputMaybe<Scalars['Int']>
  partnerId?: InputMaybe<Scalars['String']>
  sortBy?: InputMaybe<Scalars['String']>
  sortDirection?: InputMaybe<SortDirection>
  status?: InputMaybe<Status>
}

export type ItemInput = {
  active?: InputMaybe<Scalars['Boolean']>
  coverImage?: InputMaybe<Scalars['String']>
  description?: InputMaybe<Scalars['String']>
  gallery?: InputMaybe<Array<GalleryItemInput>>
  id?: InputMaybe<Scalars['ID']>
  name: Scalars['String']
  partner: Scalars['ID']
  status?: InputMaybe<Status>
  tags: Array<Scalars['ID']>
}

export type Location = {
  __typename?: 'Location'
  coordinate?: Maybe<Coordinate>
  name: Scalars['String']
  placeId?: Maybe<Scalars['String']>
}

export type LocationInput = {
  coordinate?: InputMaybe<CoordinateInput>
  name: Scalars['String']
  placeId?: InputMaybe<Scalars['String']>
}

export type Message = {
  __typename?: 'Message'
  attachment?: Maybe<MessageAttachment>
  conversation: Conversation
  createdAt?: Maybe<Scalars['DateTime']>
  id: Scalars['ID']
  seenAt?: Maybe<Scalars['DateTime']>
  sender: User
  text?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type MessageAttachment = {
  __typename?: 'MessageAttachment'
  name?: Maybe<Scalars['String']>
  payload?: Maybe<Scalars['String']>
  type: Scalars['String']
}

export type MessagesFilter = {
  conversationId?: InputMaybe<Scalars['ID']>
  filter?: InputMaybe<Scalars['String']>
  limit: Scalars['Int']
  offset?: InputMaybe<Scalars['Int']>
  searchByText?: InputMaybe<Scalars['String']>
  senderId?: InputMaybe<Scalars['ID']>
  sortBy?: InputMaybe<Scalars['String']>
  sortDirection?: InputMaybe<SortDirection>
}

export type Mutation = {
  __typename?: 'Mutation'
  acceptAvailability: Scalars['Boolean']
  acceptQuoteRequest: QuoteResponse
  activatePartner: Partner
  addCost: Budget
  addGuest: Guest
  addTokenToPartner: Transaction
  addUserNeed: UserNeed
  createQATestingUser: Scalars['Boolean']
  createQuestion: Question
  delete: Scalars['Boolean']
  deleteAvailability: Scalars['Boolean']
  deleteBlog: Scalars['Boolean']
  deleteCategory: Scalars['Boolean']
  deleteComplain: Complain
  deleteCost: Scalars['Boolean']
  deleteGuest: Scalars['Boolean']
  deleteItem: Scalars['Boolean']
  deleteNeed: Scalars['Boolean']
  deleteProfile: Scalars['Boolean']
  deleteTag: Scalars['Boolean']
  deleteTagGroup: Scalars['Boolean']
  deleteUser: Scalars['Boolean']
  deleteUserNeed: Scalars['Boolean']
  feedbackQuoteResponse: QuoteResponse
  insertAvailability: Availability
  insertBlog: Blog
  insertCategory: Category
  insertComplain: Complain
  insertCover: Cover
  insertHighlight: Highlight
  insertItem: Item
  insertNeed: Need
  insertTag: Tag
  insertTagGroup: TagGroup
  insertUser: User
  invitePartner: Scalars['Boolean']
  leaveConversation: Scalars['Boolean']
  likeItem: User
  linkAccount: Scalars['Boolean']
  linkQATestingUser: Scalars['Boolean']
  moveHighlight: Array<Highlight>
  newConversation: Conversation
  openAccount: Scalars['Boolean']
  partnerQuote: QuoteRequest
  registerNotificationToken: NotificationPreferences
  rejectAvailability: Scalars['Boolean']
  rejectForYou: UserNeed
  rejectQuoteRequest: QuoteResponse
  removeCover: Scalars['Boolean']
  requestAvailability: Availability
  requestQuote: QuoteRequest
  seeConversation: Conversation
  seeQuoteRequest: QuoteRequest
  seeQuoteResponse: QuoteResponse
  seenUserNeed: Scalars['Boolean']
  sendBatchMessage: Scalars['Boolean']
  sendInvitations: Scalars['Boolean']
  sendMessage: Message
  sendNotification: Notification
  signInToDevice: Scalars['Boolean']
  signOutFromDevice: Scalars['Boolean']
  startTransaction: StartTransactionResult
  updateAvailability: Availability
  updateBlog: Blog
  updateCategory: Category
  updateConversation: Conversation
  updateCost: Budget
  updateCouple: Couple
  updateCover: Cover
  updateGuest: Guest
  updateHighlight: Highlight
  updateItem: Item
  updateMessage: Message
  updateNeed: Need
  updateNotificationsEnabled: Scalars['Boolean']
  updatePartner: Partner
  updateTag: Tag
  updateTagGroup: TagGroup
  updateUser: User
  updateUserNeed: UserNeed
  updateWedding: Wedding
  validateTransaction: ValidateTransactionResult
}

export type MutationAcceptAvailabilityArgs = {
  id: Scalars['ID']
}

export type MutationAcceptQuoteRequestArgs = {
  input: NewQuoteResponseInput
}

export type MutationActivatePartnerArgs = {
  id: Scalars['ID']
}

export type MutationAddCostArgs = {
  input: NewCostInput
}

export type MutationAddGuestArgs = {
  input: NewGuestInput
}

export type MutationAddTokenToPartnerArgs = {
  input: AdminTopUpInput
}

export type MutationAddUserNeedArgs = {
  input: UserNeedInput
}

export type MutationCreateQaTestingUserArgs = {
  input: QaTestingUserInput
}

export type MutationCreateQuestionArgs = {
  input: QuestionInput
}

export type MutationDeleteArgs = {
  id: Scalars['ID']
}

export type MutationDeleteAvailabilityArgs = {
  id: Scalars['ID']
}

export type MutationDeleteBlogArgs = {
  id: Scalars['ID']
}

export type MutationDeleteCategoryArgs = {
  id: Scalars['ID']
}

export type MutationDeleteComplainArgs = {
  id: Scalars['ID']
}

export type MutationDeleteCostArgs = {
  input: UpdateCostInput
}

export type MutationDeleteGuestArgs = {
  id: Scalars['ID']
}

export type MutationDeleteItemArgs = {
  id: Scalars['ID']
}

export type MutationDeleteNeedArgs = {
  id: Scalars['ID']
}

export type MutationDeleteTagArgs = {
  id: Scalars['ID']
}

export type MutationDeleteTagGroupArgs = {
  id: Scalars['ID']
}

export type MutationDeleteUserArgs = {
  id: Scalars['ID']
}

export type MutationDeleteUserNeedArgs = {
  id: Scalars['ID']
}

export type MutationFeedbackQuoteResponseArgs = {
  input: FeedbackQuoteResponseInput
}

export type MutationInsertAvailabilityArgs = {
  input: NewAvailabilityInput
}

export type MutationInsertBlogArgs = {
  input: NewBlogInput
}

export type MutationInsertCategoryArgs = {
  input: NewCategoryInput
}

export type MutationInsertComplainArgs = {
  input: ComplainInput
}

export type MutationInsertCoverArgs = {
  input: CoverInput
}

export type MutationInsertHighlightArgs = {
  input: HighlightInput
}

export type MutationInsertItemArgs = {
  input: ItemInput
}

export type MutationInsertNeedArgs = {
  input: NewNeedInput
}

export type MutationInsertTagArgs = {
  tag: TagInput
}

export type MutationInsertTagGroupArgs = {
  input: TagGroupInput
}

export type MutationInsertUserArgs = {
  input: NewUserInput
}

export type MutationInvitePartnerArgs = {
  id: Scalars['ID']
}

export type MutationLeaveConversationArgs = {
  id: Scalars['ID']
}

export type MutationLikeItemArgs = {
  itemId: Scalars['ID']
}

export type MutationLinkAccountArgs = {
  id: Scalars['ID']
}

export type MutationLinkQaTestingUserArgs = {
  input: QaTestingUserInput
}

export type MutationMoveHighlightArgs = {
  dir: Direction
  id: Scalars['ID']
}

export type MutationNewConversationArgs = {
  input: NewConversationInput
}

export type MutationOpenAccountArgs = {
  input: NewUserInput
}

export type MutationPartnerQuoteArgs = {
  quoteRequestInput: PartnerQuoteInput
}

export type MutationRegisterNotificationTokenArgs = {
  deviceId: Scalars['String']
  token: Scalars['String']
}

export type MutationRejectAvailabilityArgs = {
  id: Scalars['ID']
}

export type MutationRejectForYouArgs = {
  forYouId: Scalars['ID']
  userNeedId: Scalars['ID']
}

export type MutationRejectQuoteRequestArgs = {
  input: NewQuoteResponseInput
}

export type MutationRemoveCoverArgs = {
  id: Scalars['ID']
}

export type MutationRequestAvailabilityArgs = {
  input: RequestAvailabilityInput
}

export type MutationRequestQuoteArgs = {
  input: NewQuoteRequestInput
}

export type MutationSeeConversationArgs = {
  id: Scalars['ID']
}

export type MutationSeeQuoteRequestArgs = {
  input: Scalars['ID']
}

export type MutationSeeQuoteResponseArgs = {
  input: Scalars['ID']
}

export type MutationSeenUserNeedArgs = {
  id: Scalars['ID']
}

export type MutationSendBatchMessageArgs = {
  input: NewBatchMessageInput
}

export type MutationSendMessageArgs = {
  input: NewMessageInput
}

export type MutationSendNotificationArgs = {
  input: SendNotificationInput
}

export type MutationSignInToDeviceArgs = {
  deviceId: Scalars['String']
}

export type MutationSignOutFromDeviceArgs = {
  deviceId: Scalars['String']
}

export type MutationStartTransactionArgs = {
  input: StartTransactionInput
}

export type MutationUpdateAvailabilityArgs = {
  id: Scalars['ID']
  input: UpdateAvailabilityInput
}

export type MutationUpdateBlogArgs = {
  input: UpdateBlogInput
}

export type MutationUpdateCategoryArgs = {
  input: UpdateCategoryInput
}

export type MutationUpdateConversationArgs = {
  input: UpdateConversationInput
}

export type MutationUpdateCostArgs = {
  input: UpdateCostInput
}

export type MutationUpdateCoupleArgs = {
  input: UpdateCoupleInput
}

export type MutationUpdateCoverArgs = {
  input: CoverInput
}

export type MutationUpdateGuestArgs = {
  input: UpdateGuestInput
}

export type MutationUpdateHighlightArgs = {
  input: HighlightInput
}

export type MutationUpdateItemArgs = {
  input: ItemInput
}

export type MutationUpdateMessageArgs = {
  input: UpdateMessageInput
}

export type MutationUpdateNeedArgs = {
  id: Scalars['ID']
  input: UpdateNeedInput
}

export type MutationUpdateNotificationsEnabledArgs = {
  deviceId: Scalars['String']
  enabled: Scalars['Boolean']
}

export type MutationUpdatePartnerArgs = {
  input: UpdatePartnerInput
}

export type MutationUpdateTagArgs = {
  id: Scalars['ID']
  input: TagInput
}

export type MutationUpdateTagGroupArgs = {
  id: Scalars['ID']
  input: TagGroupInput
}

export type MutationUpdateUserArgs = {
  input: UpdateUserInput
}

export type MutationUpdateUserNeedArgs = {
  id: Scalars['ID']
  input: UserNeedInput
}

export type MutationUpdateWeddingArgs = {
  input: UpdateWeddingInput
}

export type MutationValidateTransactionArgs = {
  input: ValidateTransactionInput
}

export type Need = {
  __typename?: 'Need'
  category: Category
  createdAt: Scalars['DateTime']
  hide?: Maybe<Scalars['Boolean']>
  id: Scalars['ID']
  name: Scalars['String']
  questions: Array<Question>
  updatedAt: Scalars['DateTime']
}

export type NewAdminInput = {
  name: Scalars['String']
  phone: Scalars['String']
}

export type NewAvailabilityInput = {
  date: Scalars['DateTime']
  partner: Scalars['ID']
  status: AvailabilityStatus
  user: Scalars['ID']
}

export type NewBatchMessageInput = {
  attachment?: InputMaybe<AttachmentInput>
  ids: Array<Scalars['ID']>
  text?: InputMaybe<Scalars['String']>
}

export type NewBlogInput = {
  author: Scalars['String']
  content: Scalars['String']
  coverImage: Scalars['String']
  name: Scalars['String']
  ordering: Scalars['Int']
}

export type NewCategoryInput = {
  extraFields: Array<Scalars['String']>
  hide: Scalars['Boolean']
  image: Scalars['String']
  name: Scalars['String']
  ordering: Scalars['Int']
  tags: Array<Scalars['ID']>
}

export type NewConversationInput = {
  message: NewMessageInput
  participants: Array<Scalars['String']>
}

export type NewCostInput = {
  amount: Scalars['Int']
  budgetId: Scalars['ID']
  icon?: InputMaybe<Scalars['String']>
  name: Scalars['String']
}

export type NewCoupleInput = {
  myName: Scalars['String']
  needs?: InputMaybe<Array<Scalars['ID']>>
  partnersName: Scalars['String']
  wedding?: InputMaybe<NewWeddingInput>
  whoAreYou: CoupleRole
}

export type NewGuestInput = {
  email: Scalars['String']
  icon?: InputMaybe<Scalars['String']>
  name: Scalars['String']
  weddingId: Scalars['ID']
}

export type NewMessageInput = {
  attachment?: InputMaybe<AttachmentInput>
  conversation?: InputMaybe<Scalars['ID']>
  sender?: InputMaybe<Scalars['ID']>
  silent?: InputMaybe<AttachmentInput>
  text?: InputMaybe<Scalars['String']>
}

export type NewNeedInput = {
  category: Scalars['ID']
  hide?: InputMaybe<Scalars['Boolean']>
  name: Scalars['String']
  questions?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type NewPartnerInput = {
  areaLocation?: InputMaybe<AreaLocationInput>
  availableWeekdays?: InputMaybe<Array<WeekDay>>
  category: Scalars['ID']
  class: PartnerClass
  contactEmail: Scalars['String']
  contactName: Scalars['String']
  contactPhone: Scalars['String']
  coverImages?: InputMaybe<Array<Scalars['String']>>
  description: Scalars['String']
  fixLocation?: InputMaybe<FixLocationInput>
  guestRange?: InputMaybe<RangeInput>
  items?: InputMaybe<Array<Scalars['ID']>>
  name: Scalars['String']
  needs?: InputMaybe<Array<Scalars['ID']>>
  phone: Scalars['String']
  profileImage?: InputMaybe<Scalars['String']>
  seatingCapacity?: InputMaybe<Scalars['Int']>
  services?: InputMaybe<Array<Scalars['ID']>>
  standingCapacity?: InputMaybe<Scalars['Int']>
  tags?: InputMaybe<Array<Scalars['ID']>>
  verified: Scalars['Boolean']
  website?: InputMaybe<Scalars['String']>
}

export type NewQuoteRequestInput = {
  acceptingQuotes: Scalars['Boolean']
  attachments?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  category: Scalars['ID']
  coupleId: Scalars['ID']
  forYouId?: InputMaybe<Scalars['ID']>
  partnerId: Scalars['ID']
  text?: InputMaybe<Scalars['String']>
  type: QuoteType
  userNeedCopy: UserNeedCopyInput
  userNeedId?: InputMaybe<Scalars['ID']>
  weddingCopy: WeddingCopyInput
}

export type NewQuoteResponseInput = {
  attachments?: InputMaybe<Array<QuoteAttachmentInput>>
  coupleId: Scalars['ID']
  email?: InputMaybe<Scalars['String']>
  fixPrice?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
  partnerId: Scalars['ID']
  phone?: InputMaybe<Scalars['String']>
  priceRange?: InputMaybe<RangeInput>
  reasons?: InputMaybe<Array<Scalars['String']>>
  requestId: Scalars['ID']
  sendEmail?: InputMaybe<Scalars['Boolean']>
  status?: InputMaybe<QuoteResponseStatus>
  text?: InputMaybe<Scalars['String']>
}

export type NewUserInput = {
  active?: InputMaybe<Scalars['Boolean']>
  admin?: InputMaybe<NewAdminInput>
  cognitoId?: InputMaybe<Scalars['String']>
  couple?: InputMaybe<NewCoupleInput>
  devices?: InputMaybe<Array<DeviceInput>>
  email: Scalars['String']
  name?: InputMaybe<Scalars['String']>
  partner?: InputMaybe<NewPartnerInput>
  profileImage?: InputMaybe<Scalars['String']>
  role?: InputMaybe<UserRole>
  status?: InputMaybe<Status>
  type: UserType
}

export type NewWeddingInput = {
  address?: InputMaybe<LocationInput>
  addressNote?: InputMaybe<Scalars['String']>
  budgetRange?: InputMaybe<RangeInput>
  coupleId?: InputMaybe<Scalars['ID']>
  coverImage?: InputMaybe<Scalars['String']>
  date?: InputMaybe<Scalars['DateTime']>
  description?: InputMaybe<Scalars['String']>
  guestRange?: InputMaybe<RangeInput>
  locationTags?: InputMaybe<Array<Scalars['String']>>
  needs?: InputMaybe<Array<Scalars['ID']>>
  possibleAddresses?: InputMaybe<Array<LocationInput>>
  possibleDays?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  possibleMonths?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  possibleYear?: InputMaybe<Scalars['String']>
}

export type Notification = {
  __typename?: 'Notification'
  body: Scalars['String']
  createdAt?: Maybe<Scalars['DateTime']>
  data?: Maybe<Scalars['String']>
  id: Scalars['ID']
  sender: User
  title: Scalars['String']
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type NotificationPreferences = {
  __typename?: 'NotificationPreferences'
  createdAt: Scalars['DateTime']
  enabled: Scalars['Boolean']
  token: Scalars['String']
}

export type NotificationPreferencesInput = {
  enabled: Scalars['Boolean']
  token: Scalars['String']
}

export type OrderedHighlightElement = {
  __typename?: 'OrderedHighlightElement'
  element: HighlightElement
  order: Scalars['Int']
}

export type OrderedHighlightElementInput = {
  id: Scalars['ID']
  order: Scalars['Int']
}

export type PaginatedAvailability = {
  __typename?: 'PaginatedAvailability'
  elements?: Maybe<Array<Availability>>
  total: Scalars['Int']
}

export type PaginatedBlog = {
  __typename?: 'PaginatedBlog'
  elements?: Maybe<Array<Blog>>
  total: Scalars['Int']
}

export type PaginatedCategory = {
  __typename?: 'PaginatedCategory'
  elements?: Maybe<Array<Category>>
  total: Scalars['Int']
}

export type PaginatedConversation = {
  __typename?: 'PaginatedConversation'
  elements?: Maybe<Array<Conversation>>
  total: Scalars['Int']
}

export type PaginatedCouple = {
  __typename?: 'PaginatedCouple'
  elements?: Maybe<Array<Couple>>
  total: Scalars['Int']
}

export type PaginatedInspirationItem = {
  __typename?: 'PaginatedInspirationItem'
  elements?: Maybe<Array<InspirationItem>>
  relatedTags: Array<Tag>
  total: Scalars['Int']
}

export type PaginatedItem = {
  __typename?: 'PaginatedItem'
  elements?: Maybe<Array<Item>>
  total: Scalars['Int']
}

export type PaginatedMessage = {
  __typename?: 'PaginatedMessage'
  elements?: Maybe<Array<Message>>
  total: Scalars['Int']
}

export type PaginatedNeed = {
  __typename?: 'PaginatedNeed'
  elements?: Maybe<Array<Need>>
  total: Scalars['Int']
}

export type PaginatedNeedInput = {
  filter?: InputMaybe<Scalars['String']>
  limit: Scalars['Int']
  offset?: InputMaybe<Scalars['Int']>
  sortBy?: InputMaybe<Scalars['String']>
  sortDirection?: InputMaybe<SortDirection>
}

export type PaginatedPartner = {
  __typename?: 'PaginatedPartner'
  elements?: Maybe<Array<Partner>>
  total: Scalars['Int']
}

export type PaginatedPayment = {
  __typename?: 'PaginatedPayment'
  elements?: Maybe<Array<Payment>>
  total: Scalars['Int']
}

export type PaginatedQuoteRequest = {
  __typename?: 'PaginatedQuoteRequest'
  elements?: Maybe<Array<QuoteRequest>>
  total: Scalars['Int']
}

export type PaginatedTagGroup = {
  __typename?: 'PaginatedTagGroup'
  elements?: Maybe<Array<TagGroup>>
  total: Scalars['Int']
}

export type PaginatedTransaction = {
  __typename?: 'PaginatedTransaction'
  elements?: Maybe<Array<Transaction>>
  total: Scalars['Int']
}

export type PaginatedUser = {
  __typename?: 'PaginatedUser'
  elements?: Maybe<Array<AbstractUser>>
  total: Scalars['Int']
}

export type PaginatedUserNeed = {
  __typename?: 'PaginatedUserNeed'
  elements?: Maybe<Array<UserNeed>>
  total: Scalars['Int']
}

export type Partner = {
  __typename?: 'Partner'
  active: Scalars['Boolean']
  areaLocation?: Maybe<AreaLocation>
  availableWeekdays?: Maybe<Array<WeekDay>>
  category?: Maybe<Category>
  class: PartnerClass
  cognitoId?: Maybe<Scalars['String']>
  contactEmail: Scalars['String']
  contactName: Scalars['String']
  contactPhone: Scalars['String']
  coverImages: Array<Image>
  createdAt?: Maybe<Scalars['DateTime']>
  description: Scalars['String']
  devices: Array<Device>
  email: Scalars['String']
  emailPreferences: EmailPreferences
  fixLocation?: Maybe<FixLocation>
  guestRange?: Maybe<Range>
  id: Scalars['ID']
  items: Array<Item>
  lastSeenAt?: Maybe<Scalars['DateTime']>
  likedItems: Array<Maybe<Item>>
  likedPartners: Array<Maybe<Partner>>
  name: Scalars['String']
  needs: Array<Maybe<Need>>
  phone: Scalars['String']
  profileImage?: Maybe<Image>
  quotes: Array<QuoteRequest>
  registered: Scalars['Boolean']
  role: UserRole
  seatingCapacity?: Maybe<Scalars['Int']>
  services: Array<Service>
  standingCapacity?: Maybe<Scalars['Int']>
  status: Status
  tags: Array<Tag>
  type: UserType
  updatedAt: Scalars['DateTime']
  verified: Scalars['Boolean']
  wallet?: Maybe<Wallet>
  website?: Maybe<Scalars['String']>
}

export enum PartnerClass {
  A = 'A',
  B = 'B',
  C = 'C',
}

export type PartnerFilter = {
  active?: InputMaybe<Scalars['Boolean']>
  category?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<Scalars['String']>
  guestMax?: InputMaybe<Scalars['Float']>
  limit: Scalars['Int']
  offset?: InputMaybe<Scalars['Int']>
  searchByName?: InputMaybe<Scalars['String']>
  sortBy?: InputMaybe<Scalars['String']>
  sortByClass?: InputMaybe<Scalars['Boolean']>
  sortDirection?: InputMaybe<SortDirection>
  status?: InputMaybe<Scalars['String']>
  tagIds?: InputMaybe<Array<Scalars['String']>>
}

export type PartnerQuoteInput = {
  attachments?: InputMaybe<Array<QuoteAttachmentInput>>
  category: Scalars['ID']
  coupleId: Scalars['ID']
  email?: InputMaybe<Scalars['String']>
  fixPrice?: InputMaybe<Scalars['Int']>
  forYouId?: InputMaybe<Scalars['ID']>
  name?: InputMaybe<Scalars['String']>
  needId?: InputMaybe<Scalars['ID']>
  partnerId: Scalars['ID']
  phone?: InputMaybe<Scalars['String']>
  priceRange?: InputMaybe<RangeInput>
  reasons?: InputMaybe<Array<Scalars['String']>>
  requestId: Scalars['ID']
  sendEmail: Scalars['Boolean']
  text?: InputMaybe<Scalars['String']>
  type: QuoteType
  userNeedCopy: UserNeedCopyInput
  weddingCopy: WeddingCopyInput
}

export type Payment = {
  __typename?: 'Payment'
  createdAt: Scalars['DateTime']
  id: Scalars['ID']
  invoice: Invoice
  items: Array<PaymentItem>
  note?: Maybe<Scalars['String']>
  status: PaymentStatus
  total: Scalars['Int']
  transactionId?: Maybe<Scalars['String']>
  updatedAt: Scalars['DateTime']
  user: User
}

export type PaymentFilter = {
  filter?: InputMaybe<Scalars['String']>
  limit: Scalars['Int']
  offset?: InputMaybe<Scalars['Int']>
  sortBy?: InputMaybe<Scalars['String']>
  sortDirection?: InputMaybe<SortDirection>
  status?: InputMaybe<Scalars['String']>
}

export enum PaymentItem {
  Token10 = 'TOKEN10',
  Token25 = 'TOKEN25',
  Token50 = 'TOKEN50',
}

export enum PaymentStatus {
  Canceled = 'CANCELED',
  Failed = 'FAILED',
  Pending = 'PENDING',
  Success = 'SUCCESS',
}

export type Polygon = {
  __typename?: 'Polygon'
  coordinates: Array<Array<Array<Scalars['Float']>>>
  type?: Maybe<Scalars['String']>
}

export type PolygonLocation = {
  __typename?: 'PolygonLocation'
  city: Scalars['String']
  createdAt?: Maybe<Scalars['DateTime']>
  id: Scalars['ID']
  partners: Scalars['ID']
  polygon: Array<Polygon>
  radius?: Maybe<Scalars['Int']>
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type QaTestingUserInput = {
  cognitoId?: InputMaybe<Scalars['String']>
  email: Scalars['String']
  password?: InputMaybe<Scalars['String']>
}

export type Query = {
  __typename?: 'Query'
  accountOpened: Scalars['Boolean']
  allUserNeeds: Array<UserNeed>
  authToken: Scalars['String']
  authenticatedUser: User
  availabilities: PaginatedAvailability
  availability: Availability
  blog: Blog
  blogs: Array<Blog>
  budget: Budget
  budgetById: Budget
  categories: Array<Category>
  category: Category
  complain: Complain
  complains: Array<Complain>
  conversation: Conversation
  conversations: PaginatedConversation
  couple: Couple
  couples: PaginatedCouple
  cover: Cover
  covers: Array<Cover>
  filterPartners: Array<Partner>
  forYouRecommendationsCount: Scalars['Float']
  highlight: Highlight
  highlights: Array<Highlight>
  image: Image
  inspirationItems: PaginatedInspirationItem
  item: Item
  items: PaginatedItem
  location: PolygonLocation
  locations: Array<PolygonLocation>
  messages: PaginatedMessage
  need: Need
  needs: Array<Need>
  notification: Notification
  notifications: Array<Notification>
  paginatedBlogs: PaginatedBlog
  paginatedCategories: PaginatedCategory
  paginatedNeeds: PaginatedNeed
  paginatedTagGroups: PaginatedTagGroup
  partner: Partner
  partners: PaginatedPartner
  payments: PaginatedPayment
  profile: User
  question: Question
  questions: Array<Question>
  quoteRequest: QuoteRequest
  quoteRequests: PaginatedQuoteRequest
  quoteResponse?: Maybe<QuoteResponse>
  relatedPartners: Array<Partner>
  tag: Tag
  tagGroup: TagGroup
  tagGroups: Array<TagGroup>
  tags: Array<Tag>
  transaction: Transaction
  transactions: PaginatedTransaction
  unseenQuoteRequests: Scalars['Float']
  unseenUserNeeds: Scalars['Float']
  user: User
  userNeed: UserNeed
  userNeedCategorySummary: Array<UserNeedCategory>
  userNeedForCouple?: Maybe<UserNeed>
  userNeeds: PaginatedUserNeed
  userNeedsForCouple?: Maybe<Array<Maybe<UserNeed>>>
  users: PaginatedUser
  wallet: Wallet
  wedding: Wedding
  weddingById: Wedding
  weddingsByNeed: Array<Wedding>
}

export type QueryAvailabilitiesArgs = {
  input: AvailabilitiesFilterInput
}

export type QueryAvailabilityArgs = {
  input: FindAvailabilityInput
}

export type QueryBlogArgs = {
  id: Scalars['ID']
}

export type QueryBlogsArgs = {
  ids?: InputMaybe<Array<Scalars['String']>>
  searchByName?: InputMaybe<Scalars['String']>
}

export type QueryBudgetByIdArgs = {
  id: Scalars['ID']
}

export type QueryCategoriesArgs = {
  ids?: InputMaybe<Array<Scalars['String']>>
  searchByName?: InputMaybe<Scalars['String']>
}

export type QueryCategoryArgs = {
  id: Scalars['ID']
}

export type QueryComplainArgs = {
  id: Scalars['ID']
}

export type QueryConversationArgs = {
  id: Scalars['ID']
  participants?: InputMaybe<Array<Scalars['ID']>>
}

export type QueryConversationsArgs = {
  filter?: InputMaybe<ConversationsFilter>
}

export type QueryCoupleArgs = {
  id: Scalars['ID']
}

export type QueryCouplesArgs = {
  input: CoupleFilter
}

export type QueryCoverArgs = {
  id: Scalars['ID']
}

export type QueryFilterPartnersArgs = {
  input: FilterPartnerInput
}

export type QueryHighlightArgs = {
  id: Scalars['ID']
}

export type QueryInspirationItemsArgs = {
  input: InspirationItemPaginatedInput
}

export type QueryItemArgs = {
  id: Scalars['ID']
}

export type QueryItemsArgs = {
  filter: ItemFilter
}

export type QueryLocationArgs = {
  id: Scalars['ID']
}

export type QueryMessagesArgs = {
  filter?: InputMaybe<MessagesFilter>
}

export type QueryNeedArgs = {
  id: Scalars['ID']
}

export type QueryNotificationArgs = {
  id: Scalars['ID']
}

export type QueryNotificationsArgs = {
  searchTitle?: InputMaybe<Scalars['String']>
}

export type QueryPaginatedBlogsArgs = {
  input: BlogFilter
}

export type QueryPaginatedCategoriesArgs = {
  input: CategoryFilter
}

export type QueryPaginatedNeedsArgs = {
  input: PaginatedNeedInput
}

export type QueryPaginatedTagGroupsArgs = {
  input: TagGroupFilter
}

export type QueryPartnerArgs = {
  id: Scalars['ID']
}

export type QueryPartnersArgs = {
  input: PartnerFilter
}

export type QueryPaymentsArgs = {
  filter: PaymentFilter
}

export type QueryQuestionArgs = {
  id: Scalars['ID']
}

export type QueryQuoteRequestArgs = {
  id: Scalars['ID']
}

export type QueryQuoteRequestsArgs = {
  filter: QuoteRequestFilter
}

export type QueryQuoteResponseArgs = {
  id?: InputMaybe<Scalars['ID']>
  requestId?: InputMaybe<Scalars['ID']>
}

export type QueryRelatedPartnersArgs = {
  partnerId: Scalars['ID']
}

export type QueryTagArgs = {
  id: Scalars['ID']
}

export type QueryTagGroupArgs = {
  id: Scalars['ID']
}

export type QueryTagGroupsArgs = {
  searchByName?: InputMaybe<Scalars['String']>
}

export type QueryTagsArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>
  searchByName?: InputMaybe<Scalars['String']>
}

export type QueryTransactionArgs = {
  id: Scalars['ID']
}

export type QueryTransactionsArgs = {
  filter: TransactionFilter
}

export type QueryUnseenUserNeedsArgs = {
  filter: UnseenUserNeedsInput
}

export type QueryUserArgs = {
  id: Scalars['ID']
}

export type QueryUserNeedArgs = {
  id: Scalars['ID']
}

export type QueryUserNeedCategorySummaryArgs = {
  filter: UserNeedsCategorySummaryInput
}

export type QueryUserNeedForCoupleArgs = {
  needId: Scalars['ID']
}

export type QueryUserNeedsArgs = {
  filter: UserNeedsPaginationInput
}

export type QueryUsersArgs = {
  input: UserFilter
}

export type QueryWalletArgs = {
  id: Scalars['ID']
}

export type QueryWeddingByIdArgs = {
  id: Scalars['ID']
}

export type QueryWeddingsByNeedArgs = {
  id: Scalars['ID']
}

export type Question = {
  __typename?: 'Question'
  createdAt?: Maybe<Scalars['DateTime']>
  fields: Array<QuestionField>
  helperText?: Maybe<Scalars['String']>
  id: Scalars['ID']
  title: Scalars['String']
  updatedAt: Scalars['DateTime']
}

export type QuestionField = {
  __typename?: 'QuestionField'
  label?: Maybe<Scalars['String']>
  name: Scalars['String']
  placeholder: Scalars['String']
  props?: Maybe<Array<Maybe<QuestionProp>>>
  type: QuestionType
}

export type QuestionFieldInput = {
  label?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  placeholder?: InputMaybe<Scalars['String']>
  props?: InputMaybe<Array<InputMaybe<QuestionFieldPropOptionInput>>>
  type?: InputMaybe<QuestionType>
}

export type QuestionFieldPropOptionInput = {
  currency?: InputMaybe<Scalars['Boolean']>
  max?: InputMaybe<Scalars['Int']>
  min?: InputMaybe<Scalars['Int']>
  options?: InputMaybe<Array<InputMaybe<QuestionPropOptionInput>>>
  step?: InputMaybe<Scalars['Int']>
}

export type QuestionInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>
  fields: Array<QuestionFieldInput>
  helperText?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['ID']>
  title?: InputMaybe<Scalars['String']>
  updatedAt?: InputMaybe<Scalars['DateTime']>
}

export type QuestionProp = {
  __typename?: 'QuestionProp'
  currency?: Maybe<Scalars['Boolean']>
  max?: Maybe<Scalars['Int']>
  min?: Maybe<Scalars['Int']>
  options?: Maybe<Array<Maybe<QuestionPropOption>>>
  step?: Maybe<Scalars['Int']>
}

export type QuestionPropOption = {
  __typename?: 'QuestionPropOption'
  label?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

export type QuestionPropOptionInput = {
  label?: InputMaybe<Scalars['String']>
  value?: InputMaybe<Scalars['String']>
}

export type QuestionResponse = {
  __typename?: 'QuestionResponse'
  CHECK?: Maybe<Scalars['Boolean']>
  CHECK_GROUP?: Maybe<Array<Scalars['String']>>
  CHECK_GROUP_INLINE?: Maybe<Array<Scalars['String']>>
  CURRENCY?: Maybe<Scalars['Float']>
  DATE?: Maybe<Scalars['String']>
  NUMBER?: Maybe<Scalars['Float']>
  RADIO_GROUP?: Maybe<Scalars['String']>
  RANGE?: Maybe<Range>
  RANGE_CURRENCY?: Maybe<Range>
  TEXT?: Maybe<Scalars['String']>
  TEXT_LONG?: Maybe<Scalars['String']>
  TIME?: Maybe<Scalars['String']>
  question?: Maybe<Question>
  questionId: Scalars['String']
}

export type QuestionResponseInput = {
  CHECK?: InputMaybe<Scalars['Boolean']>
  CHECK_GROUP?: InputMaybe<Array<Scalars['String']>>
  CHECK_GROUP_INLINE?: InputMaybe<Array<Scalars['String']>>
  CURRENCY?: InputMaybe<Scalars['Float']>
  DATE?: InputMaybe<Scalars['String']>
  NUMBER?: InputMaybe<Scalars['Float']>
  RADIO_GROUP?: InputMaybe<Scalars['String']>
  RANGE?: InputMaybe<RangeInput>
  RANGE_CURRENCY?: InputMaybe<RangeInput>
  TEXT?: InputMaybe<Scalars['String']>
  TEXT_LONG?: InputMaybe<Scalars['String']>
  TIME?: InputMaybe<Scalars['String']>
  questionId?: InputMaybe<Scalars['String']>
}

export enum QuestionType {
  Check = 'CHECK',
  CheckGroup = 'CHECK_GROUP',
  CheckGroupInline = 'CHECK_GROUP_INLINE',
  Currency = 'CURRENCY',
  Date = 'DATE',
  Number = 'NUMBER',
  RadioGroup = 'RADIO_GROUP',
  Range = 'RANGE',
  RangeCurrency = 'RANGE_CURRENCY',
  Text = 'TEXT',
  TextLong = 'TEXT_LONG',
  Time = 'TIME',
}

export type QuoteAttachment = {
  __typename?: 'QuoteAttachment'
  hash: Scalars['String']
  name: Scalars['String']
}

export type QuoteAttachmentInput = {
  hash: Scalars['String']
  name: Scalars['String']
}

export type QuoteRequest = {
  __typename?: 'QuoteRequest'
  attachments?: Maybe<Array<Maybe<Scalars['String']>>>
  category: Scalars['ID']
  conversation?: Maybe<Conversation>
  couple: Couple
  coupleId: Scalars['ID']
  createdAt: Scalars['DateTime']
  id: Scalars['ID']
  partner: Partner
  partnerId: Scalars['ID']
  quoteResponse?: Maybe<QuoteResponse>
  responseId?: Maybe<Scalars['ID']>
  seenAt?: Maybe<Scalars['DateTime']>
  status: QuoteRequestStatus
  text?: Maybe<Scalars['String']>
  type: QuoteType
  updatedAt: Scalars['DateTime']
  userNeedCopy?: Maybe<UserNeedCopy>
  weddingCopy?: Maybe<WeddingCopy>
}

export type QuoteRequestFilter = {
  category?: InputMaybe<Scalars['ID']>
  filter?: InputMaybe<Scalars['String']>
  filterType?: InputMaybe<Scalars['String']>
  limit: Scalars['Int']
  offset?: InputMaybe<Scalars['Int']>
  search?: InputMaybe<Scalars['String']>
  sortBy?: InputMaybe<Scalars['String']>
  sortDirection?: InputMaybe<SortDirection>
  status?: InputMaybe<QuoteRequestStatus>
  statuses?: InputMaybe<Array<InputMaybe<QuoteRequestStatus>>>
  type?: InputMaybe<QuoteType>
  types?: InputMaybe<Array<QuoteType>>
}

export enum QuoteRequestStatus {
  Accepted = 'ACCEPTED',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
}

export type QuoteResponse = {
  __typename?: 'QuoteResponse'
  address?: Maybe<Scalars['String']>
  attachments?: Maybe<Array<Maybe<QuoteAttachment>>>
  coupleId: Scalars['ID']
  createdAt: Scalars['DateTime']
  email?: Maybe<Scalars['String']>
  feedback?: Maybe<QuoteResponseFeedback>
  fixPrice?: Maybe<Scalars['Int']>
  id: Scalars['ID']
  name?: Maybe<Scalars['String']>
  partner: Partner
  partnerId: Scalars['ID']
  phone?: Maybe<Scalars['String']>
  priceRange?: Maybe<Range>
  quoteRequest: QuoteRequest
  reasons?: Maybe<Array<Scalars['String']>>
  requestId: Scalars['ID']
  seenAt?: Maybe<Scalars['DateTime']>
  sendEmail?: Maybe<Scalars['Boolean']>
  status: QuoteResponseStatus
  text?: Maybe<Scalars['String']>
  updatedAt: Scalars['DateTime']
}

export type QuoteResponseFeedback = {
  __typename?: 'QuoteResponseFeedback'
  option: Scalars['String']
}

export enum QuoteResponseStatus {
  Accepted = 'ACCEPTED',
  Rejected = 'REJECTED',
}

export enum QuoteType {
  Direct = 'DIRECT',
  ForYou = 'FOR_YOU',
  Wall = 'WALL',
}

export type Range = {
  __typename?: 'Range'
  high: Scalars['Int']
  low: Scalars['Int']
}

export type RangeInput = {
  high: Scalars['Int']
  low: Scalars['Int']
}

export type RequestAvailabilityInput = {
  date: Scalars['DateTime']
  partner: Scalars['ID']
}

export type SendNotificationInput = {
  body: Scalars['String']
  payload?: InputMaybe<Scalars['String']>
  target: Scalars['String']
  targetUserId?: InputMaybe<Scalars['ID']>
  title: Scalars['String']
  type?: InputMaybe<Scalars['String']>
}

/** Service  */
export type Service = {
  __typename?: 'Service'
  active?: Maybe<Scalars['Boolean']>
  createdAt: Scalars['DateTime']
  id: Scalars['ID']
  name: Scalars['String']
  ordering?: Maybe<Scalars['Int']>
  updatedAt: Scalars['DateTime']
}

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type StartTransactionInput = {
  invoice: InvoiceInput
  items: Array<PaymentItem>
  url: Scalars['String']
}

export type StartTransactionResult = {
  __typename?: 'StartTransactionResult'
  paymentUrl: Scalars['String']
  transactionId: Scalars['Int']
}

export enum Status {
  Active = 'ACTIVE',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
}

/** tag  */
export type Tag = {
  __typename?: 'Tag'
  categories: Array<Category>
  createdAt?: Maybe<Scalars['DateTime']>
  hide?: Maybe<Scalars['Boolean']>
  id: Scalars['ID']
  items: Array<Item>
  name?: Maybe<Scalars['String']>
  priority?: Maybe<Scalars['Int']>
  updatedAt: Scalars['DateTime']
}

export type TagGroup = {
  __typename?: 'TagGroup'
  category?: Maybe<Category>
  createdAt: Scalars['DateTime']
  id: Scalars['ID']
  image: Image
  name: Scalars['String']
  tags: Array<Tag>
  updatedAt: Scalars['DateTime']
}

export type TagGroupFilter = {
  filter?: InputMaybe<Scalars['String']>
  limit: Scalars['Int']
  offset?: InputMaybe<Scalars['Int']>
  searchByName?: InputMaybe<Scalars['String']>
  sortBy?: InputMaybe<Scalars['String']>
  sortDirection?: InputMaybe<SortDirection>
}

export type TagGroupInput = {
  category: Scalars['ID']
  createdAt?: InputMaybe<Scalars['DateTime']>
  id?: InputMaybe<Scalars['ID']>
  image: Scalars['String']
  name?: InputMaybe<Scalars['String']>
  tags: Array<Scalars['String']>
  updatedAt?: InputMaybe<Scalars['DateTime']>
}

export type TagInput = {
  hide?: InputMaybe<Scalars['Boolean']>
  items?: InputMaybe<Array<Scalars['String']>>
  name: Scalars['String']
  priority?: InputMaybe<Scalars['Int']>
}

export type Transaction = {
  __typename?: 'Transaction'
  amount: Scalars['Int']
  createdAt: Scalars['DateTime']
  details: TransactionDetail
  id: Scalars['ID']
  note?: Maybe<Scalars['String']>
  updatedAt: Scalars['DateTime']
  wallet: Wallet
}

export type TransactionDetail = {
  __typename?: 'TransactionDetail'
  data?: Maybe<QuoteResponse>
  remainingTokens: Scalars['Float']
  type: TransactionType
}

export type TransactionFilter = {
  filter?: InputMaybe<Scalars['String']>
  limit: Scalars['Int']
  offset?: InputMaybe<Scalars['Int']>
  sortBy?: InputMaybe<Scalars['String']>
  sortDirection?: InputMaybe<SortDirection>
}

export enum TransactionType {
  QuoteResponse = 'QUOTE_RESPONSE',
  TopUp = 'TOP_UP',
}

export type UnseenUserNeedsInput = {
  category?: InputMaybe<Scalars['ID']>
  needIds?: InputMaybe<Array<Scalars['ID']>>
  status: UserNeedStatus
}

export type UpdateAdminInput = {
  name?: InputMaybe<Scalars['String']>
  phone?: InputMaybe<Scalars['String']>
}

export type UpdateAvailabilityInput = {
  date?: InputMaybe<Scalars['DateTime']>
  partner?: InputMaybe<Scalars['ID']>
  status?: InputMaybe<AvailabilityStatus>
  user?: InputMaybe<Scalars['ID']>
}

export type UpdateBlogInput = {
  author?: InputMaybe<Scalars['String']>
  content?: InputMaybe<Scalars['String']>
  coverImage?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  name?: InputMaybe<Scalars['String']>
  ordering?: InputMaybe<Scalars['Int']>
}

export type UpdateCategoryInput = {
  extraFields?: InputMaybe<Array<Scalars['String']>>
  hide?: InputMaybe<Scalars['Boolean']>
  id: Scalars['ID']
  image: Scalars['String']
  name?: InputMaybe<Scalars['String']>
  ordering?: InputMaybe<Scalars['Int']>
  tags?: InputMaybe<Array<Scalars['ID']>>
}

export type UpdateConversationInput = {
  id: Scalars['ID']
  participants?: InputMaybe<Array<Scalars['String']>>
}

export type UpdateCostInput = {
  amount?: InputMaybe<Scalars['Int']>
  icon?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  name?: InputMaybe<Scalars['String']>
}

export type UpdateCoupleInput = {
  adminNote?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  likedItems?: InputMaybe<Array<Scalars['String']>>
  likedPartners?: InputMaybe<Array<Scalars['String']>>
  myName?: InputMaybe<Scalars['String']>
  needs?: InputMaybe<Array<Scalars['ID']>>
  partnersName?: InputMaybe<Scalars['String']>
  profileImage?: InputMaybe<Scalars['String']>
  wedding?: InputMaybe<UpdateWeddingInput>
  whoAreYou?: InputMaybe<CoupleRole>
}

export type UpdateGuestInput = {
  email?: InputMaybe<Scalars['String']>
  icon?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  invitation?: InputMaybe<Scalars['ID']>
  name?: InputMaybe<Scalars['String']>
}

export type UpdateMessageInput = {
  attachment?: InputMaybe<AttachmentInput>
  id: Scalars['ID']
  seenAt?: InputMaybe<Scalars['DateTime']>
  text?: InputMaybe<Scalars['String']>
}

export type UpdateNeedInput = {
  category?: InputMaybe<Scalars['ID']>
  hide?: InputMaybe<Scalars['Boolean']>
  name?: InputMaybe<Scalars['String']>
  questions?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type UpdatePartnerInput = {
  areaLocation?: InputMaybe<AreaLocationInput>
  availableWeekdays?: InputMaybe<Array<WeekDay>>
  category?: InputMaybe<Scalars['ID']>
  class?: InputMaybe<PartnerClass>
  contactEmail?: InputMaybe<Scalars['String']>
  contactName?: InputMaybe<Scalars['String']>
  contactPhone?: InputMaybe<Scalars['String']>
  coverImages?: InputMaybe<Array<Scalars['String']>>
  description?: InputMaybe<Scalars['String']>
  emailPreferences?: InputMaybe<EmailPreferencesInput>
  fixLocation?: InputMaybe<FixLocationInput>
  guestRange?: InputMaybe<RangeInput>
  id: Scalars['ID']
  items?: InputMaybe<Array<Scalars['ID']>>
  likedItems?: InputMaybe<Array<Scalars['String']>>
  likedPartners?: InputMaybe<Array<Scalars['String']>>
  name?: InputMaybe<Scalars['String']>
  needs?: InputMaybe<Array<Scalars['ID']>>
  phone?: InputMaybe<Scalars['String']>
  profileImage?: InputMaybe<Scalars['String']>
  seatingCapacity?: InputMaybe<Scalars['Int']>
  services?: InputMaybe<Array<Scalars['ID']>>
  standingCapacity?: InputMaybe<Scalars['Int']>
  tags?: InputMaybe<Array<Scalars['ID']>>
  verified?: InputMaybe<Scalars['Boolean']>
  website?: InputMaybe<Scalars['String']>
}

export type UpdateUserInput = {
  active?: InputMaybe<Scalars['Boolean']>
  admin?: InputMaybe<UpdateAdminInput>
  cognitoId?: InputMaybe<Scalars['String']>
  couple?: InputMaybe<UpdateCoupleInput>
  devices?: InputMaybe<Array<DeviceInput>>
  email?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  name?: InputMaybe<Scalars['String']>
  partner?: InputMaybe<UpdatePartnerInput>
  profileImage?: InputMaybe<Scalars['String']>
  role?: InputMaybe<UserRole>
  status?: InputMaybe<Status>
  type?: InputMaybe<UserType>
}

export type UpdateWeddingInput = {
  address?: InputMaybe<LocationInput>
  addressNote?: InputMaybe<Scalars['String']>
  budgetRange?: InputMaybe<RangeInput>
  coupleId?: InputMaybe<Scalars['ID']>
  coverImage?: InputMaybe<Scalars['String']>
  date?: InputMaybe<Scalars['DateTime']>
  description?: InputMaybe<Scalars['String']>
  guestRange?: InputMaybe<RangeInput>
  id?: InputMaybe<Scalars['ID']>
  locationTags?: InputMaybe<Array<Scalars['String']>>
  needs?: InputMaybe<Array<Scalars['ID']>>
  possibleAddresses?: InputMaybe<Array<LocationInput>>
  possibleDays?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  possibleMonths?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  possibleYear?: InputMaybe<Scalars['String']>
}

export type User = Admin | Couple | Partner

export type UserFilter = {
  active?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Scalars['String']>
  limit: Scalars['Int']
  offset?: InputMaybe<Scalars['Int']>
  searchByName?: InputMaybe<Scalars['String']>
  sortBy?: InputMaybe<Scalars['String']>
  sortDirection?: InputMaybe<SortDirection>
}

export type UserNeed = {
  __typename?: 'UserNeed'
  acceptingQuotes: Scalars['Boolean']
  adminText?: Maybe<Scalars['String']>
  approvedAt?: Maybe<Scalars['DateTime']>
  category: Scalars['ID']
  couple: Scalars['ID']
  createdAt: Scalars['DateTime']
  directQuotesCount: Scalars['Float']
  forYouQuotesCount: Scalars['Float']
  hasSentQuote: Scalars['Boolean']
  id: Scalars['ID']
  images: Array<Image>
  isNew: Scalars['Boolean']
  need: Need
  origin: UserNeedOrigin
  partnersForYou: Array<ForYou>
  progress: Scalars['Float']
  questionResponses: Array<Array<QuestionResponse>>
  quoteResponses: Array<QuoteResponse>
  quotesCount: Scalars['Float']
  status: UserNeedStatus
  text?: Maybe<Scalars['String']>
  updatedAt: Scalars['DateTime']
  wallQuotesCount: Scalars['Float']
  wedding: Wedding
}

export type UserNeedCategory = {
  __typename?: 'UserNeedCategory'
  amount: Scalars['Float']
  category: Scalars['String']
  id: Scalars['ID']
}

export type UserNeedCopy = {
  __typename?: 'UserNeedCopy'
  acceptingQuotes: Scalars['Boolean']
  adminText?: Maybe<Scalars['String']>
  approvedAt?: Maybe<Scalars['DateTime']>
  couple: Scalars['ID']
  images: Array<Image>
  need: Need
  origin: UserNeedOrigin
  progress: Scalars['Float']
  questionResponses: Array<Array<QuestionResponse>>
  status: UserNeedStatus
  text?: Maybe<Scalars['String']>
}

export type UserNeedCopyInput = {
  acceptingQuotes?: InputMaybe<Scalars['Boolean']>
  adminText?: InputMaybe<Scalars['String']>
  approvedAt?: InputMaybe<Scalars['DateTime']>
  couple?: InputMaybe<Scalars['ID']>
  images?: InputMaybe<Array<Scalars['String']>>
  need: Scalars['ID']
  origin?: InputMaybe<UserNeedOrigin>
  progress?: InputMaybe<Scalars['Float']>
  questionResponses: Array<Array<QuestionResponseInput>>
  status?: InputMaybe<UserNeedStatus>
  text?: InputMaybe<Scalars['String']>
}

export type UserNeedInput = {
  adminText?: InputMaybe<Scalars['String']>
  approvedAt?: InputMaybe<Scalars['DateTime']>
  category: Scalars['ID']
  couple?: InputMaybe<Scalars['ID']>
  images?: InputMaybe<Array<Scalars['String']>>
  need: Scalars['ID']
  partnersForYou?: InputMaybe<Array<InputMaybe<ForYouInput>>>
  questionResponses: Array<Array<QuestionResponseInput>>
  status: UserNeedStatus
  text?: InputMaybe<Scalars['String']>
}

export enum UserNeedOrigin {
  Onboarding = 'ONBOARDING',
  Quote = 'QUOTE',
  Settings = 'SETTINGS',
}

export enum UserNeedStatus {
  Accepted = 'ACCEPTED',
  Archived = 'ARCHIVED',
  Deleted = 'DELETED',
  Pending = 'PENDING',
}

export type UserNeedsCategorySummaryInput = {
  status: UserNeedStatus
}

export type UserNeedsPaginationInput = {
  category?: InputMaybe<Scalars['ID']>
  complete?: InputMaybe<Scalars['Boolean']>
  couple?: InputMaybe<Array<Scalars['ID']>>
  filter?: InputMaybe<Scalars['String']>
  limit: Scalars['Int']
  needIds?: InputMaybe<Array<Scalars['ID']>>
  offset?: InputMaybe<Scalars['Int']>
  sortBy?: InputMaybe<Scalars['String']>
  sortDirection?: InputMaybe<SortDirection>
  status?: InputMaybe<UserNeedStatus>
}

export enum UserRole {
  Admin = 'ADMIN',
  Partner = 'PARTNER',
  User = 'USER',
}

export enum UserType {
  Admin = 'ADMIN',
  Couple = 'COUPLE',
  Partner = 'PARTNER',
}

export type ValidateTransactionInput = {
  r: Scalars['String']
  s: Scalars['String']
}

export type ValidateTransactionResult = {
  __typename?: 'ValidateTransactionResult'
  orderRef: Scalars['String']
  status: Scalars['String']
  success: Scalars['Boolean']
  transactionId: Scalars['String']
}

export type Wallet = {
  __typename?: 'Wallet'
  balance: Scalars['Int']
  createdAt?: Maybe<Scalars['DateTime']>
  id: Scalars['ID']
  partner: Partner
  partnerId: Scalars['ID']
  transactions: Array<Transaction>
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type Wedding = {
  __typename?: 'Wedding'
  address?: Maybe<Location>
  addressNote?: Maybe<Scalars['String']>
  budgetRange?: Maybe<Range>
  couple: Couple
  coupleId: Scalars['ID']
  coverImage?: Maybe<Image>
  createdAt: Scalars['DateTime']
  date?: Maybe<Scalars['DateTime']>
  description?: Maybe<Scalars['String']>
  guestRange?: Maybe<Range>
  guests: Array<Guest>
  id: Scalars['ID']
  locationTags?: Maybe<Array<Tag>>
  possibleAddresses?: Maybe<Array<Location>>
  possibleDays?: Maybe<Array<Maybe<Scalars['Int']>>>
  possibleMonths?: Maybe<Array<Maybe<Scalars['Int']>>>
  possibleYear?: Maybe<Scalars['String']>
  updatedAt: Scalars['DateTime']
}

export type WeddingCopy = {
  __typename?: 'WeddingCopy'
  address?: Maybe<Location>
  addressNote?: Maybe<Scalars['String']>
  budgetRange?: Maybe<Range>
  date?: Maybe<Scalars['DateTime']>
  guestRange?: Maybe<Range>
  locationTags?: Maybe<Array<Tag>>
  possibleAddresses?: Maybe<Array<Location>>
  possibleDays?: Maybe<Array<Scalars['Int']>>
  possibleMonths?: Maybe<Array<Scalars['Int']>>
  possibleYear?: Maybe<Scalars['String']>
}

export type WeddingCopyInput = {
  address?: InputMaybe<LocationInput>
  addressNote?: InputMaybe<Scalars['String']>
  budgetRange?: InputMaybe<RangeInput>
  date?: InputMaybe<Scalars['DateTime']>
  guestRange?: InputMaybe<RangeInput>
  locationTags?: InputMaybe<Array<Scalars['String']>>
  possibleAddresses?: InputMaybe<Array<LocationInput>>
  possibleDays?: InputMaybe<Array<Scalars['Int']>>
  possibleMonths?: InputMaybe<Array<Scalars['Int']>>
  possibleYear?: InputMaybe<Scalars['String']>
}

export enum WeekDay {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
}

export type SearchBlogsQueryVariables = Exact<{
  input: BlogFilter
}>

export type SearchBlogsQuery = {
  __typename?: 'Query'
  paginatedBlogs: {
    __typename?: 'PaginatedBlog'
    elements?: Array<{
      __typename?: 'Blog'
      id: string
      name: string
      author: string
      ordering: number
      content: string
      coverImage: { __typename?: 'Image'; name: string }
    }> | null
  }
}

export type SearchCategoriesQueryVariables = Exact<{
  input: CategoryFilter
}>

export type SearchCategoriesQuery = {
  __typename?: 'Query'
  paginatedCategories: {
    __typename?: 'PaginatedCategory'
    elements?: Array<{
      __typename?: 'Category'
      id: string
      name: string
      ordering: number
      extraFields: Array<string>
      hide: boolean
      image: { __typename?: 'Image'; name: string }
      tags: Array<{ __typename?: 'Tag'; id: string; name?: string | null }>
    }> | null
  }
}

export type PaginatedNeedsQueryVariables = Exact<{
  input: PaginatedNeedInput
}>

export type PaginatedNeedsQuery = {
  __typename?: 'Query'
  paginatedNeeds: {
    __typename?: 'PaginatedNeed'
    total: number
    elements?: Array<{ __typename?: 'Need'; id: string; name: string; updatedAt: any }> | null
  }
}

export type NeedsQueryVariables = Exact<{ [key: string]: never }>

export type NeedsQuery = {
  __typename?: 'Query'
  needs: Array<{ __typename?: 'Need'; id: string; name: string }>
}

export type NeedQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type NeedQuery = {
  __typename?: 'Query'
  need: {
    __typename?: 'Need'
    id: string
    name: string
    hide?: boolean | null
    category: { __typename?: 'Category'; id: string; name: string }
  }
}

export type UserNeedForAdminQueryVariables = Exact<{
  userNeedId: Scalars['ID']
}>

export type UserNeedForAdminQuery = {
  __typename?: 'Query'
  userNeed: {
    __typename?: 'UserNeed'
    id: string
    category: string
    status: UserNeedStatus
    directQuotesCount: number
    wallQuotesCount: number
    forYouQuotesCount: number
    quotesCount: number
    origin: UserNeedOrigin
    acceptingQuotes: boolean
    text?: string | null
    adminText?: string | null
    progress: number
    createdAt: any
    approvedAt?: any | null
    images: Array<{ __typename?: 'Image'; name: string }>
    partnersForYou: Array<{
      __typename?: 'ForYou'
      id: string
      status: QuoteRequestStatus
      partner: { __typename?: 'Partner'; id: string; name: string }
    }>
    questionResponses: Array<
      Array<{
        __typename?: 'QuestionResponse'
        questionId: string
        TEXT?: string | null
        TEXT_LONG?: string | null
        NUMBER?: number | null
        TIME?: string | null
        DATE?: string | null
        CURRENCY?: number | null
        CHECK_GROUP?: Array<string> | null
        CHECK_GROUP_INLINE?: Array<string> | null
        CHECK?: boolean | null
        RADIO_GROUP?: string | null
        question?: {
          __typename?: 'Question'
          id: string
          title: string
          fields: Array<{ __typename?: 'QuestionField'; label?: string | null }>
        } | null
        RANGE?: { __typename?: 'Range'; low: number; high: number } | null
        RANGE_CURRENCY?: { __typename?: 'Range'; low: number; high: number } | null
      }>
    >
    quoteResponses: Array<{
      __typename?: 'QuoteResponse'
      id: string
      requestId: string
      status: QuoteResponseStatus
      createdAt: any
      quoteRequest: { __typename?: 'QuoteRequest'; type: QuoteType }
      partner: { __typename?: 'Partner'; name: string }
    }>
    need: {
      __typename?: 'Need'
      id: string
      name: string
      questions: Array<{ __typename?: 'Question'; id: string }>
    }
    wedding: {
      __typename?: 'Wedding'
      possibleYear?: string | null
      possibleMonths?: Array<number | null> | null
      possibleDays?: Array<number | null> | null
      date?: any | null
      couple: {
        __typename?: 'Couple'
        id: string
        name: string
        createdAt?: any | null
        email: string
      }
      guestRange?: { __typename?: 'Range'; low: number; high: number } | null
      possibleAddresses?: Array<{ __typename?: 'Location'; name: string }> | null
      locationTags?: Array<{ __typename?: 'Tag'; id: string; name?: string | null }> | null
      address?: {
        __typename?: 'Location'
        name: string
        coordinate?: { __typename?: 'Coordinate'; lat: number; lng: number } | null
      } | null
    }
  }
}

export type UserNeedsCategorySummaryQueryVariables = Exact<{
  filter: UserNeedsCategorySummaryInput
}>

export type UserNeedsCategorySummaryQuery = {
  __typename?: 'Query'
  userNeedCategorySummary: Array<{
    __typename?: 'UserNeedCategory'
    id: string
    category: string
    amount: number
  }>
}

export type UserNeedForPartnerQueryVariables = Exact<{
  userNeedId: Scalars['ID']
}>

export type UserNeedForPartnerQuery = {
  __typename?: 'Query'
  userNeed: {
    __typename?: 'UserNeed'
    id: string
    category: string
    status: UserNeedStatus
    quotesCount: number
    origin: UserNeedOrigin
    acceptingQuotes: boolean
    text?: string | null
    adminText?: string | null
    approvedAt?: any | null
    progress: number
    createdAt: any
    images: Array<{ __typename?: 'Image'; name: string }>
    partnersForYou: Array<{
      __typename?: 'ForYou'
      id: string
      partner: { __typename?: 'Partner'; id: string; name: string }
    }>
    questionResponses: Array<
      Array<{
        __typename?: 'QuestionResponse'
        questionId: string
        TEXT?: string | null
        TEXT_LONG?: string | null
        NUMBER?: number | null
        TIME?: string | null
        DATE?: string | null
        CURRENCY?: number | null
        CHECK_GROUP?: Array<string> | null
        CHECK_GROUP_INLINE?: Array<string> | null
        CHECK?: boolean | null
        RADIO_GROUP?: string | null
        question?: {
          __typename?: 'Question'
          id: string
          title: string
          fields: Array<{ __typename?: 'QuestionField'; label?: string | null }>
        } | null
        RANGE?: { __typename?: 'Range'; low: number; high: number } | null
        RANGE_CURRENCY?: { __typename?: 'Range'; low: number; high: number } | null
      }>
    >
    need: {
      __typename?: 'Need'
      id: string
      name: string
      questions: Array<{ __typename?: 'Question'; id: string }>
    }
    wedding: {
      __typename?: 'Wedding'
      possibleYear?: string | null
      possibleMonths?: Array<number | null> | null
      possibleDays?: Array<number | null> | null
      date?: any | null
      couple: {
        __typename?: 'Couple'
        id: string
        name: string
        email: string
        createdAt?: any | null
      }
      guestRange?: { __typename?: 'Range'; low: number; high: number } | null
      possibleAddresses?: Array<{ __typename?: 'Location'; name: string }> | null
      locationTags?: Array<{ __typename?: 'Tag'; id: string; name?: string | null }> | null
      address?: {
        __typename?: 'Location'
        name: string
        coordinate?: { __typename?: 'Coordinate'; lat: number; lng: number } | null
      } | null
    }
  }
}

export type UserNeedsForAdminQueryVariables = Exact<{
  filter: UserNeedsPaginationInput
}>

export type UserNeedsForAdminQuery = {
  __typename?: 'Query'
  userNeeds: {
    __typename?: 'PaginatedUserNeed'
    total: number
    elements?: Array<{
      __typename?: 'UserNeed'
      id: string
      category: string
      status: UserNeedStatus
      isNew: boolean
      quotesCount: number
      directQuotesCount: number
      wallQuotesCount: number
      forYouQuotesCount: number
      origin: UserNeedOrigin
      acceptingQuotes: boolean
      progress: number
      createdAt: any
      approvedAt?: any | null
      wedding: {
        __typename?: 'Wedding'
        id: string
        date?: any | null
        possibleYear?: string | null
        couple: {
          __typename?: 'Couple'
          name: string
          myName: string
          partnersName: string
          email: string
          createdAt?: any | null
          id: string
        }
        guestRange?: { __typename?: 'Range'; low: number; high: number } | null
        address?: { __typename?: 'Location'; name: string } | null
        possibleAddresses?: Array<{ __typename?: 'Location'; name: string }> | null
      }
      need: {
        __typename?: 'Need'
        id: string
        name: string
        category: { __typename?: 'Category'; image: { __typename?: 'Image'; name: string } }
        questions: Array<{ __typename?: 'Question'; id: string }>
      }
      questionResponses: Array<
        Array<{
          __typename?: 'QuestionResponse'
          questionId: string
          TEXT?: string | null
          TEXT_LONG?: string | null
          NUMBER?: number | null
          TIME?: string | null
          DATE?: string | null
          CURRENCY?: number | null
          CHECK_GROUP?: Array<string> | null
          CHECK_GROUP_INLINE?: Array<string> | null
          CHECK?: boolean | null
          RADIO_GROUP?: string | null
          RANGE?: { __typename?: 'Range'; low: number; high: number } | null
          RANGE_CURRENCY?: { __typename?: 'Range'; low: number; high: number } | null
        }>
      >
      quoteResponses: Array<{ __typename?: 'QuoteResponse'; id: string }>
    }> | null
  }
}

export type UserNeedsForPartnerQueryVariables = Exact<{
  filter: UserNeedsPaginationInput
}>

export type UserNeedsForPartnerQuery = {
  __typename?: 'Query'
  userNeeds: {
    __typename?: 'PaginatedUserNeed'
    total: number
    elements?: Array<{
      __typename?: 'UserNeed'
      id: string
      category: string
      status: UserNeedStatus
      createdAt: any
      isNew: boolean
      quotesCount: number
      origin: UserNeedOrigin
      acceptingQuotes: boolean
      approvedAt?: any | null
      hasSentQuote: boolean
      progress: number
      wedding: {
        __typename?: 'Wedding'
        id: string
        date?: any | null
        possibleYear?: string | null
        couple: {
          __typename?: 'Couple'
          name: string
          myName: string
          partnersName: string
          email: string
          createdAt?: any | null
          id: string
        }
        guestRange?: { __typename?: 'Range'; low: number; high: number } | null
        address?: { __typename?: 'Location'; name: string } | null
        possibleAddresses?: Array<{ __typename?: 'Location'; name: string }> | null
      }
      need: {
        __typename?: 'Need'
        id: string
        name: string
        category: { __typename?: 'Category'; image: { __typename?: 'Image'; name: string } }
        questions: Array<{ __typename?: 'Question'; id: string }>
      }
      questionResponses: Array<
        Array<{
          __typename?: 'QuestionResponse'
          questionId: string
          TEXT?: string | null
          TEXT_LONG?: string | null
          NUMBER?: number | null
          TIME?: string | null
          DATE?: string | null
          CURRENCY?: number | null
          CHECK_GROUP?: Array<string> | null
          CHECK_GROUP_INLINE?: Array<string> | null
          CHECK?: boolean | null
          RADIO_GROUP?: string | null
          RANGE?: { __typename?: 'Range'; low: number; high: number } | null
          RANGE_CURRENCY?: { __typename?: 'Range'; low: number; high: number } | null
        }>
      >
    }> | null
  }
}

export type InsertNeedMutationVariables = Exact<{
  input: NewNeedInput
}>

export type InsertNeedMutation = {
  __typename?: 'Mutation'
  insertNeed: {
    __typename?: 'Need'
    id: string
    name: string
    category: { __typename?: 'Category'; id: string; name: string }
  }
}

export type UpdateNeedMutationVariables = Exact<{
  id: Scalars['ID']
  input: UpdateNeedInput
}>

export type UpdateNeedMutation = {
  __typename?: 'Mutation'
  updateNeed: {
    __typename?: 'Need'
    id: string
    name: string
    category: { __typename?: 'Category'; id: string; name: string }
  }
}

export type DeleteNeedMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type DeleteNeedMutation = { __typename?: 'Mutation'; deleteNeed: boolean }

export type WeddingsByNeedQueryVariables = Exact<{
  weddingsByNeedId: Scalars['ID']
}>

export type WeddingsByNeedQuery = {
  __typename?: 'Query'
  weddingsByNeed: Array<{
    __typename?: 'Wedding'
    id: string
    date?: any | null
    createdAt: any
    couple: {
      __typename?: 'Couple'
      id: string
      name: string
      email: string
      status: Status
      active: boolean
    }
  }>
}

export type SeenUserNeedMutationVariables = Exact<{
  seenUserNeedId: Scalars['ID']
}>

export type SeenUserNeedMutation = { __typename?: 'Mutation'; seenUserNeed: boolean }

export type UpdateUserNeedMutationVariables = Exact<{
  updateUserNeedId: Scalars['ID']
  input: UserNeedInput
}>

export type UpdateUserNeedMutation = {
  __typename?: 'Mutation'
  updateUserNeed: {
    __typename?: 'UserNeed'
    id: string
    text?: string | null
    adminText?: string | null
  }
}

export type SearchPartnersQueryVariables = Exact<{
  input: PartnerFilter
}>

export type SearchPartnersQuery = {
  __typename?: 'Query'
  partners: {
    __typename?: 'PaginatedPartner'
    elements?: Array<{
      __typename?: 'Partner'
      id: string
      name: string
      category?: { __typename?: 'Category'; id: string } | null
    }> | null
  }
}

export type PaymentsQueryVariables = Exact<{
  filter: PaymentFilter
}>

export type PaymentsQuery = {
  __typename?: 'Query'
  payments: {
    __typename?: 'PaginatedPayment'
    total: number
    elements?: Array<{
      __typename?: 'Payment'
      id: string
      transactionId?: string | null
      items: Array<PaymentItem>
      status: PaymentStatus
      note?: string | null
      createdAt: any
      updatedAt: any
      user:
        | { __typename?: 'Admin' }
        | { __typename?: 'Couple' }
        | { __typename?: 'Partner'; id: string; name: string; email: string }
      invoice: {
        __typename?: 'Invoice'
        type: InvoiceType
        name: string
        company: string
        country: string
        state: string
        city: string
        zip: string
        address: string
        phone: string
        taxNumber: string
      }
    }> | null
  }
}

export type StartTransactionMutationVariables = Exact<{
  input: StartTransactionInput
}>

export type StartTransactionMutation = {
  __typename?: 'Mutation'
  startTransaction: { __typename?: 'StartTransactionResult'; paymentUrl: string }
}

export type ValidateTransactionMutationVariables = Exact<{
  input: ValidateTransactionInput
}>

export type ValidateTransactionMutation = {
  __typename?: 'Mutation'
  validateTransaction: {
    __typename?: 'ValidateTransactionResult'
    success: boolean
    status: string
    orderRef: string
    transactionId: string
  }
}

export type QuoteRequestQueryVariables = Exact<{
  quoteRequestId: Scalars['ID']
}>

export type QuoteRequestQuery = {
  __typename?: 'Query'
  quoteRequest: {
    __typename?: 'QuoteRequest'
    id: string
    attachments?: Array<string | null> | null
    createdAt: any
    type: QuoteType
    text?: string | null
    status: QuoteRequestStatus
    partnerId: string
    coupleId: string
    quoteResponse?: {
      __typename?: 'QuoteResponse'
      id: string
      status: QuoteResponseStatus
      reasons?: Array<string> | null
      text?: string | null
      seenAt?: any | null
      sendEmail?: boolean | null
      feedback?: { __typename?: 'QuoteResponseFeedback'; option: string } | null
      attachments?: Array<{
        __typename?: 'QuoteAttachment'
        name: string
        hash: string
      } | null> | null
    } | null
    couple: {
      __typename?: 'Couple'
      id: string
      name: string
      myName: string
      partnersName: string
      email: string
      lastSeenAt?: any | null
    }
    weddingCopy?: {
      __typename?: 'WeddingCopy'
      date?: any | null
      possibleYear?: string | null
      possibleMonths?: Array<number> | null
      possibleDays?: Array<number> | null
      address?: { __typename?: 'Location'; name: string } | null
      possibleAddresses?: Array<{ __typename?: 'Location'; name: string }> | null
      guestRange?: { __typename?: 'Range'; low: number; high: number } | null
      locationTags?: Array<{ __typename?: 'Tag'; name?: string | null }> | null
    } | null
    partner: { __typename?: 'Partner'; name: string }
    userNeedCopy?: {
      __typename?: 'UserNeedCopy'
      need: { __typename?: 'Need'; name: string }
      questionResponses: Array<
        Array<{
          __typename?: 'QuestionResponse'
          TEXT?: string | null
          TEXT_LONG?: string | null
          NUMBER?: number | null
          TIME?: string | null
          DATE?: string | null
          CURRENCY?: number | null
          CHECK_GROUP?: Array<string> | null
          CHECK_GROUP_INLINE?: Array<string> | null
          CHECK?: boolean | null
          RADIO_GROUP?: string | null
          RANGE?: { __typename?: 'Range'; low: number; high: number } | null
          RANGE_CURRENCY?: { __typename?: 'Range'; low: number; high: number } | null
          question?: {
            __typename?: 'Question'
            id: string
            title: string
            fields: Array<{ __typename?: 'QuestionField'; label?: string | null }>
          } | null
        }>
      >
    } | null
  }
}

export type QuoteRequestsQueryVariables = Exact<{
  filter: QuoteRequestFilter
}>

export type QuoteRequestsQuery = {
  __typename?: 'Query'
  quoteRequests: {
    __typename?: 'PaginatedQuoteRequest'
    total: number
    elements?: Array<{
      __typename?: 'QuoteRequest'
      id: string
      type: QuoteType
      seenAt?: any | null
      attachments?: Array<string | null> | null
      text?: string | null
      status: QuoteRequestStatus
      createdAt: any
      conversation?: { __typename?: 'Conversation'; id: string } | null
      partner: { __typename?: 'Partner'; name: string }
      quoteResponse?: {
        __typename?: 'QuoteResponse'
        id: string
        seenAt?: any | null
        createdAt: any
        feedback?: { __typename?: 'QuoteResponseFeedback'; option: string } | null
      } | null
      weddingCopy?: {
        __typename?: 'WeddingCopy'
        date?: any | null
        possibleYear?: string | null
        address?: { __typename?: 'Location'; name: string } | null
        guestRange?: { __typename?: 'Range'; low: number; high: number } | null
        possibleAddresses?: Array<{ __typename?: 'Location'; name: string }> | null
      } | null
      couple: {
        __typename?: 'Couple'
        id: string
        myName: string
        partnersName: string
        name: string
        email: string
        lastSeenAt?: any | null
        profileImage?: { __typename?: 'Image'; name: string } | null
      }
      userNeedCopy?: {
        __typename?: 'UserNeedCopy'
        need: {
          __typename?: 'Need'
          name: string
          category: { __typename?: 'Category'; image: { __typename?: 'Image'; name: string } }
        }
      } | null
    }> | null
  }
}

export type UnseenQuoteRequestsQueryVariables = Exact<{ [key: string]: never }>

export type UnseenQuoteRequestsQuery = { __typename?: 'Query'; unseenQuoteRequests: number }

export type QuoteResponseQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>
}>

export type QuoteResponseQuery = {
  __typename?: 'Query'
  quoteResponse?: {
    __typename?: 'QuoteResponse'
    id: string
    name?: string | null
    partnerId: string
    coupleId: string
    email?: string | null
    phone?: string | null
    text?: string | null
    status: QuoteResponseStatus
    reasons?: Array<string> | null
    createdAt: any
    attachments?: Array<{
      __typename?: 'QuoteAttachment'
      name: string
      hash: string
    } | null> | null
    partner: { __typename?: 'Partner'; name: string; contactEmail: string; phone: string }
  } | null
}

export type AcceptQuoteRequestMutationVariables = Exact<{
  input: NewQuoteResponseInput
}>

export type AcceptQuoteRequestMutation = {
  __typename?: 'Mutation'
  acceptQuoteRequest: { __typename?: 'QuoteResponse'; id: string }
}

export type RejectQuoteRequestMutationVariables = Exact<{
  input: NewQuoteResponseInput
}>

export type RejectQuoteRequestMutation = {
  __typename?: 'Mutation'
  rejectQuoteRequest: { __typename?: 'QuoteResponse'; id: string }
}

export type PartnerQuoteMutationVariables = Exact<{
  quoteRequestInput: PartnerQuoteInput
}>

export type PartnerQuoteMutation = {
  __typename?: 'Mutation'
  partnerQuote: { __typename?: 'QuoteRequest'; id: string }
}

export type SeeQuoteRequestMutationVariables = Exact<{
  input: Scalars['ID']
}>

export type SeeQuoteRequestMutation = {
  __typename?: 'Mutation'
  seeQuoteRequest: { __typename?: 'QuoteRequest'; id: string }
}

export type SearchTagGroupsQueryVariables = Exact<{
  input: TagGroupFilter
}>

export type SearchTagGroupsQuery = {
  __typename?: 'Query'
  paginatedTagGroups: {
    __typename?: 'PaginatedTagGroup'
    elements?: Array<{
      __typename?: 'TagGroup'
      id: string
      name: string
      tags: Array<{ __typename?: 'Tag'; id: string; name?: string | null }>
      category?: { __typename?: 'Category'; id: string; name: string } | null
    }> | null
  }
}

export type TransactionsQueryVariables = Exact<{
  filter: TransactionFilter
}>

export type TransactionsQuery = {
  __typename?: 'Query'
  transactions: {
    __typename?: 'PaginatedTransaction'
    total: number
    elements?: Array<{
      __typename?: 'Transaction'
      id: string
      amount: number
      createdAt: any
      wallet: { __typename?: 'Wallet'; partner: { __typename?: 'Partner'; name: string } }
      details: { __typename?: 'TransactionDetail'; remainingTokens: number }
    }> | null
  }
}

export type SearchUsersQueryVariables = Exact<{
  input: UserFilter
}>

export type SearchUsersQuery = {
  __typename?: 'Query'
  users: {
    __typename?: 'PaginatedUser'
    elements?: Array<{
      __typename?: 'AbstractUser'
      id: string
      name: string
      email: string
      profileImage?: { __typename?: 'Image'; name: string } | null
    }> | null
  }
}

export type UsersQueryQueryVariables = Exact<{
  input: UserFilter
}>

export type UsersQueryQuery = {
  __typename?: 'Query'
  users: {
    __typename?: 'PaginatedUser'
    total: number
    elements?: Array<{
      __typename?: 'AbstractUser'
      id: string
      name: string
      email: string
      type: UserType
      role: UserRole
      status: Status
      createdAt?: any | null
    }> | null
  }
}

export type WeddingByIdQueryVariables = Exact<{
  weddingByIdId: Scalars['ID']
}>

export type WeddingByIdQuery = {
  __typename?: 'Query'
  weddingById: {
    __typename?: 'Wedding'
    id: string
    date?: any | null
    description?: string | null
    possibleYear?: string | null
    possibleMonths?: Array<number | null> | null
    possibleDays?: Array<number | null> | null
    couple: { __typename?: 'Couple'; name: string }
    coverImage?: { __typename?: 'Image'; name: string } | null
    address?: { __typename?: 'Location'; name: string } | null
    guestRange?: { __typename?: 'Range'; low: number; high: number } | null
    possibleAddresses?: Array<{ __typename?: 'Location'; name: string }> | null
    locationTags?: Array<{ __typename?: 'Tag'; id: string; name?: string | null }> | null
  }
}

export type UnseenUserNeedsQueryVariables = Exact<{
  filter: UnseenUserNeedsInput
}>

export type UnseenUserNeedsQuery = { __typename?: 'Query'; unseenUserNeeds: number }
