import { Formik, Form, FormikConfig, FormikContextType } from 'formik'
import * as yup from 'yup'
import { PartnerAutocompleteFormik } from 'modules/partner'

const validationSchema = yup.object({})

export interface RecommendFormValues {
  recommendations: string[]
}

export interface RecommendFormContextType extends FormikContextType<RecommendFormValues> {}

export interface RecommendFormProps extends FormikConfig<RecommendFormValues> {
  categoryId: string
}

const RecommendForm = ({
  initialValues = {
    recommendations: [],
  },
  categoryId,
  children,
  onSubmit,
  ...props
}: RecommendFormProps) => (
  <Formik
    onSubmit={onSubmit}
    validationSchema={validationSchema}
    initialValues={initialValues}
    {...props}>
    <Form noValidate autoComplete="off">
      {/* @ts-ignore  */}
      <PartnerAutocompleteFormik
        id="recommendations"
        name="recommendations"
        label="Recommended partners"
        labelId="user-need-recommend-partner-ids-select"
        margin="normal"
        fullWidth
        categoryId={categoryId}
        filterSelectedOptions
        multiple
      />
      {children}
    </Form>
  </Formik>
)

export default RecommendForm
