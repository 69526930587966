import { makeStyles } from '@material-ui/core/styles'
import { useEffect, useState } from 'react'
import { useTagGroup } from '../hooks'
import Avatar from '@material-ui/core/Avatar'
import { Autocomplete, useIsMount } from 'modules/ui'
import config from 'modules/config'

const imagesPath = config.app.IMAGES_URL

const useStyles = makeStyles(theme => ({
  root: {},
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginRight: 15,
  },
}))

export default props => {
  const classes = useStyles()
  const { searchTagGroup } = useTagGroup()
  const [open, setOpen] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState([])
  const isMountRef = useIsMount()

  const handleInputChange = (e, value) => {
    setSearchTerm(value)
  }

  useEffect(() => {
    if (isMountRef) return

    if (!open) {
      setSearchTerm('')
      return
    }

    ;(async () => {
      setLoading(true)
      const { payload } = await searchTagGroup(searchTerm)
      setOptions(payload)
      setLoading(false)
    })()
  }, [open, searchTerm])

  return (
    <Autocomplete
      {...props}
      options={options || []}
      open={open}
      loading={loading}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      onInputChange={handleInputChange}
      getOptionSelected={(option, value) => option.id === value.id}
      getOptionLabel={option => option.name}
      renderOption={option => (
        <>
          <Avatar
            alt={option.name}
            src={imagesPath + '/' + option.image}
            className={classes.small}
          />
          {option.name}
        </>
      )}
    />
  )
}
