import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'
import { createSagaAction } from 'saga-toolkit'

const name = 'cover'

export const coverAdapter = createEntityAdapter()

const initialState = {
  loading: false,
  error: null,
  covers: coverAdapter.getInitialState(),
  cover: null,
}

export const fetchCovers = createSagaAction(`${name}/fetchCovers`)
export const fetchCover = createSagaAction(`${name}/fetchCover`)
export const insertCover = createSagaAction(`${name}/insertCover`)
export const removeCover = createSagaAction(`${name}/removeCover`)
export const updateCover = createSagaAction(`${name}/updateCover`)

const handlePending = state => ({
  ...state,
  loading: true,
})

const handleRejected = (state, { error }) => ({
  ...state,
  error,
  loading: false,
})

const slice = createSlice({
  name,
  initialState,
  reducers: {
    clearCover: state => ({
      ...state,
      cover: null,
    }),
  },
  extraReducers: {
    // fetchCovers
    [fetchCovers.pending]: handlePending,
    [fetchCovers.fulfilled]: (state, { payload }) => {
      coverAdapter.setAll(state.covers, payload)
      state.loading = false
    },
    [fetchCovers.rejected]: handleRejected,
    // fetchCover
    [fetchCover.pending]: handlePending,
    [fetchCover.fulfilled]: (state, { payload }) => {
      return { ...state, cover: payload, loading: false }
    },
    [fetchCover.rejected]: handleRejected,

    // insertCover
    [insertCover.pending]: handlePending,
    [insertCover.fulfilled]: state => ({
      ...state,
      loading: false,
    }),
    [insertCover.rejected]: handleRejected,

    // removeCover
    [removeCover.pending]: handlePending,
    [removeCover.fulfilled]: (state, { payload }) => {
      coverAdapter.removeOne(state.covers, payload.id)
      state.loading = false
    },
    [removeCover.rejected]: handleRejected,

    // updateCover
    [updateCover.pending]: handlePending,
    [updateCover.fulfilled]: (state, { payload }) => {
      coverAdapter.updateOne(state.covers, { id: payload.id, changes: payload })
      state.cover = { ...state.cover, ...payload }
      state.loading = false
    },
    [updateCover.rejected]: handleRejected,
  },
})

export const { clearCover } = slice.actions

export default slice.reducer
