import Admin from '../Admin'
import Home from './Home'
import { userIsAdmin, userIsPartner } from '../../wrappers'

const AdminHomeWithAuth = userIsAdmin(Admin)
const HomeWithAuth = userIsPartner(Home)

export default () => (
  <>
    <AdminHomeWithAuth />
    <HomeWithAuth />
  </>
)
