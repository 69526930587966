import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Paper from '@material-ui/core/Paper'
import ImageIcon from '@material-ui/icons/Image'
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined'
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline'
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined'
import Chip from '@material-ui/core/Chip'
import Container from '@material-ui/core/Container'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { QuoteRequestStatus } from 'modules/api'
import { Typography, Button } from 'modules/ui'
import { useT } from 'modules/i18n'
import QuoteRequestStatusChip from './QuoteRequestStatusChip'
import QuoteTypeBadge from './QuoteTypeBadge'

const imageHeight = 192
const borderRadius = 10

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    position: 'relative',
    width: 256,
    borderRadius,
  },
  image: {
    height: imageHeight,
    borderRadius,
  },
  emptyImage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: imageHeight,
    borderRadius,
    backgroundColor: palette.grey[500],
  },
  isNew: {
    position: 'absolute',
    top: borderRadius,
    left: borderRadius,
    width: 80,
    color: 'white',
    backgroundColor: '#FEC140',
    fontWeight: 700,
  },
  statusChip: {
    position: 'absolute',
    top: 10,
    left: 10,
    color: 'white',
    fontWeight: 700,
    paddingLeft: spacing(0.5),
    paddingRight: spacing(0.5),
  },
  typeBadge: {
    position: 'absolute',
    top: imageHeight,
    transform: 'translateY(-50%)',
  },
  content: {
    paddingTop: 26,
  },
  light: {
    color: '#7a7a7a',
  },
  list: {
    margin: '5px 0',
  },
  detail: {
    padding: '2px 0',
  },
  detailIcon: {
    minWidth: 30,
  },
  detailLabel: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    color: '#6b7280',
    fontSize: 11,
  },
  actions: {
    justifyContent: 'center',
  },
  details: {
    'width': 120,
    'height': 40,
    'borderRadius': 4,
    'fontSize': 16,
    'color': 'white',
    'backgroundColor': palette.primary.main,
    '&:hover': {
      backgroundColor: palette.primary.main,
    },
  },
  bottomText: {
    fontSize: 12,
    textAlign: 'center',
    padding: spacing(1),
  },
}))

interface ITypeBadgeData {
  label: string
  info: string
  chipBackground?: string
  infoColor?: string
}

interface Props {
  image?: string
  status?: QuoteRequestStatus
  isNew?: boolean
  typeBadge: ITypeBadgeData
  name: string
  email: string
  needTitle: string
  date: string
  guestRange: string
  location: string
  onDetailsClick: () => void
  style?: object
  bottomText?: string
}

const QuoteRequestCard = ({
  image = null,
  status = null,
  isNew = false,
  typeBadge,
  name,
  email,
  needTitle,
  date,
  guestRange,
  location,
  onDetailsClick,
  style = {},
  bottomText,
}: Props) => {
  const classes = useStyles()
  const t = useT()

  return (
    <Card className={classes.root} elevation={4} style={style}>
      {image ? (
        <CardMedia className={classes.image} image={image} title={t('Category')} />
      ) : (
        <Paper className={classes.emptyImage} elevation={0}>
          <ImageIcon />
        </Paper>
      )}
      {status && (
        <QuoteRequestStatusChip status={status} className={classes.statusChip} size="small" />
      )}
      {!status && isNew && <Chip className={classes.isNew} label={t('New')} size="small" />}
      {typeBadge && (
        <Container className={classes.typeBadge}>
          <QuoteTypeBadge
            label={typeBadge.label}
            info={typeBadge.info}
            chipBackground={typeBadge.chipBackground}
            infoColor={typeBadge.infoColor}
          />
        </Container>
      )}
      <CardContent className={classes.content}>
        <Typography variant="h6" noWrap>
          {name}
        </Typography>
        <Typography className={classes.light} variant="caption" noWrap>
          {email}
        </Typography>
        <Typography className={classes.light} variant="body2" noWrap>
          {`${needTitle} ${t('looking for service')}`}
        </Typography>
        <List className={classes.list}>
          <ListItem className={classes.detail}>
            <ListItemIcon className={classes.detailIcon}>
              <CalendarTodayOutlinedIcon color="primary" />
            </ListItemIcon>
            <ListItemText classes={{ primary: classes.detailLabel }} primary={date} />
          </ListItem>
          <ListItem className={classes.detail}>
            <ListItemIcon className={classes.detailIcon}>
              <PeopleOutlineIcon color="primary" />
            </ListItemIcon>
            <ListItemText classes={{ primary: classes.detailLabel }} primary={guestRange} />
          </ListItem>
          <ListItem className={classes.detail}>
            <ListItemIcon className={classes.detailIcon}>
              <RoomOutlinedIcon color="primary" />
            </ListItemIcon>
            <ListItemText classes={{ primary: classes.detailLabel }} primary={location} />
          </ListItem>
        </List>
      </CardContent>
      <CardActions className={classes.actions}>
        <Button className={classes.details} onClick={onDetailsClick}>
          {'Details'}
        </Button>
      </CardActions>

      {bottomText && (
        <Typography variant="body2" className={classes.bottomText}>
          {bottomText}
        </Typography>
      )}
    </Card>
  )
}

export default QuoteRequestCard
