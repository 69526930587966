import { Route, useRouteMatch } from 'react-router-dom'
import New from './New'
import Edit from './Edit'
import Remove from './Remove'
import List from './List'

const Inspiration = () => {
  const match = useRouteMatch()

  return (
    <>
      <Route
        path={[match.url, `${match.url}/new`, `${match.url}/:id/edit`, `${match.url}/:id/remove`]}
        exact
        component={List}
      />
      <Route path={`${match.url}/new`} exact component={New} />
      <Route path={`${match.url}/:id/edit`} component={Edit} />
      <Route path={`${match.url}/:id/remove`} component={Remove} />
    </>
  )
}

export default Inspiration
