import { useRouteMatch } from 'react-router'
import AddIcon from '@material-ui/icons/Add'
import { Box } from '@material-ui/core'
import { CoversList, useCover } from 'modules/cover'
import { Page, NavButton, RowActions } from 'modules/ui'

function List() {
  const { covers, loading } = useCover()
  const match = useRouteMatch()

  return (
    <Page title="Covers" fullHeight>
      <CoversList items={covers} loading={loading} rowActionsComponent={RowActions} />
      <Box display="flex" justifyContent="flex-end" p={1}>
        <NavButton to={`${match.url}/new`} tooltip="New">
          <AddIcon />
        </NavButton>
      </Box>
    </Page>
  )
}

export default List
