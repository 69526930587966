import { NavLink } from 'react-router-dom'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { makeStyles } from '@material-ui/core/styles'
import { useT } from 'modules/i18n'

const useStyles = makeStyles(theme => ({
  root: {
    '&:active, &.active': {
      background: theme.palette.primary.main,
      color: 'white',
    },
    '&:active $icon, &.active $icon': {
      color: 'white',
    },
  },
  icon: {},
  selected: {},
}))
export default ({ children, title, ...props }) => {
  const t = useT()
  const classes = useStyles()

  return (
    <ListItem className={classes.root} button component={NavLink} {...props}>
      <ListItemIcon className={classes.icon}>{children}</ListItemIcon>
      <ListItemText primary={t(title)} />
    </ListItem>
  )
}
