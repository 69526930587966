import { useState } from 'react'
import Modal from '@material-ui/core/Modal'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Backdrop from '@material-ui/core/Backdrop'
import Grow from '@material-ui/core/Grow'

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  root: {
    'boxShadow': 24,
    '& img': {
      maxWidth: '95vh',
      height: '100%',
      maxHeight: '95vh',
    },
  },
}))

const ImageAttachment = ({ src, createdAt, ...props }) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)

  const handleClickImage = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <img onClick={handleClickImage} width={150} src={src} {...props} alt="Attachment" />
      <Modal
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Grow in={open}>
          <Box className={classes.root}>
            <img src={src} alt="" />
          </Box>
        </Grow>
      </Modal>
    </>
  )
}

export default ImageAttachment
