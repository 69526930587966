import Grid from '@material-ui/core/Grid'
import { TagForm, useTag } from 'modules/tag'
import { Page, Typography } from 'modules/ui'

export default () => {
  const { tag, updateTag } = useTag()

  const handleSubmit = async data => {
    await updateTag(data)
  }

  if (!tag) {
    return 'Loading...'
  }

  return (
    <Page title={`Edit "${tag.name}"`}>
      <Grid container>
        <Grid item xs={12} sm={8} md={6} lg={4}>
          <Typography variant="h5">Tag details</Typography>
          <TagForm initialValues={tag} onSubmit={handleSubmit} />
        </Grid>
      </Grid>
    </Page>
  )
}
