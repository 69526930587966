import { Grid, makeStyles } from '@material-ui/core'
import { Page, useTable, NavButton } from 'modules/ui'
import { useT } from 'modules/i18n'
import { getPaginationParams } from 'modules/app'
import { ConversationsTable } from 'modules/chat'
import { useConversation } from 'modules/conversation'

const useStyles = makeStyles(() => ({
  navButton: {
    fontSize: '0.875rem',
    fontWeight: 500,
    textTransform: 'uppercase',
  },
}))

const RowActions = (row: any) => {
  const t = useT()
  const classes = useStyles()
  const typePagesMap = {
    COUPLE: 'couples',
    PARTNERS: 'partners',
    ADMIN: 'users',
  }

  return (
    <Grid container wrap="nowrap">
      <NavButton className={classes.navButton} to={`/conversations/${row?.id}/view`} tooltip="View">
        {t('View')}
      </NavButton>
      {row?.participants.map(
        ({ id, type }) =>
          type === 'COUPLE' && (
            <NavButton
              key={id}
              className={classes.navButton}
              to={`/couples/${id}/view`}
              tooltip="View couple in CIC">
              {t('CIC')}
            </NavButton>
          ),
      )}
    </Grid>
  )
}

const Conversations = () => {
  const { pageSize, page, filter, sortBy, sortDirection } = getPaginationParams()
  const { handleChangePage, handleChangeRowsPerPage, handleSearch, handleSort } = useTable()
  const { conversations, totalConversations, loading } = useConversation()

  return (
    <Page>
      <ConversationsTable
        conversations={conversations}
        totalElements={totalConversations || 0}
        pageSize={pageSize}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleSearch={handleSearch}
        page={page}
        toggleSort={handleSort}
        sortDirection={sortDirection}
        sortBy={sortBy}
        filter={filter}
        loading={loading}
        rowActionsComponent={RowActions}
        title="Conversations"
      />
    </Page>
  )
}

export default Conversations
