import { CheckboxSelect, TextField } from 'modules/formik'
import { Form, Formik } from 'formik'
import * as yup from 'yup'

const reasons = [
  {
    label: 'Nem hiszem, hogy a szolgáltatásom megfelelő a pár számára',
    value: 'Nem hiszem, hogy a szolgáltatásom megfelelő a pár számára',
  },
  {
    label: 'Sajnos azon a dátumon nem tudok szolgáltatást vállalni',
    value: 'Sajnos azon a dátumon nem tudok szolgáltatást vállalni',
  },
  {
    label: 'A pár által megadott keretösszeg távol esik a szolgáltatási áraimtól',
    value: 'A pár által megadott keretösszeg távol esik a szolgáltatási áraimtól',
  },
  {
    label: 'A párnak olyan egyedi kérése van, amelyet nem tudok teljesíteni',
    value: 'A párnak olyan egyedi kérése van, amelyet nem tudok teljesíteni',
  },
  {
    label: 'Abban a városban nem tudok szolgáltatást vállalni',
    value: 'Abban a városban nem tudok szolgáltatást vállalni',
  },
  {
    label: 'Egyéb',
    value: 'Egyéb',
  },
]

const validationSchema = yup.object().shape({
  text: yup.string(),
  reasons: yup
    .array()
    .min(1, 'You need at select least one reason to reject this quote request')
    .required('Required'),
})

const RejectQuoteRequestForm = ({
  initialValues = {
    reasons: [],
    text: '',
  },
  onSubmit,
  innerRef,
  readonly = false,
}) => (
  <Formik
    innerRef={innerRef}
    validationSchema={validationSchema}
    initialValues={initialValues}
    onSubmit={onSubmit}>
    <FormBody readonly={readonly} />
  </Formik>
)

const FormBody = ({ readonly }) => (
  <Form autoComplete="off">
    <CheckboxSelect name="reasons" options={reasons} disabled={readonly} />
    <TextField
      variant="outlined"
      fullWidth
      id="text"
      name="text"
      label=""
      multiline
      placeholder="Start to type..."
      disabled={readonly}
    />
  </Form>
)

export default RejectQuoteRequestForm
