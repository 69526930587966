import { useRouteMatch } from 'react-router'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import { Box } from '@material-ui/core'
import { NavButton } from 'modules/ui'
import { TagsList, useTag } from 'modules/tag'
import { Page } from 'modules/ui'

const RowActions = ({ row }) => {
  const match = useRouteMatch()

  return (
    <>
      <NavButton to={`${match.url}/${row.id}/edit`} tooltip="Edit">
        <EditIcon fontSize="small" />
      </NavButton>
      <NavButton to={`${match.url}/${row.id}/remove`} tooltip="Delete">
        <DeleteIcon fontSize="small" />
      </NavButton>
    </>
  )
}

export default () => {
  const { tags, loading } = useTag()
  const match = useRouteMatch()

  return (
    <Page title="Tags" fullHeight>
      <TagsList tags={tags} loading={loading} rowActionsComponent={RowActions} />
      <Box display="flex" justifyContent="flex-end" p={1}>
        <NavButton to={`${match.url}/new`} tooltip="New">
          <AddIcon />
        </NavButton>
      </Box>
    </Page>
  )
}
