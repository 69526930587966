import { useState } from 'react'
import { useHistory } from 'react-router'
import Grid from '@material-ui/core/Grid'
import { BlogForm, useBlog } from 'modules/blog'
import { Page, Loader } from 'modules/ui'
import { uploadToS3 } from 'modules/storage'

const New = () => {
  const { loading, createBlog } = useBlog()
  const history = useHistory()
  const [submitting, setSubmitting] = useState(false)

  const handleSubmit = async data => {
    setSubmitting(true)

    const { payload } = await createBlog({
      ...data,
      coverImage: data.coverImage && (await uploadToS3(data.coverImage)),
    })
    setSubmitting(false)
    history.push(`/blogs/${payload.id}/edit`)
  }

  if (loading) {
    return 'Loading...'
  }

  return (
    <Page title="New blog">
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={6} md={4}>
          <BlogForm onSubmit={handleSubmit} />
          <Loader loading={submitting} />
        </Grid>
      </Grid>
    </Page>
  )
}

export default New
