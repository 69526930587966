import { useEffect, useState, useRef } from 'react'
import Picker, { SKIN_TONE_MEDIUM_DARK } from 'emoji-picker-react'
import Scrollbar from 'react-perfect-scrollbar'
import { Formik, Form } from 'formik'
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import SendIcon from '@material-ui/icons/Send'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotions'
import AvatarGroup from '@material-ui/lab/AvatarGroup'
import { TextField } from 'modules/formik'
import { useT } from 'modules/i18n'
import { UserAutocomplete, useUser } from 'modules/user'
import { Avatar, Loader, Typography, Button } from 'modules/ui'
import config from 'modules/config'
import Message from './Message'
import ImageAttachmentPicker from './ImageAttachmentPicker'
import HtmlAttachmentPickerForm from './HtmlAttachmentPicker'

const imagesPath = config.app.IMAGES_URL

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    backgroundColor: palette.background.paper,
    flexWrap: 'nowrap',
    paddingLeft: spacing(2),
  },
  header: {
    flexBasis: 'unset',
    alignItems: 'center',
    paddingBottom: spacing(1),
    flex: 1,
    borderBottom: `1px solid ${palette.grey.main}`,
  },
  body: {
    alignItems: 'flex-end',
    flexBasis: '100%',
  },
  footer: {
    flexBasis: 'unset',
  },
  emojiPicker: {
    position: 'absolute',
    bottom: 0,
    transform: 'translate(0%,-40%)',
  },
  participants: {},
  sender: {
    display: 'flex',
  },
  enterMessage: {
    flex: 1,
    margin: spacing(1),
  },
  headerAvatars: {
    marginRight: spacing(1),
  },
  loadMoreButtonContainer: {
    padding: spacing(2),
    width: '100%',
    textAlign: 'center',
  },
  loadMoreButton: {
    opacity: 0.6,
    borderRadius: 25,
    maxWidth: 500,
  },
  seenContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  seenLabel: {
    fontWeight: 'bold',
  },
  seenDate: {
    marginLeft: spacing(1),
  },
  participant: {
    cursor: 'pointer',
  },
}))

const Participants = ({ participants, classes, onClick, ...props }) => (
  <>
    <Grid item>
      <AvatarGroup max={2} className={classes.headerAvatars}>
        {participants.map(({ profileImage, name }, i) => (
          <Avatar
            key={i}
            alt={name}
            src={imagesPath + '/' + (profileImage?.name || profileImage) || ''}
          />
        ))}
      </AvatarGroup>
    </Grid>
    <Grid item xs={8}>
      <Typography {...props} variant="h6">
        {participants.map(participant => (
          <span
            key={participant.id}
            className={classes.participant}
            onClick={() => onClick(participant)}>
            {participant.name}
          </span>
        ))}
      </Typography>
    </Grid>
  </>
)

const EnterParticipants = ({ participants = [], onEnterParticipant, userId }) => {
  const handleChange = (_, value) => {
    onEnterParticipant(value)
  }

  const filterOwnUser = user => user.id !== userId

  return (
    <UserAutocomplete
      filterOptions={filterOwnUser}
      onChange={handleChange}
      value={participants}
      name="participants"
      label="Participants"
      margin="normal"
      fullWidth
      filterSelectedOptions
      disableClearable
      multiple
    />
  )
}

const EnterMessage = ({
  disabled,
  innerRef,
  onEnterMessage,
  showEmojiPicker,
  setShowPicker,
  loading,
  classes,
  uploading,
}) => {
  const [attachment, setAttachment] = useState()

  const handleSubmit = async (data, { resetForm }) => {
    const { message } = data

    await onEnterMessage({
      text: message,
      attachment,
    })
    resetForm()
    setAttachment()
  }

  const handlePickAttachment = type => payload => {
    setAttachment({ type, payload })
  }
  return (
    <Formik innerRef={innerRef} onSubmit={handleSubmit} initialValues={{ message: '' }}>
      {({ values }) => (
        <Form noValidate autoComplete="off">
          <div className={classes.sender}>
            <IconButton onClick={() => setShowPicker(!showEmojiPicker)}>
              <EmojiEmotionsIcon />
            </IconButton>

            <ImageAttachmentPicker
              selected={attachment?.type === 'image'}
              name="image"
              onSubmit={handlePickAttachment('image')}
            />

            <HtmlAttachmentPickerForm
              selected={attachment?.type === 'html'}
              onSubmit={handlePickAttachment('html')}
            />

            <div className={classes.enterMessage}>
              <TextField
                multiline
                onKeyDown={e => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault()
                    innerRef.current.handleSubmit()
                  }
                }}
                name="message"
                placeholder="Enter message..."
                fullWidth
                disabled={disabled}
              />
            </div>

            <IconButton
              type="submit"
              disabled={disabled || (!values.message && !attachment) || loading}>
              <SendIcon />
            </IconButton>
          </div>
          <Loader
            variant="determinate"
            value={uploading}
            loading={uploading !== 0 && uploading !== 100}
          />
        </Form>
      )}
    </Formik>
  )
}

const Conversation = ({
  participants: _participants = [],
  messages = [],
  newConversation,
  noParticipants,
  onEnterMessage,
  onNewConversation,
  onLoadMore,
  userId,
  loading,
  totalMessages,
  onClickAttachment,
  uploading,
  id,
  onClickParticipant,
}) => {
  const [participants, setParticipants] = useState(_participants)
  const classes = useStyles()
  const t = useT()
  const enterMessageForm = useRef(null)
  const [showEmojiPicker, setShowPicker] = useState(false)
  const scrollbarRef = useRef()
  const { user } = useUser()

  const reversedMessages = messages.slice(0).reverse()
  const lastMessage = reversedMessages[reversedMessages.length - 1]

  useEffect(() => {
    if (user) setParticipants([user])
  }, [user])

  const onEmojiClick = (_, emojiObject) => {
    const { values, setFieldValue } = enterMessageForm.current
    const emojiAddedMessage = values.message + emojiObject.emoji

    setFieldValue('message', emojiAddedMessage)
  }

  const handleEnterMessage = async ({ text, attachment }) => {
    if (newConversation && !noParticipants) {
      onNewConversation({
        text,
        attachment,
        participants,
      })
    } else {
      onEnterMessage({ text, attachment })
      scrollbarRef.current.scrollTop = scrollbarRef.current.scrollHeight
    }
  }

  const handleClickLoadMore = async () => {
    const prevHeight = scrollbarRef.current.scrollHeight
    await onLoadMore()
    scrollbarRef.current.scrollTop = scrollbarRef.current.scrollHeight - prevHeight
  }

  useEffect(() => {
    setParticipants([..._participants])
  }, [id])

  const ScrollToBottom = () => {
    const elementRef = useRef()
    useEffect(() => {
      if (!loading) {
        elementRef.current.scrollIntoView()
      }
    }, [])
    return <div ref={elementRef} />
  }

  return (
    <Grid className={classes.root} spacing={2} container direction="column" alignItems="stretch">
      {!noParticipants && (
        <Grid container className={classes.header}>
          {newConversation ? (
            <EnterParticipants
              userId={userId}
              participants={participants}
              onEnterParticipant={setParticipants}
            />
          ) : (
            <Participants
              classes={classes}
              participants={participants.filter(({ id }) => id !== userId)}
              onClick={onClickParticipant}
            />
          )}
        </Grid>
      )}
      <Scrollbar containerRef={ref => (scrollbarRef.current = ref)}>
        <Grid item className={classes.body}>
          {!loading && messages?.length < totalMessages && (
            <div className={classes.loadMoreButtonContainer}>
              <Button
                fullWidth
                className={classes.loadMoreButton}
                onClick={handleClickLoadMore}
                color="primary">
                <Typography variant="inherit">Load more</Typography>
              </Button>
            </div>
          )}
          {reversedMessages.map((message, i) => (
            <Message
              userId={userId}
              key={i}
              onClickAttachment={onClickAttachment}
              {...message}
              sender={{
                ...message.sender,
                image: participants.find(({ id }) => id === message.sender?.id)?.profileImage || '',
              }}
            />
          ))}
          {lastMessage && (
            <div className={classes.seenContainer}>
              <Typography className={classes.seenLabel} variant="body2">
                {lastMessage.seenAt ? t('Read') : ''}
              </Typography>
              {lastMessage.seenAt && (
                <Typography className={classes.seenDate} variant="body2">
                  {moment(lastMessage.seenAt).format('YYYY.MM.DD HH:mm')}
                </Typography>
              )}
            </div>
          )}
          <ScrollToBottom />
        </Grid>
      </Scrollbar>
      <Grid item className={classes.footer}>
        <EnterMessage
          innerRef={enterMessageForm}
          classes={classes}
          loading={loading}
          uploading={uploading}
          setShowPicker={setShowPicker}
          showEmojiPicker={showEmojiPicker}
          onEnterMessage={handleEnterMessage}
          disabled={!noParticipants && !participants?.length}
        />
        {!!showEmojiPicker && (
          <ClickAwayListener
            mouseEvent="onMouseDown"
            touchEvent="onTouchStart"
            onClickAway={() => setShowPicker(false)}>
            <div className={classes.emojiPicker}>
              <Picker
                onEmojiClick={onEmojiClick}
                disableAutoFocus={true}
                skinTone={SKIN_TONE_MEDIUM_DARK}
                groupNames={{ smileys_people: 'PEOPLE' }}
                native
              />
            </div>
          </ClickAwayListener>
        )}
      </Grid>
    </Grid>
  )
}

export default Conversation
