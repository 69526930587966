import { conversationsAdapter } from './slice'

export const selectRoot = state => state.chat
export const selectLimit = state => selectRoot(state).limit
export const selectOffset = state => selectRoot(state).offset
export const selectConversations = state => selectRoot(state).conversations

export const {
  // selectIds,
  // selectEntities,
  selectAll: selectAllConversations,
  // selectTotal,
  selectById: selectConversationById,
} = conversationsAdapter.getSelectors(selectConversations)

export const selectUnseenConversations = state => {
  const { userId } = state.chat
  const conversations = selectAllConversations(state)

  return conversations
    .map(conversation => {
      const unseenMessageIds = conversation.messages
        .filter(({ sender, seenAt }) => sender?.id !== userId && !seenAt)
        .map(({ id }) => id)

      if (!unseenMessageIds.length) {
        return false
      }

      return {
        conversationId: conversation.id,
        unseenMessageIds,
      }
    })
    .filter(c => c)
}
