import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import { makeStyles } from '@material-ui/core/styles'
import CardContent from '@material-ui/core/CardContent'

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: theme.breakpoints.values.sm,
    width: '100%',
  },
  title: {
    fontWeight: 800,
  },
}))

const HealthCheckCard = ({
  conversationIssues = [],
  conversationIssuesButtonComponent: ConversationIssuesButton,
}) => {
  const classes = useStyles()

  return (
    <Box m={1}>
      <Grid container>
        <Grid item xs={6} md={4}>
          <Card className={classes.card} sx={{ minWidth: 275, maxWidth: 600 }}>
            <CardContent>
              <Typography className={classes.title} variant="subtitle1" gutterBottom>
                Chat issues
              </Typography>
              <Typography sx={{ mb: 1.5 }}>
                Number of conversation issues: {conversationIssues.length}
              </Typography>
              <ConversationIssuesButton />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  )
}

export default HealthCheckCard
