import React from 'react'
import { useHistory } from 'react-router-dom'
import { Box, makeStyles } from '@material-ui/core'
import { Button, Typography } from 'modules/ui'
import { useProfile } from 'modules/profile'
import { useNeed } from 'modules/need'
import LatestWeddingsItem from './LatestWeddingsItem'

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    backgroundColor: 'white',
    borderRadius: '10px',
    overflow: 'hidden',
    boxShadow: '0px 17px 17px #EFF1F9BF',
    position: 'relative',
    paddingLeft: spacing(4.5),
    paddingRight: spacing(4.5),
    paddingTop: spacing(4),
    paddingBottom: spacing(5),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    rowGap: spacing(6),
  },
  title: {
    fontSize: '18px',
    fontFamily: 'Poppins',
    fontWeight: 500,
    color: '#3F4254',
  },
  subtitle: {
    fontSize: '10px',
    fontFamily: 'Poppins',
    fontWeight: 300,
    color: '#86ADBF',
    marginBottom: '13px',
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '13px',
  },
  showAllButton: {
    width: '100%',
    height: '50px',
    textTransform: 'none',
    fontSize: '18px',
    fontFamily: 'Poppins',
    fontWeight: 500,
    borderRadius: '5px',
  },
}))

const LatestWeddingsCard = () => {
  const classes = useStyles()
  const { user } = useProfile()
  const { userNeeds } = useNeed()
  const history = useHistory()

  const handleShowClick = (id: string) => {
    history.push(`/weddings/${id}/need`)
  }

  const handleShowAllClick = () => {
    history.push('/weddings')
  }

  return (
    <Box className={classes.root}>
      <Box>
        <Typography className={classes.title} tParams={{ categoryName: user.category.name }}>
          {'Latest weddings in {{categoryName}} category'}
        </Typography>
        <Typography className={classes.subtitle}>Send a quote and get new customers!</Typography>
        {!!userNeeds && (
          <Box className={classes.list}>
            {[...userNeeds].splice(0, 5).map(({ id, need, wedding }, i) => (
              <LatestWeddingsItem
                key={i.toString()}
                wedding={wedding}
                need={need}
                onShowClick={() => handleShowClick(id)}
              />
            ))}
          </Box>
        )}
      </Box>
      <Button
        variant="contained"
        color="secondary"
        className={classes.showAllButton}
        onClick={handleShowAllClick}>
        Show all
      </Button>
    </Box>
  )
}

export default LatestWeddingsCard
