import { takeLatestAsync } from 'saga-toolkit'
import { requestApi } from 'modules/api'
import * as actions from './slice'
import * as operations from './operations'

function* fetchQuoteRequest({ meta }) {
  const { id } = meta.arg

  const { quoteRequest } = yield requestApi(operations.quoteRequestQuery, {
    quoteRequestId: id,
  })

  return quoteRequest
}

function* declineQuoteRequest({ meta }) {
  const { declineQuoteRequest } = yield requestApi(operations.declineQuoteRequestMutation, {
    input: meta.arg,
  })

  return declineQuoteRequest
}

function* fetchQuoteResponse({ meta }) {
  const { id } = meta.arg

  const { quoteResponse } = yield requestApi(operations.quoteResponseQuery, {
    id,
  })

  return quoteResponse
}

function* fetchQuoteRequests({ meta }) {
  const filter = meta.arg
  const { quoteRequests } = yield requestApi(operations.quoteRequestsQuery, { filter })

  return quoteRequests
}

function* fetchUnseenQuotes() {
  const { unseenQuoteRequests } = yield requestApi(operations.unseenQuoteRequestsQuery)

  return unseenQuoteRequests
}

function* acceptQuoteRequest({ meta }) {
  const { input } = meta.arg

  const { acceptQuoteRequest } = yield requestApi(operations.acceptQuoteRequestMutation, {
    input,
  })

  return acceptQuoteRequest
}

function* partnerQuote({ meta }) {
  const { quoteRequestInput } = meta.arg

  const { partnerQuote } = yield requestApi(operations.partnerQuoteMutation, {
    quoteRequestInput,
  })

  return partnerQuote
}

function* seeQuoteRequest({ meta }) {
  const { id: input } = meta.arg

  const { seeQuoteRequest } = yield requestApi(operations.seeQuoteRequestMutation, {
    input,
  })

  return seeQuoteRequest
}

export default [
  takeLatestAsync(actions.declineQuoteRequest.type, declineQuoteRequest),
  takeLatestAsync(actions.acceptQuoteRequest.type, acceptQuoteRequest),
  takeLatestAsync(actions.partnerQuote.type, partnerQuote),
  takeLatestAsync(actions.seeQuoteRequest.type, seeQuoteRequest),
  takeLatestAsync(actions.fetchQuoteRequest.type, fetchQuoteRequest),
  takeLatestAsync(actions.fetchQuoteRequests.type, fetchQuoteRequests),
  takeLatestAsync(actions.fetchUnseenQuotes.type, fetchUnseenQuotes),
  takeLatestAsync(actions.fetchQuoteResponse.type, fetchQuoteResponse),
]
