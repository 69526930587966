// @ts-nocheck
import { Formik, Form } from 'formik'
import { makeStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import * as yup from 'yup'
import { TextField, Select, SubmitButton, Checkbox } from 'modules/formik'
import { Category } from 'modules/api'
import { Typography } from 'modules/ui'

export type NeedFormValues = {
  name: string
  category?: Category
  hide: boolean
}

export type NeedFormProps = {
  initialValues: NeedFormValues
  categories: Category[]
  categoryLoading?: boolean
  onSubmit: (values: NeedFormValues) => void
  submitButton?: boolean
}

export const NeedForm = ({
  initialValues = {
    name: '',
    category: null,
    hide: false,
  },
  categories = [],
  categoryLoading = false,
  onSubmit = () => {},
  submitButton = true,
}: NeedFormProps) => {
  const classes = useStyles()

  return (
    <Formik
      initialValues={{
        ...initialValues,
        category: initialValues.category?.id,
      }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}>
      <Form className={classes.root} autoComplete="off">
        <TextField
          id="name"
          name="name"
          label="Name"
          margin="normal"
          variant="standard"
          fullWidth
        />
        <Select
          id="category"
          loading={categoryLoading}
          name="category"
          label="Category"
          labelId="partner-edit-form-category-select"
          margin="dense"
          fullWidth>
          {categories.map(({ id, name }, i) => (
            <MenuItem key={i} value={id}>
              {name}
            </MenuItem>
          ))}
        </Select>
        <Checkbox
          className={classes.checkBox}
          name="hide"
          fullWidth
          label={<Typography>Hide</Typography>}
        />
        {/** TODO: This form will be changed. */}
        {!!submitButton && (
          <SubmitButton color="primary" variant="contained" size="small">
            save
          </SubmitButton>
        )}
      </Form>
    </Formik>
  )
}

const validationSchema = yup.object().shape({
  name: yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
  category: yup.string().min(2, 'Pick a category!').required('Required'),
})

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  fileUpload: {
    margin: 20,
  },
  profilePictureContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  checkBox: {
    marginTop: spacing(2),
    marginBottom: spacing(2),
  },
}))
