import { Formik, Form, useField } from 'formik'
import { SubmitButton, withFormikField } from 'modules/formik'

// TODO fix formik controlled / uncontrolled error

const FileInput = withFormikField(({ onChange, ...props }) => {
  const [, , helpers] = useField(props)

  const handleChange = ({ target }) => {
    const { files } = target
    const reader = new FileReader()

    reader.onload = () => {
      const ids = reader.result
        .split('\n')
        .map(line => line.split(';')[0])
        .filter(id => !!id)

      helpers.setValue(ids)
    }

    reader.readAsText(files[0])
  }

  return (
    <>
      <input type="hidden" {...props} />
      <input type="file" accept=".csv" onChange={handleChange} />
    </>
  )
})

const BatchUploadForm = ({ initialValues = { ids: '' }, onSubmit }) => (
  <Formik initialValues={{ initialValues }} onSubmit={onSubmit}>
    <Form noValidate autoComplete="off">
      <FileInput name="ids" />
      <SubmitButton color="primary" variant="contained" size="small">
        Load
      </SubmitButton>
    </Form>
  </Formik>
)

export default BatchUploadForm
