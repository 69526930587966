import { useState, useRef, forwardRef } from 'react'
import { useField } from 'formik'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import Avatar from '@material-ui/core/Avatar'
import Badge from '@material-ui/core/Badge'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import IconButton from '@material-ui/core/IconButton'
import CancelIcon from '@material-ui/icons/Cancel'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { UploadImageForm } from 'modules/partner'
import { Button, Tooltip, Typography } from 'modules/ui'
import config from 'modules/config'

const imagesPath = config.app.IMAGES_URL

const useStyles = makeStyles(theme => ({
  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  dialogTitle: {
    margin: theme.spacing(2),
    fontWeight: 700,
  },
}))

export default ({ name }) => {
  const [openDialog, setOpenDialog] = useState(false)
  const classes = useStyles()
  const [, meta, helpers] = useField({
    name,
  })

  const handleProfileImageImageSubmit = data => {
    setValue(data.image)
    setOpenDialog(false)
  }

  const getAvatarUrl = profileImage => {
    return profileImage instanceof File
      ? URL.createObjectURL(profileImage)
      : profileImage
      ? `${imagesPath}/${profileImage?.name}`
      : ''
  }

  const profileImageForm = useRef()
  const { setValue } = helpers
  const { value } = meta

  const ProfileBadge = forwardRef(function (props, ref) {
    return (
      <Badge
        {...props}
        ref={ref}
        overlap="circular"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        badgeContent={
          !!value ? (
            <IconButton aria-label="delete" onClick={() => setValue('')}>
              <CancelIcon />
            </IconButton>
          ) : (
            <IconButton aria-label="add" onClick={() => setOpenDialog(true)}>
              <AddCircleIcon />
            </IconButton>
          )
        }>
        <Avatar className={classes.large} src={getAvatarUrl(value)} />
      </Badge>
    )
  })

  return (
    <>
      {meta.error ? (
        <Tooltip arrow={true} open={true} title={meta.error} placement="top-start">
          <ProfileBadge />
        </Tooltip>
      ) : (
        <ProfileBadge />
      )}

      <Dialog fullWidth maxWidth="sm" open={openDialog} onClose={() => setOpenDialog(false)}>
        <Typography className={classes.dialogTitle} variant="h5" id="form-dialog-title">
          Upload profile picture
        </Typography>
        <DialogContent>
          <UploadImageForm onSubmit={handleProfileImageImageSubmit} innerRef={profileImageForm} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => profileImageForm.current.submitForm()} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
