import { useLocation, useHistory } from 'react-router-dom'

const useQueryState = ({ defaultValue, queryParam }) => {
  const history = useHistory()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const value = queryParams.get(queryParam)

  const handleChange = e => {
    e.target.value !== defaultValue
      ? queryParams.set(queryParam, e.target.value)
      : queryParams.delete(queryParam)
    history.replace(`${location.pathname}?${queryParams.toString()}`)
  }

  return {
    handleChange,
    value: value || defaultValue,
  }
}

export default useQueryState
