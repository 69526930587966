import React from 'react'
import { Grid } from '@material-ui/core'
import TransactionList from './TransactionList'
import TokenCounter from './TokenCounter'
import TransactionMaker from './TransactionMaker'

function PartnerWallet({ partner, onAddTransaction }) {
  return (
    <Grid spacing={2} container direction="row" style={{ height: '100%' }}>
      <Grid item xs={12} sm={12} md={8}>
        <TransactionList wallet={partner.wallet} />
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        <TokenCounter wallet={partner.wallet} />
        <Grid item style={{ marginTop: '10px' }}>
          <TransactionMaker onSubmit={onAddTransaction} />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default PartnerWallet
