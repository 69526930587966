import { pick } from 'rambda'
import { takeLatestAsync, takeAggregateAsync } from 'saga-toolkit'
import * as actions from './slice'
import { query, mutation } from 'modules/api'

const tagFields = ['id', 'name', 'priority', 'hide']

function* fetchTags() {
  const options = {
    operation: 'tags',
    fields: tagFields,
  }

  const result = yield query(options, true)

  return result.tags
}

function* fetchTag({ meta }) {
  const { id } = meta.arg
  const options = {
    operation: 'tag',
    fields: tagFields,
    variables: {
      id: {
        type: 'ID',
        value: id,
        required: true,
      },
    },
  }

  const result = yield query(options, true)

  return result.tag
}

function* searchTag({ meta }) {
  const options = {
    operation: 'tags',
    fields: tagFields,
    variables: {
      searchByName: {
        name: 'searchByName',
        type: 'String',
        value: meta.arg,
      },
    },
  }

  const result = yield query(options, false)

  return result.tags
}

function* createTag({ meta }) {
  const options = {
    operation: 'insertTag',
    fields: ['id', 'name', 'hide'],
    variables: {
      tag: {
        value: meta.arg,
        type: 'TagInput!',
      },
    },
  }

  const result = yield mutation(options, true)

  return result.insertTag
}

function* updateTag({ meta }) {
  const { id } = meta.arg
  const body = pick(['name', 'priority', 'hide'], meta.arg)
  const options = {
    operation: 'updateTag',
    fields: tagFields,
    variables: {
      id: {
        value: id,
        type: 'ID!',
      },
      input: {
        value: body,
        type: 'TagInput!',
      },
    },
  }

  const result = yield mutation(options, true)

  return result.updateTag
}

function* removeTag({ meta }) {
  const { id } = meta.arg
  const options = {
    operation: 'deleteTag',
    variables: {
      id: {
        value: id,
        type: 'ID!',
      },
    },
  }

  const result = yield mutation(options, true)

  return result.deleteTag
}

export default [
  takeLatestAsync(actions.fetchTags.type, fetchTags),
  takeLatestAsync(actions.fetchTag.type, fetchTag),
  takeAggregateAsync(actions.searchTag.type, searchTag),
  takeLatestAsync(actions.createTag.type, createTag),
  takeLatestAsync(actions.updateTag.type, updateTag),
  takeLatestAsync(actions.removeTag.type, removeTag),
]
