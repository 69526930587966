import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import * as yup from 'yup'
import { Formik, Form } from 'formik'
import { TextField, SubmitButton } from 'modules/formik'

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
}))

const validationSchema = yup.object({
  /** TODO: validation */
})

const initialState = {
  adminNote: '',
}

const AdminNoteForm = ({ ...props }) => {
  const classes = useStyles()

  return (
    <Formik {...props} validationSchema={validationSchema}>
      <Form className={classes.root} noValidate autoComplete="off">
        <Grid container>
          <Grid item xs={12}>
            <TextField
              name="adminNote"
              label="Admin note"
              margin="normal"
              minRows={5}
              multiline
              fullWidth
            />
          </Grid>
        </Grid>
        <SubmitButton color="primary" variant="contained" size="small">
          Save
        </SubmitButton>
      </Form>
    </Formik>
  )
}

export default AdminNoteForm
