import { useState } from 'react'
import { Link } from 'react-router-dom'
import { useField } from 'formik'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import CancelIcon from '@material-ui/icons/Cancel'
import IconButton from '@material-ui/core/IconButton'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import FormControl from '@material-ui/core/FormControl'
import { makeStyles } from '@material-ui/core/styles'
import { Button, FormLabel, FormHelperText, Typography } from '.'
import config from 'modules/config'

const imagesPath = config.app.IMAGES_URL

const useStyles = makeStyles(() => ({
  attachmentText: {
    textDecoration: 'none',
    fontStyle: 'italic',
    color: 'grey',
  },
}))

export interface AttachmentsUploaderProps {
  name: string
  maxFileSize?: number
  label: string
  className?: string
  labelId?: string
  fullWidth?: boolean
  margin?: 'none' | 'dense' | 'normal'
  disabled?: boolean
}

const AttachmentsUploader = ({
  name,
  maxFileSize,
  label,
  className,
  labelId,
  fullWidth,
  margin,
  disabled,
}: AttachmentsUploaderProps) => {
  const classes = useStyles()
  const [uploadError, setUploadError] = useState('')
  const [, meta, helpers] = useField({
    name,
  })
  const { setValue } = helpers
  const { value } = meta

  const handleClickRemoveItem = ind => {
    const tmpFiles = value.filter((item, i) => ind !== value.length - 1 - i)
    setValue(tmpFiles)
  }

  const onSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      if (maxFileSize && e.target.files[0].size > maxFileSize) {
        setUploadError(e.target.files[0].name + ': file size is too big!')
        return
      }
      setUploadError('')
      setValue([...value, e.target.files[0]])
      e.target.value = ''
    }
  }

  return (
    <FormControl className={className} fullWidth={fullWidth} margin={margin}>
      <FormLabel id={labelId}>{label}</FormLabel>
      {!disabled && (
        <>
          {/* @ts-ignore */}
          <Button color="primary" component="label">
            <Typography variant="inherit">Upload attachments (Excel, Word, PDF)</Typography>
            <input
              type="file"
              accept="application/pdf, application/vnd.ms-excel, .xlsx, .doc, .docx"
              hidden
              multiple
              onChange={onSelectFile}
            />
          </Button>
        </>
      )}
      <FormHelperText error>{uploadError || ''}</FormHelperText>
      <List dense={false}>
        {value.map((attachment, i) => {
          return (
            <ListItem key={attachment.hash}>
              <ListItemText
                primary={
                  <Link
                    className={classes.attachmentText}
                    target="_blank"
                    to={{ pathname: `${imagesPath}/${attachment.hash}` }}>
                    {attachment.name}
                  </Link>
                }
              />
              {!disabled && (
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => handleClickRemoveItem(i)}>
                    <CancelIcon color="primary" />
                  </IconButton>
                </ListItemSecondaryAction>
              )}
            </ListItem>
          )
        })}
      </List>
    </FormControl>
  )
}

export default AttachmentsUploader
