import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'
import { createSagaAction } from 'saga-toolkit'
import { QuoteRequestStatus } from 'modules/api'

const name = 'quote'

export const quoteRequestsAdapter = createEntityAdapter()
export const quoteResponsesAdapter = createEntityAdapter()

const initialState = {
  parallelTasks: 0,
  error: null,
  quoteRequest: null,
  quoteResponse: null,
  quoteRequests: quoteRequestsAdapter.getInitialState(),
  total: 0,
  unseenQuotes: 0,
}

export const fetchQuoteResponse = createSagaAction(`${name}/fetchQuoteResponse`)
export const fetchQuoteRequest = createSagaAction(`${name}/fetchQuoteRequest`)
export const fetchQuoteRequests = createSagaAction(`${name}/fetchQuoteRequests`)
export const fetchUnseenQuotes = createSagaAction(`${name}/fetchUnseenQuotes`)
export const declineQuoteRequest = createSagaAction(`${name}/declineQuoteRequest`)
export const acceptQuoteRequest = createSagaAction(`${name}/acceptQuoteRequest`)
export const partnerQuote = createSagaAction(`${name}/partnerQuote`)
export const seeQuoteRequest = createSagaAction(`${name}/seeQuoteRequest`)

const handlePending = state => ({
  ...state,
  parallelTasks: state.parallelTasks + 1,
})

const handleRejected = (state, { error }) => ({
  ...state,
  error,
  parallelTasks: state.parallelTasks - 1,
})

const slice = createSlice({
  name,
  initialState,
  reducers: {
    clearQuoteRequest: state => ({
      ...state,
      quoteRequest: null,
    }),
    clearQuoteResponse: state => ({
      ...state,
      quoteResponse: null,
    }),
    clearQuotes: state => ({
      ...state,
      quoteRequests: quoteRequestsAdapter.getInitialState(),
      total: 0,
    }),
  },
  extraReducers: {
    // quoteRequests
    [fetchQuoteRequests.pending]: handlePending,
    [fetchQuoteRequests.fulfilled]: (state, { payload }) => {
      quoteRequestsAdapter.setAll(state.quoteRequests, payload.elements)
      state.parallelTasks = state.parallelTasks - 1
      state.total = payload.total
    },
    [fetchQuoteRequests.rejected]: handleRejected,
    // unseenQuoteRequests
    [fetchUnseenQuotes.pending]: handlePending,
    [fetchUnseenQuotes.fulfilled]: (state, { payload }) => {
      state.parallelTasks = state.parallelTasks - 1
      state.unseenQuotes = payload
    },
    [fetchUnseenQuotes.rejected]: handleRejected,
    // quoteRequest
    [fetchQuoteRequest.pending]: handlePending,
    [fetchQuoteRequest.fulfilled]: (state, { payload }) => ({
      ...state,
      quoteRequest: payload,
      parallelTasks: state.parallelTasks - 1,
    }),
    [fetchQuoteRequest.rejected]: handleRejected,
    //rejectQuoteRequests
    [declineQuoteRequest.pending]: handlePending,
    [declineQuoteRequest.fulfilled]: state => ({
      ...state,
      quoteRequest: { ...state.quoteRequest, status: 'REJECTED' },
      parallelTasks: state.parallelTasks - 1,
    }),
    [declineQuoteRequest.rejected]: (state, { payload }) => ({
      ...state,
      quoteResponse: payload,
      parallelTasks: state.parallelTasks - 1,
    }),
    //acceptQuoteResponse
    [acceptQuoteRequest.pending]: handlePending,
    [acceptQuoteRequest.fulfilled]: (state, { payload }) => ({
      ...state,
      quoteResponse: payload,
      quoteRequest: { ...state.quoteRequest, status: QuoteRequestStatus.Accepted },
      parallelTasks: state.parallelTasks - 1,
    }),
    [acceptQuoteRequest.rejected]: handleRejected,
    // quoteResponse
    [fetchQuoteResponse.pending]: handlePending,
    [fetchQuoteResponse.fulfilled]: (state, { payload }) => ({
      ...state,
      quoteResponse: payload,
      parallelTasks: state.parallelTasks - 1,
    }),
    [fetchQuoteResponse.rejected]: handleRejected,
    // partnerQuote
    [partnerQuote.pending]: handlePending,
    [partnerQuote.fulfilled]: state => ({
      ...state,
      parallelTasks: state.parallelTasks - 1,
    }),
    [partnerQuote.rejected]: handleRejected,
    // seeQuoteRequest
    [seeQuoteRequest.pending]: handlePending,
    [seeQuoteRequest.fulfilled]: state => ({
      ...state,
      parallelTasks: state.parallelTasks - 1,
    }),
    [seeQuoteRequest.rejected]: handleRejected,
  },
})

export const { clearQuoteRequest, clearQuoteResponse, clearQuotes } = slice.actions

export default slice.reducer
