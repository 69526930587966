import clsx from 'clsx'
import AvatarGroup from '@material-ui/lab/AvatarGroup'
import Typography from '@material-ui/core/Typography'
import EditIcon from '@material-ui/icons/EditOutlined'
import ViewIcon from '@material-ui/icons/VisibilityOutlined'
import LikeIcon from '@material-ui/icons/FavoriteBorderOutlined'
import { Grid, Card, Box, makeStyles, Avatar, IconButton } from '@material-ui/core'
import { Status } from 'modules/api'
import config from 'modules/config'
import InspirationStatus from './InspirationStatus'
import IconWithCounter from './IconWithCounter'

const imagesPath = config.app.IMAGES_URL

const useStyles = makeStyles(({ spacing }) => ({
  card: {
    padding: spacing(1),
    borderRadius: spacing(2),
    height: '120px',
    boxShadow: 'none',
    display: 'grid',
    gridTemplateColumns: '128px 138px minmax(300px, auto) 120px 284px 56px 90px',
  },
  avatar: {
    borderRadius: '16px',
    width: '52px',
    height: '52px',
    border: '4px solid #FFFFFF',
  },
  col: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
  },
  textCol: {
    justifyContent: 'flex-start',
    paddingLeft: '30px',
    paddingRight: '16px',
  },
  name: {
    fontWeight: 500,
    fontSize: '18px',
    color: '#495A69',
    WebkitLineClamp: 3,
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
  },
}))

const getStatus = (status, isActive) => {
  if (status === Status.Active) {
    return isActive ? 'ACTIVE' : 'HIDDEN'
  }
  return status
}

const InspirationItemCard = ({ id, coverImage, name, gallery, status, active, onClick }) => {
  const classes = useStyles()

  return (
    <Grid item>
      <Card className={classes.card} style={{ padding: 0 }}>
        <Box className={classes.col}>
          <Avatar src={`${imagesPath}/${coverImage}`} className={classes.avatar} />
        </Box>
        <Box className={classes.col} style={{ justifyContent: 'flex-start' }}>
          {!!gallery.length && (
            <AvatarGroup max={3} classes={{ avatar: classes.avatar }} spacing={8}>
              {gallery.map((item, i) => (
                <Avatar key={i.toString()} src={imagesPath + '/' + item.image} />
              ))}
            </AvatarGroup>
          )}
        </Box>
        <Box className={clsx(classes.col, classes.textCol)}>
          <Typography className={classes.name}>{name}</Typography>
        </Box>
        <Box className={classes.col}>
          <InspirationStatus variant={getStatus(status, active)} />
        </Box>
        <Box
          className={classes.col}
          style={{ columnGap: '14px', paddingLeft: '24px', paddingRight: '24px' }}>
          <IconWithCounter icon={<ViewIcon />} />
          <IconWithCounter icon={<LikeIcon />} />
        </Box>
        <Box className={classes.col}>
          <IconButton onClick={() => onClick({ id })}>
            <EditIcon />
          </IconButton>
        </Box>
      </Card>
    </Grid>
  )
}

export default InspirationItemCard
