import { Button as UIButton, ButtonProps as UIButtonProps } from '@material-ui/core'
import { TOptions, StringMap } from 'i18next'
import { useT } from 'modules/i18n'

export type ButtonProps = UIButtonProps & {
  children: string | React.ReactNode
  tParams?: TOptions<StringMap>
}

const Button = ({ children, tParams, ...props }: ButtonProps) => {
  const t = useT()

  return (
    <UIButton {...props}>{isString(children) ? t(children as string, tParams) : children}</UIButton>
  )
}

const isString = value => {
  return typeof value === 'string'
}

export default Button
