import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'
import { createSagaAction } from 'saga-toolkit'

const name = 'availability'

export const availabilitiesAdapter = createEntityAdapter()

const initialState = {
  loading: false,
  error: null,
  availabilities: availabilitiesAdapter.getInitialState(),
  availability: null,
  total: 0,
  limit: 50,
  offset: 0,
}

export const fetchAvailabilities = createSagaAction(`${name}/fetchAvailabilities`)
export const fetchAvailability = createSagaAction(`${name}/fetchAvailability`)
export const acceptAvailability = createSagaAction(`${name}/acceptAvailability`)
export const rejectAvailability = createSagaAction(`${name}/rejectAvailability`)

const handlePending = state => ({
  ...state,
  loading: true,
})

const handleRejected = (state, { error }) => ({
  ...state,
  error,
  loading: false,
})

const slice = createSlice({
  name,
  initialState,
  reducers: {
    clearAvailability: state => ({
      ...state,
      availability: null,
    }),
    setOffset: (state, { payload }) => ({
      ...state,
      offset: payload,
    }),
  },
  extraReducers: {
    // availabilities
    [fetchAvailabilities.pending]: handlePending,
    [fetchAvailabilities.fulfilled]: (state, { payload }) => {
      availabilitiesAdapter.setAll(state.availabilities, payload.availabilities)
      state.loading = false
      state.total = payload.total
    },
    [fetchAvailabilities.rejected]: handleRejected,

    // availability
    [fetchAvailability.pending]: handlePending,
    [fetchAvailability.fulfilled]: (state, { payload }) => ({
      ...state,
      availability: payload,
      loading: false,
    }),
    [fetchAvailability.rejected]: handleRejected,

    // rejectAvailability
    [rejectAvailability.pending]: handlePending,
    [rejectAvailability.fulfilled]: (state, { payload }) => {
      availabilitiesAdapter.updateOne(state.availabilities, {
        id: payload.id,
        changes: { status: 'rejected' },
      })
      state.loading = false
    },
    [rejectAvailability.rejected]: handleRejected,

    // acceptAvailability
    [acceptAvailability.pending]: handlePending,
    [acceptAvailability.fulfilled]: (state, { payload }) => {
      availabilitiesAdapter.updateOne(state.availabilities, {
        id: payload.id,
        changes: { status: 'accepted' },
      })
      state.loading = false
    },
    [acceptAvailability.rejected]: handleRejected,
  },
})

export const { clearAvailability, setOffset } = slice.actions

export default slice.reducer
