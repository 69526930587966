import { createSlice } from '@reduxjs/toolkit'
import { createSagaAction } from 'saga-toolkit'

const name = 'admin'

const initialState = {
  loading: false,
  error: null,
  healthCheck: null,
}

export const updateAdmin = createSagaAction(`${name}/updateAdmin`)
export const fetchHealthCheck = createSagaAction(`${name}/fetchHealthCheck`)
export const fixConversationIssues = createSagaAction(`${name}/fixConversationIssues`)

const handlePending = state => ({
  ...state,
  loading: true,
})

const handleRejected = (state, { error }) => ({
  ...state,
  error,
  loading: false,
})

const slice = createSlice({
  name,
  initialState,
  extraReducers: {
    // updateAdmin
    [updateAdmin.pending]: handlePending,
    [updateAdmin.fulfilled]: state => {
      state.loading = false
    },
    [updateAdmin.rejected]: handleRejected,

    // fetchHealthCheck
    [fetchHealthCheck.pending]: handlePending,
    [fetchHealthCheck.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.healthCheck = payload
    },
    [fetchHealthCheck.rejected]: handleRejected,

    // fixConversationIssues
    [fixConversationIssues.pending]: handlePending,
    [fixConversationIssues.fulfilled]: state => {
      state.loading = false
    },
    [fixConversationIssues.rejected]: handleRejected,
  },
})

export default slice.reducer
