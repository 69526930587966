import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as forgotPasswordActions from '../slice/forgotPassword'

const selectForgotPasswordState = state => state.auth.forgotPassword

const useForgotPassword = () => ({
  ...bindActionCreators(forgotPasswordActions, useDispatch()),
  ...useSelector(selectForgotPasswordState),
})

export default useForgotPassword
