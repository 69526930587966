import { useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { Dialog } from 'modules/ui'
import { RecommendForm, RecommendFormContextType, useNeed } from 'modules/need'
import { QuoteRequestStatus } from 'modules/api'

const RecommendPartnersDialog = () => {
  const { userNeed, updateUserNeed } = useNeed()
  const history = useHistory()
  const formRef = useRef<RecommendFormContextType>()
  const initialState = {
    recommendations: userNeed.partnersForYou.map(({ partner, status }) => ({ ...partner, status })),
  }

  const handleSubmit = async ({ recommendations }) => {
    try {
      await updateUserNeed({
        id: userNeed.id,
        input: {
          status: userNeed.status,
          need: userNeed.need.id,
          category: userNeed.category,
          // because `QuestionResponseInput` has no `question` field
          questionResponses: userNeed.questionResponses.map(qrs =>
            qrs.map(({ question, ...rest }) => rest),
          ),
          partnersForYou: recommendations.map(({ id, status }) => ({
            partnerId: id,
            status: status || QuoteRequestStatus.Pending,
            createdAt: new Date(),
            updatedAt: new Date(),
          })),
        },
      })
    } catch (e) {
      console.error('Error during userneed status update')
    }
  }

  const handleClickSubmit = () => formRef.current?.submitForm()

  const handleDialogClose = () => {
    history.goBack()
  }

  return (
    <Dialog
      title="Recommend partners for user need"
      closeButtonText="Cancel"
      confirmButtonText="Save"
      onClose={handleDialogClose}
      onConfirm={handleClickSubmit}>
      <RecommendForm
        innerRef={formRef}
        initialValues={initialState}
        categoryId={userNeed.category}
        onSubmit={handleSubmit}
      />
    </Dialog>
  )
}

export default RecommendPartnersDialog
