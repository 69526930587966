import clsx from 'clsx'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  root: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    background: 'linear-gradient(125deg, #FEC140 0%, #FA709A 100%) 0% 0% no-repeat padding-box',
  },
}))

const GradientPage = ({ children, className, ...props }) => {
  const classes = useStyles()

  return (
    <Box className={clsx(classes.root, className)} {...props}>
      {children}
    </Box>
  )
}

export default GradientPage
