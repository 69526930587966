import { useHistory } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core'
import { useProfile } from 'modules/profile'
import { Page, Typography } from 'modules/ui'
import { UserType } from 'modules/api'
import { LatestWeddingsCard, WelcomeCard } from 'modules/dashboard'

const useStyles = makeStyles(() => ({
  root: {
    display: 'grid',
    gridTemplateColumns: 'minmax(360px, 1fr) minmax(400px, 500px)',
    gridColumnGap: '13px',
  },
}))

const Home = () => {
  const { user } = useProfile()
  const classes = useStyles()
  const history = useHistory()

  if (!user) {
    return null
  }

  const active = user.status === 'ACTIVE'

  if (user.type === UserType.Admin) {
    return <Typography>Yo {user.name}!</Typography>
  }

  const hideWeddings = user?.category?.hide

  const handleEditProfile = () => {
    history.push('/profile')
  }

  const handleNewInspiration = () => {
    history.push('/inspiration/new')
  }

  return (
    <Page disablePadding disableBackground maxWidth="lg">
      <Box className={classes.root}>
        <WelcomeCard
          handleEditProfile={handleEditProfile}
          handleNewInspiration={handleNewInspiration}
        />
        {active && !hideWeddings && <LatestWeddingsCard />}
      </Box>
    </Page>
  )
}

export default Home
