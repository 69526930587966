import { createAction, createSlice } from '@reduxjs/toolkit'
import { createSagaAction } from 'saga-toolkit'

const name = 'tracking'

const initialState = {
  trackingStatus: null,
  initialized: false,
  loading: false,
  error: null,
}

export const init = createSagaAction(`${name}/init`)
export const track = createAction(`${name}/track`, (name, properties) => ({
  payload: {
    name,
    properties,
  },
}))
export const identify = createAction(`${name}/identify`)
export const flush = createSagaAction(`${name}/flush`)

const slice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: {
    [init.pending]: state => ({
      ...state,
      loading: true,
    }),
    [init.fulfilled]: state => ({
      ...state,
      initialized: true,
      loading: false,
    }),
    [init.rejected]: (state, { error }) => ({
      ...state,
      error,
      loading: false,
    }),
  },
})

export default slice.reducer
