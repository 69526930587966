import { useEffect, useRef } from 'react'
import * as yup from 'yup'
import { Form, Formik, useFormikContext } from 'formik'
import MenuItem from '@material-ui/core/MenuItem'
import { makeStyles } from '@material-ui/core/styles'
import { useBlog } from 'modules/blog'
import { useCategory } from 'modules/category'
import { Checkbox, Select, SubmitButton, TextField } from 'modules/formik'
import { usePartner } from 'modules/partner'
import { useTagGroup } from 'modules/tagGroup'
import { HighlightTypes, HighlightVariants } from 'modules/api'
import { Typography } from 'modules/ui'
import HighlightAutoComplete from './HighlightAutoComplete'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  fileUpload: {
    alignItems: 'flex-start',
  },
}))

const validationSchema = yup.object({
  name: yup
    .string('Enter name')
    .min(3, 'Should be of minimum 3 characters length')
    .required('Name is required'),
})

const initialState = {
  name: '',
  type: '',
  variant: '',
  randomized: false,
  description: '',
  elements: [],
}
const variants = [
  { name: 'Tag', value: HighlightVariants.Tag },
  { name: 'Wide', value: HighlightVariants.Wide },
  { name: 'Wide alternative', value: HighlightVariants.WideAlt },
  { name: 'Primary', value: HighlightVariants.Primary },
  { name: 'Primary wide', value: HighlightVariants.PrimaryWide },
  { name: 'Secondary', value: HighlightVariants.Secondary },
  { name: 'Secondary alternative', value: HighlightVariants.SecondaryAlt },
  { name: 'Image', value: HighlightVariants.Image },
]
const types = [
  { name: 'Blog', value: HighlightTypes.Blog },
  { name: 'Category', value: HighlightTypes.Category },
  { name: 'Partner', value: HighlightTypes.Partner },
  { name: 'Tag Group', value: HighlightTypes.TagGroup },
]

const FormBody = () => {
  const { values, setFieldValue } = useFormikContext()
  const { searchPartner } = usePartner()
  const { searchCategory } = useCategory()
  const { searchTagGroup } = useTagGroup()
  const { searchBlog } = useBlog()

  const classes = useStyles()
  const didMountRef = useRef(false)

  useEffect(() => {
    didMountRef.current ? setFieldValue('elements', []) : (didMountRef.current = true)
  }, [values.type])

  const getSearchFunction = () => {
    switch (values.type) {
      case 'PARTNER':
        return searchTerm => searchPartner({ filter: searchTerm })
      case 'CATEGORY':
        return searchTerm => searchCategory({ filter: searchTerm })
      case 'TAG_GROUP':
        return searchTerm => searchTagGroup({ filter: searchTerm })
      case 'BLOG':
        return searchTerm => searchBlog({ filter: searchTerm })
      default:
        break
    }
  }
  const searchFunction = getSearchFunction()

  return (
    <Form className={classes.root} noValidate autoComplete="off">
      <TextField id="name" name="name" label="Name" margin="normal" fullWidth />
      <TextField
        id="description"
        name="description"
        label="Description"
        margin="normal"
        fullWidth
        multiline
        rows={6}
      />
      <Select name="type" label="Type" margin="normal" fullWidth>
        {types.map(({ name, value }, i) => (
          <MenuItem key={i} value={value}>
            {name}
          </MenuItem>
        ))}
      </Select>
      <Select name="variant" label="Variant" margin="normal" fullWidth>
        {variants.map(({ name, value }, i) => (
          <MenuItem key={i} value={value}>
            {name}
          </MenuItem>
        ))}
      </Select>
      <Checkbox name="randomized" fullWidth label={<Typography>Randomized order</Typography>} />
      <HighlightAutoComplete
        id="elements"
        name="elements"
        label="Elements"
        margin="normal"
        fullWidth
        filterSelectedOptions
        multiple
        disableClearable
        searchFunction={searchFunction}
      />
      <SubmitButton color="primary" variant="contained" size="small">
        Save
      </SubmitButton>
    </Form>
  )
}

const HighlightForm = ({ initialValues = initialState, ...props }) => {
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues} {...props}>
      <FormBody />
    </Formik>
  )
}

export default HighlightForm
