import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'
import { Typography, TextField } from 'modules/ui'
import config from 'modules/config'

const imagesPath = config.app.IMAGES_URL

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  header: {
    display: 'flex',
  },
  title: {
    flex: 1,
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
}))

const ConversationsHead = ({
  onSearchChange,
  profileImage,
  actionIconsComponent: ActionIconsComponent = () => null,
}) => {
  const classes = useStyles()

  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12}>
        <div className={classes.header}>
          <Avatar src={imagesPath + '/' + (profileImage?.name || '')} />
          <Typography className={classes.title} variant="h6">
            Conversations
          </Typography>
          <ActionIconsComponent />
        </div>
      </Grid>
      <Grid item xs={12}>
        <TextField placeholder="Search..." fullWidth onChange={onSearchChange} />
      </Grid>
    </Grid>
  )
}

export default ConversationsHead
