import BaseImageUploader from 'react-images-upload'
import { useT } from 'modules/i18n'

const isString = value => {
  return typeof value === 'string'
}

export const ImageUploader = ({ label, buttonText, ...props }) => {
  const t = useT()

  return (
    <BaseImageUploader
      label={isString(label) ? t(label) : label}
      buttonText={isString(buttonText) ? t(buttonText) : buttonText}
      {...props}
    />
  )
}

export default ImageUploader
