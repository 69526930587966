import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'
import { createSagaAction } from 'saga-toolkit'

const name = 'need'

export const needsAdapter = createEntityAdapter()
export const weddingsAdapter = createEntityAdapter()

const initialState = {
  loading: false,
  error: null,
  needs: needsAdapter.getInitialState(),
  need: null,
  total: 0,
  limit: 12,
  offset: 0,
  weddingsByNeed: {
    weddings: weddingsAdapter.getInitialState(),
    total: 0,
  },
  userNeed: null,
  userNeeds: [],
  userNeedsSummary: [],
}

export const fetchNeeds = createSagaAction(`${name}/fetchNeeds`)
export const fetchAllNeeds = createSagaAction(`${name}/fetchAllNeeds`)
export const fetchNeed = createSagaAction(`${name}/fetchNeed`)
export const fetchWeddingsByNeed = createSagaAction(`${name}/fetchWeddingsByNeed`)
export const fetchUserNeedForAdmin = createSagaAction(`${name}/fetchUserNeedForAdmin`)
export const fetchUserNeedCategorySummary = createSagaAction(`${name}/fetchUserNeedCategorySummary`)
export const fetchUserNeedForPartner = createSagaAction(`${name}/fetchUserNeedForPartner`)
export const fetchUserNeeds = createSagaAction(`${name}/fetchUserNeeds`)
export const fetchUserNeedsForAdmin = createSagaAction(`${name}/fetchUserNeedsForAdmin`)
export const insertNeed = createSagaAction(`${name}/insertNeed`)
export const updateNeed = createSagaAction(`${name}/updateNeed`)
export const removeNeed = createSagaAction(`${name}/removeNeed`)
export const seenUserNeed = createSagaAction(`${name}/seenUserNeed`)
export const updateUserNeed = createSagaAction(`${name}/updateUserNeed`)

const handlePending = state => ({
  ...state,
  loading: true,
})

const handleRejected = (state, { error }) => ({
  ...state,
  error,
  loading: false,
})

const slice = createSlice({
  name,
  initialState,
  reducers: {
    clearNeed: state => ({
      ...state,
      need: null,
    }),
    setOffset: (state, { payload }) => ({
      ...state,
      offset: payload,
    }),
    clear: () => initialState,
  },
  extraReducers: {
    // fetchNeeds
    [fetchNeeds.pending]: handlePending,
    [fetchNeeds.fulfilled]: (state, { payload }) => {
      needsAdapter.setAll(state.needs, payload.elements)
      state.loading = false
      state.total = payload.total
    },
    [fetchNeeds.rejected]: handleRejected,

    //fetchAllneeds
    [fetchAllNeeds.pending]: handlePending,
    [fetchAllNeeds.fulfilled]: (state, { payload }) => {
      needsAdapter.setAll(state.needs, payload)
      state.loading = false
    },
    [fetchAllNeeds.rejected]: handleRejected,

    // fetchNeed
    [fetchNeed.pending]: handlePending,
    [fetchNeed.fulfilled]: (state, { payload }) => ({
      ...state,
      need: payload,
      loading: false,
    }),
    [fetchNeed.rejected]: handleRejected,

    // fetchUserNeedForAdmin
    [fetchUserNeedForAdmin.pending]: handlePending,
    [fetchUserNeedForAdmin.fulfilled]: (state, { payload }) => ({
      ...state,
      userNeed: payload,
      loading: false,
    }),
    [fetchUserNeedForAdmin.rejected]: handleRejected,

    // fetchUserNeedCategorySummary
    [fetchUserNeedCategorySummary.pending]: handlePending,
    [fetchUserNeedCategorySummary.fulfilled]: (state, { payload }) => ({
      ...state,
      userNeedsSummary: payload,
      loading: false,
    }),
    [fetchUserNeedCategorySummary.rejected]: handleRejected,

    // fetchUserNeedForPartner
    [fetchUserNeedForPartner.pending]: (state, { meta: { arg } }) => ({
      ...state,
      userNeed: arg.id === state.userNeed?.id ? { ...state.userNeed } : null,
      loading: true,
    }),
    [fetchUserNeedForPartner.fulfilled]: (state, { payload }) => ({
      ...state,
      userNeed: payload,
      loading: false,
    }),
    [fetchUserNeedForPartner.rejected]: handleRejected,

    // fetchUserNeeds
    [fetchUserNeeds.pending]: handlePending,
    [fetchUserNeeds.fulfilled]: (state, { payload }) => {
      state.userNeeds = payload.elements
      state.loading = false
      state.total = payload.total
    },
    [fetchUserNeeds.rejected]: handleRejected,

    // fetchUserNeedsForAdmin
    [fetchUserNeedsForAdmin.pending]: handlePending,
    [fetchUserNeedsForAdmin.fulfilled]: (state, { payload }) => {
      state.userNeeds = payload.elements
      state.loading = false
      state.total = payload.total
    },
    [fetchUserNeedsForAdmin.rejected]: handleRejected,

    // insertNeed
    [insertNeed.pending]: handlePending,
    [insertNeed.fulfilled]: state => ({
      ...state,
      loading: false,
    }),
    [insertNeed.rejected]: handleRejected,

    // updateNeed
    [updateNeed.pending]: handlePending,
    [updateNeed.fulfilled]: (state, { payload }) => {
      needsAdapter.updateOne(state.needs, { id: payload.id, changes: payload })
      state.blog = { ...state.blog, ...payload }
      state.loading = false
    },
    [updateNeed.rejected]: handleRejected,

    // fetchWeddingsByNeed
    [fetchWeddingsByNeed.pending]: handlePending,
    [fetchWeddingsByNeed.fulfilled]: (state, { payload }) => {
      weddingsAdapter.setAll(state.weddingsByNeed.weddings, payload)
      state.loading = false
      state.weddingsByNeed.total = payload.length
    },
    [fetchWeddingsByNeed.rejected]: handleRejected,

    // seenUserNeed
    [seenUserNeed.pending]: handlePending,
    [seenUserNeed.fulfilled]: state => ({
      ...state,
      loading: false,
    }),
    [seenUserNeed.rejected]: handleRejected,

    // updateUserNeed
    [updateUserNeed.pending]: handlePending,
    [updateUserNeed.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        userNeed: { ...state.userNeed, ...payload },
        loading: false,
      }
    },
    [updateUserNeed.rejected]: handleRejected,
  },
})

export const { clearNeed, clear, setOffset, setPage } = slice.actions

export default slice.reducer
