import { bindActionCreators } from 'redux'
import { useDispatch, useSelector } from 'modules/app'
import * as actions from './slice'
import * as selectors from './selectors'

export const useChat = () => {
  const conversations = useSelector(selectors.selectAllConversations)
  const unseenConversations = useSelector(selectors.selectUnseenConversations)
  const findCoversationById = id => conversations.find(conversation => conversation.id === id)
  const findCoversationsByUserId = userId =>
    conversations.filter(({ participants }) => participants.map(({ id }) => id).includes(userId))

  return {
    ...bindActionCreators(actions, useDispatch()),
    ...useSelector(selectors.selectRoot),
    conversations,
    unseenConversations,
    findCoversationById,
    findCoversationsByUserId,
  }
}
