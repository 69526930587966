import moment from 'moment'
import { useHistory, useRouteMatch } from 'react-router'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  makeStyles,
} from '@material-ui/core'
import { Button, Page, Typography, OpenableImage, NavButton } from 'modules/ui'
import { UserNeed, UserNeedStatus, Image, Wedding, QuoteResponse } from 'modules/api'
import { useNeed, createColumns, RecommendedPartnersList } from 'modules/need'
import { formatPlannedDate, formatPlannedLocation, QuoteResponseStatusChip } from 'modules/quote'
import QuoteTypeBadge, {
  getDefaultQuoteTypeBadgeProps,
} from 'modules/quote/components/QuoteTypeBadge'
import { CustomNeedList } from 'modules/quote'
import { useT } from 'modules/i18n'
import config from 'modules/config'
import { updateStatus } from './List'

const imagesPath = config.app.IMAGES_URL
const attachmentImageSize = 120

const useStyles = makeStyles(theme => ({
  weddingDetails: {
    'marginTop': theme.spacing(2),
    '& p': {
      display: 'inline',
    },
  },
  attachedImages: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(3),
    margin: `${theme.spacing(2)}px 0`,
  },
  attachedImage: {
    width: attachmentImageSize,
    height: attachmentImageSize,
    objectFit: 'cover',
    borderRadius: theme.spacing(3),
  },
  qrList: {
    maxWidth: 750,
  },
  qrName: {
    flexBasis: 300,
  },
  navButton: {
    fontSize: '0.875rem',
    fontWeight: 500,
    textTransform: 'uppercase',
  },
  quoteTypeBadge: {
    marginRight: theme.spacing(1),
  },
}))

const AuxButton = props => <Button color="primary" variant="contained" size="small" {...props} />

const View = () => {
  const history = useHistory()
  const match = useRouteMatch()
  const { userNeed, updateUserNeed }: { userNeed: UserNeed; updateUserNeed: any } = useNeed()
  const t = useT()
  const columns = createColumns({ RowActions: null, t }).filter(c => c.valueGetter)

  if (!userNeed) {
    return null
  }

  const handleStatusUpdate = (status: UserNeedStatus) => {
    updateStatus(userNeed, updateUserNeed, history, status)
  }

  const handleClickRecommendPartners = () => {
    history.push(`${match.url}/recommend-partners`)
  }

  const handleClickEditAdminText = () => {
    history.push(`${match.url}/edit-admin-text`)
  }

  return (
    <Page>
      <Grid container direction="column">
        <Grid item>
          <Typography variant="h5" gutterBottom>
            {userNeed.wedding.couple.name} /{' '}
            {columns.find(({ field }) => field === 'need')?.valueGetter(userNeed)}
          </Typography>
          <Box display="flex" mt={4} mx={-1}>
            {columns.map(column => (
              <Box key={column.field} mx={1}>
                <Typography variant="subtitle2">{column.headerName}</Typography>
                {column.valueGetter(userNeed)}
              </Box>
            ))}
          </Box>
        </Grid>

        <WeddingDetails wedding={userNeed.wedding} />
        <CustomNeedList questionResponses={userNeed.questionResponses} />

        <Grid item>
          <Box mb={4}>
            <Typography variant="h5" gutterBottom>
              Textual addition
            </Typography>
            <Typography gutterBottom>{userNeed.adminText || userNeed.text || '-'}</Typography>
            <AuxButton variant="text" onClick={handleClickEditAdminText}>
              {userNeed.adminText || userNeed.text ? 'Edit' : 'Add'}
            </AuxButton>
          </Box>
        </Grid>

        <AttachedImages images={userNeed.images} />
        <QuotesReceived quoteResponses={userNeed.quoteResponses} />

        <Grid item>
          <Box style={{ minHeight: '400px' }} mb={4}>
            <Typography variant="h5" gutterBottom>
              Recommended partners
            </Typography>
            <RecommendedPartnersList
              items={userNeed.partnersForYou.map(({ partner, status }) => ({
                id: partner.id,
                name: partner.name,
                status,
              }))}
              style={{ minHeight: '400px', marginBottom: 4 }}
            />
            <AuxButton variant="text" onClick={handleClickRecommendPartners}>
              Recommend partners
            </AuxButton>
          </Box>
        </Grid>

        <Grid item>
          {userNeed.status === UserNeedStatus.Accepted && (
            <AuxButton onClick={() => handleStatusUpdate(UserNeedStatus.Archived)}>
              Archive
            </AuxButton>
          )}
          {userNeed.status !== UserNeedStatus.Accepted && (
            <AuxButton onClick={() => handleStatusUpdate(UserNeedStatus.Accepted)}>
              Approve
            </AuxButton>
          )}
        </Grid>
      </Grid>
    </Page>
  )
}

export default View

type QuotesReceivedProps = {
  quoteResponses: QuoteResponse[]
}

const QuotesReceived = (props: QuotesReceivedProps) => {
  const classes = useStyles()
  const t = useT()

  if (!props.quoteResponses || props.quoteResponses.length === 0) return null

  return (
    <>
      <Typography variant="h5">Quotes received</Typography>
      <Grid container direction="column" className={classes.qrList}>
        {props.quoteResponses.map(qr => (
          <Grid key={qr.id} item>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item className={classes.qrName}>
                {qr.partner.name}
              </Grid>
              <Grid item>
                <QuoteTypeBadge
                  {...getDefaultQuoteTypeBadgeProps(qr.quoteRequest.type)}
                  className={classes.quoteTypeBadge}
                />
              </Grid>
              <Grid item>
                <QuoteResponseStatusChip status={qr.status} />
              </Grid>
              <Grid item>{moment(qr.createdAt).format('YYYY.MM.DD. HH:mm')}</Grid>
              <Grid item>
                <NavButton
                  className={classes.navButton}
                  to={`/quotes/${qr.requestId}/view-response`}
                  tooltip="View">
                  {t('View')}
                </NavButton>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </>
  )
}

type WeddingDetailsProps = {
  wedding: Wedding
}

const WeddingDetails = (props: WeddingDetailsProps) => {
  const classes = useStyles()
  const t = useT()

  return (
    <Box className={classes.weddingDetails}>
      <Box mb={1}>
        <Typography variant="subtitle2">Wedding date</Typography>
        {formatWeddingDate(props.wedding, t)}
      </Box>
      <Box mb={1}>
        <Typography variant="subtitle2">Wedding location</Typography>
        {formatWeddingLocation(props.wedding)}
      </Box>
      <Box mb={1}>
        <Typography variant="subtitle2">Guests</Typography>
        {formatGuestRange(props.wedding)}
      </Box>
    </Box>
  )
}

type AttachedImagesProps = {
  images: Image[]
}

const AttachedImages = (props: AttachedImagesProps) => {
  const classes = useStyles()

  if (!props.images || props.images.length === 0) return null

  return (
    <>
      <Typography variant="h5">Attached images</Typography>
      <Box className={classes.attachedImages}>
        {props.images.map(({ name }) => (
          <OpenableImage
            key={name}
            className={classes.attachedImage}
            queryParams={`height=${attachmentImageSize}`}
            src={`${imagesPath}/${name}`}
          />
        ))}
      </Box>
    </>
  )
}

function formatWeddingDate(wedding: Wedding, t): string {
  if (wedding.date) return moment(wedding.date).format('YYYY.MM.DD.')
  return formatPlannedDate(wedding, t)
}

function formatWeddingLocation(wedding: Wedding): string {
  return wedding.address?.name || formatPlannedLocation(wedding)
}

function formatGuestRange(wedding: Wedding): string {
  const { low, high } = wedding.guestRange || { low: '?', high: '?' }
  return `${low}-${high}`
}
