import { CircularProgress } from '@material-ui/core'
import { useFormikContext } from 'formik'
import { Button, ButtonProps } from 'modules/ui'
import { useT } from 'modules/i18n'

export type SubmitButtonProps = ButtonProps & {
  progressSize?: number
}

export const SubmitButton = ({
  children,
  disabled = false,
  progressSize = 16,
  ...props
}: SubmitButtonProps) => {
  const { isSubmitting } = useFormikContext()
  const t = useT()

  return (
    <Button type="submit" {...props} disabled={isSubmitting || disabled}>
      {typeof children === 'string' ? t(children) : children}
      {isSubmitting && (
        <CircularProgress
          size={progressSize}
          style={{
            color: 'white',
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: `-${progressSize / 2}px`,
            marginLeft: `-${progressSize / 2}px`,
          }}
        />
      )}
    </Button>
  )
}
