import { useContext, useEffect } from 'react'
import { Route, useRouteMatch, useHistory } from 'react-router-dom'
import { SignUpStateProvider, SignUpStepper, SingUpContext } from 'modules/auth'
import { Link } from 'modules/ui'
import SignIn from './SignIn'
import Company from './Company'
import Registration from './Registration'
import Verification from './Verification'
import Details from './Details'
import Contact from './Contact'

const SignUp = () => {
  const history = useHistory()
  const match = useRouteMatch()
  const { lastPath, setLastPath, state } = useContext(SingUpContext)
  const { userId: partnerId, type } = state
  const { pathname } = history.location

  const handleSkipToVerification = () => {
    history.push(`${match.url}/verification`)
  }

  const steps = [
    {
      label: 'Base information',
      path: '/company',
      infoText: (
        <>
          Kérlek, add meg a szolgáltatásod nevét, kategóriáját, illetve írj egy rövid bemutatkozót a
          párok számára, valamint válaszd ki a listából a tevékenységedhez illő címkéket!
          <br />
          Amennyiben már korábban megkezdett regisztrációt szeretnél folytatni és beírni az
          email-ben érkezett hitelesítő kódot,
          <Link color="primary" onClick={handleSkipToVerification} style={{ cursor: 'pointer' }}>
            skip to the verification page
          </Link>
        </>
      ),
    },
    {
      label: 'Details',
      path: '/details',
      infoText:
        'Kérlek, hogy add meg a profilodon nyilvánosan megjeleníteni kívánt adatokat, melyeket a párok is látni fognak!',
    },
    {
      label: 'Contact',
      path: '/contact',
      infoText:
        'Kérlek, hogy add meg a kapcsolattartói adataidat! Ezek az adatok nem lesznek nyilvánosak! Fontos, hogy az itt megadott e-mail cím lesz a bejelentkezési címed, illetve ezen keresztül fogod kapni a rendszer értesítéseket!',
    },
    {
      label: 'Registration',
      path: '/registration',
      infoText:
        'Kérlek adj meg egy jelszót a regisztrációhoz! Ügyelj arra, hogy legalább 8 karakterből álljon, és tartalmazzon legalább egy nagybetűt, illetve számot.',
    },
    {
      label: 'Verification',
      path: '/verification',
      infoText:
        'Kérlek, add meg az email-ben kapott ellenőrző kódot, hogy megerősítsük a regisztrációdat!',
    },
    {
      label: 'Sign In',
      path: '/sign-in',
      infoText: 'Gratulálunk! Elkészült a Wedding Manager profilod!',
    },
  ]
  const activeStep = steps.indexOf(
    steps.find(({ path }) => `${match.url}${path}` === location.pathname) || steps[0],
  )

  useEffect(() => {
    if (type === 'ADMIN' && state.email !== '' && pathname === match.url) {
      history.push(`${match.url}/registration`)
      return
    }

    if (type !== 'ADMIN' && !partnerId && !lastPath && pathname !== match.url) {
      history.push(match.url)
      return
    }

    setLastPath(pathname)
  }, [pathname, lastPath, setLastPath, history, match.url, partnerId])

  return (
    <SignUpStepper steps={steps} activeStep={activeStep}>
      <Route path={[match.url, `${match.url}/company`]} exact component={Company} />
      <Route path={`${match.url}/details`} exact component={Details} />
      <Route path={`${match.url}/contact`} exact component={Contact} />
      <Route path={`${match.url}/registration`} exact component={Registration} />
      <Route path={`${match.url}/verification`} exact component={Verification} />
      <Route path={`${match.url}/sign-in`} exact component={SignIn} />
    </SignUpStepper>
  )
}

const SignUpWithProvider = () => (
  <SignUpStateProvider>
    <SignUp />
  </SignUpStateProvider>
)

export default SignUpWithProvider
