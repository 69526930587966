import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Dialog, TextField } from 'modules/ui'
import { useNeed } from 'modules/need'
import { UserNeed } from 'modules/api'

const EditAdminTextDialog = () => {
  const { userNeed, updateUserNeed }: { userNeed: UserNeed; updateUserNeed: any } = useNeed()
  const history = useHistory()
  const [adminText, setAdminText] = useState('')

  const handleClickConfirm = async () => {
    await updateUserNeed({
      id: userNeed.id,
      input: {
        need: userNeed.need.id,
        category: userNeed.category,
        status: userNeed.status,
        // workaround because `QuestionResponseInput` has no `question` field
        questionResponses: userNeed.questionResponses.map(qrs =>
          qrs.map(({ question, ...rest }) => rest),
        ),
        text: userNeed.text,
        adminText,
      },
    })
  }

  const handleDialogClose = () => {
    history.goBack()
  }

  useEffect(() => {
    setAdminText(userNeed.adminText || userNeed.text)
  }, [userNeed.adminText, userNeed.text])

  return (
    <Dialog
      title="Edit additional text"
      closeButtonText="Cancel"
      confirmButtonText="Save"
      onClose={handleDialogClose}
      onConfirm={handleClickConfirm}>
      <TextField
        label="Textual addition"
        placeholder=""
        helperText=""
        multiline
        value={adminText}
        onChange={e => setAdminText(e.target.value)}
        fullWidth
      />
    </Dialog>
  )
}

export default EditAdminTextDialog
