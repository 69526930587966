import { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { CompanyForm } from 'modules/auth'
import { useCategory } from 'modules/category'
import { SingUpContext } from 'modules/auth'

const Company = () => {
  const { categories, loading: categoryLoading } = useCategory()
  const history = useHistory()
  const { state, setState } = useContext(SingUpContext)

  const submitForm = values => {
    setState({ ...state, ...values })
    history.push('/sign-up/details')
  }

  const handleSkipToVerificationClick = () => {
    history.push('/sign-up/verification')
  }

  return (
    <CompanyForm
      submitForm={submitForm}
      categories={categories}
      categoryLoading={categoryLoading}
      onSkipToVerificationClick={handleSkipToVerificationClick}
      state={state}
    />
  )
}

export default Company
