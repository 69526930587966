import { gql } from 'graphql-tag'

export const paginatedCategories = gql`
  query SearchCategories($input: CategoryFilter!) {
    paginatedCategories(input: $input) {
      elements {
        id
        name
        image {
          name
        }
        tags {
          id
          name
        }
        ordering
        extraFields
        hide
      }
    }
  }
`
