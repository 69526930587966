import { Box, makeStyles, BoxProps } from '@material-ui/core'
import SchoolIcon from '@material-ui/icons/School'
import clsx from 'clsx'
import { Typography } from 'modules/ui'

interface HelpFieldProps extends BoxProps {
  text: string
}

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    marginTop: spacing(2),
    marginBottom: spacing(2),
    backgroundColor: 'white',
    padding: spacing(2),
    border: '1px dashed lightGrey',
    borderRadius: spacing(2),
  },
  highlight: {
    width: 36,
    minWidth: 36,
    display: 'flex',
    height: 36,
    backgroundColor: palette.primary.main,
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    borderRadius: '100%',
    marginRight: spacing(2),
  },
  text: {
    display: 'inline',
    alignSelf: 'center',
    color: 'grey',
  },
}))

const HelpField = ({ text, className, ...props }: HelpFieldProps) => {
  const classes = useStyles()

  return (
    <Box
      className={clsx(classes.root, className)}
      padding="xl"
      color="primary"
      display={'inline-flex'}
      border="dashed"
      {...props}>
      <Box className={classes.highlight}>
        <SchoolIcon style={{ color: 'white' }} display="inline" />
      </Box>
      <Typography className={classes.text}>{text}</Typography>
    </Box>
  )
}

export default HelpField
