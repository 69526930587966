import { useState } from 'react'
import { unwrapResult } from '@reduxjs/toolkit'
import { Tab, Tabs } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import { useCategory } from 'modules/category'
import { Status } from 'modules/api'
import { NavButton, Page, Button } from 'modules/ui'
import { useItem } from 'modules/item'
import { PartnerDetail, PartnerWallet, processPartnerFormSubmit, usePartner } from 'modules/partner'

const RowActions = ({ row }) => {
  return (
    <>
      <NavButton to={`/items/${row.id}/edit`} tooltip="Edit">
        <EditIcon fontSize="small" />
      </NavButton>
      <NavButton to={`/items/${row.id}/remove`} tooltip="Remove">
        <DeleteIcon fontSize="small" />
      </NavButton>
    </>
  )
}

const Edit = () => {
  const { partner, updatePartner, activatePartner, addTokenToPartner } = usePartner()
  const { categories, loading: categoryLoading } = useCategory()
  const [submitting, setSubmitting] = useState(false)
  const [tab, setTab] = useState(0)
  const { items, loading, fetchItems, total, limit, offset, setOffset } = useItem()

  const handleSubmit = async data => {
    setSubmitting(true)
    const processedPartner = await processPartnerFormSubmit(data)
    await updatePartner(processedPartner)
    setSubmitting(false)
  }

  const handleActive = async () => {
    setSubmitting(true)
    await activatePartner({ id: partner.id })
    setSubmitting(false)
  }

  const onAddTransaction = async ({ amount, action, note }, { resetForm }) => {
    try {
      const operation = action === 'add' ? amount : -amount
      unwrapResult(await addTokenToPartner({ partnerId: partner.id, amount: operation, note }))
      resetForm()
    } catch (e) {
      throw e
    }
  }

  if (!partner) {
    return 'Loading...'
  }

  const initialValues = {
    ...partner,
    ...(!!partner.guestRange
      ? { guestRange: [partner.guestRange.low, partner.guestRange.high] }
      : { guestRange: [0, 1] }),
  }

  return (
    <Page fullHeight={tab === 1} title={`Edit "${partner.name}"`}>
      <Tabs
        value={tab}
        indicatorColor="primary"
        textColor="primary"
        onChange={(_, newValue) => setTab(newValue)}>
        <Tab label="Details" />
        <Tab label="Wallet" />
      </Tabs>
      {tab === 0 ? (
        <PartnerDetail
          categoryLoading={categoryLoading}
          initialValues={initialValues}
          categories={categories}
          handleSubmit={handleSubmit}
          submitting={submitting}
          items={items}
          loading={loading}
          RowActions={RowActions}
          fetchItems={fetchItems}
          total={total}
          setOffset={setOffset}
          offset={offset}
          limit={limit}
          actionButtons={
            partner.status !== Status.Active && (
              <Button variant="contained" color="primary" onClick={handleActive}>
                Activate
              </Button>
            )
          }
        />
      ) : (
        <PartnerWallet partner={partner} onAddTransaction={onAddTransaction} />
      )}
    </Page>
  )
}

export default Edit
