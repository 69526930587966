import React from 'react'
import { Typography as UITypography, TypographyProps as UITypographyProps } from '@material-ui/core'
import { useT } from 'modules/i18n'

export interface TypographyProps extends UITypographyProps {
  tParams?: any
  component?: React.ElementType
}

export const Typography: React.FC<TypographyProps> = ({ children, tParams, ...props }) => {
  const t = useT()

  return (
    <UITypography {...props}>
      {isString(children) ? t(children as string, tParams) : children}
    </UITypography>
  )
}

const isString = value => {
  return typeof value === 'string'
}

export default Typography
