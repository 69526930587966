import React from 'react'
import { Typography } from 'modules/ui'

const FormError = ({ message }) => {
  return (
    <>
      <Typography color="error" variant="caption" gutterBottom>
        Error
      </Typography>
      <Typography color="error" variant="body2" gutterBottom>
        {message}
      </Typography>
    </>
  )
}

export default FormError
