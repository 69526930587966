import { makeStyles } from '@material-ui/core/styles'
import BaseCheckbox, { CheckboxProps as BaseCheckboxProps } from '@material-ui/core/Checkbox'
import FormControl, { FormControlProps } from '@material-ui/core/FormControl'
import clsx from 'clsx'
import { FormHelperText, FormControlLabel } from 'modules/ui'

const useStyles = makeStyles(theme => ({
  root: {},
}))

export interface CheckboxProps extends Omit<FormControlProps, 'onChange'> {
  label?: React.ReactNode
  name?: string
  value?: string
  checked?: boolean
  helperText?: string
  onChange?: BaseCheckboxProps['onChange']
}

const Checkbox = ({
  name,
  value,
  checked,
  label,
  helperText,
  className,
  onChange,
  ...props
}: CheckboxProps) => {
  const classes = useStyles()

  return (
    <FormControl {...props} className={clsx(classes.root, className)}>
      <FormControlLabel
        label={label}
        control={<BaseCheckbox {...{ name, value, checked, onChange }} color="primary" />}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}

export default Checkbox
