import { withStyles } from '@material-ui/core/styles'
import BaseSwitch from '@material-ui/core/Switch'
import { Typography } from 'modules/ui'
import { Status } from 'modules/api'

const getLabelForStatus = status => {
  switch (status) {
    case 'ACTIVE':
      return 'Your account is active!'
    case 'suspended':
      return 'Your profile is suspended! Please contact us!'
    default:
      return 'Your profile is under review, please be patient!'
  }
}

const Switch = withStyles(theme => ({
  root: {
    width: 52,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBaseFulfilled: {
    'padding': 1,
    'transform': 'translateX(24px)',
    '&$checked': {
      'transform': 'translateX(24px)',
      'color': theme.palette.common.white,
      '& + $track': {
        backgroundColor: theme.palette.success.main,
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  switchBaseRejected: {
    'padding': 1,
    'color': 'white',
    'opacity': 1,
    'transform': 'translateX(2px)',

    '& + $track': {
      backgroundColor: theme.palette.error.main,
      opacity: 0.8,
      border: 'none',
    },
  },
  switchBasePending: {
    'padding': 1,
    'color': '#FFFFFF',
    'opacity': 1,
    'transform': 'translateX(2px)',

    '& + $track': {
      backgroundColor: theme.palette.error.warning,
      opacity: 0.8,
      border: 'none',
    },
  },
  thumb: {
    transform: 'translateY(1px)',
    width: 22,
    height: 22,
  },
  track: {
    borderRadius: 24 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <BaseSwitch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase:
          props.status !== Status.Active
            ? props.status === Status.Rejected
              ? classes.switchBaseRejected
              : classes.switchBasePending
            : classes.switchBaseFulfilled,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  )
})

export default ({ status }) => {
  return (
    <>
      <Switch checked={status === 'ACTIVE'} status={status} name="profile-status" />
      <Typography color="textSecondary" display="inline">
        {getLabelForStatus(status)}
      </Typography>
    </>
  )
}
