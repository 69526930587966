import moment from 'moment'
import { Table } from 'modules/ui'
import { UserNeed, SortDirection, Couple } from 'modules/api'
import { useT } from 'modules/i18n'

export const createColumns = ({ RowActions, t }) => [
  {
    field: 'need',
    headerName: 'Need',
    width: 140,
    valueGetter: (row: UserNeed) => row.need?.name,
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 140,
    valueGetter: (row: UserNeed) => row.status,
  },
  {
    field: 'completion',
    headerName: '%',
    width: 50,
    valueGetter: (row: UserNeed) => `${row.progress ?? '0'}%`,
  },
  {
    field: 'directQuotesCount',
    headerName: 'Direct quotes',
    width: 50,
    valueGetter: (row: UserNeed) => row.directQuotesCount,
  },
  {
    field: 'wallQuotesCount',
    headerName: 'Wall quotes',
    width: 50,
    valueGetter: (row: UserNeed) => row.wallQuotesCount,
  },
  {
    field: 'forYouQuotesCount',
    headerName: 'ForYou quotes',
    width: 50,
    valueGetter: (row: UserNeed) => row.forYouQuotesCount,
  },
  {
    field: 'createdAt',
    headerName: 'Created at',
    width: 200,
    valueGetter: (row: UserNeed) => moment(row.createdAt).format('YYYY.MM.DD HH:mm'),
    sortable: true,
  },
  {
    field: 'approvedAt',
    headerName: 'Approved at',
    width: 200,
    valueGetter: ({ approvedAt }: UserNeed) =>
      approvedAt ? moment(approvedAt).format('YYYY.MM.DD HH:mm') : '-',
    sortable: true,
  },
  {
    field: 'origin',
    headerName: 'Origin',
    width: 200,
    valueGetter: (row: UserNeed) =>
      `${t(row.origin.charAt(0) + row.origin.slice(1).toLowerCase())} ${
        row.acceptingQuotes ? '✓' : '☓'
      }`,
  },
  {
    field: 'couple',
    headerName: 'Couple',
    width: 200,
    valueGetter: (row: UserNeed) => row.wedding.couple.name,
  },
  {
    field: 'signUp',
    headerName: 'Sign up',
    width: 200,
    valueGetter: (row: UserNeed) => moment(row.wedding.couple.createdAt).format('YYYY.MM.DD HH:mm'),
  },
  {
    field: 'email',
    headerName: 'Email address',
    width: 200,
    valueGetter: (row: UserNeed) => row.wedding.couple.email,
  },
  {
    field: '',
    headerName: 'Actions',
    width: 140,
    sortable: false,
    disableClickEventBubbling: true,
    renderCell: RowActions,
  },
]

export type UserNeedListProps = {
  userNeeds: UserNeed[]
  loading: boolean
  rowActionsComponent(row: UserNeed): JSX.Element
  pageSize: number
  total: number
  handleChangePage(page: number): void
  handleChangeRowsPerPage(rows: number): void
  page: number
  toggleSort(newSortBy: string, sortBy: string, sortDirection: SortDirection): void
  sortDirection: SortDirection
  sortBy: string
  filter: string
  title: string
  handleSearch(search: string): void
}

export const UserNeedList = ({
  userNeeds,
  rowActionsComponent: RowActions,
  total,
  ...props
}: UserNeedListProps) => {
  const t = useT()
  const columns = createColumns({ RowActions, t })

  return <Table data={userNeeds} columns={columns} totalElements={total} {...props} />
}
