import { useState } from 'react'
import { unwrapResult } from '@reduxjs/toolkit'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import { useSignIn, useSignUp } from 'modules/auth'
import { GradientPage, Typography, TextField, Button, Link } from 'modules/ui'
import config from 'modules/config'

const qaTestingEnabled = config.app.QA_TESTING === 'true'

const cardHeight = 700
const cardMargin = 50

const useStyles = makeStyles(() => ({
  root: {
    textAlign: 'center',
    lineHeight: '100vh',
    overflow: 'auto',
    fontFamily: 'Poppins',
  },
  card: {
    position: 'relative',
    display: 'inline-block', // it's a trick to have proper scrolling behavior
    verticalAlign: 'middle',
    margin: cardMargin,
    width: 1088,
    height: cardHeight,
    boxShadow: '5px 10px 20px #00000029',
    borderRadius: 100,
    textAlign: 'initial',
    lineHeight: 'initial',
    overflow: 'visible',
  },
  logo: {
    height: 205,
    marginTop: 21,
  },
  partner: {
    position: 'relative',
    top: -20,
    fontFamily: 'inherit',
    fontSize: 27,
    fontWeight: 600,
    letterSpacing: 3.32,
    color: '#B2B2BD',
  },
  form: {
    'marginTop': -25,
    'width': 536,
    '& .MuiFormControl-root': {
      marginTop: 12,
    },
    '& .MuiFormLabel-root': {
      fontFamily: 'inherit',
      fontSize: 18,
      letterSpacing: 0,
      color: '#808495',
    },
    '& .MuiInput-underline': {
      'height': 50,
      '&::before': {
        borderBottom: '2px solid #E9E9F0',
      },
    },
  },
  forgotPassword: {
    alignSelf: 'flex-end',
    marginTop: 40,
    width: 'fit-content',
    fontSize: 15,
    letterSpacing: 0,
    color: '#4D4F5C',
  },
  submit: {
    'alignSelf': 'center',
    'marginTop': 85,
    'width': 311,
    'height': 50,
    'borderRadius': 8,
    'background': 'linear-gradient(261deg, #FEC140 0%, #FA709A 100%) 0% 0% no-repeat padding-box',
    '& .MuiTypography-root': {
      fontSize: 15,
      fontFamily: 'Poppins',
      textTransform: 'initial',
      letterSpacing: 0.32,
    },
  },
  copyright: {
    position: 'absolute',
    transform: 'translate(-50%, 50%)',
    left: '50%',
    bottom: `min((${cardHeight}px - 100vh) / 4, -${cardMargin / 2}px)`,
    fontFamily: 'inherit',
    fontSize: 14,
    letterSpacing: 0,
    color: 'white',
  },
}))

const SignIn = () => {
  const [state, setState] = useState({
    email: '',
    password: '',
  })
  const { loading: signInLoading, signIn, error } = useSignIn()
  const classes = useStyles()
  const { qaTestingProcess } = useSignUp()

  const handleChange = ({ target }) => {
    setState({
      ...state,
      [target.id]: target.value,
    })
  }

  const handleSubmit = async event => {
    event.preventDefault()

    try {
      unwrapResult(await signIn(state))
    } catch (err) {
      if (err.code === 'UserNotFoundException' && qaTestingEnabled) {
        await qaTestingProcess(state)
        window.location.reload()
      }
    }
  }

  return (
    <GradientPage className={classes.root}>
      <Card className={classes.card}>
        <Grid container direction="column" alignItems="center">
          <img className={classes.logo} alt="logo" src="/wm-text-gradient-new.png" />
          <Typography className={classes.partner}>PARTNER</Typography>
          {error && <Typography color="error">{error.message}</Typography>}
          <form className={classes.form} onSubmit={handleSubmit} noValidate autoComplete="off">
            <Grid container direction="column">
              <TextField
                className={classes.root}
                autoFocus
                id="email"
                type="email"
                label="Email address"
                value={state.email}
                onChange={handleChange}
              />
              <TextField
                autoFocus
                id="password"
                type="password"
                label="Password"
                value={state.password}
                onChange={handleChange}
              />
              <Link href="/forgot-password" className={classes.forgotPassword}>
                Forgot Password
              </Link>
              <Button
                className={classes.submit}
                disabled={signInLoading}
                variant="contained"
                color="primary"
                type="submit">
                <Typography>Sign in</Typography>
              </Button>
            </Grid>
          </form>
        </Grid>
        <Typography className={classes.copyright}>
          Ⓒ {new Date().getFullYear()} Wedding Manager Kft.
        </Typography>
      </Card>
    </GradientPage>
  )
}

export default SignIn
