import { createSlice, createAction, createEntityAdapter } from '@reduxjs/toolkit'
import { createSagaAction } from 'saga-toolkit'

const name = 'notification'

export const notificationsAdapter = createEntityAdapter()

const initialState = {
  initialized: false,
  authorized: false,
  registered: false,
  token: null,
  loading: false,
  error: null,
  notifications: notificationsAdapter.getInitialState(),
  notification: null,
}

export const init = createSagaAction(`${name}/init`)
export const startService = createAction(`${name}/startService`)
export const stopService = createAction(`${name}/stopService`)
export const receiveMessage = createAction(`${name}/receiveMessage`)
export const showNotification = createAction(`${name}/showNotification`)
export const interactNotification = createAction(`${name}/interactNotification`)
export const fetchNotifications = createSagaAction(`${name}/fetchNotifications`)
export const fetchNotification = createSagaAction(`${name}/fetchNotification`)
export const searchNotification = createSagaAction(`${name}/searchNotification`)
export const sendNotification = createSagaAction(`${name}/sendNotification`)
export const registerNotificationToken = createSagaAction(`${name}/registerNotificationToken`)
export const updateNotificationsEnabled = createSagaAction(`${name}/updateNotificationsEnabled`)

const handlePending = state => ({
  ...state,
  loading: true,
})

const handleRejected = (state, { error }) => ({
  ...state,
  error,
  loading: false,
})

const slice = createSlice({
  name,
  initialState,
  reducers: {
    clearNotification: state => ({
      ...state,
      notification: null,
    }),
  },
  extraReducers: {
    // init
    [init.fulfilled]: (state, { payload }) => ({
      ...state,
      ...payload,
      initialized: true,
    }),
    [init.rejected]: (state, { error }) => ({
      ...state,
      error,
    }),

    // notificationTokenRegistered
    [updateNotificationsEnabled.fulfilled]: (state, { meta }) => ({
      ...state,
      enabled: meta.arg,
    }),
    [updateNotificationsEnabled.rejected]: (state, { error }) => ({
      ...state,
      error,
    }),

    // notifications
    [fetchNotifications.pending]: handlePending,
    [fetchNotifications.fulfilled]: (state, { payload }) => {
      notificationsAdapter.setAll(state.notifications, payload)
      state.loading = false
    },
    [fetchNotifications.rejected]: handleRejected,

    // notification
    [fetchNotification.pending]: handlePending,
    [fetchNotification.fulfilled]: (state, { payload }) => ({
      ...state,
      notification: payload,
      loading: false,
    }),
    [fetchNotification.rejected]: handleRejected,

    // sendNotification
    [sendNotification.pending]: handlePending,
    [sendNotification.fulfilled]: state => ({
      ...state,
      loading: false,
    }),
    [sendNotification.rejected]: handleRejected,
  },
})

export const { clearNotification } = slice.actions

export default slice.reducer
