import { Wedding } from 'modules/api'
import { months, days } from 'modules/i18n'

export const formatPlannedDate = (weddingCopy: Wedding, t: any): string => {
  const parts = [weddingCopy.possibleYear]
  if (weddingCopy.possibleMonths?.length > 0) {
    parts.push(weddingCopy.possibleMonths.map(idx => t(months[idx])).join(', '))
  }
  if (weddingCopy.possibleDays?.length > 0) {
    parts.push(weddingCopy.possibleDays.map(idx => t(days[idx])).join(', '))
  }
  return parts.join(' | ')
}

export const formatPlannedLocation = (weddingCopy: Wedding): string => {
  const parts = [weddingCopy.possibleAddresses.map(a => a.name).join(', ')]

  if (weddingCopy.locationTags && weddingCopy.locationTags?.length > 0) {
    parts.push(weddingCopy.locationTags.map(t => t.name).join(', '))
  }

  return parts.join(' | ')
}
