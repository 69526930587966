import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as actions from './slice'
import * as selectors from './selectors'

export const useNeed = () => ({
  ...bindActionCreators(actions, useDispatch()),
  ...useSelector(selectors.selectRoot),
  needs: useSelector(selectors.selectAll),
  weddingsByNeed: useSelector(selectors.weddingByNeed.selectAll),
})
