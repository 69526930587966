import { pick } from 'rambda'
import { takeLatestAsync } from 'saga-toolkit'
import { query, mutation, requestApi } from 'modules/api'
import * as actions from './slice'
import * as operations from './operations'

const blogFields = ['id', 'name', 'author', 'ordering', { coverImage: ['name'] }, 'content']

const parseBlog = blog => ({
  ...blog,
  coverImage: blog.coverImage?.name,
})

// TODO merge searchBlog
function* fetchBlogs() {
  return yield searchBlog({ meta: { arg: { limit: 1000 } } })
}

function* searchBlog({ meta }) {
  const { filter, limit = 20, offset = 0 } = meta.arg
  const { paginatedBlogs } = yield requestApi(operations.paginatedBlogs, {
    input: {
      filter,
      limit,
      offset,
    },
  })

  return paginatedBlogs.elements?.map(parseBlog)
}

function* fetchBlog({ meta }) {
  const { id } = meta.arg
  const options = {
    operation: 'blog',
    fields: blogFields,
    variables: {
      id: {
        type: 'ID',
        value: id,
        required: true,
      },
    },
  }

  const { blog } = yield query(options, true)

  return parseBlog(blog)
}

function* createBlog({ meta }) {
  const options = {
    operation: 'insertBlog',
    fields: ['id'],
    variables: {
      input: {
        value: meta.arg,
        type: 'NewBlogInput!',
      },
    },
  }

  const result = yield mutation(options, true)

  return result.insertBlog
}

function* updateBlog({ meta }) {
  const body = pick(['content', 'name', 'coverImage', 'author', 'ordering', 'id'], meta.arg)
  const options = {
    operation: 'updateBlog',
    fields: blogFields,
    variables: {
      input: {
        value: body,
        type: 'UpdateBlogInput!',
      },
    },
  }

  const { updateBlog } = yield mutation(options, true)

  return parseBlog(updateBlog)
}

function* removeBlog({ meta }) {
  const { id } = meta.arg
  const options = {
    operation: 'deleteBlog',
    variables: {
      id: {
        value: id,
        type: 'ID!',
      },
    },
  }

  yield mutation(options, true)
}

export default [
  takeLatestAsync(actions.fetchBlogs.type, fetchBlogs),
  takeLatestAsync(actions.fetchBlog.type, fetchBlog),
  takeLatestAsync(actions.createBlog.type, createBlog),
  takeLatestAsync(actions.updateBlog.type, updateBlog),
  takeLatestAsync(actions.removeBlog.type, removeBlog),
  takeLatestAsync(actions.searchBlog.type, searchBlog),
]
