import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { Box, makeStyles } from '@material-ui/core'
import { Typography, AttachmentsUploader } from 'modules/ui'
import { TextField, Checkbox } from 'modules/formik'
import config from 'modules/config'

const useStyles = makeStyles(theme => ({
  textLabel: {
    marginTop: theme.spacing(1),
    fontWeight: 'bold',
  },
  text: {
    marginTop: theme.spacing(2),
  },
  numDaysLabel: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  numDaysUnit: {
    display: 'inline-block',
    marginLeft: theme.spacing(1),
    lineHeight: '56px',
  },
  price: {
    display: 'flex',
    gap: theme.spacing(1),
  },
  priceLabel: {
    color: '#2cb8a5',
    fontSize: 20,
    lineHeight: '56px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  priceField: {
    '& .MuiInputBase-root::after': {
      content: "'Huf'",
      position: 'absolute',
      top: 0,
      right: 4,
      fontSize: 12,
    },
    '& .MuiInputBase-root.Mui-disabled::after': {
      color: 'inherit',
    },
  },
  hyphen: {
    lineHeight: '56px',
  },
  attachmentsLabel: {
    marginTop: theme.spacing(6),
    fontWeight: 'bold',
  },
}))

const validationSchema = Yup.object().shape({
  text: Yup.string().required('Required'),
})

/* todo add types
export interface QuoteResponseContactViewProps {
  initialValues?: {
    text: string
    attachments: string[]
    sendEmail?: boolean
  }
  onSubmit?: (values: any) => void
  innerRef?: any
  readonly?: boolean
}*/

const QuoteResponseContactView = ({
  initialValues = {
    text: '',
    attachments: [],
    sendEmail: false,
  },
  readonly = false,
  ...props
}) => (
  <Formik validationSchema={validationSchema} initialValues={initialValues} {...props}>
    <FormBody readonly={readonly} />
  </Formik>
)

const FormBody = ({ readonly }) => {
  const classes = useStyles()

  return (
    <Form autoComplete="off">
      {!readonly && (
        <Typography className={classes.textLabel} color="textSecondary">
          Below, you can even compile a specific quote, attach documents, or simply write a message
          to the couple for further consultation!
        </Typography>
      )}
      <TextField
        className={classes.text}
        variant="outlined"
        fullWidth
        id="text"
        name="text"
        label=""
        multiline
        placeholder="Start to type..."
        disabled={readonly}
      />
      <Typography className={classes.attachmentsLabel} color="textSecondary">
        Attach documents
      </Typography>
      <AttachmentsUploader
        maxFileSize={config.app.MAX_ATTACHMENT_SIZE}
        label=""
        name="attachments"
        margin="normal"
        disabled={readonly}
      />
      <Box>
        <Checkbox
          name="sendEmail"
          label="I would like the couple to receive my offer by email!"
          disabled={readonly}
        />
      </Box>
    </Form>
  )
}

export default QuoteResponseContactView
