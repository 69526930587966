import mixpanel from 'mixpanel-browser'
import Analytics from '@aws-amplify/analytics'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { call, take, takeEvery, select } from 'redux-saga/effects'
import { takeLatestAsync } from 'saga-toolkit'
import flat from 'flat'
import * as actions from './slice'
import { selectRoot } from './selectors'
import sentryIgnore from './sentryIgnore'

const isAuthorized = () => true

function* init({ meta }) {
  const { analytics, mixpanelToken, sentryDsn, environment } = meta.arg

  mixpanel.init(mixpanelToken)

  yield call(() => Analytics.configure(analytics))
  Sentry.init({
    dsn: sentryDsn,
    normalizeDepth: 10,
    integrations: [new BrowserTracing()],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    environment,
  })
}

function* waitForInit() {
  const state = yield select(selectRoot)

  if (state.initialized) {
    return state
  }

  while (true) {
    yield take(actions.init.fulfilled)

    const state = yield select(selectRoot)

    if (state.initialized) {
      return state
    }
  }
}

function* track({ payload }) {
  const { name, properties } = payload

  yield waitForInit()

  try {
    yield call(() => mixpanel.track(name, properties && flat(properties)))
    yield call(() => Analytics.record({ name, attributes: properties }))
  } catch (error) {
    console.warn('Tracking.track error:', error)
  }
}

function* identify({ payload }) {
  const { id, profile } = payload
  const { trackingStatus } = yield waitForInit()

  if (!isAuthorized(trackingStatus)) {
    return
  }

  try {
    yield call(() => mixpanel.identify(id))
    yield call(() => mixpanel.people.set(profile))
  } catch (error) {
    console.warn('Tracking.identify error:', error)
  }
}

function* flush() {
  yield waitForInit()

  try {
    yield call(() => mixpanel.flush())
  } catch (error) {
    console.warn('Tracking.flush error:', error)
  }
}

function* captureErrors({ error }) {
  if (!error || sentryIgnore.includes(error.name)) {
    return
  }

  const sentryError = Error(error.message)

  sentryError.code = error.code
  sentryError.stack = error.stack

  Sentry.captureException(sentryError)
}

export default [
  takeLatestAsync(actions.init.type, init),
  takeEvery(actions.track.type, track),
  takeEvery(actions.identify.type, identify),
  takeLatestAsync(actions.flush.type, flush),
  takeEvery('*', captureErrors),
]
