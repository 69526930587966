import { createWrappers, selectors as authSelectors } from 'modules/auth'
import { selectors as profileSelectors } from 'modules/profile'

const { isAuthenticated, isSignInLoading, getSignInData } = authSelectors

const selectRole = state => profileSelectors.selectRoot(state).user?.role
const isAdmin = state => isAuthenticated(state) && selectRole(state) === 'ADMIN'
const isPartner = state => isAuthenticated(state) && selectRole(state) === 'PARTNER'

const wrappers = createWrappers({
  redirectPath: '/sign-in',
  isAuthenticated,
  isSignInLoading,
  getSignInData,
  isAdmin,
  isPartner,
})

export const {
  userIsAuthenticated,
  userIsAdmin,
  userIsPartner,
  userIsPartnerRedir,
  userIsAuthenticatedRedir,
  userIsNotAuthenticated,
  userIsNotAuthenticatedRedir,
  userIsAdminRedir,
} = wrappers
