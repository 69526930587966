import { uploadToS3 } from 'modules/storage'

const processPartnerFormSubmit = async profile => {
  const { coverImages, tags, needs, guestRange, profileImage, areaLocation } = profile
  const tagIdList = tags.map(item => item.id)
  const needIdList = needs.map(need => need.id)
  const imagesToUpload = coverImages.filter(image => image instanceof File || image instanceof Blob)
  const tasks = imagesToUpload.map(uploadToS3)
  const newImages = await Promise.all(tasks)

  const uploadProfilePictureIfNecessary = async dataProfileImage => {
    if (!dataProfileImage) return ''

    if (dataProfileImage instanceof File || dataProfileImage instanceof Blob) {
      return await uploadToS3(dataProfileImage)
    }
  }
  const parsedProfile = {
    ...profile,
    type: 'PARTNER',
    ...(areaLocation && {
      areaLocation: {
        ...areaLocation,
        radius: areaLocation.availableNationwide ? 0 : areaLocation.radius,
      },
    }),
    guestRange: {
      low: guestRange[0],
      high: guestRange[1],
    },
    tags: tagIdList,
    needs: needIdList,
    profileImage: await uploadProfilePictureIfNecessary(profileImage),
    coverImages: [
      ...coverImages
        .filter(image => !(image instanceof File || image instanceof Blob))
        .map(({ name }) => name),
      ...newImages,
    ],
  }

  return parsedProfile
}

export default processPartnerFormSubmit
