import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'
import { createSagaAction } from 'saga-toolkit'

const name = 'conversation'

export const conversationsAdapter = createEntityAdapter()

const initialState = {
  loading: false,
  error: null,
  conversations: conversationsAdapter.getInitialState(),
  totalConversations: 0,
  messages: [],
  totalMessages: 0,
  limit: 10,
}

export const fetchConversations = createSagaAction(`${name}/fetchConversations`)
export const fetchConversationMessages = createSagaAction(`${name}/fetchConversationMessages`)

const handlePending = state => {
  return {
    ...state,
    loading: true,
  }
}

const handleRejected = (state, { error }) => {
  return {
    ...state,
    error,
    loading: false,
  }
}

const slice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: {
    // fetchConversations
    [fetchConversations.pending]: handlePending,
    [fetchConversations.fulfilled]: (state, { payload }) => {
      conversationsAdapter.setAll(state.conversations, payload.elements)
      state.totalConversations = payload.total
      state.loading = false
    },
    [fetchConversations.rejected]: handleRejected,

    // fetchConversationMessages
    [fetchConversationMessages.pending]: state => ({
      ...state,
      loading: true,
    }),

    [fetchConversationMessages.fulfilled]: (state, { payload, meta }) => {
      if (meta.arg.loadMore) {
        const messagesWithNew = [...state.messages, ...payload.messages.elements]

        state.messages = messagesWithNew
        state.loading = false
      } else {
        state.messages = payload.messages.elements
      }

      state.totalMessages = payload.messages.total
      state.loading = false
    },

    [fetchConversationMessages.rejected]: (state, { error }) => ({
      ...state,
      error,
      loading: false,
    }),
  },
})

export default slice.reducer
