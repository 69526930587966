import { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Loader, Button } from 'modules/ui'
import { usePartner } from 'modules/partner'

export default () => {
  const { id } = useParams()
  const history = useHistory()
  const [open, setOpen] = useState(false)
  const { partner, invitePartner } = usePartner()
  const [loading, setLoading] = useState(false)

  const goBack = () => {
    history.goBack()
  }

  const handleCancel = () => {
    setOpen(false)
  }

  const handleInvite = async () => {
    setLoading(true)
    await invitePartner({ id })
    setLoading(false)
    handleCancel()
  }

  useEffect(() => {
    setOpen(true)
  }, [])

  if (!partner) {
    return null
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={handleCancel}
      TransitionProps={{ onExited: goBack }}>
      <DialogTitle id="form-dialog-title">Invite {partner.name}?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Send an email invitation to the partner's address: {partner.contactEmail}
        </DialogContentText>
        <Loader loading={loading} />
      </DialogContent>
      <DialogActions>
        <Button size="small" onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          size="small"
          color="primary"
          variant="contained"
          disabled={loading}
          onClick={handleInvite}>
          Invite
        </Button>
      </DialogActions>
    </Dialog>
  )
}
