import { useState } from 'react'
import { DataGrid } from '@material-ui/data-grid'
import DoneIcon from '@material-ui/icons/Done'
import ClearIcon from '@material-ui/icons/Clear'

const createColumns = ({ RowActions }) => [
  {
    field: 'name',
    headerName: 'Name',
    width: 220,
  },
  {
    field: 'type',
    headerName: 'Type',
    width: 140,
  },
  {
    field: 'variant',
    headerName: 'Variant',
    width: 140,
  },
  {
    field: 'randomized',
    headerName: 'Randomized',
    width: 160,
    renderCell: ({ row }) => (row.randomized ? <DoneIcon /> : <ClearIcon />),
  },
  {
    field: '',
    headerName: 'Actions',
    width: 280,
    sortable: false,
    disableClickEventBubbling: true,
    renderCell: RowActions,
  },
]

export default ({ highlights, rowActionsComponent: RowActions, ...props }) => {
  const columns = createColumns({ RowActions })
  const [pageSize, setPageSize] = useState(25)

  return (
    <DataGrid
      rows={highlights}
      columns={columns}
      pageSize={pageSize}
      onPageSizeChange={newPageSize => setPageSize(newPageSize)}
      rowsPerPageOptions={[25, 50, 100]}
      pagination
      {...props}
    />
  )
}
