import { gql } from 'graphql-tag'

export const paginatedNeedsQuery = gql`
  query PaginatedNeeds($input: PaginatedNeedInput!) {
    paginatedNeeds(input: $input) {
      total
      elements {
        id
        name
        updatedAt
      }
    }
  }
`

export const needsQuery = gql`
  query Needs {
    needs {
      id
      name
    }
  }
`

export const needQuery = gql`
  query Need($id: ID!) {
    need(id: $id) {
      id
      name
      hide
      category {
        id
        name
      }
    }
  }
`

export const userNeedQueryForAdmin = gql`
  query UserNeedForAdmin($userNeedId: ID!) {
    userNeed(id: $userNeedId) {
      id
      category
      status
      directQuotesCount
      wallQuotesCount
      forYouQuotesCount
      quotesCount
      origin
      acceptingQuotes
      text
      adminText
      progress
      createdAt
      approvedAt
      images {
        name
      }
      partnersForYou {
        id
        status
        partner {
          id
          name
        }
      }
      questionResponses {
        questionId
        question {
          id
          title
          fields {
            label
          }
        }
        TEXT
        TEXT_LONG
        NUMBER
        TIME
        DATE
        CURRENCY
        RANGE {
          low
          high
        }
        RANGE_CURRENCY {
          low
          high
        }
        CHECK_GROUP
        CHECK_GROUP_INLINE
        CHECK
        RADIO_GROUP
      }
      quoteResponses {
        id
        requestId
        quoteRequest {
          type
        }
        partner {
          name
        }
        status
        createdAt
      }
      need {
        id
        name
        questions {
          id
        }
      }
      wedding {
        couple {
          id
          name
          createdAt
          email
        }
        guestRange {
          low
          high
        }
        possibleYear
        possibleMonths
        possibleDays
        possibleAddresses {
          name
        }
        locationTags {
          id
          name
        }
        date
        address {
          name
          coordinate {
            lat
            lng
          }
        }
      }
    }
  }
`

export const userNeedsCategorySummary = gql`
  query UserNeedsCategorySummary($filter: UserNeedsCategorySummaryInput!) {
    userNeedCategorySummary(filter: $filter) {
      id
      category
      amount
    }
  }
`

export const userNeedQueryForPartner = gql`
  query UserNeedForPartner($userNeedId: ID!) {
    userNeed(id: $userNeedId) {
      id
      category
      status
      quotesCount
      origin
      acceptingQuotes
      text
      adminText
      approvedAt
      progress
      images {
        name
      }
      partnersForYou {
        id
        partner {
          id
          name
        }
      }
      questionResponses {
        questionId
        question {
          id
          title
          fields {
            label
          }
        }
        TEXT
        TEXT_LONG
        NUMBER
        TIME
        DATE
        CURRENCY
        RANGE {
          low
          high
        }
        RANGE_CURRENCY {
          low
          high
        }
        CHECK_GROUP
        CHECK_GROUP_INLINE
        CHECK
        RADIO_GROUP
      }
      need {
        id
        name
        questions {
          id
        }
      }
      createdAt
      wedding {
        couple {
          id
          name
          email
          createdAt
        }
        guestRange {
          low
          high
        }
        possibleYear
        possibleMonths
        possibleDays
        possibleAddresses {
          name
        }
        locationTags {
          id
          name
        }
        date
        address {
          name
          coordinate {
            lat
            lng
          }
        }
      }
    }
  }
`

export const userNeedsQueryForAdmin = gql`
  query UserNeedsForAdmin($filter: UserNeedsPaginationInput!) {
    userNeeds(filter: $filter) {
      elements {
        id
        category
        status
        isNew
        quotesCount
        directQuotesCount
        wallQuotesCount
        forYouQuotesCount
        origin
        acceptingQuotes
        progress
        createdAt
        approvedAt
        wedding {
          id
          couple {
            name
            myName
            partnersName
            email
            createdAt
            id
          }
          date
          possibleYear
          guestRange {
            low
            high
          }
          address {
            name
          }
          possibleAddresses {
            name
          }
        }
        need {
          id
          category {
            image {
              name
            }
          }
          name
          questions {
            id
          }
        }
        questionResponses {
          questionId
          TEXT
          TEXT_LONG
          NUMBER
          TIME
          DATE
          CURRENCY
          RANGE {
            low
            high
          }
          RANGE_CURRENCY {
            low
            high
          }
          CHECK_GROUP
          CHECK_GROUP_INLINE
          CHECK
          RADIO_GROUP
        }
        quoteResponses {
          id
        }
      }
      total
    }
  }
`

export const userNeedsQueryForPartner = gql`
  query UserNeedsForPartner($filter: UserNeedsPaginationInput!) {
    userNeeds(filter: $filter) {
      elements {
        id
        category
        status
        createdAt
        isNew
        quotesCount
        origin
        acceptingQuotes
        approvedAt
        hasSentQuote
        progress
        wedding {
          id
          couple {
            name
            myName
            partnersName
            email
            createdAt
            id
          }
          date
          possibleYear
          guestRange {
            low
            high
          }
          address {
            name
          }
          possibleAddresses {
            name
          }
        }
        need {
          id
          category {
            image {
              name
            }
          }
          name
          questions {
            id
          }
        }
        questionResponses {
          questionId
          TEXT
          TEXT_LONG
          NUMBER
          TIME
          DATE
          CURRENCY
          RANGE {
            low
            high
          }
          RANGE_CURRENCY {
            low
            high
          }
          CHECK_GROUP
          CHECK_GROUP_INLINE
          CHECK
          RADIO_GROUP
        }
      }
      total
    }
  }
`

export const insertNeedMutation = gql`
  mutation InsertNeed($input: NewNeedInput!) {
    insertNeed(input: $input) {
      id
      name
      category {
        id
        name
      }
    }
  }
`

export const updateNeedMutation = gql`
  mutation UpdateNeed($id: ID!, $input: UpdateNeedInput!) {
    updateNeed(id: $id, input: $input) {
      id
      name
      category {
        id
        name
      }
    }
  }
`

export const removeNeedMutation = gql`
  mutation DeleteNeed($id: ID!) {
    deleteNeed(id: $id)
  }
`

export const weddingsByNeedIdQuery = gql`
  query WeddingsByNeed($weddingsByNeedId: ID!) {
    weddingsByNeed(id: $weddingsByNeedId) {
      id
      date
      createdAt
      couple {
        id
        name
        email
        status
        active
      }
    }
  }
`

export const seenUserNeedMutation = gql`
  mutation SeenUserNeed($seenUserNeedId: ID!) {
    seenUserNeed(id: $seenUserNeedId)
  }
`

export const updateUserNeedMutation = gql`
  mutation UpdateUserNeed($updateUserNeedId: ID!, $input: UserNeedInput!) {
    updateUserNeed(id: $updateUserNeedId, input: $input) {
      id
      text
      adminText
    }
  }
`
