import {
  StandardTextFieldProps,
  FilledTextFieldProps,
  OutlinedTextFieldProps,
  TextField as BaseTextField,
  TextFieldProps as BaseTextFieldProps,
} from '@material-ui/core'
import { useT } from 'modules/i18n'

const isString = value => {
  return typeof value === 'string'
}
type TextFieldVariant =
  | StandardTextFieldProps['variant']
  | FilledTextFieldProps['variant']
  | OutlinedTextFieldProps['variant']

export interface TextFieldProps extends Omit<BaseTextFieldProps, 'variant'> {
  label?: string | any
  helperText?: string
  variant?: TextFieldVariant
}

export const TextField = ({ label, placeholder, helperText, ...props }: TextFieldProps) => {
  const t = useT()

  return (
    <BaseTextField
      variant={props.variant || 'standard'}
      label={isString(label) ? t(label) : label}
      placeholder={isString(placeholder) ? t(placeholder) : placeholder}
      helperText={t(helperText)}
      {...props}
    />
  )
}

export default TextField
