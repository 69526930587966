import { useState } from 'react'
import { useHistory } from 'react-router'
import Grid from '@material-ui/core/Grid'
import { PartnerForm, usePartner, processPartnerFormSubmit } from 'modules/partner'
import { useCategory } from 'modules/category'
import { Page, Loader } from 'modules/ui'
import config from 'modules/config'

const imagesPath = config.app.IMAGES_URL

export default () => {
  const { loading, createPartner } = usePartner()
  const { categories, loading: categoryLoading } = useCategory()
  const history = useHistory()
  const [submitting, setSubmitting] = useState(false)

  const handleSubmit = async data => {
    setSubmitting(true)
    const processedPartner = await processPartnerFormSubmit(data)
    const { payload } = await createPartner(processedPartner)

    setSubmitting(false)
    history.push(`/partners/${payload.id}/edit`)
  }

  if (loading) {
    return 'Loading...'
  }

  return (
    <Page title="New partner">
      <Grid container>
        <Grid item xs={12} sm={8} md={6}>
          <PartnerForm
            categoryLoading={categoryLoading}
            categories={categories}
            onSubmit={handleSubmit}
          />
          <Loader loading={submitting} />
        </Grid>
      </Grid>
    </Page>
  )
}
