import { Page } from 'modules/ui'

export default () => (
  <Page>
    <div>
      <p
        dir="ltr"
        style={{ lineHeight: '1.2', textAlign: 'center', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '13.999999999999998pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          Wedding Manager
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: '1.2', textAlign: 'center', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '13.999999999999998pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          Általános Szerződési és Felhasználási Feltételek&nbsp;
        </span>
      </p>
      <p>
        <br />
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: '1.2', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          [Hatályos]
        </span>
        <span
          style={{
            fontSize: '11pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          &nbsp;
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          2021. szeptember 15. napjától módosításig, vagy visszavonásig.
        </span>
      </p>
      <p>
        <br />
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: '1.2', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          1./ [Vállalkozás adatai]
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: '1.2', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          A Vállalkozás az Elker. tv. 4.§ szerinti adatai:
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: '1.2', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          Név:&nbsp;
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          <span className="Apple-tab-span" style={{ whiteSpace: 'pre' }}>
            &nbsp; &nbsp;&nbsp;
          </span>
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          <span className="Apple-tab-span" style={{ whiteSpace: 'pre' }}>
            &nbsp; &nbsp;&nbsp;
          </span>
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          <span className="Apple-tab-span" style={{ whiteSpace: 'pre' }}>
            &nbsp; &nbsp;&nbsp;
          </span>
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          Wedding Manager Kft.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: '1.2', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          Székhely:&nbsp;
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          <span className="Apple-tab-span" style={{ whiteSpace: 'pre' }}>
            &nbsp; &nbsp;&nbsp;
          </span>
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          <span className="Apple-tab-span" style={{ whiteSpace: 'pre' }}>
            &nbsp; &nbsp;&nbsp;
          </span>
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          3360 Heves, Hősök tere 1.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: '1.2', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          E-mail:
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          <span className="Apple-tab-span" style={{ whiteSpace: 'pre' }}>
            &nbsp; &nbsp;&nbsp;
          </span>
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          <span className="Apple-tab-span" style={{ whiteSpace: 'pre' }}>
            &nbsp; &nbsp;&nbsp;
          </span>
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          <span className="Apple-tab-span" style={{ whiteSpace: 'pre' }}>
            &nbsp; &nbsp;&nbsp;
          </span>
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          info@weddingmanager.hu
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: '1.2', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          Cégjegyzék:
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          <span className="Apple-tab-span" style={{ whiteSpace: 'pre' }}>
            &nbsp; &nbsp;&nbsp;
          </span>
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          <span className="Apple-tab-span" style={{ whiteSpace: 'pre' }}>
            &nbsp; &nbsp;&nbsp;
          </span>
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          Egri Törvényszék Cégbírósága, Cg. 10-09-038826
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: '1.2', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          Adószám:
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          <span className="Apple-tab-span" style={{ whiteSpace: 'pre' }}>
            &nbsp; &nbsp;&nbsp;
          </span>
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          <span className="Apple-tab-span" style={{ whiteSpace: 'pre' }}>
            &nbsp; &nbsp;&nbsp;
          </span>
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          29276167-2-10
        </span>
      </p>
      <p>
        <br />
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: '1.2', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          Tárhelyszolgáltató:
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          <span className="Apple-tab-span" style={{ whiteSpace: 'pre' }}>
            &nbsp; &nbsp;&nbsp;
          </span>
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          Amazon Web Services, Inc.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: '1.2', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          Székhely:
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          <span className="Apple-tab-span" style={{ whiteSpace: 'pre' }}>
            &nbsp; &nbsp;&nbsp;
          </span>
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          <span className="Apple-tab-span" style={{ whiteSpace: 'pre' }}>
            &nbsp; &nbsp;&nbsp;
          </span>
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          410 Terry Avenue North, Seattle, WA 98109-5210, ATTN: AWS Legal
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: '1.2', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          E-mail:
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          <span className="Apple-tab-span" style={{ whiteSpace: 'pre' }}>
            &nbsp; &nbsp;&nbsp;
          </span>
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          <span className="Apple-tab-span" style={{ whiteSpace: 'pre' }}>
            &nbsp; &nbsp;&nbsp;
          </span>
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          <span className="Apple-tab-span" style={{ whiteSpace: 'pre' }}>
            &nbsp; &nbsp;&nbsp;
          </span>
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          info@aws.amazon.com
        </span>
      </p>
      <p>
        <br />
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: '1.2', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          2./ [ÁSZF alkalmazási köre]
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: '1.2', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          2.1
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          &nbsp;Ezen Általános Szerződési és Felhasználási Feltételek („
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          ÁSZF
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          ”) a Wedding Manager Kft. („
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          Wedding Manager
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          ” vagy „
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          Vállalkozás
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          ”) által üzemeltetett Wedding Manager applikáció és hozzá kapcsolódó online felület és
          annak bármely jelenleg vagy jövőben egyéb felületeken elérhető változatai (a továbbiakban
          együttesen: „
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          Program
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          ”) igénybe vételének és használatának feltételeit tartalmazza.
        </span>
      </p>
      <p>
        <br />
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: '1.2', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          2.2
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          &nbsp;A Program használatára regisztráló személy („
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          Felhasználó
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          ”) a regisztrációval egyidejűleg elfogadja, hogy jelen ÁSZF rendelkezéseit megismerte és
          azokat magára nézve kötelezőnek fogadja el. A Felhasználó személyes adatait a Wedding
          Manager adatkezelési tájékoztatójában foglaltak szerint kezeli.
        </span>
      </p>
      <p>
        <br />
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: '1.2', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          2.3
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          &nbsp;Szerződés a Felek között fogyasztói szerződésnek minősülhet, amennyiben a
          Felhasználó a szakmája, önálló foglalkozása vagy üzleti tevékenysége körén kívül eljáró
          természetes személy („
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          Fogyasztó
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          ”) (Ptk. 8:1.§). A szerződés a Felhasználó regisztrációjának visszaigazolásával jön létre.
          Szerződéses kapcsolat elválaszthatatlan részét képezik jelen ÁSZF, illetve Adatkezelési
          tájékoztató egésze, melynek körében a Felhasználó személyes adatainak kezeléséhez
          hozzájárul. Felhasználó az előbb nevezett dokumentumok mindenkor hatályos változatát
          elektronikus úton folyamatosan elérheti.
        </span>
      </p>
      <p>
        <br />
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: '1.2', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          2.4
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          &nbsp;A Vállalkozás bármikor jogosult az ÁSZF egyoldalú módosítására azzal, hogy azt
          hatálybalépését megelőzően 11 (tizenegy) nappal közzéteszi, melyet a Felhasználók a
          módosítást követően a Program további használatával elfogadnak.&nbsp;
        </span>
      </p>
      <p>
        <br />
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: '1.2', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          2.5
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          &nbsp;A Vállalkozás magyar jogalany. A Program használatával a Felhasználó elfogadja, hogy
          a Program használata során a magyar jog irányadó. A jelen ÁSZF-re ugyancsak a magyar jog
          irányadó. A Wedding Manager kifejezetten kizárja bármely külföldi állam jogának
          alkalmazását, az érvényesen ki nem zárható rendelkezések kivételével.
        </span>
      </p>
      <p>
        <br />
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: '1.2', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          2.6
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          &nbsp;Jelen ÁSZF-re elsődlegesen irányadó magyar jogszabályok:
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: '1.2', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          - a Polgári Törvénykönyvről szóló 2013. évi V. törvény („
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          Ptk
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          .”)
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: '1.2', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          - az elektronikus kereskedelmi Programok, valamint az információs társadalommal összefüggő
          Programok egyes kérdéseiről szóló 2001. évi CVIII. törvény („
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          Elker. tv
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          .”)
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: '1.2', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          - a fogyasztó és a vállalkozás közötti szerződések részletes szabályairól szóló 45/2014.
          (II. 26.) Korm. rendelet („
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          Kormányrendelet
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          ”)
        </span>
      </p>
      <p>
        <br />
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: '1.2', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          3./ [Program igénybevétele – Közvetítő szolgáltatás]
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: '1.2', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          3.1
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          &nbsp;A Program célja, hogy esküvő vagy egyéb rendezvény szervezésével kapcsolatban
          platformot szolgáltasson a szolgáltatók és a Felhasználók számára. A Wedding Manager által
          üzemeltetett Programot a Felhasználó regisztrációját követően használati díjfizetés nélkül
          jogosult igénybe venni.
        </span>
      </p>
      <p>
        <br />
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: '1.2', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          3.2
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          &nbsp;A Program keretében a Programban regisztráló esküvői és egyéb rendezvényekkel
          kapcsolatos szolgáltatásokat nyújtó vállalkozások („
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          Szolgáltatók
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          ”) jogosultak hirdetéseket közzétenni. A Felhasználók a Szolgáltatók által közzétett
          tartalmakat tekinthetik meg és dönthetnek egyes Szolgáltatók felkereséséről saját döntésük
          szerint. A Wedding Manager a Program üzemeltetése során jelenleg nem nyújt szerződés
          közvetítési szolgáltatást, vagyis a Felhasználók és a Szolgáltatók közötti
          kapcsolatfelvétel megkönnyítésén túl a Wedding Managernek nincsen szerepe a felek közötti
          esetleges szerződések létrehozásában.
        </span>
      </p>
      <p>
        <br />
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: '1.2', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          3.3
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          &nbsp;A Felhasználó kizárólagos felelőssége a fentiek figyelembevételével, hogy a
          Szolgáltatók által közzétett hirdetések alapján döntsön egyes Szolgáltatók felkereséséről.
          A Wedding Manager nem nyújt a Szolgáltatók kiválasztása érdekében előzetes értékelési,
          vagy egyéb minőségbiztosítási szolgáltatást, így a Wedding Manager használata nem váltja
          ki a Felhasználó részéről a Szolgáltató ellenőrzésének, személyes felkeresésének és
          megfelelő szerződés kötésének feladatát.
        </span>
      </p>
      <p>
        <br />
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: '1.2', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          3.4
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          &nbsp;A Wedding Manager külön felhívja a figyelmet arra, hogy a Szolgáltatók által
          közzétett tartalmak valódiságáért a Szolgáltatók rendelkeznek kizárólagos felelősséggel,
          így a Wedding Manager felelőssége e körben kizárt. A Wedding Manager e körben
          alkalmazásszolgáltatóként és keresőszolgáltatóként jár el és kifejezetten rögzíti, hogy
        </span>
      </p>
      <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: '48px' }}>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: 'lower-alpha',
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre',
          }}>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.2',
              textAlign: 'justify',
              marginTop: '0pt',
              marginBottom: '0pt',
            }}>
            <span
              style={{
                fontSize: '10pt',
                fontFamily: 'Arial',
                color: '#000000',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap',
              }}>
              a Szolgáltatók által feltöltött információk továbbítását nem a Wedding Manager
              kezdeményezi;
            </span>
          </p>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: 'lower-alpha',
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre',
          }}>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.2',
              textAlign: 'justify',
              marginTop: '0pt',
              marginBottom: '0pt',
            }}>
            <span
              style={{
                fontSize: '10pt',
                fontFamily: 'Arial',
                color: '#000000',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap',
              }}>
              nem a Wedding Manager választja meg az információ továbbításának címzettjét, és
            </span>
          </p>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: 'lower-alpha',
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre',
          }}>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.2',
              textAlign: 'justify',
              marginTop: '0pt',
              marginBottom: '0pt',
            }}>
            <span
              style={{
                fontSize: '10pt',
                fontFamily: 'Arial',
                color: '#000000',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap',
              }}>
              a továbbított Szolgáltatói hirdetést tartalmazó információt nem a Wedding Manager
              választja ki, illetve azt nem változtatja meg, továbbá
            </span>
          </p>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: 'lower-alpha',
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre',
          }}>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.2',
              textAlign: 'justify',
              marginTop: '0pt',
              marginBottom: '0pt',
            }}>
            <span
              style={{
                fontSize: '10pt',
                fontFamily: 'Arial',
                color: '#000000',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap',
              }}>
              a Wedding Managernek nincs tudomása a Programba feltöltött információval kapcsolatos
              jogellenes magatartásról, vagy arról, hogy az információ bárkinek a jogát vagy jogos
              érdekét sérti; illetve
            </span>
          </p>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: 'lower-alpha',
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre',
          }}>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.2',
              textAlign: 'justify',
              marginTop: '0pt',
              marginBottom: '0pt',
            }}>
            <span
              style={{
                fontSize: '10pt',
                fontFamily: 'Arial',
                color: '#000000',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap',
              }}>
              amint a d) pontban foglaltakról a Wedding Manager tudomást szerez, haladéktalanul
              intézkedik az információ eltávolításáról, vagy a hozzáférést nem biztosítja.
            </span>
          </p>
        </li>
      </ol>
      <p>
        <br />
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: '1.2', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          3.5&nbsp;
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          A Program használata során a Felhasználó jogosult az általa kiválasztott Szolgáltatókkal
          felvenni a kapcsolatot akár a Programon kívül, akár a Program által biztosított chat
          funkció használatával. A Felhasználó a chat funkció használata során vállalja, hogy a
          Felhasználó által elküldött bármely információ
        </span>
      </p>
      <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: '48px' }}>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: 'disc',
            fontSize: '10pt',
            fontFamily: '"Noto Sans Symbols",sans-serif',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre',
          }}>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.2',
              textAlign: 'justify',
              marginTop: '0pt',
              marginBottom: '0pt',
            }}>
            <span
              style={{
                fontSize: '10pt',
                fontFamily: 'Arial',
                color: '#000000',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap',
              }}>
              naprakész és valós; valamint
            </span>
          </p>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: 'disc',
            fontSize: '10pt',
            fontFamily: '"Noto Sans Symbols",sans-serif',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre',
          }}>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.2',
              textAlign: 'justify',
              marginTop: '0pt',
              marginBottom: '0pt',
            }}>
            <span
              style={{
                fontSize: '10pt',
                fontFamily: 'Arial',
                color: '#000000',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap',
              }}>
              nem hamis, pontatlan vagy megtévesztő;
            </span>
          </p>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: 'disc',
            fontSize: '10pt',
            fontFamily: '"Noto Sans Symbols",sans-serif',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre',
          }}>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.2',
              textAlign: 'justify',
              marginTop: '0pt',
              marginBottom: '0pt',
            }}>
            <span
              style={{
                fontSize: '10pt',
                fontFamily: 'Arial',
                color: '#000000',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap',
              }}>
              nem minősül bármely módon jogellenesnek, tisztességtelennek vagy erkölcstelennek;
            </span>
          </p>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: 'disc',
            fontSize: '10pt',
            fontFamily: '"Noto Sans Symbols",sans-serif',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre',
          }}>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.2',
              textAlign: 'justify',
              marginTop: '0pt',
              marginBottom: '0pt',
            }}>
            <span
              style={{
                fontSize: '10pt',
                fontFamily: 'Arial',
                color: '#000000',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap',
              }}>
              nem sérti bármely harmadik fél szerzői és egyéb iparjogvédelmi jogát, jó hírnevét,
              becsületét, üzleti titkát, vagy bármely egyéb személyiségi jogát;
            </span>
          </p>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: 'disc',
            fontSize: '10pt',
            fontFamily: '"Noto Sans Symbols",sans-serif',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre',
          }}>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.2',
              textAlign: 'justify',
              marginTop: '0pt',
              marginBottom: '0pt',
            }}>
            <span
              style={{
                fontSize: '10pt',
                fontFamily: 'Arial',
                color: '#000000',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap',
              }}>
              nem obszcén, illetve nem tartalmaz olyan információkat, amelyeket a Wedding Manager
              saját kizárólagos belátása szerint bármilyen formában helytelennek ítél;
            </span>
          </p>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: 'disc',
            fontSize: '10pt',
            fontFamily: '"Noto Sans Symbols",sans-serif',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre',
          }}>
          <p
            dir="ltr"
            style={{
              lineHeight: '1.2',
              textAlign: 'justify',
              marginTop: '0pt',
              marginBottom: '0pt',
            }}>
            <span
              style={{
                fontSize: '10pt',
                fontFamily: 'Arial',
                color: '#000000',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap',
              }}>
              nem tartalmaz olyan kártékony kódokat („vírusok”), adatokat vagy utasításokat, amelyek
              akár szándékosan, akár anélkül kárt okoznak vagy ellehetetlenítik, megnehezítik a
              Program tervezett bármely funkcióját, ide értve, de nem kizárólagosan a vírusokat,
              trójai programokat, illetve az olyan egyéb számítógépes programozási rutinokat,
              amelyek károsíthatnak, módosíthatnak, törölhetnek, a háttérben jogosultság nélkül
              elérhetnek bármely rendszert, adatot vagy személyes információt.
            </span>
          </p>
        </li>
      </ul>
      <p>
        <br />
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: '1.2', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          4./ [Regisztráció]
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: '1.2', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          4.1
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          &nbsp;A Program igénybe vételének előfeltétele a Felhasználói regisztráció, melynek
          körében elfogadásra kerülnek az Általános Felhasználási Feltételek, illetve a Felhasználó
          hozzájárul személyes adatainak kezeléséhez. Ezt követően a Felhasználó saját használatú
          eszközein jogosult az alkalmazás rendeltetésszerű használatára. A Wedding Manager
          fenntartja a jogot, hogy a program használatától és további igénybe vételétől eltiltsa
          mindazokat, akik korábbi magatartásukkal megsértették a rendeltetésszerű használat
          követelményét, így különösen azokat, akik az alkalmazásban rögzített információkkal
          visszaéltek, illetve használat során az alkalmazás működését ellehetetlenítették, vagy
          ezek bármelyikét megkísérelték.
        </span>
      </p>
      <p>
        <br />
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: '1.2', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          4.2
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          &nbsp;A regisztráció során megadott, a Felhasználó azonosítását szolgáló adatok (név,
          email-cím és jelszó), illetve a későbbiekben a program használata során megadott adatok
          kezelése az Adatkezelési tájékoztató rendelkezései szerint történik. A regisztráció nem
          végezhető el olyan email-címmel, amelyet azonosítás céljából korábban már a Programban
          rögzítettek.
        </span>
      </p>
      <p>
        <br />
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: '1.2', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          4.3
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          &nbsp;A regisztrációkor megadott jelszó titkosságának megőrzése teljes mértékben a
          Felhasználó felelőssége. Vállalkozás a megadott adatok körében a jelszavak titkosságának
          megőrzése érdekében minden tőle elvárható technikai intézkedést megtesz annak érdekében,
          hogy megakadályozza azok nyilvánosságra kerülését, azonban gondatlan és ellenőrzési körén
          kívül eső káresemények bekövetkezte esetére kárfelelősségét kizárja.
        </span>
      </p>
      <p>
        <br />
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: '1.2', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          4.4
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          &nbsp;A 18. életévét be nem töltött, illetve korlátozottan cselekvőképes Felhasználó
          regisztrációjához és a Wedding Manager bármely használatához törvényes képviselőjének,
          vagy gondozójának beleegyezése, illetve hozzájárulása szükséges.&nbsp;
        </span>
      </p>
      <p>
        <br />
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: '1.2', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          4.5
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          &nbsp;A Vállalkozás jogosult a Felhasználó hozzáférési jogát megszüntetni, továbbá
          regisztrációját és a Felhasználó által a Programban rögzített információkat indoklás és
          előzetes értesítés nélkül törölni. A Vállalkozás fenntartja magának a jogot, hogy a
          Program bármely funkcióját, működését vagy megjelenését bármikor megváltoztassa, törölje,
          vagy a Program működését bármikor felfüggessze vagy megszüntesse. A Program működésének
          lényeges megváltoztatása, vagy annak működésének felfüggesztése esetére a Vállalkozás
          anyagi felelőssége kizárt.&nbsp;
        </span>
      </p>
      <p>
        <br />
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: '1.2', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          4.6&nbsp;
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          A Vállalkozás a Felhasználó által rögzített valamennyi adathoz hozzáfér, kivéve a zártan
          kezelt felhasználói jelszót.
        </span>
      </p>
      <p>
        <br />
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: '1.2', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          4.7
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          &nbsp;A Felhasználó hozzájárul ahhoz, hogy adatait a Vállalkozás aggregálva, vagy
          anonimizált formában statisztikai célokra, vagy a Program továbbfejlesztése érdekében
          feldolgozza, vagy harmadik fél számára átadja.
        </span>
      </p>
      <p>
        <br />
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: '1.2', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          4.8
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          &nbsp;A Vállalkozás a Felhasználó regisztrációját azonosító bármely adatot kizárólag a
          Felhasználó előzetes, legalább teljes bizonyító erejű magánokiratba foglalt hozzájárulása
          esetén, illetve jogszabályban kötelezően előírt esetekben ad ki harmadik személyek,
          illetve hatóságok számára, valamint amennyiben az adattovábbítás számla kiállításához,
          egyéb jogszabályi kötelezettség teljesítéséhez, vagy bármely felhasználói díjtartozás
          behajtásához szükséges vagy indokolt.
        </span>
      </p>
      <p>
        <br />
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: '1.2', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          4.9
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          &nbsp;Felhasználó hozzájárul ahhoz, hogy regisztrált email-címére a Vállalkozás
          tájékoztató vagy értesítő leveleket küldjön a Programhoz kapcsolódó információkkal. A
          Felhasználók ilyen megkeresése nem minősül reklám közlésének. A Felhasználó regisztrációja
          során hozzájárulását adhatja reklám célú küldemények fogadására, mely esetben a
          Vállalkozás időszakos tájékoztatást nyújt a számára a Programban elérhető új funkciókról,
          szolgáltatásokról és esetleges akciókról.
        </span>
      </p>
      <p>
        <br />
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: '1.2', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          5./ [A szerződés hatálya]
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: '1.2', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          5.1&nbsp;
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          A Felhasználó regisztrációjával létrejövő szerződés határozatlan időre jön létre és
          automatikusan megszűnik és ezáltal az adott Felhasználó fiókja és a regisztrációkor
          megadott adatai törlésre kerülnek, amennyiben a Felhasználó a Programot egy teljes éven
          keresztül nem használja (arra nem jelentkezik be), és a Vállalkozó által a törlésről
          küldött figyelmeztető e-mailre sem reagál. A szerződés automatikus megszűnése esetén a
          Felhasználó jogosult bármikor ismételten regisztrálni.
        </span>
      </p>
      <p>
        <br />
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: '1.2', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          A Felhasználói regisztráció és az ezzel létrejövő szerződés felmondására Felek bármelyike
          indokolás nélkül, azonnali hatállyal jogosult. A Felhasználó felmondását regisztrációjának
          törlése útján közölheti a Wedding Manager részére. A Wedding Manager a felmondással
          egyidőben jogosult egyoldalúan eldönteni, hogy a Felhasználó ismételt regisztrációját meg
          kívánja-e engedni. A Wedding Manager kizárja minden anyagi felelősségét a felmondásával
          összefüggésben.
        </span>
      </p>
      <p>
        <br />
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: '1.2', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          5.2 Fogyasztói felmondási jog:
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          &nbsp;A Fogyasztó jogosult továbbá Felhasználói regisztrációját jogszabályban
          meghatározott határidőn belül indokolás nélkül felmondani. A Fogyasztó a felmondás jogát a
          szerződés megkötésének napjától számított tizennégy (14) napon belül gyakorolhatja. Ha a
          Fogyasztó felmondási jogával kíván élni, úgy a felmondási szándékát tartalmazó egyértelmű
          nyilatkozatát vagy a 45/2014. (II. 26.) Korm. rendelet 2. számú melléklete szerinti
          felmondási nyilatkozat minta alkalmazásával a Vállalkozás részére az előbbi határidőn
          belül köteles megküldeni a jelen ÁSZF 1. pontjában feltüntetett elérhetőségek bármelyikén
          (így postán, faxon vagy elektronikus úton küldött levél formájában). Úgy kell tekinteni,
          hogy a Fogyasztó határidőben gyakorolja felmondási jogát, ha a fent megjelölt határidő
          lejárta előtt elküldi felmondási nyilatkozatát a Szolgáltató részére. A Vállalkozás e-mail
          formájában haladéktalanul visszaigazolja a Fogyasztó felmondási nyilatkozatának
          megérkezését és intézkedik a szerződés megszűnése érdekében.
        </span>
      </p>
      <p>
        <br />
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: '1.2', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          6./ [Felelősségviselés]
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: '1.2', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          6.1
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          &nbsp;A Vállalkozás platform szolgáltatóként kizárólag a Program üzemeltetéséért és
          rendelkezésre állásáért vállal felelősséget. A Program használata során bármely
          szolgáltatóval létrejövő kapcsolat keretében a Wedding Manager felelőssége kizárt.&nbsp;
        </span>
      </p>
      <p>
        <br />
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: '1.2', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          6.2
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          &nbsp;A Program magán rendezvényszervezési célokon túli felhasználása a Wedding Manager
          beleegyezése nélkül tilos. Az ilyen felhasználás körében okozott bármely kárért a Wedding
          Manager felelőssége kizárt.
        </span>
      </p>
      <p>
        <br />
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: '1.2', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          6.3
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          &nbsp;
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          Kellékszavatossági tájékoztató
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: '1.2', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          Milyen esetben élhet Ön a kellékszavatossági jogával?
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: '1.2', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          Ön a Vállalkozás hibás teljesítése esetén a vállalkozással szemben kellékszavatossági
          igényt érvényesíthet a Polgári Törvénykönyv szabályai szerint.
        </span>
      </p>
      <p>
        <br />
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: '1.2', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          Milyen jogok illetik meg Önt kellékszavatossági igénye alapján?
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: '1.2', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          Ön - választása szerint-az alábbi kellékszavatossági igényekkel élhet:
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: '1.2', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          Kérhet kijavítást vagy kicserélést, kivéve, ha az ezek közül az Ön által választott igény
          teljesítése lehetetlen vagy a vállalkozás számára más igénye teljesítéséhez képest
          aránytalan többletköltséggel járna. Ha a kijavítást vagy a kicserélést nem kérte, illetve
          nem kérhette, úgy igényelheti az ellenszolgáltatás arányos leszállítását vagy a hibát a
          vállalkozás költségére Ön is kijavíthatja, illetve mással kijavíttathatja vagy - végső
          esetben - a szerződéstől is elállhat.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: '1.2', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          Választott kellékszavatossági jogáról egy másikra is áttérhet, az áttérés költségét
          azonban Ön viseli, kivéve, ha az indokolt volt, vagy arra a vállalkozás adott okot.
        </span>
      </p>
      <p>
        <br />
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: '1.2', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          Milyen határidőben érvényesítheti Ön kellékszavatossági igényét?
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: '1.2', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          Ön köteles a hibát annak felfedezése után haladéktalanul, de nem később, mint a hiba
          felfedezésétől számított kettő hónapon belül közölni. Ugyanakkor felhívom a figyelmét,
          hogy a szerződés teljesítésétől számított két éves elévülési határidőn túl
          kellékszavatossági jogait már nem érvényesítheti.
        </span>
      </p>
      <p>
        <br />
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: '1.2', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          Kivel szemben érvényesítheti kellékszavatossági igényét?
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: '1.2', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          Ön a vállalkozással szemben érvényesítheti kellékszavatossági igényét.
        </span>
      </p>
      <p>
        <br />
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: '1.2', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          Milyen egyéb feltétele van kellékszavatossági jogai érvényesítésének?
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: '1.2', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          A teljesítéstől számított hat hónapon belül a kellékszavatossági igénye érvényesítésének a
          hiba közlésén túl nincs egyéb feltétele, ha Ön igazolja, hogy a terméket, illetve a
          szolgáltatást az Wedding Manager nyújtotta. A teljesítéstől számított hat hónap eltelte
          után azonban már Ön köteles bizonyítani, hogy az Ön által felismert hiba már a teljesítés
          időpontjában is megvolt.
        </span>
      </p>
      <p>
        <br />
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: '1.2', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          6.4 Termékszavatossági tájékoztató
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: '1.2', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          Tájékoztatjuk, hogy tekintettel arra, hogy a Vállalkozás ingó dolog értékesítését nem
          végzi, így termékszavatosság nem terheli.
        </span>
      </p>
      <p>
        <br />
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: '1.2', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          6.5&nbsp;
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          A Vállalkozás kizár minden felelősséget a Felhasználók által a Program használata során
          tanúsított magatartásáért.
        </span>
      </p>
      <p>
        <br />
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: '1.2', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          6.6
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          &nbsp;A Vállalkozás kizárja felelősségét a Program használatával összefüggésben tanúsított
          minden olyan magatartásért, amely a Felhasználók által használt információs rendszerek
          biztonságát sértik, különös tekintettel a számítógépes vírusok és egyéb károkozásra
          alkalmas eszközök használatára, a Vállalkozás birtokában lévő személyes és egyéb adatokhoz
          való jogosulatlan hozzáférésre, azok megsemmisítésére, károsítására, nyilvánosságra
          hozatalára.
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: '1.2', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          Az ilyen magatartással okozott, vagy azzal összefüggésben keletkezett károkért kizárólag a
          magatartás megvalósítója a felelős. Vállalkozás ugyanakkor minden a technika mindenkori
          állása szerint ésszerűen elvárhatót elkövet annak érdekében, hogy az általa kezelt Program
          adatbázisához illetéktelen személyek ne férhessenek hozzá.
        </span>
      </p>
      <p>
        <br />
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: '1.2', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          6.7
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          &nbsp;Vállalkozás kizárja felelősségét minden, rajta kívül álló okból bekövetkező olyan
          meghibásodásért, mely a Program céljának elérését átmenetileg, időszakonként visszatérően,
          vagy véglegesen akadályozza, vagy meghiúsítja. A Vállalkozás a Program folyamatos
          elérhetőségét frissítések céljából, vagy szerverhiba esetén kártalanítási kötelezettség
          nélkül jogosult időlegesen felfüggeszteni.
        </span>
      </p>
      <p>
        <br />
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: '1.2', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          6.8
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          &nbsp;Vállalkozás kizár minden felelősséget a meghibásodásokból, nem megfelelő működésből
          fakadó esetleges károkért.
        </span>
      </p>
      <p>
        <br />
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: '1.2', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          6.9
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          &nbsp;Vállalkozás fenntartja a jogot, hogy a Program elérhetőségét korlátozza,
          felfüggessze vagy véglegesen megszüntesse.
        </span>
      </p>
      <p>
        <br />
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: '1.2', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          7./ [Szerzői jogok]
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: '1.2', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          7.1
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          &nbsp;A Programot használó Felhasználó az alkalmazás tartalmának kizárólag magáncélú
          felhasználására jogosult. A magáncélú felhasználás ellenértékhez nem kötött, azonban
          jövedelemszerzés vagy jövedelemfokozás célját közvetlenül és közvetett módon sem
          szolgálhatja, amennyiben jelen ÁSZF másképp nem rendelkezik.
        </span>
      </p>
      <p>
        <br />
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: '1.2', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          7.2
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          &nbsp;Bármiféle üzleti jellegű felhasználáshoz Vállalkozás előzetes, írásbeli
          hozzájárulása vagy jelen ÁSZF kifejezett hozzájárulása szükséges. Vállalkozás logójának,
          védjegyeinek használata kizárólag előzetes engedély alapján lehetséges.
        </span>
      </p>
      <p>
        <br />
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: '1.2', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          7.3
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          &nbsp;A Programból és annak adatbázisából bármilyen anyagot átvenni írásos hozzájárulás
          esetén is csak a Vállalkozásra való hivatkozással lehet.
        </span>
      </p>
      <p>
        <br />
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: '1.2', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          7.4
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          &nbsp;A Vállalkozás fenntartja minden jogát Programjának valamennyi elemére, így
          mindenekelőtt annak forráskódjára, a Program elnevezésére és annak domain-neveire, az
          azokkal képzett másodlagos domain nevekre valamint az internetes reklámfelületeire.
        </span>
      </p>
      <p>
        <br />
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: '1.2', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          7.5
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          &nbsp;Tilos a Program tartalmának, illetve egyes részeinek adaptációja vagy visszafejtése;
          bármely olyan alkalmazás használata, amellyel a Program vagy annak bármely része
          módosítható vagy indexelhető.
        </span>
      </p>
      <p>
        <br />
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: '1.2', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          7.6
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          &nbsp;A Wedding Manager név és annak bármely domain-je szerzői jogi védelmet élveznek,
          felhasználásuk kizárólag a Vállalkozás írásos hozzájárulásával lehetséges.
        </span>
      </p>
      <p>
        <br />
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: '1.2', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          7.7
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          &nbsp;A Programban a Szolgáltatók által közzétett bármely szerzői mű szerzői jogainak
          jogosultja az azt feltöltő Szolgáltató, így e körben a Wedding Manager felelőssége kizárt.
          Amennyiben bármely harmadik személynek valamely szerzői mű vonatkozásában a Programban
          történő közzétételt akadályozó szerzői joga állna fenn, úgy kérjük aré az Elker tv. 13.§
          szerint értesítse a Wedding Managert.
        </span>
      </p>
      <p>
        <br />
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: '1.2', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '12pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          8./ [Panaszkezelés]
        </span>
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: '1.2', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          8.1
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          &nbsp;Bármely felhasználói panasz felmerülése esetén Felek kötelesek tárgyalásokat
          folytatni a felhasználói panasz rendezése és jogviták megelőzése érdekében.
        </span>
      </p>
      <p>
        <br />
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: '1.2', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          8.2
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          &nbsp;Felhasználói panasz előterjesztésének az info@weddingmanager.hu
        </span>
        <span
          style={{
            fontSize: '9pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          &nbsp;
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          e-mail-címen, illetve a Vállalkozás székhelyén levél útján van helye. Az ilyen módon
          előterjesztett felhasználói panasz elintézésére annak beérkezését követő 30 napon belül a
          Vállalkozás érdemi intézkedéseket kell foganatosítson, illetve választ adjon.
        </span>
      </p>
      <p>
        <br />
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: '1.2', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          8.3
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          &nbsp;A panaszeljárásban rendezésre nem kerülő kérdésekben Felek között a jogvita
          rendezésére a magyar jog alkalmazandó. A későbbi esetleges eljárásokra Felek hatáskörtől
          függően a Budapesti II. és III. kerületi Bíróság kizárólagos illetékességét kötik ki,
          illetve az ügyben a Pp. szerint általánosan illetékes törvényszék jár el.
        </span>
      </p>
      <p>
        <br />
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: '1.2', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          8.4&nbsp;
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          Alternatív vitarendezési módszerként a Fogyasztóknak jogában áll békéltető testületi
          eljárást kezdeményezni. Budapest területén a Fogyasztók a Budapesti Kereskedelmi és
          Iparkamara mellett működő Budapesti Békéltető Testület eljárását vehetik igénybe. Az egyéb
          békéltető testületekről, valamint azok elérhetőségéről további információt a&nbsp;
        </span>
        <a href="https://bekeltetes.hu/" style={{ textDecoration: 'none' }}>
          <span
            style={{
              fontSize: '10pt',
              fontFamily: 'Arial',
              color: '#0000ff',
              backgroundColor: 'transparent',
              fontWeight: 400,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'underline',
              WebkitTextDecorationSkip: 'none',
              textDecorationSkipInk: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}>
            https://bekeltetes.hu/
          </span>
        </a>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          &nbsp;címen érhet el.
        </span>
      </p>
      <p>
        <br />
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: '1.2', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          8.5.
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          &nbsp;A fogyasztói jogviták online rendezésére a Fogyasztók az Európai Parlament és Tanács
          524/2013/EU rendeletének értelmében létrehozott online platformot is igénybe vehetik. A
          platform a&nbsp;
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          http://ec.europa.eu/odr
        </span>
        <span
          style={{
            fontSize: '9pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          &nbsp;
        </span>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          címen érhető el, amelyen keresztül panaszok megtételére van lehetőség.
        </span>
      </p>
      <p>
        <br />
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: '1.2', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'italic',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          Jelen dokumentum nem kerül iktatásra, kizárólag elektronikus formában kerül megkötésre,
          későbbiekben nem kereshető vissza, magatartási kódexre nem utal. A Program működésével és
          megrendelési folyamatával kapcsolatosan felmerülő kérdések esetén a megadott
          elérhetőségeinken rendelkezésére állunk!
        </span>
      </p>
      <p>
        <br />
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: '1.2', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          Kelt: Budapesten, 2021. szeptember 15. napján
        </span>
      </p>
      <p>
        <br />
      </p>
      <p
        dir="ltr"
        style={{ lineHeight: '1.2', textAlign: 'right', marginTop: '0pt', marginBottom: '0pt' }}>
        <span
          style={{
            fontSize: '10pt',
            fontFamily: 'Arial',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}>
          Wedding Manager Kft.
        </span>
      </p>
    </div>
  </Page>
)
