import API from '@aws-amplify/api'
import { GraphQLResult } from '@aws-amplify/api-graphql'
import { call } from 'redux-saga/effects'
import * as qb from 'gql-query-builder'
import config from 'modules/config'

export function* requestApi<T>(query, variables?, authToken?) {
  try {
    const { data } = (yield call(() =>
      API.graphql(
        {
          query,
          variables,
        },
        {
          ...(authToken && { 'auth-token': authToken }),
          version: config.apiVersion,
        },
      ),
    )) as GraphQLResult<T>

    return data
  } catch (e) {
    const messages = e.errors.map(({ message }) => message)
    const error = Error('API error: ' + messages.join(', '))

    if (error.message === 'Network Error') {
      error.name = 'NetworkError'
    }

    if (messages.includes('Unsupported version')) {
      error.name = 'ApiVersionError'
    }

    throw error
  }
}

export function* query(options, _protected = false) {
  // TODO remove deprecated protected
  const { query, variables } = qb.query(options)

  return yield requestApi(query, variables)
}

export function* mutation(options, _protected = false) {
  // TODO remove deprecated protected
  const { query, variables } = qb.mutation(options)

  return yield requestApi(query, variables)
}

export * from './generated'
