import moment from 'moment'
import { SortDirection, User } from 'modules/api'
import { Table } from 'modules/ui'

const createColumns = RowActions => [
  {
    field: 'name',
    headerName: 'Name',
    width: 220,
  },
  {
    field: 'type',
    headerName: 'Type',
    width: 140,
  },
  {
    field: 'role',
    headerName: 'Role',
    width: 140,
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 140,
  },
  {
    field: 'createdAt',
    headerName: 'Created at',
    width: 220,
    valueGetter: row => moment(row.createdAt).format('YYYY.MM.DD. HH:mm'),
  },
  {
    field: '',
    headerName: 'Actions',
    width: 220,
    sortable: false,
    disableClickEventBubbling: true,
    renderCell: RowActions,
  },
]

export interface UsersListProps {
  users: User[]
  total: number
  rowActionsComponent: React.FC<{ row: User }>
  title: string
  page: number
  pageSize: number
  filter: string
  sortBy: string
  sortDirection: SortDirection
  toggleSort: (sortBy: string, newSortBy: string, sortDirection: SortDirection) => void
  handleChangePage: (page: number) => void
  handleChangeRowsPerPage: (rows: number) => void
  handleSearch: (search: string | { search: string; type: string }) => void
}

const UsersList = ({ users, total, rowActionsComponent, ...props }: UsersListProps) => {
  const columns = createColumns(rowActionsComponent)
  return <Table data={users} totalElements={total} columns={columns} {...props} />
}

export default UsersList
