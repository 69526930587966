import { useHistory } from 'react-router'
import Grid from '@material-ui/core/Grid'
import { Page } from 'modules/ui'
import { TagForm, useTag } from 'modules/tag'

const initialValues = {
  name: '',
  priority: 0,
  hide: false,
}

export default () => {
  const { loading, createTag } = useTag()
  const history = useHistory()

  const handleSubmit = async data => {
    const { payload } = await createTag(data)
    history.push(`/tags/${payload.id}/edit`)
  }

  if (loading) {
    return 'Loading...'
  }

  return (
    <Page title="New tag">
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={6} md={4}>
          <TagForm initialValues={initialValues} onSubmit={handleSubmit} />
        </Grid>
      </Grid>
    </Page>
  )
}
