import { useState } from 'react'
import { useHistory } from 'react-router'
import Grid from '@material-ui/core/Grid'
import { CategoryForm, useCategory } from 'modules/category'
import { Page } from 'modules/ui'
import { uploadToS3 } from 'modules/storage'

const initialValues = {
  name: '',
  image: '',
  tags: [],
  ordering: 0,
  extraFields: [],
  hide: false,
}

const New = () => {
  const { loading, createCategory } = useCategory()
  const history = useHistory()
  const [, setSubmitting] = useState(false)

  const handleSubmit = async data => {
    setSubmitting(true)
    const tagIdList = data.tags.map(item => item.id)
    const extraFields = data.extraFields.map(extraField => extraField?.field)

    const { payload } = await createCategory({
      ...data,
      extraFields,
      tags: tagIdList,
      image: data.image && (await uploadToS3(data.image)),
    })
    setSubmitting(false)
    history.push(`/categories/${payload.id}/edit`)
  }

  if (loading) {
    return 'Loading...'
  }

  return (
    <Page title="New category">
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={6} md={4}>
          <CategoryForm initialValues={initialValues} onSubmit={handleSubmit} />
        </Grid>
      </Grid>
    </Page>
  )
}

export default New
