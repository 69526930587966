import { useParams, useHistory } from 'react-router-dom'
import { Dialog } from 'modules/ui'
import { useBlog } from 'modules/blog'

const Remove = ({ match }) => {
  const { id } = useParams()
  const { replace } = useHistory()
  const { blog, removeBlog } = useBlog()

  const goBack = () => replace('/' + match.url.split('/')[1])

  const handleRemove = async () => {
    await removeBlog({ id })
  }

  if (!blog) {
    return null
  }

  return (
    <Dialog
      title={blog.name}
      text="Are you sure you want to remove it?"
      closeButtonText="Cancel"
      confirmButtonText="Remove"
      onClose={goBack}
      onConfirm={handleRemove}
    />
  )
}

export default Remove
