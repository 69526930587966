import { useState, useEffect } from 'react'
import {
  Dialog as BaseDialog,
  DialogProps as BaseDialogProps,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import { useT } from 'modules/i18n'
import Loader from './Loader'
import Button from './Button'

export interface DialogProps extends Omit<BaseDialogProps, 'open'> {
  title?: string
  text?: string
  closeButtonText?: string
  confirmButtonText?: string
  loading?: boolean
  children?: BaseDialogProps['children']
  onClose?: () => void
  onConfirm?: () => Promise<void>
}

const Dialog = ({
  children,
  title,
  text,
  closeButtonText = 'Close',
  confirmButtonText = 'Confirm',
  loading,
  onClose,
  onConfirm,
  ...props
}: DialogProps) => {
  const [open, setOpen] = useState(false)
  const [_loading, setLoading] = useState(false)
  const t = useT()

  const handleClose = () => {
    setOpen(false)
  }

  const handleConfirm = async () => {
    setLoading(true)
    await onConfirm()
    setLoading(false)
    handleClose()
  }

  useEffect(() => {
    setOpen(true)
  }, [])

  return (
    <BaseDialog
      fullWidth
      maxWidth="sm"
      open={open}
      TransitionProps={{ onExited: onClose }}
      onClose={handleClose}
      {...props}>
      {title && <DialogTitle>{t(title)}</DialogTitle>}
      <DialogContent>
        {text && <DialogContentText>{t(text)}</DialogContentText>}
        {children}
        <Loader loading={loading || _loading} />
      </DialogContent>
      <DialogActions>
        {onClose && (
          <Button size="small" onClick={handleClose}>
            {t(closeButtonText)}
          </Button>
        )}
        {onConfirm && (
          <Button
            color="primary"
            size="small"
            variant="contained"
            disabled={loading}
            onClick={handleConfirm}>
            {t(confirmButtonText)}
          </Button>
        )}
      </DialogActions>
    </BaseDialog>
  )
}

export default Dialog
