import { delay, put, takeEvery } from 'redux-saga/effects'
import { putAsync, takeEveryAsync } from 'saga-toolkit'
import { push as navigate } from 'connected-react-router'
import { actions as appActions } from 'modules/app'
import { actions as authActions } from 'modules/auth'
import { actions as chatActions } from 'modules/chat'
import { actions as notificationActions } from 'modules/notification'
import { actions as profileActions } from 'modules/profile'
import { actions as trackingActions } from 'modules/tracking'
import { actions as quoteActions } from 'modules/quote'
import { actions as weddingActions } from 'modules/wedding'
import { initTransalation } from 'modules/i18n'
import config from 'modules/config'
import { UserNeedStatus } from 'modules/api'
import translations from '../translations'

function redirectIfMobile() {
  const target = 'https://weddingmanager.app/mobilmegnyitas'
  const ignoredPaths = ['/purchase-token', '/purchase-token/result']

  if (ignoredPaths.includes(window.location.pathname)) {
    return false
  }

  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    window.location.href = target

    return true
  }

  return false
}

function* initSession() {
  const profile = yield putAsync(profileActions.fetchProfile())
  const { id, email, type } = profile

  const data = {
    id,
    profile: {
      $email: email,
      Type: type,
    },
  }

  switch (type) {
    case 'ADMIN':
    case 'PARTNER':
      data.profile.$name = profile.name
      break
    default:
      break
  }

  yield putAsync(profileActions.registerDevice())
  yield put(trackingActions.identify(data))
  yield startNotificationService()
  yield put(chatActions.setUserId(id))

  try {
    yield putAsync(chatActions.fetchConversations())
  } catch (error) {
    console.warn('Unable to fetch chat conversations')
  }

  if (type === 'PARTNER') {
    yield put(quoteActions.fetchUnseenQuotes())
    yield put(
      weddingActions.fetchUnseenWeddings({
        status: UserNeedStatus.Accepted,
        category: profile.category.id,
      }),
    )
  }
}

function* appStart() {
  if (redirectIfMobile()) {
    return
  }

  initTransalation(translations)

  if (config.enableTracking) {
    yield putAsync(
      trackingActions.init({
        analytics: config.analytics,
        sentryDsn: config.sentryDsn,
        mixpanelToken: config.mixpanelToken,
        environment: config.app.STAGE,
      }),
    )
  }

  try {
    yield putAsync(authActions.continueSession())
    yield initSession()
    yield put(trackingActions.track('Continue user session'))
  } catch (error) {
    if (error?.name === 'NoCurrentUser') {
      return
    }
    yield putAsync(authActions.signOut())
  }

  yield put(trackingActions.track('App start'))
  yield delay(300)
}

function* signIn() {
  yield put(trackingActions.track('Sign in'))
  yield initSession()
  yield put(navigate('/'))
}

function* signOut() {
  yield put(trackingActions.track('Sign out'))
  yield putAsync(profileActions.signOut())
  yield put(notificationActions.stopService())
}

function* startNotificationService() {
  yield putAsync(notificationActions.init())
  yield put(notificationActions.startService())
}

export default [
  takeEveryAsync(appActions.start.type, appStart),
  takeEvery(authActions.signIn.fulfilled, signIn),
  takeEvery(authActions.signOut.pending, signOut),
]
