import { useState } from 'react'
import { useHistory } from 'react-router'
import Grid from '@material-ui/core/Grid'
import { NeedDetail, useNeed } from 'modules/need'
import { useCategory } from 'modules/category'
import { Page, Loader } from 'modules/ui'

export const New = () => {
  const { loading, insertNeed } = useNeed()
  const { categories, loading: categoryLoading } = useCategory()
  const history = useHistory()
  const [submitting, setSubmitting] = useState(false)

  const handleSubmit = async data => {
    setSubmitting(true)

    const { payload } = await insertNeed(data)

    setSubmitting(false)
    history.push(`/needs/${payload.id}/edit`)
  }

  if (loading) {
    return 'Loading...'
  }

  return (
    <Page fullHeight title="New Need">
      <Grid container>
        <Grid item xs={12} sm={8} md={6}>
          <Loader loading={submitting} />
          <NeedDetail
            initialValues={{ name: '', hide: false }}
            categoryLoading={categoryLoading}
            categories={categories}
            handleSubmit={handleSubmit}
            submitting={submitting}
          />
        </Grid>
      </Grid>
    </Page>
  )
}
