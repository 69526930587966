import React from 'react'
import { Box, makeStyles } from '@material-ui/core'
import { Typography } from 'modules/ui'

const useStyles = makeStyles(() => ({
  root: {
    width: 120,
    minWidth: 120,
    height: 48,
    borderRadius: '14px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    fontSize: '14px',
    fontWeight: 600,
  },
}))

interface Props {
  variant: 'PENDING' | 'ACTIVE' | 'REJECTED' | 'HIDDEN'
}

const VARIANTS = {
  PENDING: { backgroundColor: '#D5E92033', textColor: '#F6BD20', text: 'Inspiration pending' },
  ACTIVE: { backgroundColor: '#4CBC9A1A', textColor: '#00A389', text: 'Inspiration active' },
  REJECTED: { backgroundColor: '#CE33331A', textColor: '#FF0000', text: 'Inspiration rejected' },
  HIDDEN: { backgroundColor: '#A4A4A433', textColor: '#505050', text: 'Inspiration hidden' },
}

const InspirationStatus = ({ variant }: Props) => {
  const classes = useStyles()
  const { backgroundColor, textColor, text } = VARIANTS[variant]

  return (
    <Box className={classes.root} style={{ backgroundColor }}>
      <Typography className={classes.text} style={{ color: textColor }}>
        {text}
      </Typography>
    </Box>
  )
}

export default InspirationStatus
