import { gql } from 'graphql-tag'

export const paymentsQuery = gql`
  query Payments($filter: PaymentFilter!) {
    payments(filter: $filter) {
      total
      elements {
        id
        transactionId
        items
        status
        note
        createdAt
        updatedAt
        user {
          ... on Partner {
            id
            name
            email
          }
        }
        invoice {
          type
          name
          company
          country
          state
          city
          zip
          address
          phone
          taxNumber
        }
      }
    }
  }
`

export const startTransaction = gql`
  mutation StartTransaction($input: StartTransactionInput!) {
    startTransaction(input: $input) {
      paymentUrl
    }
  }
`

export const validateTransaction = gql`
  mutation ValidateTransaction($input: ValidateTransactionInput!) {
    validateTransaction(input: $input) {
      success
      status
      orderRef
      transactionId
    }
  }
`
