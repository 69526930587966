import { Box, makeStyles } from '@material-ui/core'
import { useNeed } from 'modules/need'
import { useTable } from 'modules/ui'
import { NeedsCardList } from 'modules/quote'
import { getPaginationParams } from 'modules/app'

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    padding: spacing(2),
    paddingTop: spacing(1),
  },
}))

const List = () => {
  const classes = useStyles()
  const { userNeeds, total, loading } = useNeed()
  const { handleChangePage } = useTable()
  const { pageSize, page } = getPaginationParams({ pageSize: 12 })

  return (
    <Box className={classes.root}>
      <NeedsCardList
        origin="weddings"
        userNeeds={userNeeds}
        loading={loading}
        numberOfPages={Math.ceil(total / pageSize)}
        page={page}
        onChangePage={handleChangePage}
      />
    </Box>
  )
}

export default List
