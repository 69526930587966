import { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Table as MuiTable,
  TableSortLabel,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { Typography } from 'modules/ui'
import { SortDirection } from 'modules/api'
import { EnhancedTableToolbar } from './EnhancedTableToolbar'
import { TableFilterDialog } from './TableFilterDialog'

export interface TableProps {
  title: string
  data: any
  columns: any
  totalElements: number
  page: number
  pageSize: number
  filter: string
  sortBy: string
  sortDirection: SortDirection
  // contains the display name and the field name to search in
  // e.g. ["Display Name", "SearchableFieldInDB"]
  filterTypes?: string[][]
  toggleSort: (sortBy: string, newSortBy: string, sortDirection: SortDirection) => void
  handleChangePage: (page: number) => void
  handleChangeRowsPerPage: (rows: number) => void
  // search returns a string if filtertypes are not defined otherwise returns an object with search and filtertype
  handleSearch: (search: string | { search: string; type: string }) => void
}

const useStyles = makeStyles({
  tableContainer: {
    minWidth: 650,
    maxWidth: 'calc(100vw - 380px)',
  },
  height: {
    maxHeight: '80vh',
  },
})

const renderCell = (column, row) => {
  if (column?.renderCell) {
    return column.renderCell(row)
  }
  if (column?.valueGetter) {
    return column.valueGetter(row)
  }

  return row[column.field]
}

const Row = ({ columns, row, renderCell }) => {
  return (
    <TableRow>
      {columns.map((column, i) => {
        return <TableCell key={i}>{renderCell(column, row)}</TableCell>
      })}
    </TableRow>
  )
}

const Table = ({
  data,
  columns,
  totalElements,
  pageSize,
  sortBy,
  page,
  handleChangePage,
  sortDirection,
  handleChangeRowsPerPage,
  toggleSort,
  title,
  filter,
  handleSearch,
  filterTypes = [],
}: TableProps) => {
  const classes = useStyles()
  const [filterDialogOpen, setFilterDialogOpen] = useState(false)

  return (
    <>
      <EnhancedTableToolbar
        title={title}
        handleFilterClick={() => setFilterDialogOpen(prevState => !prevState)}
        count={totalElements}
        rowsPerPage={pageSize}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      <TableContainer className={classes.tableContainer} component={Paper}>
        <MuiTable stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>
              {columns.map(({ headerName, field, sortable }, i) => (
                <TableCell key={i}>
                  {sortable ? (
                    <TableSortLabel
                      active={sortBy === field}
                      direction={
                        (sortBy === field
                          ? sortDirection.toLowerCase()
                          : SortDirection.Asc.toLowerCase()) as 'asc' | 'desc'
                      }
                      onClick={() => toggleSort(field, sortBy, sortDirection)}>
                      <Typography>{headerName}</Typography>
                    </TableSortLabel>
                  ) : (
                    <Typography>{headerName}</Typography>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, i) => (
              <Row key={i} row={row} columns={columns} renderCell={renderCell} />
            ))}
          </TableBody>
        </MuiTable>
      </TableContainer>

      <TableFilterDialog
        open={filterDialogOpen}
        setOpen={setFilterDialogOpen}
        handleSearch={handleSearch}
        filterTypes={filterTypes}
        filter={filter}
      />
    </>
  )
}

export default Table
