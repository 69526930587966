import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Chip, Box } from '@material-ui/core'
import clsx from 'clsx'
import { QuoteType } from 'modules/api'
import { Typography } from 'modules/ui'
import { useT } from 'modules/i18n'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  chip: {
    width: 105,
    color: 'white',
    fontWeight: 700,
  },
  info: {
    width: '100%',
    height: 32,
    color: '#959ab1',
    fontSize: 11,
    lineHeight: '13px',
    fontWeight: 500,
    textAlign: 'center',
    paddingTop: '2px',
    marginTop: 10,
  },
}))

interface Props {
  label: string
  info?: string
  chipBackground?: string
  infoColor?: string
  className?: string
}

export const getDefaultQuoteTypeBadgeProps = (type: QuoteType) => {
  switch (type) {
    case QuoteType.Direct:
      return {
        label: 'Direct request',
      }
    case QuoteType.Wall:
      return {
        label: 'Weddings wall',
        chipBackground: '#a90ecc',
      }
    case QuoteType.ForYou:
      return {
        label: 'By recommendation',
        chipBackground: '#00D3FF',
      }
  }
}

const QuoteTypeBadge = ({ label, info, chipBackground, infoColor, className = '' }: Props) => {
  const classes = useStyles()
  const theme = useTheme()
  const t = useT()

  return (
    <Box className={clsx(classes.container, className && className)}>
      <Chip
        className={classes.chip}
        label={t(label)}
        size="small"
        style={{ background: chipBackground ? chipBackground : theme.palette.primary.main }}
      />
      {info && (
        <Typography className={classes.info} style={{ color: infoColor ? infoColor : '#959ab1' }}>
          {info}
        </Typography>
      )}
    </Box>
  )
}

export default QuoteTypeBadge
