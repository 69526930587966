import API from '@aws-amplify/api'
import cc from 'camelcase-keys'
import sc from 'snakecase-keys'
import { pick } from 'rambda'
import { call } from 'redux-saga/effects'
import { takeLatestAsync } from 'saga-toolkit'
import * as actions from './slice'

const parseFilterGroups = result => cc(result, { deep: true })
const parseFilterGroup = result => cc(result, { deep: true })

function* fetchFilterGroups() {
  const result = yield call(API.get.bind(API), 'default', '/filter_groups')

  return parseFilterGroups(result)
}

function* fetchFilterGroup({ meta }) {
  const { id } = meta.arg
  const result = yield call(API.get.bind(API), 'default', `/filter_groups/${id}`)

  return parseFilterGroup(result)
}

function* createFilterGroup({ meta }) {
  const body = sc(meta.arg)

  const result = yield call(API.post.bind(API), 'default', '/filter_groups', { body })
  return parseFilterGroup(result)
}

function* updateFilterGroup({ meta }) {
  const { id } = meta.arg
  const body = sc(pick(['title', 'description', 'ordering', 'variant', 'entities'], meta.arg))

  yield call(API.patch.bind(API), 'default', `/filter_groups/${id}`, { body })
}

function* removeFilterGroup({ meta }) {
  const { id } = meta.arg

  yield call(API.del.bind(API), 'default', `/filter_groups/${id}`)
}

export default [
  takeLatestAsync(actions.fetchFilterGroups.type, fetchFilterGroups),
  takeLatestAsync(actions.fetchFilterGroup.type, fetchFilterGroup),
  takeLatestAsync(actions.createFilterGroup.type, createFilterGroup),
  takeLatestAsync(actions.updateFilterGroup.type, updateFilterGroup),
  takeLatestAsync(actions.removeFilterGroup.type, removeFilterGroup),
]
