import { useState } from 'react'
import {
  DialogTitle,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  FormControlLabel,
  FormControl,
  Radio,
  RadioGroup,
  Select,
  MenuItem,
} from '@material-ui/core'
import { Typography, Button } from 'modules/ui'
import { useT } from 'modules/i18n'

export interface TableFilterDialogProps {
  open: boolean
  setOpen: (open: boolean) => void
  handleSearch: (search: string | { search: string; type: string }) => void
  filter: string
  filterTypes: string[][]
}

export const TableFilterDialog = ({ open, setOpen, handleSearch, filter, filterTypes }) => {
  const t = useT()
  const filterTypesEnabled = filterTypes.length > 0
  const [filterType, setFilterType] = useState(filterTypesEnabled ? filterTypes[0][1] : '')
  const [search, setSearch] = useState(filter || '')

  const submitDialog = () => {
    handleSearch(filterTypesEnabled ? { search, type: filterType } : search)
    setOpen(false)
  }

  const onClear = () => {
    handleSearch(filterTypesEnabled ? { search: '', type: '' } : '')
    setSearch('')
    setOpen(false)
  }

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      e.preventDefault()
      e.stopPropagation()
      submitDialog()
    }
  }

  const handleFilterTypeSelect = e => {
    setFilterType(e.target.value)
  }

  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Typography variant="h6">Search</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography>
            {filterTypesEnabled ? 'Search in the following options' : 'Search in names only'}
          </Typography>
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="search"
          label={t('Keyword')}
          type="text"
          fullWidth
          onKeyDown={handleKeyDown}
          value={search}
          onChange={({ target }) => setSearch(target.value)}
        />
        {filterTypesEnabled && (
          <>
            <FormControl fullWidth>
              <Select
                value={filterType}
                label={t('Filter category')}
                onChange={handleFilterTypeSelect}>
                {filterTypes.map(([label, value]) => (
                  <MenuItem key={value} value={value}>
                    {t(label)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClear} size="small">
          {filter ? 'Clear' : 'Cancel'}
        </Button>
        <Button onClick={submitDialog} color="primary" variant="contained" size="small">
          Search
        </Button>
      </DialogActions>
    </Dialog>
  )
}
