import { call, put, fork, take, race, select } from 'redux-saga/effects'
import { eventChannel } from 'redux-saga'
import { takeEveryAsync, takeLatestAsync, putAsync } from 'saga-toolkit'
import firebase from 'firebase/app'
import 'firebase/messaging'
import { mutation, query } from 'modules/api'
import { selectors as profileSelectors } from 'modules/profile'
import * as actions from './slice'

const senderField = [
  {
    '...on Partner': ['name', 'id'],
  },
  {
    '...on Admin': ['name', 'id'],
  },
  {
    '...on Couple': ['name', 'id'],
  },
]

const notificationsFields = [
  'id',
  'title',
  {
    sender: senderField,
  },
  'createdAt',
]

const notificationFields = [
  'id',
  'title',
  'body',
  {
    sender: senderField,
  },
  'createdAt',
]

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FB_API_KEY,
  authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FB_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FB_APP_ID,
  measurementId: process.env.REACT_APP_FB_MEASUREMENT_ID,
}
const vapidKey = process.env.REACT_APP_FB_VAPID_KEY

firebase.initializeApp(firebaseConfig)

const supported = firebase.messaging.isSupported()
let messaging = null

if (supported) {
  messaging = firebase.messaging()
}

function* init() {
  let authorized
  let token
  let registered

  if (supported) {
    try {
      token = yield call(() => messaging.getToken({ vapidKey }))
      authorized = !!token

      yield putAsync(actions.registerNotificationToken())
    } catch (error) {}
  }

  return {
    authorized,
    registered,
    token,
    supported,
  }
}

function* registerNotificationToken() {
  const token = yield call(() => messaging.getToken())
  const deviceId = yield select(profileSelectors.selectBrowserId)

  const { registerNotificationToken } = yield mutation({
    operation: 'registerNotificationToken',
    fields: ['token', 'enabled', 'createdAt'],
    variables: {
      deviceId: { value: deviceId, type: 'String', required: true },
      token: { value: token, type: 'String', required: true },
    },
  })

  return registerNotificationToken
}

function* updateNotificationsEnabled({ meta }) {
  const deviceId = yield select(profileSelectors.selectBrowserId)

  const { updateNotificationsEnabled } = yield mutation({
    operation: 'updateNotificationsEnabled',
    variables: {
      deviceId: { value: deviceId, type: 'String', required: true },
      enabled: { value: meta.arg, type: 'Boolean', required: true },
    },
  })

  return updateNotificationsEnabled
}

function receiveMessage() {
  const channel = eventChannel(emitter => {
    const receiveMessage = type => async message => {
      emitter({ type, message })
    }

    navigator.serviceWorker.onmessage = ({ data }) => {
      if (document.visibilityState !== 'visible') {
        receiveMessage('background')(data)
      }
    }

    const unsubscribes = [
      messaging.onMessage(receiveMessage('foreground')),
      () => (navigator.serviceWorker.onmessage = undefined),
    ]

    return () => {
      unsubscribes.forEach(unsubscribe => unsubscribe())
    }
  })

  return channel
}

function* watchReceiveMessage() {
  if (!supported) {
    return
  }

  while (true) {
    yield take(actions.startService.type)

    const channel = yield call(receiveMessage)

    try {
      while (true) {
        const { message, stop } = yield race({
          message: take(channel),
          stop: take(actions.stopService.type),
        })

        if (stop) {
          break
        }

        yield put(actions.receiveMessage(message))
      }
    } finally {
    }
  }
}

function* fetchNotifications() {
  const { notifications } = yield query(
    {
      operation: 'notifications',
      fields: notificationsFields,
    },
    true,
  )

  return notifications
}

function* fetchNotification({ meta }) {
  const { id } = meta.arg
  const { notification } = yield query(
    {
      operation: 'notification',
      fields: notificationFields,
      variables: {
        id: {
          type: 'ID',
          value: id,
          required: true,
        },
      },
    },
    true,
  )

  return notification
}

function* searchNotification({ meta }) {
  const searchByTitle = meta.arg
  const { notifications } = yield query({
    operation: 'notifications',
    fields: ['id', 'title', 'createdAt'],
    variables: {
      searchByTitle: {
        value: searchByTitle,
        required: true,
      },
    },
  })

  return notifications
}

function* sendNotification({ meta }) {
  const options = {
    operation: 'sendNotification',
    fields: notificationFields,
    variables: {
      input: {
        value: meta.arg,
        type: 'SendNotificationInput',
        required: true,
      },
    },
  }

  const { sendNotification } = yield mutation(options, true)

  return sendNotification
}

export default [
  takeEveryAsync(actions.init.type, init),
  takeLatestAsync(actions.registerNotificationToken.type, registerNotificationToken),
  fork(watchReceiveMessage),
  takeEveryAsync(actions.fetchNotifications.type, fetchNotifications),
  takeEveryAsync(actions.fetchNotification.type, fetchNotification),
  takeEveryAsync(actions.searchNotification.type, searchNotification),
  takeEveryAsync(actions.sendNotification.type, sendNotification),
  takeLatestAsync(actions.updateNotificationsEnabled.type, updateNotificationsEnabled),
]
