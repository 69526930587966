import { RichTextEditor as UIRichTextEditor } from 'modules/ui'
import { useField } from 'formik'

export default ({ name, value, onChange, onBlur, ...props }) => {
  const [field, meta, helpers] = useField({ name, value, onChange, onBlur })

  const handleChange = data => {
    helpers.setValue(data)
  }

  return (
    <UIRichTextEditor
      {...field}
      {...props}
      onChange={handleChange}
      error={meta.touched && !!meta.error}
      helperText={meta.error || ''}
    />
  )
}
