import { makeStyles } from '@material-ui/core'
import ImageList from '@material-ui/core/ImageList'
import GridListTile from '@material-ui/core/GridListTile'
import moment from 'moment'
import { OpenableImage, Typography, TextField } from 'modules/ui'
import { CustomNeedList } from 'modules/quote'
import config from 'modules/config'

const imagesPath = config.app.IMAGES_URL

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(2),
  },
  rejectButtonContainer: {
    textAlign: 'center',
    margin: theme.spacing(2),
  },
  attachment: {
    width: '100%',
  },
  gridList: {
    flexWrap: 'nowrap',
    transform: 'translateZ(0)',
  },
  attachmentsTitle: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  marginTop: {
    marginTop: theme.spacing(1),
  },
}))

const FormBody = ({ disabled, initialValues }) => {
  const classes = useStyles()
  const { couple, weddingCopy, text, attachments, createdAt, userNeedCopy } = initialValues
  const inputProps = {
    style: { color: 'black' },
    disableUnderline: true,
  }

  const inputLabelProps = { shrink: true, style: { color: 'black' } }

  return (
    <div className={classes.root} noValidate autoComplete="off">
      <TextField
        id="myName"
        name="myName"
        label="My name"
        margin="normal"
        fullWidth
        disabled
        InputProps={inputProps}
        InputLabelProps={inputLabelProps}
        value={couple.myName}
      />
      <TextField
        id="partnersName"
        name="partnersName"
        label="Partner's name"
        margin="normal"
        fullWidth
        disabled
        InputProps={inputProps}
        InputLabelProps={inputLabelProps}
        value={couple.partnersName}
      />
      {weddingCopy?.date && (
        <TextField
          id="date"
          name="date"
          label="Wedding date"
          margin="normal"
          fullWidth
          disabled
          InputProps={inputProps}
          InputLabelProps={inputLabelProps}
          value={moment(weddingCopy.date).format('LL')}
        />
      )}
      {weddingCopy?.possibleYear && (
        <TextField
          id="possibleYear"
          name="possibleYear"
          label="Wedding date"
          margin="normal"
          fullWidth
          disabled
          InputProps={inputProps}
          InputLabelProps={inputLabelProps}
          value={weddingCopy.possibleYear}
        />
      )}
      {weddingCopy?.address && (
        <TextField
          id="address"
          name="address"
          label="Wedding location"
          margin="normal"
          fullWidth
          disabled
          InputProps={inputProps}
          InputLabelProps={inputLabelProps}
        />
      )}

      {weddingCopy?.possibleAddresses?.length && (
        <TextField
          id="address"
          name="address.name"
          label="Wedding location"
          margin="normal"
          fullWidth
          disabled
          InputProps={inputProps}
          InputLabelProps={inputLabelProps}
          value={weddingCopy.possibleAddresses.map(address => address.name).join(', ')}
        />
      )}

      <TextField
        id="email"
        name="email"
        label="Email address"
        disabled
        margin="normal"
        fullWidth
        InputProps={inputProps}
        InputLabelProps={inputLabelProps}
        value={couple.email}
      />
      <TextField
        id="text"
        name="text"
        label="Quote's description"
        disabled
        margin="normal"
        color="black"
        fullWidth
        multiline
        InputProps={inputProps}
        InputLabelProps={inputLabelProps}
        value={text}
      />
      <TextField
        id="createdAt"
        name="createdAt"
        label="Created at"
        disabled
        margin="normal"
        color="black"
        fullWidth
        InputProps={inputProps}
        InputLabelProps={inputLabelProps}
        value={moment(createdAt).format('LL')}
      />
      <Typography variant="caption" className={classes.attachmentsTitle}>
        Attachments
      </Typography>
      <ImageList className={classes.gridList} cols={2.5}>
        {attachments.map((attachment, index) => (
          <GridListTile key={index}>
            <OpenableImage queryParams="height=240" src={`${imagesPath}/${attachment}`} />
          </GridListTile>
        ))}
      </ImageList>

      <div classes={classes.marginTop}>
        <CustomNeedList questionResponses={userNeedCopy?.questionResponses} />
      </div>
      <Typography color="textSecondary">
        You can see the couples special needs about the service here
      </Typography>
    </div>
  )
}

const QuoteRequestForm = ({ onSubmit, disabled, initialValues }) => (
  <>
    <FormBody disabled={disabled} initialValues={initialValues} />
  </>
)

export default QuoteRequestForm
