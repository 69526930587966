import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import { Typography } from 'modules/ui'

const useStyles = makeStyles(() => ({
  root: {},
}))

const Row = ({ title, children }) => (
  <Box my={1}>
    <Typography variant="caption" color="textSecondary">
      {title}
    </Typography>
    <Typography>{children}</Typography>
  </Box>
)

const NotificationView = ({ id, title, body, sender }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Row title="Title">{title}</Row>
      <Row title="Body">{body}</Row>
      <Row title="Sender">{sender?.name || 'Unknown'}</Row>
    </div>
  )
}

export default NotificationView
