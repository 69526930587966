import { takeLatestAsync } from 'saga-toolkit'
import { select } from '@redux-saga/core/effects'
import * as profileSelectors from 'modules/profile/selectors'
import { requestApi } from 'modules/api'
import * as actions from './slice'
import * as operations from './operations'

function* fetchWedding({ meta }) {
  const { id } = meta.arg

  const { weddingById } = yield requestApi(operations.weddingByIdQuery, {
    weddingByIdId: id,
  })

  return weddingById
}

function* fetchUnseenWeddings({ meta }) {
  const filter = meta.arg
  const profile = yield select(profileSelectors.selectRoot)

  const { user } = profile

  const { unseenUserNeeds } = yield requestApi(operations.unseenUserNeedsQuery, {
    filter: {
      ...filter,
      needIds: user?.needs?.map(({ id }) => id) ?? [],
    },
  })

  return unseenUserNeeds
}

export default [
  takeLatestAsync(actions.fetchWedding.type, fetchWedding),
  takeLatestAsync(actions.fetchUnseenWeddings.type, fetchUnseenWeddings),
]
