import { useCallback, useRef, useState } from 'react'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'
import { Box, makeStyles } from '@material-ui/core'
import { useT } from 'modules/i18n'
import { QuoteType } from 'modules/api'
import { Typography, Button } from 'modules/ui'
import QuoteResponseForm from './QuoteResponseForm'

export interface QuoteResponseContactViewProps {
  balance: number
  quoteType: QuoteType
  onAcceptQuoteRequest: (data: any) => void | Promise<void>
  onRejectQuoteRequest?: () => void
  onCancel?: () => void
}

const quoteTypePrices = {
  [QuoteType.Direct]: 0,
  [QuoteType.ForYou]: 10,
  [QuoteType.Wall]: 10,
}

const useStyles = makeStyles(theme => ({
  root: {},
  acceptButton: {
    margin: theme.spacing(1),
    width: '280px',
    textTransform: 'none',
  },
  rejectButton: {
    alignSelf: 'flex-start',
    backgroundColor: theme.palette.grey[500],
    color: 'white',
    margin: theme.spacing(1),
    width: '280px',
    textTransform: 'none',
  },
  actions: {
    marginTop: theme.spacing(2),
  },
  contactUs: {
    fontWeight: 'bold',
  },
}))

const QuoteResponseContactView = ({
  balance,
  quoteType,
  onCancel,
  onAcceptQuoteRequest,
  onRejectQuoteRequest,
}: QuoteResponseContactViewProps) => {
  const classes = useStyles()
  const t = useT()
  const [isFormValid, setFormValid] = useState(false)
  const acceptForm = useRef()

  const acceptFormRef = useCallback<any>(node => {
    if (!node) return

    setFormValid(node.dirty && node.isValid)
    acceptForm.current = node
  }, [])

  const handleClickSend = async () => {
    if (!acceptForm?.current) return

    // @ts-ignore
    await acceptForm?.current?.submitForm()
  }

  return (
    <>
      <Typography variant="h5" className={classes.contactUs}>
        Contact us
      </Typography>
      <QuoteResponseForm onSubmit={onAcceptQuoteRequest} innerRef={acceptFormRef} />
      <Box
        className={classes.actions}
        justifyContent="center"
        display="flex"
        alignContent="center"
        alignItems="center">
        {onRejectQuoteRequest && (
          <Button
            variant="contained"
            className={classes.rejectButton}
            onClick={onRejectQuoteRequest}>
            Reject direct quote request
          </Button>
        )}
        {onCancel && (
          <Button variant="contained" className={classes.rejectButton} onClick={onCancel}>
            Cancel
          </Button>
        )}
        <Box display="flex" flexDirection="column" alignItems="center">
          <Button
            disabled={!isFormValid}
            onClick={handleClickSend}
            type="submit"
            className={classes.acceptButton}
            color="primary"
            variant="contained">
            {`${t('Send')} ${quoteTypePrices[quoteType]}`}
            <MonetizationOnIcon />
          </Button>
          <Typography tParams={{ tokens: balance }} color="textSecondary">
            {'Available tokens: {{tokens}}'}
          </Typography>
        </Box>
      </Box>
    </>
  )
}

export default QuoteResponseContactView
