import { useRouteMatch } from 'react-router'
import AddIcon from '@material-ui/icons/Add'
import VisibilityIcon from '@material-ui/icons/Visibility'
import { Box } from '@material-ui/core'
import { NavButton } from 'modules/ui'
import { NotificationsList, useNotification } from 'modules/notification'
import { Page } from 'modules/ui'

const RowActions = ({ row }) => {
  const match = useRouteMatch()

  return (
    <>
      <NavButton to={`${match.url}/${row.id}/view`} tooltip="View">
        <VisibilityIcon fontSize="small" />
      </NavButton>
    </>
  )
}

const SenderColumn = ({ value }) => {
  if (!value) {
    return '-'
  }

  const { id, name } = value

  return (
    <NavButton to={`/users/${id}/edit`} tooltip="View" size="small">
      {name}
    </NavButton>
  )
}

export default () => {
  const { notifications, loading } = useNotification()
  const match = useRouteMatch()

  return (
    <Page title="Notifications" fullHeight>
      <NotificationsList
        notifications={notifications}
        loading={loading}
        rowActionsComponent={RowActions}
        senderColumnComponent={SenderColumn}
      />
      <Box display="flex" justifyContent="flex-end" p={1}>
        <NavButton to={`${match.url}/send`} tooltip="Send">
          <AddIcon />
        </NavButton>
      </Box>
    </Page>
  )
}
