import { pick } from 'rambda'

export const partnerProfileFields = [
  'id',
  'type',
  'cognitoId',
  'name',
  'description',
  'website',
  'role',
  'email',
  'class',
  'verified',
  'status',
  'phone',
  { needs: ['id', 'name'] },
  'standingCapacity',
  'seatingCapacity',
  'availableWeekdays',
  { coverImages: ['name'] },
  { profileImage: ['name'] },
  'contactName',
  'contactEmail',
  'contactPhone',
  { devices: ['id', 'signedIn', { notificationPreferences: ['token', 'enabled'] }] },
  {
    items: [
      'id',
      'name',
      'active',
      'status',
      { coverImage: ['name'] },
      { tags: ['id', 'name'] },
      {
        gallery: ['name', { image: ['name'] }],
      },
      'likes',
      'views',
    ],
  },
  {
    fixLocation: [
      'address',
      'city',
      'zip',
      'street',
      'streetNumber',
      { coordinate: ['lat', 'lng'] },
    ],
  },
  { areaLocation: ['address', 'radius', 'city', 'availableNationwide'] },
  { guestRange: ['low', 'high'] },
  {
    tags: ['id', 'name'],
  },
  {
    category: ['id', 'name', 'extraFields', 'hide'],
  },
  {
    wallet: [
      'id',
      'balance',
      'partnerId',
      'createdAt',
      { transactions: ['id', 'amount', 'note', 'createdAt'] },
    ],
  },
  { emailPreferences: ['approveUserNeed'] },
]

export const adminProfileFields = [{ profileImage: ['name'] }, 'name', 'email', 'phone']

export const coupleProfileFields = [
  'myName',
  'partnersName',
  'whoAreYou',
  { profileImage: ['name'] },
  'weddingDate',
  {
    livingLocation: [
      'placeId',
      'name',
      {
        coordinate: ['lat', 'lng'],
      },
    ],
  },
  {
    weddingLocation: [
      'placeId',
      'name',
      {
        coordinate: ['lat', 'lng'],
      },
    ],
  },
  {
    guestRange: ['low', 'high'],
  },
  {
    budgetRange: ['low', 'high'],
  },
  {
    likedItems: ['id', { coverImage: ['name'] }],
  },
  {
    likedPartners: ['id', 'name', { profileImage: ['name'] }],
  },
]

export const createUpdateUserInput = user => {
  const { type } = user
  const updateObject = { ...pick(['id', 'type', 'email', 'role', 'status'], user) }

  switch (type) {
    case 'PARTNER':
      updateObject.partner = {
        ...pick(
          [
            'name',
            'profileImage',
            'description',
            'tags',
            'category',
            'city',
            'zip',
            'phone',
            'website',
            'standingCapacity',
            'seatingCapacity',
            'availableWeekdays',
            'coverImages',
            'contactName',
            'contactEmail',
            'contactPhone',
            'fixLocation',
            'category',
            'areaLocation',
            'id',
            'verified',
            'class',
            'needs',
            'emailPreferences',
          ],
          user,
        ),
      }

      return updateObject

    case 'COUPLE':
      updateObject.couple = {
        ...pick(
          [
            'id',
            'whoAreYou',
            'myName',
            'partnersName',
            'livingLocation',
            'interest',
            'wedding',
            'adminNote',
          ],
          user,
        ),
      }
      return updateObject

    case 'ADMIN':
      updateObject.admin = { ...pick(['name', 'phone'], user) }
      return updateObject
    default:
      return null
  }
}
