import { Payment } from 'modules/api'
import { Table, TableProps } from 'modules/ui'
import moment from 'moment'

const createColumns = ({ RowActions }) => [
  {
    field: 'id',
    headerName: 'ID',
    width: 220,
  },
  {
    field: 'transactionId',
    headerName: 'Transaction Id',
    width: 120,
  },
  {
    field: 'items',
    headerName: 'Item(s)',
    width: 120,
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 120,
  },
  {
    field: 'user',
    headerName: 'User',
    width: 220,
    valueGetter: ({ user }) => user.name,
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    width: 180,
    sortable: true,
    renderCell: row => moment(row.updatedAt).format('YYYY-MM-DD HH:mm'),
  },
  {
    field: 'updatedAt',
    headerName: 'Updated At',
    width: 180,
    sortable: true,
    renderCell: row => moment(row.updatedAt).format('YYYY-MM-DD HH:mm'),
  },
  ...(RowActions
    ? [
        {
          field: '',
          headerName: 'Actions',
          width: 140,
          sortable: false,
          disableClickEventBubbling: true,
          renderCell: RowActions,
        },
      ]
    : []),
]

export interface PaymentListProps extends Omit<TableProps, 'columns'> {
  data: Payment[]
  rowActionsComponent: React.FC<{ row: Payment }>
}

const PaymentsList = ({ rowActionsComponent: RowActions, ...props }: PaymentListProps) => {
  const columns = createColumns({ RowActions })

  return <Table columns={columns} {...props} />
}

export default PaymentsList
