import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { createSagaAction } from 'saga-toolkit'

const name = 'payment'

export const paymentsAdapter = createEntityAdapter()

const initialState = {
  loading: false,
  error: null,
  result: null,
  payments: paymentsAdapter.getInitialState(),
  total: 0,
}

export const fetchPayments = createSagaAction(`${name}/fetchPayments`)
export const startTransaction = createSagaAction(`${name}/startTransaction`)
export const validateTransaction = createSagaAction(`${name}/validateTransaction`)

const handlePending = state => ({
  ...state,
  loading: true,
})

const handleRejected = (state, { error }) => ({
  ...state,
  error,
  loading: false,
})

const slice = createSlice({
  name,
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: {
    // fetchPayments
    [fetchPayments.pending]: handlePending,
    [fetchPayments.fulfilled]: (state, { payload }) => {
      paymentsAdapter.setAll(state.payments, payload.elements)
      state.loading = false
      state.total = payload.total
    },
    [fetchPayments.rejected]: handleRejected,

    // startTransaction
    [startTransaction.pending]: handlePending,
    [startTransaction.fulfilled]: state => ({
      ...state,
      loading: false,
    }),
    [startTransaction.rejected]: handleRejected,

    // validateTransaction
    [validateTransaction.pending]: handlePending,
    [validateTransaction.fulfilled]: (state, { payload }) => ({
      ...state,
      result: payload,
      loading: false,
    }),
    [validateTransaction.rejected]: handleRejected,
  },
})

export default slice.reducer
