import UIFormControlLabel, {
  FormControlLabelProps as UIFormControlLabelProps,
} from '@material-ui/core/FormControlLabel'
import { useT } from 'modules/i18n'

export interface FormControlLabelProps extends UIFormControlLabelProps {
  label: React.ReactNode
}

const isString = value => {
  return typeof value === 'string'
}

export const FormControlLabel = ({ label, ...props }: FormControlLabelProps) => {
  const t = useT()

  return <UIFormControlLabel label={isString(label) ? t(label + '') : label} {...props} />
}

export default FormControlLabel
