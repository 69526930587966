import React, { useState, useCallback } from 'react'
import { useField } from 'formik'
import { makeStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import Modal from '@material-ui/core/Modal'
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import CancelIcon from '@material-ui/icons/Cancel'
import Cropper from 'react-easy-crop'
import { Badge, Typography, Button, FormHelperText } from 'modules/ui'

const useStyles = makeStyles(({ palette, spacing, breakpoints }) => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    background: palette.background.paper,
    height: 600,
    overflow: 'auto',
    boxShadow: 24,
    padding: spacing(4),
  },
  preViewImage: {
    margin: '0 auto',
    border: '3px solid white',
  },
  cropContainer: {
    position: 'relative',
    width: '100%',
    height: 200,
    background: '#333',
    [breakpoints.up('sm')]: {
      height: 400,
    },
  },
}))

//https://codesandbox.io/s/q8q1mnr01w?file=/src/cropImage.js

const createImage = url =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', error => reject(error))
    image.setAttribute('crossOrigin', 'anonymous')
    image.src = url
  })

function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180
}

async function getCroppedImg(imageSrc, pixelCrop, rotation = 0) {
  const image = await createImage(imageSrc)
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  const maxSize = Math.max(image.width, image.height)
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2))

  canvas.width = safeArea
  canvas.height = safeArea

  ctx.translate(safeArea / 2, safeArea / 2)
  ctx.rotate(getRadianAngle(rotation))
  ctx.translate(-safeArea / 2, -safeArea / 2)

  ctx.drawImage(image, safeArea / 2 - image.width * 0.5, safeArea / 2 - image.height * 0.5)
  const data = ctx.getImageData(0, 0, safeArea, safeArea)

  canvas.width = pixelCrop.width
  canvas.height = pixelCrop.height

  ctx.putImageData(
    data,
    Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
    Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y),
  )

  return new Promise(resolve => {
    canvas.toBlob(file => {
      resolve(URL.createObjectURL(file))
    }, 'image/jpeg')
  })
}

const FileUploaderField = ({
  id,
  name,
  value,
  helperText,
  fullWidth,
  margin,
  singleImage,
  className,
  onChange,
  onBlur,
  maxFileSize,
}) => {
  const [field, meta, helpers] = useField({
    name,
    value,
    onChange,
    onBlur,
  })
  const [profileImage, setProfileImage] = useState(field.value)
  const [uploadError, setUploadError] = useState('')
  const [upImg, setUpImg] = useState(field.value)
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [rotation, setRotation] = useState(0)
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const onSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      if (e.target.files[0].size > maxFileSize) {
        setUploadError(e.target.files[0].name + ': file size is too big!')
        return
      }
      setUploadError('')
      const reader = new FileReader()
      reader.addEventListener('load', () => setUpImg(reader.result))
      reader.readAsDataURL(e.target.files[0])
    }
  }

  const removeProfilePicture = () => {
    setProfileImage('')
    setUpImg('')
    helpers.setValue('')
  }

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const updateCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(upImg, croppedAreaPixels, rotation)

      fetch(croppedImage)
        .then(res => res.blob())
        .then(blob => {
          const file = new File([blob], 'profilePicture.jpg', { type: blob.type })
          helpers.setValue(file)
        })

      setProfileImage(croppedImage)
      setOpen(false)
    } catch (e) {
      console.error(e)
    }
  }, [croppedAreaPixels, rotation])

  return (
    <>
      <Button onClick={handleOpen}>Upload profile picture</Button>
      {!!profileImage && (
        <Badge
          overlap="circular"
          iconContent={
            <IconButton style={{ padding: 0 }} aria-label="delete" onClick={removeProfilePicture}>
              <CancelIcon color="primary" fontSize="large" />
            </IconButton>
          }>
          <img
            className={classes.preViewImage}
            width={400}
            id="profile-image"
            src={profileImage}
            alt="profile"
          />
        </Badge>
      )}
      {meta.error && <FormHelperText error>{meta.error || ''}</FormHelperText>}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box className={classes.root}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Upload profile picture
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <FormControl className={className} fullWidth={fullWidth} margin={margin}>
              <Button color="primary" variant="contained" component="label">
                Upload File
                <input type="file" accept="image/*" hidden onChange={onSelectFile} />
              </Button>
              {upImg && (
                <div className={classes.cropContainer}>
                  <Cropper
                    image={upImg}
                    crop={crop}
                    rotation={rotation}
                    zoom={zoom}
                    disabled
                    aspect={3 / 2}
                    onCropChange={setCrop}
                    onRotationChange={setRotation}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                  />
                </div>
              )}
              <FormHelperText error>{uploadError || ''}</FormHelperText>
              <Button color="primary" disabled={!upImg} onClick={updateCroppedImage}>
                Update
              </Button>
              <Button onClick={handleClose}>Vissza</Button>
            </FormControl>
          </Typography>
        </Box>
      </Modal>
    </>
  )
}

export default FileUploaderField
