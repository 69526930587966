import { useRouteMatch } from 'react-router'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import SaveIcon from '@material-ui/icons/Save'
import Grid from '@material-ui/core/Grid'
import { Box, IconButton } from '@material-ui/core'
import { getPaginationParams } from 'modules/app'
import { NavButton, Page, Tooltip, useTable } from 'modules/ui'
import { CoupleList, useCouple } from 'modules/couple'

const RowActions = row => {
  const match = useRouteMatch()
  const { id } = row

  return (
    <Grid container wrap="nowrap">
      <NavButton to={`${match.url}/${id}/view`} tooltip="View">
        <OpenInNewIcon fontSize="small" />
      </NavButton>
    </Grid>
  )
}

const List = () => {
  const { couples, total, loading, exportCouples } = useCouple()
  const { handleChangePage, handleChangeRowsPerPage, handleSearch, handleSort } = useTable()
  const { pageSize, page, filter, sortBy, sortDirection } = getPaginationParams()

  const handleExportClick = () => {
    return exportCouples({ filter })
  }

  return (
    <Page fullHeight>
      <CoupleList
        data={couples}
        rowActionsComponent={RowActions}
        pageSize={pageSize}
        totalElements={total}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        page={page}
        toggleSort={handleSort}
        sortDirection={sortDirection}
        sortBy={sortBy}
        filter={filter}
        title="Couple list"
        handleSearch={handleSearch}
      />
      <Box display="flex" justifyContent="flex-end" p={1}>
        <Tooltip title="Export" enterDelay={100} leaveDelay={200} placement="bottom">
          <IconButton
            aria-label="Export"
            color="inherit"
            disabled={loading}
            onClick={handleExportClick}>
            <SaveIcon />
          </IconButton>
        </Tooltip>
      </Box>
    </Page>
  )
}

export default List
