import React, { ReactElement } from 'react'
import { Box, makeStyles } from '@material-ui/core'
import { Typography } from 'modules/ui'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: '4px',
  },
}))

interface Props {
  icon: ReactElement
  value?: string | number
}

const IconWithCounter = ({ icon, value = 'soon' }: Props) => {
  const classes = useStyles()
  return (
    <Box className={classes.root}>
      {icon}
      <Typography>{value}</Typography>
    </Box>
  )
}

export default IconWithCounter
