import { makeStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import { Formik, Form, useFormikContext } from 'formik'
import * as yup from 'yup'
import { TextField, Select, SubmitButton } from 'modules/formik'
import { UserAutocompleteFormik } from 'modules/user'

const initialState = {
  title: '',
  body: '',
  target: '',
  targetUser: { name: '', id: '', email: '' },
}

const targets = [
  { id: 'partners', title: 'Partners' },
  { id: 'users', title: 'Users' },
  { id: 'single-user', title: 'Single User' },
  { id: 'all', title: 'All' },
]

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
}))

const validationSchema = yup.object({
  title: yup
    .string('Enter title')
    .min(3, 'Should be of minimum 3 characters length')
    .required('Title is required'),
  body: yup
    .string('Enter body')
    .min(10, 'Should be of minimum 10 characters length')
    .required('Body is required'),
  target: yup.string('Select target audience').required('Body is required'),
})

const TargetUserInput = props => {
  const { values } = useFormikContext()

  if (values.target !== 'single-user') {
    return null
  }

  return (
    <UserAutocompleteFormik
      id="targetUser"
      name="targetUser"
      label="Target User"
      margin="normal"
      fullWidth
      filterSelectedOptions
      disableClearable
    />
  )
}

const NotificationForm = ({ initialValues, ...props }) => {
  const classes = useStyles()

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{ ...initialState, ...initialValues }}
      {...props}>
      <Form className={classes.root} noValidate autoComplete="off">
        <TextField id="title" name="title" label="Title" margin="normal" fullWidth />
        <TextField id="body" name="body" label="Body" margin="normal" fullWidth multiline />
        <Select
          id="target"
          name="target"
          label="Target"
          labelId="filter-edit-form-target-id-select"
          margin="normal"
          fullWidth>
          {targets.map(({ id, title }, i) => (
            <MenuItem key={i} value={id}>
              {title}
            </MenuItem>
          ))}
        </Select>
        <TargetUserInput />
        <SubmitButton color="primary" variant="contained" size="small">
          send
        </SubmitButton>
      </Form>
    </Formik>
  )
}

export default NotificationForm
