import moment from 'moment'
import { useHistory, useParams } from 'react-router'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Container, Typography as UITypography } from '@material-ui/core'
import {
  ArrowBack,
  EventNoteOutlined,
  LocationOnOutlined,
  PeopleOutlined,
} from '@material-ui/icons'
import { Button, Page, Typography, TextField, OpenableImage } from 'modules/ui'
import { QuoteRequestStatus } from 'modules/api'
import { useT } from 'modules/i18n'
import {
  CustomNeedList,
  formatPlannedDate,
  formatPlannedLocation,
  QuoteRequestStatusChip,
  useQuote,
} from 'modules/quote'
import config from 'modules/config'

const imagesPath = config.app.IMAGES_URL

const attachmentImageSize = 120

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    padding: spacing(2),
    width: '100%',
  },
  backButton: {
    backgroundColor: 'white',
    borderRadius: spacing(4),
    border: '1px solid lightGrey',
    padding: spacing(1.5),
    paddingRight: spacing(3),
    paddingLeft: spacing(3),
    marginRight: spacing(3),
    alignContent: 'center',
    justifyContent: 'center',
    marginLeft: spacing(3),
    marginBottom: spacing(2),
  },
  arrowBack: {
    marginRight: spacing(1),
    color: palette.primary.main,
  },
  needTitle: {
    fontSize: '1.5rem',
  },
  needIcon: {
    color: 'grey',
    margin: spacing(2),
  },
  btn: {
    'minWidth': 261,
    'borderRadius': 4,
    'fontSize': 16,
    'color': 'white',
    'background': palette.primary.main,
    'textTransform': 'none',
    'marginLeft': 'auto',
    'marginRight': 'auto',
    'marginTop': spacing(4),
    '&:hover': {
      background: palette.primary.main,
    },
  },
  defaultParamContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    columnGap: spacing(2),
    marginTop: spacing(1),
    marginBottom: spacing(2),
  },
  attachmentImages: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: spacing(3),
    margin: `${spacing(2)}px 0`,
  },
  attachmentImage: {
    width: attachmentImageSize,
    height: attachmentImageSize,
    objectFit: 'cover',
    borderRadius: spacing(3),
  },
}))

const ShowButton = ({ status, onClickAccept, onClickReject }) => {
  const classes = useStyles()
  let btnProps

  switch (status) {
    case QuoteRequestStatus.Pending:
    default:
      btnProps = null
      break
    case QuoteRequestStatus.Accepted:
      btnProps = {
        children: 'Árajánlat megtekintése',
        onClick: onClickAccept,
      }
      break
    case QuoteRequestStatus.Rejected:
      btnProps = {
        children: 'Elutasítás megtekintése',
        onClick: onClickReject,
      }
  }
  return btnProps ? <Button variant="contained" className={classes.btn} {...btnProps} /> : <></>
}

const DefaultParam = ({ Icon, label, value }) => {
  const classes = useStyles()

  return (
    <Box display="flex" alignItems={'center'}>
      <Icon fontSize="large" className={classes.needIcon} />
      <Box>
        <Typography color="textSecondary">{label}</Typography>
        <Typography>{value}</Typography>
      </Box>
    </Box>
  )
}

const AdminView = ({ match }) => {
  const { quoteRequest } = useQuote()
  const classes = useStyles()
  const t = useT()
  const history = useHistory()
  const { id } = useParams()

  if (!quoteRequest || quoteRequest.id !== match.params.id) {
    return null
  }

  const { weddingCopy } = quoteRequest

  const header = (
    <Box>
      <Typography variant="h5">
        {`${quoteRequest.couple.myName} & ${quoteRequest.couple.partnersName} `}
        <Typography className={classes.needTitle} display="inline" color="textSecondary">
          {`${t('asked him for a quote')}: ${quoteRequest.partner.name}`}
        </Typography>
      </Typography>
      <Typography display="inline" color="textSecondary">
        Receive time:
      </Typography>
      <UITypography display="inline" color="textSecondary">
        {moment(quoteRequest.createdAt).format('YYYY.MM.DD HH:mm')}
      </UITypography>
    </Box>
  )

  const defaultParams = (
    <Box mt={3}>
      <Typography variant="h5">Default params</Typography>
      <Typography color="textSecondary">
        You can see the default informations about the wedding here
      </Typography>
      <Box className={classes.defaultParamContainer}>
        <DefaultParam
          Icon={EventNoteOutlined}
          label={weddingCopy.date ? 'Date' : 'Planned date (Suitable months and days)'}
          value={
            weddingCopy.date
              ? moment(weddingCopy.date).format('YYYY-MM-DD')
              : formatPlannedDate(weddingCopy, t)
          }
        />
        <DefaultParam
          Icon={LocationOnOutlined}
          label={weddingCopy.address ? 'Location' : 'Planned location(s) and favorite features'}
          value={weddingCopy.address?.name || formatPlannedLocation(weddingCopy)}
        />
        <DefaultParam
          Icon={PeopleOutlined}
          label="Guests"
          value={`${weddingCopy.guestRange.low}－${weddingCopy.guestRange.high}`}
        />
      </Box>
    </Box>
  )

  const customParams = (
    <Box mt={3}>
      <Typography variant="h5">Custom params</Typography>
      <Typography color="textSecondary">
        You can see the couples special needs about the service here
      </Typography>
      {quoteRequest.userNeedCopy?.questionResponses && (
        <CustomNeedList questionResponses={quoteRequest.userNeedCopy.questionResponses} />
      )}
    </Box>
  )

  const textualAddition = (
    <Box mt={3}>
      <Typography variant="h5">Textual addition</Typography>
      <Typography color="textSecondary">The description which is given by couple</Typography>
      <Box mt={2} mb={2}>
        <TextField
          helperText=""
          fullWidth
          disabled
          placeholder=""
          label=""
          value={quoteRequest.text}
          variant="outlined"
          multiline
        />
      </Box>
    </Box>
  )

  return (
    <Container maxWidth="lg" className={classes.root}>
      <Button className={classes.backButton} onClick={history.goBack}>
        <ArrowBack className={classes.arrowBack} /> {t('Back')}
      </Button>
      <Page>
        {header}
        <Box display="flex" alignItems={'center'} mt={2}>
          <QuoteRequestStatusChip status={quoteRequest.status} />
        </Box>
        {defaultParams}
        {customParams}
        {!!quoteRequest.text && <>{textualAddition}</>}
        {quoteRequest?.attachments?.length > 0 && (
          <Box mb={1} display="flex" flexDirection="column">
            <Typography variant="h5">Images attached by the couple</Typography>
            <Typography color="textSecondary">
              Here you can find the images attached by the couple
            </Typography>
            <Box className={classes.attachmentImages}>
              {quoteRequest.attachments.map(a => (
                <OpenableImage
                  key={a}
                  className={classes.attachmentImage}
                  queryParams={`height=${attachmentImageSize}`}
                  src={`${imagesPath}/${a}`}
                />
              ))}
            </Box>
          </Box>
        )}
        <ShowButton
          status={quoteRequest.status}
          onClickAccept={() => history.push(`/quotes/${id}/view-response`)}
          onClickReject={() => history.push(`/quotes/${id}/view-rejection`)}
        />
      </Page>
    </Container>
  )
}

export default AdminView
