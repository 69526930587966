import { useRouteMatch } from 'react-router'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import VisibilityIcon from '@material-ui/icons/Visibility'
import { Box } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { getPaginationParams } from 'modules/app'
import { NavButton, Page, useTable } from 'modules/ui'
import { NeedsList, useNeed } from 'modules/need'

export const List = () => {
  const { needs, loading, total } = useNeed()
  const { handleChangePage, handleChangeRowsPerPage, handleSearch, handleSort } = useTable()
  const match = useRouteMatch()
  const { pageSize, page, filter, sortBy, sortDirection } = getPaginationParams()

  return (
    <Page fullHeight title="Needs list">
      <NeedsList
        needs={needs}
        loading={loading}
        rowActionsComponent={RowActions}
        pageSize={pageSize}
        total={total}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        page={page}
        toggleSort={handleSort}
        sortDirection={sortDirection}
        sortBy={sortBy}
        filter={filter}
        handleSearch={handleSearch}
      />
      <Box display="flex" justifyContent="flex-end" p={1}>
        <NavButton to={`${match.url}/new`} tooltip="New">
          <AddIcon />
        </NavButton>
      </Box>
    </Page>
  )
}

const RowActions = row => {
  const match = useRouteMatch()
  const { id } = row

  return (
    <Grid container wrap="nowrap">
      <NavButton to={`${match.url}/${id}/edit`} tooltip="Edit">
        <EditIcon fontSize="small" />
      </NavButton>
      <NavButton to={`${match.url}/${id}/weddings`} tooltip="View Weddings">
        <VisibilityIcon fontSize="small" />
      </NavButton>
      <NavButton to={`${match.url}/${id}/remove`} tooltip="Delete">
        <DeleteIcon fontSize="small" />
      </NavButton>
    </Grid>
  )
}
