import { makeStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import clsx from 'clsx'
import { useField } from 'formik'
import { ImageUploader, FormHelperText } from 'modules/ui'

const useStyles = makeStyles(() => ({
  root: {},
}))

export default ({
  id,
  name,
  value,
  helperText,
  fullWidth,
  margin,
  singleImage,
  className,
  onChange,
  onBlur,
  ...props
}) => {
  const classes = useStyles()
  const [field, meta, helpers] = useField({
    name,
    value,
    onChange,
    onBlur,
  })

  const handleChange = files => {
    helpers.setValue(singleImage ? files?.[0] : files)
  }

  return (
    <FormControl className={clsx(classes.root, className)} fullWidth={fullWidth} margin={margin}>
      <ImageUploader {...field} {...props} singleImage={singleImage} onChange={handleChange} />
      {meta.error && <FormHelperText error>{meta.error}</FormHelperText>}
    </FormControl>
  )
}
