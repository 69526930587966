import { takeLatestAsync } from 'saga-toolkit'
import { put, select } from 'redux-saga/effects'
import { query, mutation, requestApi, PartnerFilter } from 'modules/api'
import { selectPartner } from './selectors'
import * as actions from './slice'
import * as operations from './operations'
import { createUpdateUserInput, partnerProfileFields } from '../profile/fields'

const partnerFields = [
  'id',
  'name',
  'email',
  'phone',
  'website',
  'description',
  { needs: ['id', 'name'] },
  { coverImages: ['name'] },
  { category: ['id', 'name'] },
  { tags: ['id', 'name'] },
  {
    fixLocation: [
      'address',
      'city',
      'zip',
      'street',
      'streetNumber',
      { coordinate: ['lat', 'lng'] },
    ],
  },
  { areaLocation: ['address', 'radius', 'city', 'availableNationwide'] },
  { guestRange: ['low', 'high'] },
  { profileImage: ['name'] },
  'registered',
  'contactName',
  'contactEmail',
  'contactPhone',
  'verified',
  'class',
  'standingCapacity',
  'seatingCapacity',
  'availableWeekdays',
  'status',
]

const partnerFieldsWithWallet = [
  ...partnerFields,
  {
    wallet: ['id', 'balance', { transactions: ['id', 'amount', 'note', 'createdAt'] }],
  },
]

const parsePartner = partner => ({
  ...partner,
  profileImage: partner.profileImage?.name,
})

function* fetchPartners({ meta }) {
  const { offset, limit, filter, sortBy, sortDirection, status } = meta.arg

  const options = {
    operation: 'partners',
    fields: [
      'total',
      {
        elements: [
          'id',
          'name',
          { profileImage: ['name'] },
          { category: ['name', 'id'] },
          { tags: ['name', 'id'] },
          { items: ['name', 'id'] },
          'phone',
          'email',
          'registered',
          'verified',
          'website',
          'contactPhone',
          { quotes: ['status'] },
          { wallet: ['balance'] },
          'updatedAt',
        ],
      },
    ],
    variables: {
      input: {
        value: {
          limit,
          offset,
          filter,
          sortBy,
          sortDirection,
          status,
        },
        type: 'PartnerFilter!',
      },
    },
  }

  const { partners } = yield query(options, true)

  return partners
}

function* fetchPartner({ meta }) {
  const { id } = meta.arg
  const options = {
    operation: 'partner',
    fields: partnerFieldsWithWallet,
    variables: {
      id: {
        type: 'ID',
        value: id,
        required: true,
      },
    },
  }

  const { partner } = yield query(options, true)

  return parsePartner(partner)
}

function* searchPartner({ meta }) {
  const { filter, category, limit = 20, offset = 0 }: PartnerFilter = meta.arg
  const { partners } = yield requestApi(operations.searchPartnerQuery, {
    input: {
      filter,
      category,
      limit,
      offset,
    },
  })

  return partners.elements
}

function* createPartner({ meta }) {
  const input = createUpdateUserInput(meta.arg)
  const options = {
    operation: 'insertUser',
    fields: [{ '...on Partner': ['id'] }],
    variables: {
      input: {
        value: input,
        type: 'NewUserInput!',
      },
    },
  }

  const { newPartner } = yield mutation(options, true)

  return newPartner
}

function* updatePartner({ meta }) {
  const input = createUpdateUserInput(meta.arg)

  const options = {
    operation: 'updateUser',
    fields: [{ '...on Partner': partnerProfileFields }],
    variables: {
      input: {
        value: input,
        type: 'UpdateUserInput!',
      },
    },
  }

  const { updateUser } = yield mutation(options, true)

  return parsePartner(updateUser)
}

function* activatePartner({ meta }) {
  const { id } = meta.arg

  const options = {
    operation: 'activatePartner',
    fields: partnerFieldsWithWallet,
    variables: {
      id: {
        value: id,
        type: 'ID!',
      },
    },
  }

  const { activatePartner } = yield mutation(options, true)

  return parsePartner(activatePartner)
}

function* removePartner({ meta }) {
  const { id } = meta.arg
  const options = {
    operation: 'deleteUser',
    variables: {
      id: {
        value: id,
        type: 'ID!',
      },
    },
  }

  const result = yield mutation(options, true)

  return result
}

function* invitePartner({ meta }) {
  const { id } = meta.arg
  const options = {
    operation: 'invitePartner',
    variables: {
      id: {
        value: id,
        type: 'ID',
        required: true,
      },
    },
  }

  const { invitePartner } = yield mutation(options, true)

  return invitePartner
}

function* addTokenToPartner({ meta }) {
  const transactionInput = meta.arg

  const options = {
    operation: 'addTokenToPartner',
    fields: ['id'],
    variables: {
      input: {
        value: transactionInput,
        type: 'AdminTopUpInput!',
      },
    },
  }

  yield mutation(options, true)
  const partner = yield select(selectPartner)
  yield put(actions.fetchPartner({ id: partner.id }))
}

export default [
  takeLatestAsync(actions.fetchPartners.type, fetchPartners),
  takeLatestAsync(actions.fetchPartner.type, fetchPartner),
  takeLatestAsync(actions.searchPartner.type, searchPartner),
  takeLatestAsync(actions.createPartner.type, createPartner),
  takeLatestAsync(actions.updatePartner.type, updatePartner),
  takeLatestAsync(actions.activatePartner.type, activatePartner),
  takeLatestAsync(actions.removePartner.type, removePartner),
  takeLatestAsync(actions.invitePartner.type, invitePartner),
  takeLatestAsync(actions.addTokenToPartner.type, addTokenToPartner),
]
