import { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { unwrapResult } from '@reduxjs/toolkit'
import { RegisterForm, useSignUp, SingUpContext } from 'modules/auth'

const Registration = () => {
  const { signUp, error } = useSignUp()
  const history = useHistory()
  const { state, setState } = useContext(SingUpContext)

  const submitSignUp = async values => {
    const { termsAndConditions, gdpr, ...rest } = values
    try {
      unwrapResult(
        await signUp({
          ...state,
          ...rest,
          tags: state.tags?.map(({ id }) => id),
        }),
      )
      setState({
        ...state,
        ...rest,
      })
      history.push('/sign-up/verification')
    } catch (e) {
      console.error(e)
    }
  }

  const goBack = () => {
    history.push('/sign-up/contact')
  }

  return (
    <RegisterForm
      submitSignUp={submitSignUp}
      error={error}
      email={state.contactEmail}
      goBack={goBack}
    />
  )
}

export default Registration
