import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'
import { createSagaAction } from 'saga-toolkit'

const name = 'tagGroup'

export const tagGroupsAdapter = createEntityAdapter()

const initialState = {
  loading: false,
  error: null,
  tagGroups: tagGroupsAdapter.getInitialState(),
  tagGroup: null,
}

export const fetchTagGroups = createSagaAction(`${name}/fetchTagGroups`)
export const fetchTagGroup = createSagaAction(`${name}/fetchTagGroup`)
export const searchTagGroup = createSagaAction(`${name}/searchTagGroup`)
export const createTagGroup = createSagaAction(`${name}/createTagGroup`)
export const updateTagGroup = createSagaAction(`${name}/updateTagGroup`)
export const removeTagGroup = createSagaAction(`${name}/removeTagGroup`)

const handlePending = state => ({
  ...state,
  loading: true,
})

const handleRejected = (state, { error }) => ({
  ...state,
  error,
  loading: false,
})

const slice = createSlice({
  name,
  initialState,
  reducers: {
    clearTagGroup: state => ({
      ...state,
      tagGroup: null,
    }),
  },
  extraReducers: {
    // TagGroups
    [fetchTagGroups.pending]: handlePending,
    [fetchTagGroups.fulfilled]: (state, { payload }) => {
      tagGroupsAdapter.setAll(state.tagGroups, payload)
      state.loading = false
    },
    [fetchTagGroups.rejected]: handleRejected,

    //TagGroup
    [fetchTagGroup.pending]: handlePending,
    [fetchTagGroup.fulfilled]: (state, { payload }) => ({
      ...state,
      tagGroup: payload,
      loading: false,
    }),
    [fetchTagGroup.rejected]: handleRejected,

    // createTagGroup
    [createTagGroup.pending]: handlePending,
    [createTagGroup.fulfilled]: state => ({
      ...state,
      loading: false,
    }),
    [createTagGroup.rejected]: handleRejected,

    // updateTagGroup
    [updateTagGroup.pending]: handlePending,
    [updateTagGroup.fulfilled]: (state, { payload }) => {
      tagGroupsAdapter.updateOne(state.tagGroups, { id: payload.id, changes: payload })
      state.tagGroup = { ...state.tagGroup, ...payload }
      state.loading = false
    },
    [updateTagGroup.rejected]: handleRejected,
  },
})

export const { clearTagGroup } = slice.actions

export default slice.reducer
