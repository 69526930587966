import { Box } from '@material-ui/core'
import { Countdown } from 'modules/ui'

const WeddingCountdown = ({ weddingDate }) => {
  return (
    <Box>
      {weddingDate && <Countdown untilDate={weddingDate} precision={['D', 'H', 'M', 'S']} />}
    </Box>
  )
}

export default WeddingCountdown
