import { combineReducers } from 'redux'
import forgotPassword from './forgotPassword'
import signIn from './signIn'
import signUp from './signUp'

export * from './forgotPassword'
export * from './signIn'
export * from './signUp'

export default combineReducers({
  forgotPassword,
  signIn,
  signUp,
})
