import { useRouteMatch } from 'react-router'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import InsertLinkIcon from '@material-ui/icons/InsertLink'
import DeleteIcon from '@material-ui/icons/Delete'
import { Box } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { Status } from 'modules/api'
import { getPaginationParams, useQueryState } from 'modules/app'
import { NavButton, Page, useTable, Picker } from 'modules/ui'
import { PartnerList, usePartner } from 'modules/partner'

const statusPickerOptions = [
  { name: 'All', value: 'all' },
  { name: 'Pending', value: Status.Pending },
  { name: 'Active', value: Status.Active },
  { name: 'Rejected', value: Status.Rejected },
]

const RowActions = row => {
  const match = useRouteMatch()
  const { id, registered } = row

  return (
    <Grid container wrap="nowrap">
      {!registered && (
        <NavButton to={`${match.url}/${id}/invite`} tooltip="Invite">
          <InsertLinkIcon fontSize="small" />
        </NavButton>
      )}
      <NavButton to={`${match.url}/${id}/edit`} tooltip="Edit">
        <EditIcon fontSize="small" />
      </NavButton>
      <NavButton to={`${match.url}/${id}/remove`} tooltip="Delete">
        <DeleteIcon fontSize="small" />
      </NavButton>
    </Grid>
  )
}

const List = () => {
  const match = useRouteMatch()
  const { partners, total } = usePartner()
  const { handleChangePage, handleChangeRowsPerPage, handleSearch, handleSort } = useTable()
  const { pageSize, page, filter, sortBy, sortDirection } = getPaginationParams()
  const { handleChange, value } = useQueryState({
    defaultValue: 'all',
    queryParam: 'status',
  })

  return (
    <Page fullHeight>
      <Grid container spacing={2}>
        <Grid item xs={10} md={6} lg={4}>
          <Picker options={statusPickerOptions} onChange={handleChange} value={value} fullWidth />
        </Grid>
      </Grid>

      <PartnerList
        data={partners}
        rowActionsComponent={RowActions}
        pageSize={pageSize}
        totalElements={total}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        page={page}
        toggleSort={handleSort}
        sortDirection={sortDirection}
        sortBy={sortBy}
        filter={filter}
        title="Partner list"
        handleSearch={handleSearch}
      />
      <Box display="flex" justifyContent="flex-end" p={1}>
        <NavButton to={`${match.url}/new`} tooltip="New">
          <AddIcon />
        </NavButton>
      </Box>
    </Page>
  )
}

export default List
