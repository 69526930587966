import { createSlice } from '@reduxjs/toolkit'
import { createSagaAction } from 'saga-toolkit'

const name = 'auth/signUp'

const initialState = {
  step: 0,
  loading: false,
  user: null,
  error: null,
  currentEmail: '',
}

export const forgotPassword = createSagaAction(`${name}/forgotPassword`)
export const confirmForgotPassword = createSagaAction(`${name}/confirmForgotPassword`)

const slice = createSlice({
  name: 'auth/forgotPassword',
  initialState,
  reducers: {
    skipToVerification: state => ({ ...state, step: 1 }),
  },
  extraReducers: {
    [forgotPassword.pending]: (state, { meta: { arg } }) => ({
      ...state,
      loading: true,
      currentEmail: arg.email,
    }),
    [forgotPassword.fulfilled]: (state, { payload, error }) => ({
      ...state,
      step: 1,
      user: payload,
      loading: false,
    }),
    [forgotPassword.rejected]: (state, { error }) => ({
      ...state,
      error,
      loading: false,
    }),
    [confirmForgotPassword.pending]: state => ({
      ...state,
      loading: true,
      error: null,
    }),

    [confirmForgotPassword.fulfilled]: (state, { payload }) => ({
      ...state,
      user: payload,
      step: 2,
      loading: false,
    }),
    [confirmForgotPassword.rejected]: (state, { error }) => ({
      ...state,
      error,
      loading: false,
    }),
  },
})

export const { skipToVerification } = slice.actions
export default slice.reducer
