import { useField } from 'formik'
import { Checkbox as BaseCheckbox, CheckboxProps as BaseCheckboxProps } from 'modules/ui'

interface CheckboxProps extends BaseCheckboxProps {}

const Checkbox = ({ name, value, checked, onChange, onBlur, ...props }: CheckboxProps) => {
  const [field, meta, helpers] = useField({
    name,
    value,
    checked,
    onChange: event => {
      onChange?.(event, !field.value)
    },
    onBlur,
  })

  const handleChange = (e, value) => {
    helpers.setValue(value)
  }

  return (
    <BaseCheckbox
      {...props}
      {...field}
      checked={field.value}
      error={!!meta.error}
      helperText={meta.error}
      onChange={handleChange}
    />
  )
}

export default Checkbox
