import { Route, useHistory, useRouteMatch } from 'react-router-dom'
import { Box, makeStyles, Tab, Tabs } from '@material-ui/core'
import { QuoteRequest } from 'modules/api'
import { QuotesCardList, SentQuoteRequests, useQuote } from 'modules/quote'
import { useT } from 'modules/i18n'
import { getPaginationParams } from 'modules/app'
import { useTable } from 'modules/ui'

export const routes = ['/quotes', '/quotes/sent']

const useStyles = makeStyles(({ spacing }) => ({
  tabs: {
    'borderRadius': spacing(3),
    'backgroundColor': 'white',
    'paddingRight': spacing(3),
    'paddingLeft': spacing(3),
    '& .MuiTabs-flexContainer': {
      justifyContent: 'center',
    },
  },
  tab: {
    height: 72,
    textTransform: 'none',
  },
  partnerRoot: {
    padding: spacing(2),
    paddingTop: spacing(1),
  },
}))

const List = () => {
  const history = useHistory()
  const classes = useStyles()
  const t = useT()
  const match = useRouteMatch()

  // todo add types for hooks like useQuote
  const { quoteRequests, total, loading } = useQuote()
  const { pageSize, page } = getPaginationParams({ pageSize: 12 })
  const { handleChangePage } = useTable()

  const handleChangeTab = (tab: number) => {
    history.push(routes[tab])
  }

  const handleQuoteRequestClick = ({ id }: QuoteRequest) => {
    history.push(`/quotes/${id}/view`)
  }

  const handleViewClick = (quoteRequestId: string) => {
    history.push(`/quotes/${quoteRequestId}/sent`)
  }

  const handleConversationClick = conversationId => {
    history.push(`/chat/conversations/${conversationId}`)
  }

  return (
    <Box className={classes.partnerRoot}>
      <Box className={classes.tabs}>
        <Tabs
          value={routes.indexOf(match.url)}
          textColor="primary"
          indicatorColor="primary"
          onChange={(_, newValue) => handleChangeTab(newValue)}
          aria-label="Quotes navigation">
          <Tab className={classes.tab} label={t('Direct quote requests')} />
          <Tab className={classes.tab} label={t('Sent quotes')} />
        </Tabs>
      </Box>
      <Route
        path={routes[0]}
        exact
        component={() => (
          <QuotesCardList
            helpText="Here are the quote requests that were organically requested directly from you through your profile! You can send quotes for these without using Token!"
            quoteRequests={quoteRequests || []}
            loading={loading}
            numberOfPages={Math.ceil(total / pageSize)}
            page={page}
            onChangePage={handleChangePage}
            onQuoteRequestClick={handleQuoteRequestClick}
          />
        )}
      />
      <Route
        path={routes[1]}
        exact
        component={() => (
          <SentQuoteRequests
            quoteRequests={quoteRequests || []}
            loading={loading}
            numberOfPages={Math.ceil(total / pageSize)}
            page={page}
            onViewClick={handleViewClick}
            onConversationClick={handleConversationClick}
            onChangePage={handleChangePage}
          />
        )}
      />
    </Box>
  )
}

export default List
