import { Route, useRouteMatch } from 'react-router-dom'
import Send from './Send'
import List from './List'
import View from './View'

export default () => {
  const match = useRouteMatch()

  return (
    <>
      <Route path={match.url} exact component={List} />
      <Route path={`${match.url}/send`} exact component={Send} />
      <Route path={`${match.url}/:id/view`} exact component={View} />
    </>
  )
}
