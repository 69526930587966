import { useLocation, useHistory } from 'react-router-dom'

export const useRouteQueryParams = () => {
  const history = useHistory()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)

  // todo fix types and bad logic here
  const setQueryParam = (paramName, value?: string) => {
    if (typeof paramName === 'string') {
      setParamValue(paramName, value)
    } else {
      Object.entries(paramName).forEach(([key, value]) => {
        setParamValue(key, value)
      })
    }

    history.replace(`${location.pathname}?${queryParams.toString()}`)

    function setParamValue(name, value) {
      if (!value) {
        queryParams.delete(name)
      } else {
        queryParams.set(name, value)
      }
    }
  }

  return [setQueryParam]
}
