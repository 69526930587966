import { call } from 'redux-saga/effects'
import Auth from '@aws-amplify/auth'
import { takeEveryAsync, putAsync } from 'saga-toolkit'
import { query, mutation } from 'modules/api'
import { createUpdateUserInput } from 'modules/profile'
import * as signInSlice from './slice/signIn'
import * as forgotPasswordSlice from './slice/forgotPassword'
import * as signUpSlice from './slice/signUp'
import * as actions from './slice'

const capitalize = s => s.replace(/\b\w/g, l => l.toUpperCase())

const selectData = ({ idToken }) => ({
  payload: idToken.payload,
  jwtToken: idToken.jwtToken,
})

function* signIn({ meta }) {
  const { email, password } = meta.arg
  yield call(() => Auth.signIn(email, password))

  const session = yield call(() => Auth.currentSession())

  try {
    const accountOpened = yield putAsync(actions.checkAccountOpened())

    if (!accountOpened) {
      const { attributes } = yield call(Auth.currentAuthenticatedUser.bind(Auth), {
        bypassCache: true,
      })
      const { userId, ...profile } = JSON.parse(attributes.profile || '{}')

      if (userId) {
        yield putAsync(actions.linkAccount({ userId }))
      } else {
        yield putAsync(actions.openAccount({ attributes, profile }))
      }
    }
  } catch (error) {
    yield call(() => Auth.signOut())
    throw Error('Failed to open account: ' + error.message)
  }

  return selectData(session)
}

function* signOut() {
  yield call(() => Auth.signOut())
}

function* continueSession() {
  try {
    const session = yield call(() => Auth.currentSession())
    return selectData(session)
  } catch (message) {
    const error = Error(message)
    error.name = capitalize(message).replaceAll(' ', '')

    throw error
  }
}

function* signUp({ meta }) {
  const { email, password, passwordConfirm, code, ...profile } = meta.arg // code needs to be taken out from profile

  if (password !== passwordConfirm) {
    throw Error('Passwords dont match')
  }

  yield call(() =>
    Auth.signUp({
      username: email,
      password,
      attributes: {
        email,
        profile: JSON.stringify(profile),
      },
    }),
  )
}

function* confirmSignUp({ meta }) {
  const { email, code } = meta.arg

  yield call(() => Auth.confirmSignUp(email, code))
}

function* checkAccountOpened() {
  const options = {
    operation: 'accountOpened',
  }
  const { accountOpened } = yield query(options, true)

  return accountOpened
}

function* openAccount({ meta }) {
  const {
    profile: { type = 'PARTNER', ...profile },
    attributes,
  } = meta.arg

  if (!['ADMIN', 'PARTNER'].includes(type)) {
    throw new Error('Incorrect user type')
  }

  const role = type === 'ADMIN' ? 'ADMIN' : 'PARTNER'
  const input = createUpdateUserInput({
    ...profile,
    role,
    type,
    email: attributes.email,
    // TODO use custom DTO (OpenAccountInput) to avoid sending unnecessary fields:
    class: 'C',
    verified: false,
  })
  const options = {
    operation: 'openAccount',
    variables: {
      input: {
        value: input,
        type: 'NewUserInput!',
      },
    },
  }

  return yield mutation(options, true)
}

function* linkAccount({ meta }) {
  const { userId } = meta.arg
  const options = {
    operation: 'linkAccount',
    variables: {
      id: {
        value: userId,
        type: 'ID',
        required: true,
      },
    },
  }

  return yield mutation(options, true)
}

function* forgotPassword({ meta }) {
  const { email } = meta.arg

  return yield call(() => Auth.forgotPassword(email))
}

function* confirmForgotPassword({ meta }) {
  const { email, password, code } = meta.arg

  yield call(() => Auth.forgotPasswordSubmit(email, code, password))
}

function* createQATestingUser({ meta }) {
  const { email, password } = meta.arg

  const options = {
    operation: 'createQATestingUser',
    variables: {
      input: {
        type: 'QATestingUserInput',
        required: true,
        value: {
          email: email.toLowerCase(),
          password,
        },
      },
    },
  }

  return yield mutation(options)
}

function* linkQATestingUser({ meta }) {
  const { email } = meta.arg
  const options = {
    operation: 'linkQATestingUser',
    variables: {
      input: {
        type: 'QATestingUserInput',
        required: true,
        value: {
          email: email.toLowerCase(),
        },
      },
    },
  }

  return yield mutation(options)
}

function* qaTestingProcess({ meta }) {
  const { email, password } = meta.arg
  yield putAsync(actions.createQATestingUser({ email, password }))
  yield call(() => Auth.signIn(email, password))
  yield putAsync(actions.linkQATestingUser({ email }))
}

export default [
  takeEveryAsync(signInSlice.signIn.type, signIn),
  takeEveryAsync(signInSlice.signOut.type, signOut),
  takeEveryAsync(signInSlice.continueSession.type, continueSession),
  takeEveryAsync(signUpSlice.signUp.type, signUp),
  takeEveryAsync(signUpSlice.confirmSignUp.type, confirmSignUp),
  takeEveryAsync(signUpSlice.checkAccountOpened.type, checkAccountOpened),
  takeEveryAsync(signUpSlice.openAccount.type, openAccount),
  takeEveryAsync(signUpSlice.linkAccount.type, linkAccount),
  takeEveryAsync(forgotPasswordSlice.forgotPassword.type, forgotPassword),
  takeEveryAsync(forgotPasswordSlice.confirmForgotPassword.type, confirmForgotPassword),
  takeEveryAsync(signUpSlice.createQATestingUser.type, createQATestingUser),
  takeEveryAsync(signUpSlice.linkQATestingUser.type, linkQATestingUser),
  takeEveryAsync(signUpSlice.qaTestingProcess.type, qaTestingProcess),
]
