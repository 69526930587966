import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { Typography, Button, HelpField } from 'modules/ui'
import InspirationItemCard from './InspirationItemCard'

const useStyles = makeStyles(theme => ({
  header: {
    marginBottom: theme.spacing(2),
  },
  btn: {
    'borderRadius': 4,
    'fontSize': 16,
    'color': 'white',
    'background': theme.palette.primary.main,
    'textTransform': 'none',
    '&:hover': {
      background: theme.palette.primary.main,
    },
  },
  inspirationContainer: {
    paddingBottom: '40px',
  },
}))

const InspirationItemList = ({ handleAddNewItem, items, handleClickItem, loading }) => {
  const classes = useStyles()

  return (
    <>
      <HelpField text="Here you can edit your inspiration scroll images!" />
      {/*TODO translation?*/}
      <Grid container spacing={2} className={classes.header} direction="row">
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddNewItem}
            className={classes.btn}>
            <Typography>Add new item</Typography>
          </Button>
        </Grid>
        <Grid item>
          <a
            href="https://www.weddingmanager.app/inspirationscroll"
            target="_blank"
            rel="noreferrer noopener"
            style={{ textDecoration: 'none' }}>
            <Button variant="contained" color="primary" className={classes.btn}>
              <Typography>Rules of uploading</Typography>
            </Button>
          </a>
        </Grid>
      </Grid>
      <Grid container spacing={2} direction="column" className={classes.inspirationContainer}>
        {items.map((item, i) => (
          <InspirationItemCard
            key={i}
            {...item}
            item={item}
            onClick={handleClickItem}
            loading={loading}
          />
        ))}
      </Grid>
    </>
  )
}

export default InspirationItemList
