import moment from 'moment'
import { NavLink } from 'react-router-dom'
import { FormLabel, Page, Typography } from 'modules/ui'
import { Box, Grid } from '@material-ui/core'
import { AdminNoteForm, useCouple } from 'modules/couple'
import { Couple, UserType } from 'modules/api'
import { useUser } from 'modules/user'
import List from '../UserNeeds/List'

const Field = ({ label, value }) => (
  <Box mb={1}>
    <FormLabel>{label}</FormLabel>
    <Typography>{value}</Typography>
  </Box>
)

interface CoupleDetailsProps extends Couple {}

const CoupleDetails: React.FC<CoupleDetailsProps> = ({
  id,
  name,
  email,
  createdAt,
  lastSeenAt,
  wedding,
}) => (
  <Box marginBottom={2}>
    <Typography variant="h6" gutterBottom>
      Details
    </Typography>
    <Field label="ID" value={id} />
    <Field label="Name" value={name} />
    <Field label="Email" value={email} />
    <Field label="Registered at" value={moment(createdAt).format('YYYY-MM-DD HH:mm')} />
    <Field label="Last seen at" value={moment(lastSeenAt).format('YYYY-MM-DD HH:mm')} />
    <Field
      label="Wedding date"
      value={wedding.date ? moment(wedding.date).format('YYYY-MM-DD') : '-'}
    />
    <Field label="Wedding possible year" value={wedding.possibleYear || '-'} />
    <Field label="Wedding possible months" value={wedding.possibleMonths?.join(', ') || '-'} />
    <Field label="Wedding possible days" value={wedding.possibleDays?.join(', ') || '-'} />
    <Field label="Wedding address" value={wedding.address?.name || '-'} />
    <Field
      label="Wedding possible addresses"
      value={wedding.possibleAddresses.map(address => address.name).join(', ') || '-'}
    />
  </Box>
)

const LikedItems = ({ likedItems }) => (
  <Box marginBottom={2}>
    <Typography variant="h6" gutterBottom>
      Liked items
    </Typography>
    {likedItems.length
      ? likedItems.map(item => (
          <Box key={item.id}>
            <NavLink to={`/items/${item.id}/edit`}>{item.name}</NavLink>
          </Box>
        ))
      : '-'}
  </Box>
)

const LikedPartners = ({ likedPartners }) => (
  <Box marginBottom={2}>
    <Typography variant="h6" gutterBottom>
      Liked partners
    </Typography>
    {likedPartners.length
      ? likedPartners.map(partner => (
          <Box key={partner.id}>
            <NavLink to={`/partners/${partner.id}/edit`}>{partner.name}</NavLink>
          </Box>
        ))
      : '-'}
  </Box>
)

const View = ({ match }) => {
  const { couple }: { couple: Couple; loading: boolean } = useCouple()
  const { updateUser } = useUser()

  const handleSubmit = async data => {
    await updateUser({
      ...data,
      id: couple.id,
      type: UserType.Couple,
    })
  }

  if (!couple || couple.id !== match.params.id) {
    return null
  }

  const likedItems = couple.likedItems.filter(item => item)
  const likedPartners = couple.likedPartners.filter(partner => partner)

  return (
    <>
      <Page title={couple?.name}>
        <Grid container>
          <Grid item xs={12} md={4}>
            <CoupleDetails {...couple} />
          </Grid>
          <Grid item xs={12} md={4}>
            <LikedItems likedItems={likedItems} />
          </Grid>
          <Grid item xs={12} md={4}>
            <LikedPartners likedPartners={likedPartners} />
          </Grid>
        </Grid>
      </Page>
      <List title="UserNeeds" embedded />
      <Page title="Admin note">
        <AdminNoteForm initialValues={{ adminNote: couple.adminNote }} onSubmit={handleSubmit} />
      </Page>
    </>
  )
}

export default View
