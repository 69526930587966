import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { Box, Container, makeStyles } from '@material-ui/core'
import { Pagination } from '@material-ui/lab'
import { HelpField } from 'modules/ui'
import { UserNeed } from 'modules/api'
import { useT } from 'modules/i18n'
import QuotesCardListItem from './QuotesCardListItem'

const useStyles = makeStyles(({ spacing }) => ({
  cardContainer: {
    display: 'grid',
    justifyContent: 'center',
    gridTemplateColumns: 'repeat(auto-fill, 256px)',
    gridGap: spacing(4),
    marginBottom: spacing(7),
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: spacing(3),
    marginBottom: spacing(4),
  },
}))

const getTypeBadgeData = ({ quotesCount }: UserNeed, t: any): { label: string; info: string } => {
  if (!quotesCount) {
    return { label: t('Be the first'), info: t('Be the first to send a quote to the couple!') }
  }
  if (quotesCount < 3) {
    return { label: t('First 3'), info: t('Be in the first three to send a quote to the couple!') }
  }
  if (quotesCount < 5) {
    return { label: t('First 5'), info: t('Be in the first five to send a quote to the couple!') }
  }
  return null
}

export interface NeedsCardListProps {
  origin?: 'quotes' | 'weddings'
  userNeeds: UserNeed[]
  loading: boolean
  numberOfPages: number
  page: number
  onChangePage: (page: number) => void
}

const NeedsCardList = ({
  origin = 'quotes',
  userNeeds,
  loading,
  numberOfPages,
  page,
  onChangePage,
}: NeedsCardListProps) => {
  const classes = useStyles()
  const history = useHistory()
  const t = useT()

  const handleDetailsClick = (userNeedId: string) => {
    history.push(`/${origin}/${userNeedId}/need`)
  }

  const pagination = (
    <Box className={classes.paginationContainer}>
      <Pagination
        color="primary"
        page={page}
        count={numberOfPages}
        onChange={(_e, p) => onChangePage(p)}
      />
    </Box>
  )

  return (
    <Container maxWidth="lg">
      <HelpField text="Here you can find all couples in the system who are looking for your service and are waiting to receive a quote from service providers like you!" />
      {pagination}
      {!loading && (
        <>
          <Box className={classes.cardContainer}>
            {userNeeds.map(item => (
              <QuotesCardListItem
                key={item.id}
                wedding={item.wedding}
                couple={item.wedding.couple}
                need={item.need}
                onDetailsClick={() => handleDetailsClick(item.id)}
                typeBadge={getTypeBadgeData(item, t)}
                style={{ opacity: item.hasSentQuote ? 0.5 : 1 }}
                isNew={item.isNew}
                bottomText={`${t('Létrehozás ideje')}: ${moment(item.approvedAt).format(
                  'YYYY.MM.DD HH:mm',
                )}`}
              />
            ))}
          </Box>
        </>
      )}
    </Container>
  )
}

export default NeedsCardList
