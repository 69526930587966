import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import FormError from './FormError'
import SignUpStepper from './SignUpStepper'
import { Button } from 'modules/ui'

const useStyles = makeStyles(({ spacing }) => ({
  footer: {
    'display': 'flex',
    'justifyContent': '',
    '& button': {
      marginLeft: spacing(1),
    },
  },
}))

const SignUpSuccess = ({ error, onSignInClick, counter }) => {
  const classes = useStyles()

  return (
    <Grid container spacing={2} alignItems="center" justifyContent="center" direction="column">
      <Grid item>{error && <FormError message={error?.message} />}</Grid>
      <Grid item xs={12} sm={6} md={4} className={classes.footer}>
        <Button variant="contained" color="primary" tParams={{ counter }} onClick={onSignInClick}>
          {'Signing in... {{counter}}'}
        </Button>
      </Grid>
    </Grid>
  )
}

export default SignUpSuccess
