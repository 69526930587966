import { useHistory } from 'react-router'
import { useLocation } from 'react-router-dom'
import { makeStyles, Checkbox, Grid, Box } from '@material-ui/core'
import { Picker, Button, NavButton, Page, useTable, Typography } from 'modules/ui'
import { UserNeed, UserNeedStatus } from 'modules/api'
import { UserNeedList, useNeed } from 'modules/need'
import { getPaginationParams, useQueryState } from 'modules/app'
import { useT } from 'modules/i18n'

const useStyles = makeStyles(() => ({
  navButton: {
    fontSize: '0.875rem',
    fontWeight: 500,
    textTransform: 'uppercase',
  },
}))

export const updateStatus = async (
  userNeed: UserNeed,
  updateUserNeed,
  history,
  status: UserNeedStatus,
) => {
  try {
    await updateUserNeed({
      id: userNeed.id,
      input: {
        status,
        need: userNeed.need.id,
        category: userNeed.category,
        // because `QuestionResponseInput` has no `question` field
        questionResponses: userNeed.questionResponses.map(qrs =>
          qrs.map(({ question, ...rest }) => rest),
        ),
      },
    })
    history.push(window.location.pathname + window.location.search)
  } catch (e) {
    console.error('Error during userneed status update')
  }
}

const RowActions = (row: UserNeed) => {
  const history = useHistory()
  const { updateUserNeed } = useNeed()
  const classes = useStyles()
  const t = useT()

  const handleStatusUpdate = (status: UserNeedStatus) => {
    updateStatus(row, updateUserNeed, history, status)
  }

  return (
    <Grid container wrap="nowrap">
      <NavButton className={classes.navButton} to={`/user-needs/${row.id}/view`} tooltip="View">
        {t('View')}
      </NavButton>
      <NavButton
        className={classes.navButton}
        to={`/chat/conversations/new?userId=${row.wedding.couple.id}`}
        tooltip="Chat">
        {t('Chat')}
      </NavButton>
      <NavButton
        className={classes.navButton}
        to={`/couples/${row.wedding.couple.id}/view`}
        tooltip="CIC">
        {t('CIC')}
      </NavButton>
      {(row.status === UserNeedStatus.Pending || row.status === UserNeedStatus.Archived) && (
        <Button onClick={() => handleStatusUpdate(UserNeedStatus.Accepted)}>Approve</Button>
      )}
      {(row.status === UserNeedStatus.Pending || row.status === UserNeedStatus.Accepted) && (
        <Button onClick={() => handleStatusUpdate(UserNeedStatus.Archived)}>Archive</Button>
      )}
    </Grid>
  )
}

const List = ({ title = undefined, embedded = false }) => {
  const t = useT()
  const { userNeeds, userNeedsSummary, loading, total } = useNeed()
  const { pageSize, page, filter, sortBy, sortDirection } = getPaginationParams()
  const { handleChangePage, handleChangeRowsPerPage, handleSearch, handleSort } = useTable()

  const allUserNeedsAmount = userNeedsSummary?.reduce((sum, { amount }) => sum + amount, 0)
  const categoriesPickerOptions = userNeedsSummary?.reduce(
    (pickerOptions, { id, category, amount }) => [
      ...pickerOptions,
      { value: id, name: `${category}  (${amount})` },
    ],
    [{ value: 'all', name: `${t('All')}  (${allUserNeedsAmount})` }],
  )

  const { handleChange: handleChangeCategory, value } = useQueryState({
    defaultValue: categoriesPickerOptions[0]?.value,
    queryParam: 'category',
  })

  const history = useHistory()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const onlyCompleteShown = queryParams.get('complete') === 'true'

  const handleFullChange = () => {
    onlyCompleteShown ? queryParams.delete('complete') : queryParams.set('complete', 'true')
    history.replace(`${location.pathname}?${queryParams.toString()}`)
  }

  return (
    <Page title={title}>
      {!embedded && (
        <Grid container spacing={2}>
          <Grid item xs={10} md={6} lg={4}>
            <Picker
              options={categoriesPickerOptions}
              value={value}
              onChange={handleChangeCategory}
              fullWidth
            />
          </Grid>
          <Grid item xs={10} md={6} lg={4}>
            <Typography variant="button">100% User Needs</Typography>
            <Checkbox checked={onlyCompleteShown} onChange={handleFullChange} size="small" />
          </Grid>
        </Grid>
      )}

      <UserNeedList
        userNeeds={userNeeds}
        loading={loading}
        rowActionsComponent={RowActions}
        pageSize={pageSize}
        total={total}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        page={page}
        toggleSort={handleSort}
        sortDirection={sortDirection}
        sortBy={sortBy}
        filter={filter}
        title=""
        handleSearch={handleSearch}
      />
    </Page>
  )
}

export default List
