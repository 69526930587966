import { Need } from '../api'
import { needsAdapter, weddingsAdapter } from './slice'

// TODO: add explicit types
export const selectRoot = (state: any) => state.need
export const selectNeeds = (state: any) => selectRoot(state).needs
export const selectNeed = (state: any): Need => selectRoot(state).need
export const selectUserNeed = (state: any): Need => selectRoot(state).userNeed
export const selectOffset = (state: any): number => selectRoot(state).offset
export const selectLimit = (state: any): number => selectRoot(state).limit

export const { selectIds, selectEntities, selectAll, selectTotal, selectById } =
  needsAdapter.getSelectors(selectNeeds)

export const weddingByNeed = weddingsAdapter.getSelectors(
  (state: any) => selectRoot(state).weddingsByNeed.weddings,
)
