import { SortDirection } from 'modules/api'
import getQueryParams from './getQueryParams'

export default function getPaginationParams(params?) {
  const queryParams = getQueryParams(params)
  const page = parseInt(queryParams.page || '1', 10)
  const pageSize = parseInt(queryParams.pageSize || '10', 10)
  const sort = queryParams.sort
  const filter = queryParams.filter
  const [sortBy, sortDirection = SortDirection.Asc] = sort ? sort.split(',') : []
  const offset = (page - 1) * pageSize
  const limit = pageSize

  return { pageSize, page, filter, sortBy, sortDirection, offset, limit }
}
