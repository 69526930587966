import { Switch, Route } from 'react-router-dom'
import {
  Admin,
  Blogs,
  Categories,
  Chat,
  Couples,
  ForgotPassword,
  Help,
  Highlights,
  Home,
  InspirationScroll,
  Items,
  Payments,
  Notifications,
  Partners,
  Privacy,
  Profile,
  Quotes,
  SignUp,
  TagGroups,
  Tags,
  TermsAndConditions,
  Users,
  Wallet,
  Welcome,
  SignIn,
  Wedding,
  Needs,
  UserNeeds,
  Weddings,
  TokenHistory,
  Conversations,
  Settings,
} from './pages'
import PurchaseToken from './pages/Wallet/PurchaseToken'
import PurchaseTokenResult from './pages/Wallet/PurchaseTokenResult'
import {
  userIsAdminRedir,
  userIsAuthenticatedRedir,
  userIsNotAuthenticatedRedir,
  userIsPartnerRedir,
  userIsNotAuthenticated,
} from './wrappers'

const WelcomeWithAuth = userIsNotAuthenticatedRedir(Welcome)
// const SignUpWithAuth = userIsNotAuthenticatedRedir(SignUp) // ?
// const ForgotPasswordWithAuth = userIsNotAuthenticatedRedir(ForgotPassword)
const HomeWithAuth = userIsAuthenticatedRedir(Home)
const AdminWithAuth = userIsAdminRedir(Admin)
const SettingsWithAuth = userIsAuthenticatedRedir(Settings)
const CategoriesWithAuth = userIsAdminRedir(Categories)
const ChatWithAuth = userIsAuthenticatedRedir(Chat)
const ItemsWithAuth = userIsAdminRedir(Items)
const PaymentsWithAuth = userIsAdminRedir(Payments)
const NotificationsWithAuth = userIsAdminRedir(Notifications)
const TagsWithAuth = userIsAdminRedir(Tags)
const TagGroupsWithAuth = userIsAdminRedir(TagGroups)
const UsersWithAuth = userIsAdminRedir(Users)
const PartnersWithAuth = userIsAdminRedir(Partners)
const NeedsWithAuth = userIsAdminRedir(Needs)
const UserNeedsWithAuth = userIsAdminRedir(UserNeeds)
const CouplesWithAuth = userIsAdminRedir(Couples)
const BlogsWithAuth = userIsAdminRedir(Blogs)
const HighlightsWithAuth = userIsAdminRedir(Highlights)
const ProfileWithAuth = userIsAuthenticatedRedir(Profile)
const InspirationScrollWithAuth = userIsPartnerRedir(InspirationScroll)
const QuotesWithAuth = userIsAuthenticatedRedir(Quotes)
const WalletWithAuth = userIsPartnerRedir(Wallet)
const SignInWithAuth = userIsNotAuthenticated(SignIn)
const WeddingsWithAuth = userIsAuthenticatedRedir(Weddings)
const TokenHistoryWithAuth = userIsAdminRedir(TokenHistory)
const ConversationsWithAuth = userIsAuthenticatedRedir(Conversations)

const Routes = ({ hideWeddings }) => {
  return (
    <Switch>
      {/* <Redirect path="/" exact to="/highlights" /> */}
      <Route path="/admin" component={AdminWithAuth} />
      <Route path="/settings" component={SettingsWithAuth} />
      <Route path="/welcome" exact component={WelcomeWithAuth} />
      <Route path={['/', '/home']} exact component={HomeWithAuth} />
      <Route path="/highlights" component={HighlightsWithAuth} />
      <Route path="/sign-in" exact component={SignInWithAuth} />
      <Route path="/sign-up" component={SignUp} />
      <Route path="/categories" component={CategoriesWithAuth} />
      <Route path="/chat" component={ChatWithAuth} />
      <Route path="/items" component={ItemsWithAuth} />
      <Route path="/payments" component={PaymentsWithAuth} />
      <Route path="/inspiration" component={InspirationScrollWithAuth} />
      <Route path="/notifications" component={NotificationsWithAuth} />
      <Route path="/tags" component={TagsWithAuth} />
      <Route path="/tag-groups" component={TagGroupsWithAuth} />
      <Route path="/partners" component={PartnersWithAuth} />
      <Route path="/couples" component={CouplesWithAuth} />
      <Route path="/blogs" component={BlogsWithAuth} />
      <Route path="/users" component={UsersWithAuth} />
      <Route path="/help" component={Help} />
      <Route path="/privacy-policy" component={Privacy} />
      <Route path="/forgot-password" component={ForgotPassword} />
      <Route path="/profile" component={ProfileWithAuth} />
      <Route path="/terms-and-conditions" component={TermsAndConditions} />
      <Route path="/quotes" component={QuotesWithAuth} />
      <Route path="/wallet" component={WalletWithAuth} />
      <Route path="/purchase-token" exact component={PurchaseToken} />
      <Route
        path="/purchase-token/result"
        exact
        component={props => <PurchaseTokenResult {...props} hiddenCloseButton />}
      />
      <Route path="/wedding/:id" component={Wedding} />
      <Route path="/needs" component={NeedsWithAuth} />
      <Route path="/user-needs" component={UserNeedsWithAuth} />
      {!hideWeddings && <Route path="/weddings" component={WeddingsWithAuth} />}
      <Route path="/token-history" component={TokenHistoryWithAuth} />
      <Route path="/conversations" component={ConversationsWithAuth} />
    </Switch>
  )
}

export default Routes
