import { take, takeEvery, put, select, delay } from 'redux-saga/effects'
import { putAsync, takeEveryAsync, takeLatestAsync } from 'saga-toolkit'
import { query, mutation } from 'modules/api'
import { actions as notificationActions } from 'modules/notification'
import * as fields from './fields'
import * as selectors from './selectors'
import * as actions from './slice'

const initialMessagesLimit = 25

const processConversations = conversations => {
  const { elements } = conversations
  const convertedElements = elements.map(element => ({
    ...element,
    messages: element.messages.elements,
    totalMessages: element.messages.total,
  }))

  return { ...conversations, elements: convertedElements }
}

const processConversation = conversation => {
  const messagesWithoutTotal = conversation.messages.elements.map(e => e)

  return { ...conversation, messages: messagesWithoutTotal }
}

function* fetchConversations({ meta }) {
  const { limit = yield select(selectors.selectLimit), offset = 0, all = false } = meta.arg || {}
  const options = {
    operation: 'conversations',
    fields: fields.conversationFields,
    variables: {
      filter: {
        type: 'ConversationsFilter',
        value: {
          messagesLimit: initialMessagesLimit,
          limit,
          offset,
          all,
        },
      },
    },
  }

  const { conversations } = yield query(options, true)

  return processConversations(conversations)
}

function* searchConversation({ meta }) {
  const { value, debounced } = meta.arg

  debounced && (yield delay(300))

  const options = {
    operation: 'conversations',
    fields: fields.conversationFields,
    variables: {
      filter: {
        type: 'ConversationsFilter',
        value: {
          searchByUserName: value,
          limit: 10,
        },
      },
    },
  }

  const { conversations } = yield query(options, true)

  return processConversations(conversations)
}

function* fetchConversation({ meta }) {
  const { id } = meta.arg
  const options = {
    operation: 'conversation',
    fields: fields.messageFields,
    variables: {
      id: {
        type: 'ID!',
        value: id,
      },
    },
  }
  const { conversation } = yield query(options, true)

  return processConversation(conversation)
}

function* fetchConversationMessages({ meta }) {
  const { conversation, limit, offset } = meta.arg

  const result = yield query(
    {
      operation: 'messages',
      fields: fields.messages,
      variables: {
        filter: {
          type: 'MessagesFilter',
          value: {
            conversationId: conversation.id,
            limit,
            offset,
          },
        },
      },
    },
    true,
  )

  return result
}

function* newConversation({ meta }) {
  const { text, participants, attachment } = meta.arg
  const options = {
    operation: 'newConversation',
    fields: fields.messageFields,
    variables: {
      input: {
        type: 'NewConversationInput',
        value: {
          participants: participants.map(({ id }) => id),
          message: { text, attachment },
        },
        required: true,
      },
    },
  }
  const { newConversation } = yield mutation(options, true)
  return processConversation(newConversation)
}

function* sendMessage({ meta }) {
  const { conversation, text, attachment } = meta.arg
  const options = {
    operation: 'sendMessage',
    fields: fields.message,
    variables: {
      input: {
        type: 'NewMessageInput',
        value: {
          conversation: conversation.id,
          text: text,
          attachment,
        },
        required: true,
      },
    },
  }
  const { sendMessage } = yield mutation(options, true)

  return sendMessage
}

function* sendBatchMessage({ meta }) {
  const { ids, text, attachment } = meta.arg
  const options = {
    operation: 'sendBatchMessage',
    variables: {
      input: {
        type: 'NewBatchMessageInput',
        value: {
          ids,
          text,
          attachment,
        },
        required: true,
      },
    },
  }
  const { sendMessage } = yield mutation(options, true)

  return sendMessage
}

function* receiveMessage({ payload }) {
  const { data } = payload.message

  if (data.type !== 'chat/message') {
    return
  }

  const message = JSON.parse(data.payload)
  const conversationId = message.conversation
  const conversation = yield select(selectors.selectConversationById, conversationId)
  const { userId } = yield select(({ chat }) => chat)

  if (!conversation && message.sender !== userId) {
    // message from new conversation: need to fetch the conversation first
    yield putAsync(actions.fetchConversation({ id: conversationId }))
  }

  yield put(actions.receiveMessage(message))
}

function* seeConversation({ meta }) {
  const { id } = meta.arg
  const conversation = yield select(selectors.selectConversationById, id)
  const selectUnseenConversations = yield select(selectors.selectUnseenConversations)
  const isConversationUnseen = selectUnseenConversations.find(
    ({ conversationId }) => conversationId === id,
  )

  if (!isConversationUnseen) {
    return
  }

  const options = {
    operation: 'seeConversation',
    fields: fields.messageFields,
    variables: {
      id: {
        value: conversation.id,
        type: 'ID!',
      },
    },
  }
  const { seeConversation } = yield mutation(options, true)

  return processConversation(seeConversation)
}

function* waitForConversation({ meta }) {
  const { id } = meta.arg

  while (true) {
    yield take('*')

    const conversation = yield select(selectors.selectConversationById, id)

    if (conversation) {
      return conversation
    }
  }
}

export default [
  takeEveryAsync(actions.fetchConversations.type, fetchConversations),
  takeEveryAsync(actions.fetchConversation.type, fetchConversation),
  takeLatestAsync(actions.fetchConversationMessages.type, fetchConversationMessages),
  takeEveryAsync(actions.newConversation.type, newConversation),
  takeEveryAsync(actions.sendMessage.type, sendMessage),
  takeEveryAsync(actions.sendBatchMessage.type, sendBatchMessage),
  takeEvery(notificationActions.receiveMessage.type, receiveMessage),
  takeEveryAsync(actions.seeConversation.type, seeConversation),
  takeEveryAsync(actions.waitForConversation.type, waitForConversation),
  takeLatestAsync(actions.searchConversation.type, searchConversation),
]
