import { Select as UISelect } from '@material-ui/core'
import { useT } from 'modules/i18n'

const isString = value => {
  return typeof value === 'string'
}

export const Select = ({ label, placeholder, ...props }) => {
  const t = useT()

  return (
    <UISelect
      label={isString(label) ? t(label) : label}
      placeholder={isString(placeholder) ? t(placeholder) : placeholder}
      {...props}
    />
  )
}

export default Select
