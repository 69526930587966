import { useState } from 'react'
import { ContentState, convertFromHTML, EditorState } from 'draft-js'
import { convertToHTML } from 'draft-convert'
import { Editor } from 'react-draft-wysiwyg'
import { FormControl, FormHelperText, makeStyles } from '@material-ui/core'
import { useT } from 'modules/i18n'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import 'draft-js/dist/Draft.css'
import FormLabel from './FormLabel'

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    marginTop: '16px',
  },
  wrapper: {
    padding: spacing(1),
    border: '1px solid #ccc',
  },
  editor: {
    padding: spacing(1),
    height: '300px',
  },
  toolbar: {
    border: '1px solid #ccc',
  },
  label: {
    display: 'block',
    marginBottom: spacing(1),
  },
}))

const RichTextEditor = ({
  value,
  error,
  helperText,
  label,
  onChange,
  onFocus,
  onBlur,
  disabled = false,
  toolbarOptions = ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'emoji', 'history'],
  converterOptions = {},
  ...props
}) => {
  const classes = useStyles()
  const blocksFromHTML = convertFromHTML(value || '')
  const state = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap,
  )
  const [editorState, setEditorState] = useState(() => EditorState.createWithContent(state))
  const [focused, setFocused] = useState(false)
  const t = useT()

  const handleContentStateChange = () => {
    const currentContentAsHTML = convertToHTML(converterOptions)(editorState.getCurrentContent())
    onChange(currentContentAsHTML)
  }

  const handleFocus = () => {
    setFocused(true)
    onFocus?.()
  }

  const handleBlur = () => {
    setFocused(false)
    onBlur?.()
  }

  return (
    <FormControl className={classes.root} error={!!error} focused={focused}>
      <FormLabel className={classes.label}>{label}</FormLabel>
      <Editor
        readOnly={disabled}
        onContentStateChange={handleContentStateChange}
        editorState={editorState}
        onEditorStateChange={setEditorState}
        defaultEditorState={value}
        wrapperClassName={classes.wrapper}
        editorClassName={classes.editor}
        toolbarClassName={classes.toolbar}
        toolbar={{
          options: toolbarOptions,
        }}
        onFocus={handleFocus}
        onBlur={handleBlur}
        {...props}
      />
      {error && <FormHelperText error>{t(helperText)}</FormHelperText>}
    </FormControl>
  )
}

export default RichTextEditor
