import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as signInActions from '../slice/signIn'
import * as selectors from '../selectors'

const selectSignInState = state => state.auth.signIn

const useSignIn = () => ({
  ...bindActionCreators(signInActions, useDispatch()),
  ...useSelector(selectSignInState),
  isAuthenticated: useSelector(selectors.isAuthenticated),
})

export default useSignIn
