import { Route, useRouteMatch } from 'react-router-dom'
import { New } from './New'
import { Edit } from './Edit'
import Remove from './Remove'
import { List } from './List'
import { WeddingsList } from './WeddingsList'

const Routes = () => {
  const match = useRouteMatch()

  return (
    <>
      <Route path={[match.url, `${match.url}/:id/remove`]} exact component={List} />
      <Route path={`${match.url}/new`} exact component={New} />
      <Route path={`${match.url}/:id/edit`} exact component={Edit} />
      <Route path={`${match.url}/:id/remove`} component={Remove} />
      <Route path={`${match.url}/:id/weddings`} component={WeddingsList} />
    </>
  )
}

export default Routes
