import { UserForm, useUser } from 'modules/user'
import { processPartnerFormSubmit } from 'modules/partner'
import { processAdminFormSubmit } from 'modules/admin'
import { Page, Loader } from 'modules/ui'

export default () => {
  const { user, loading, updateUser } = useUser()

  const handleSubmit = async data => {
    switch (data.type) {
      case 'PARTNER':
        data = await processPartnerFormSubmit(data)

        break
      case 'ADMIN':
        data = await processAdminFormSubmit(data.profile)
        break
      default:
        break
    }

    await updateUser(data)
  }

  if (!user) {
    return 'Loading...'
  }

  return (
    <Page title={`Edit "${user.name}"`}>
      <UserForm initialValues={user} onSubmit={handleSubmit} />
      <Loader loading={loading} />
    </Page>
  )
}
