import { useRouteMatch } from 'react-router'
import BuildIcon from '@material-ui/icons/Build'
import { Box } from '@material-ui/core'
import { NavButton } from 'modules/ui'
import { ConversationIssueList, useAdmin } from 'modules/admin'
import { Page } from 'modules/ui'

const ConversationIssuesList = () => {
  const { loading, healthCheck } = useAdmin()
  const match = useRouteMatch()

  return (
    <Page title="Conversation issues" fullHeight>
      <ConversationIssueList
        conversationIssues={healthCheck?.conversationIssues || []}
        loading={loading}
      />
      <Box display="flex" justifyContent="flex-end" p={1}>
        <NavButton to={`${match.url}/fix`} tooltip="Fix all issues">
          <BuildIcon />
        </NavButton>
      </Box>
    </Page>
  )
}

export default ConversationIssuesList
