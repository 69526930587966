import { Route, useRouteMatch } from 'react-router-dom'
import New from './New'
import Edit from './Edit'
import Remove from './Remove'
import List from './List'
import Invite from './Invite'

export default () => {
  const match = useRouteMatch()

  return (
    <>
      <Route
        path={[`${match.url}`, `${match.url}/:id/remove`, `${match.url}/:id/invite`]}
        exact
        component={List}
      />
      <Route path={`${match.url}/new`} exact component={New} />
      <Route path={`${match.url}/:id/edit`} exact component={Edit} />
      <Route path={`${match.url}/:id/remove`} component={Remove} />
      <Route path={`${match.url}/:id/invite`} component={Invite} />
    </>
  )
}
