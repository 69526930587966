import { takeLatestAsync } from 'saga-toolkit'
import { requestApi } from 'modules/api'
import * as actions from './slice'
import * as operations from './operations'

function* fetchTransactions({ meta }) {
  const { offset, limit, filter, sortBy, sortDirection } = meta.arg

  const transactions = yield requestApi(operations.transactionsQuery, {
    filter: {
      limit,
      offset,
      sortBy,
      sortDirection,
      filter,
    },
  })

  return transactions
}

export default [takeLatestAsync(actions.fetchTransactions.type, fetchTransactions)]
