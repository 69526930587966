import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import BaseAutocomplete, {
  AutocompleteProps as BaseAutocompleteProps,
} from '@material-ui/lab/Autocomplete'
import CircularProgress from '@material-ui/core/CircularProgress'
import FormHelperText from './FormHelperText'
import TextField from './TextField'

const useStyles = makeStyles(() => ({
  root: {},
}))

export interface AutocompleteProps extends BaseAutocompleteProps<any, any, any, any> {
  onChange: (event: any, value: any) => void
  error?: boolean
  helperText?: string
  value?: any
  label?: string
  labelId?: string
  className?: string
  fullWidth?: boolean
  margin?: 'none' | 'dense' | 'normal'
  loading?: boolean
}

const Autocomplete = ({
  onChange,
  error,
  helperText,
  value = [],
  label,
  labelId,
  className,
  fullWidth,
  margin,
  loading,
  ...props
}: AutocompleteProps) => {
  const classes = useStyles()

  return (
    <FormControl className={clsx(classes.root, className)} fullWidth={fullWidth} margin={margin}>
      <BaseAutocomplete
        {...props}
        options={props.options || []}
        value={value}
        loading={loading}
        defaultValue={value}
        onChange={onChange}
        renderInput={params => (
          <TextField
            {...params}
            label={label}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            placeholder=""
            helperText=""
          />
        )}
      />
      {error && (
        <FormHelperText id={`${labelId}-helper`} error>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  )
}

export default Autocomplete
