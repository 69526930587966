import { pick } from 'ramda'
import { takeLatestAsync } from 'saga-toolkit'
import { query, mutation, requestApi } from 'modules/api'
import * as actions from './slice'
import * as operations from './operations'

const tagGroupField = [
  'id',
  'name',
  { image: ['name'] },
  { category: ['id', 'name'] },
  { tags: ['id', 'name'] },
]

const parseTagGroup = tagGroup => ({
  ...tagGroup,
  image: tagGroup.image?.name,
})

// TODO merge searchBlog
function* fetchTagGroups() {
  return yield searchTagGroup({ meta: { arg: { limit: 1000 } } })
}

function* searchTagGroup({ meta }) {
  const { filter, limit = 20, offset = 0 } = meta.arg
  const { paginatedTagGroups } = yield requestApi(operations.paginatedTagGroups, {
    input: {
      filter,
      limit,
      offset,
    },
  })

  return paginatedTagGroups.elements?.map(parseTagGroup)
}

function* fetchTagGroup({ meta }) {
  const { id } = meta.arg
  const options = {
    operation: 'tagGroup',
    fields: tagGroupField,
    variables: {
      id: {
        type: 'ID',
        value: id,
        required: true,
      },
    },
  }

  const { tagGroup } = yield query(options, true)

  return parseTagGroup(tagGroup)
}

function* createTagGroup({ meta }) {
  const options = {
    operation: 'insertTagGroup',
    fields: ['id'],
    variables: {
      input: {
        value: meta.arg,
        type: 'TagGroupInput!',
      },
    },
  }

  const result = yield mutation(options, true)

  return result.insertTagGroup
}

function* updateTagGroup({ meta }) {
  const { id } = meta.arg
  const body = pick(['name', 'image', 'category', 'tags'], meta.arg)

  const options = {
    operation: 'updateTagGroup',
    fields: tagGroupField,
    variables: {
      id: {
        value: id,
        type: 'ID!',
      },
      input: {
        value: body,
        type: 'TagGroupInput!',
      },
    },
  }

  const { updateTagGroup } = yield mutation(options, true)

  return parseTagGroup(updateTagGroup)
}

function* removeTagGroup({ meta }) {
  const { id } = meta.arg
  const options = {
    operation: 'deleteTagGroup',
    variables: {
      id: {
        value: id,
        type: 'ID!',
      },
    },
  }

  const result = yield mutation(options, true)

  return result
}

export default [
  takeLatestAsync(actions.fetchTagGroups.type, fetchTagGroups),
  takeLatestAsync(actions.fetchTagGroup.type, fetchTagGroup),
  takeLatestAsync(actions.searchTagGroup.type, searchTagGroup),
  takeLatestAsync(actions.createTagGroup.type, createTagGroup),
  takeLatestAsync(actions.updateTagGroup.type, updateTagGroup),
  takeLatestAsync(actions.removeTagGroup.type, removeTagGroup),
]
