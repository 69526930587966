import { Route, useRouteMatch } from 'react-router-dom'
import Notifications from './Notifications'

const Settings = () => {
  const match = useRouteMatch()

  return (
    <>
      <Route path={`${match.url}/notifications`} component={Notifications} />
    </>
  )
}

export default Settings
