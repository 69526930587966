import { Table, Loader } from 'modules/ui'
import { Transaction, SortDirection } from 'modules/api'

type Props = {
  transactions: Transaction[]
  columns: any[]
  totalElements: number
  pageSize: number
  page: number
  handleChangePage(page: number): void
  handleChangeRowsPerPage(rows: number): void
  toggleSort(newSortBy: string, sortBy: string, sortDirection: SortDirection): void
  sortDirection: SortDirection
  sortBy: string
  filter: string
  title: string
  handleSearch(search: string): void
  loading: boolean
}

const TransactionList = ({
  transactions,
  columns,
  totalElements,
  pageSize,
  page,
  toggleSort,
  sortDirection,
  sortBy,
  filter,
  title,
  loading,
  handleChangePage,
  handleChangeRowsPerPage,
  handleSearch,
}: Props) => {
  if (loading) {
    return <Loader loading={loading} />
  }

  return (
    <Table
      data={transactions || []}
      columns={columns}
      totalElements={totalElements}
      pageSize={pageSize}
      page={page}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      handleSearch={handleSearch}
      toggleSort={toggleSort}
      sortDirection={sortDirection}
      sortBy={sortBy}
      filter={filter}
      title={title}
    />
  )
}

export default TransactionList
