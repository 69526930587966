export default [
  'AbortError',
  'NetworkError',
  'ApiVersionError',
  'UserNotFoundException',
  'UserNotConfirmedException',
  'NotAuthorizedException',
  'UsernameExistsException',
  'CodeMismatchException',
  'NoCurrentUser',
]
