import { partnersAdapter } from './slice'

export const selectRoot = state => state.partner
export const selectPartners = state => selectRoot(state).partners
export const selectPartner = state => selectRoot(state).partner
export const selectLimit = state => selectRoot(state).limit
export const selectOffset = state => selectRoot(state).offset

export const { selectIds, selectEntities, selectAll, selectTotal, selectById } =
  partnersAdapter.getSelectors(selectPartners)
