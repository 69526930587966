import { makeStyles, lighten } from '@material-ui/core/styles'
import { TablePagination, Toolbar, Tooltip, IconButton, Box } from '@material-ui/core'
import FilterListIcon from '@material-ui/icons/FilterList'
import { Typography } from 'modules/ui'
import { useT } from 'modules/i18n'

export interface EnhancedTableToolbarProps {
  title: string
  handleFilterClick: () => void
  count: number
  rowsPerPage: number
  page: number
  onChangePage: (page: number) => void
  onChangeRowsPerPage: (rowsPerPage) => void
}

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'right',
    maxWidth: 'calc(100vw - 380px)',
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
}))

export const EnhancedTableToolbar = ({
  title,
  handleFilterClick,
  count,
  rowsPerPage,
  page,
  onChangePage,
  onChangeRowsPerPage,
}: EnhancedTableToolbarProps) => {
  const classes = useToolbarStyles()
  const t = useT()

  return (
    <Toolbar className={classes.root}>
      <Typography variant="h6" id="tableTitle">
        {title}
      </Typography>
      <Box flexGrow={1} />
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onPageChange={(_, page) => onChangePage(page + 1)}
        onRowsPerPageChange={e => {
          onChangeRowsPerPage(parseInt(e.target.value))
        }}
      />

      <Tooltip title={t('Filter list')}>
        <IconButton aria-label="filter list" onClick={handleFilterClick}>
          <FilterListIcon />
        </IconButton>
      </Tooltip>
    </Toolbar>
  )
}
