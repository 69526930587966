import { useEffect, useState } from 'react'
import { Autocomplete, useIsMount } from 'modules/ui'
import { withFormikAutocomplete } from 'modules/formik'

const HighlightAutoCompleteWithOrder = props => {
  const { searchFunction, ...autocompleteProps } = props
  const [open, setOpen] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState([])
  const isMountRef = useIsMount()

  const handleInputChange = (e, value) => {
    setSearchTerm(value)
  }

  useEffect(() => {
    if (isMountRef) return

    if (!open) {
      setSearchTerm('')
      return
    }
    ;(async () => {
      if (searchFunction) {
        setLoading(true)
        const { payload } = await searchFunction(searchTerm)
        if (payload) {
          setOptions(payload.map(payload => ({ element: payload, order: null })))
        }
        setLoading(false)
      }
    })()
  }, [open, searchTerm])

  const setNewValue = (_e, value) => {
    const { onChange } = props
    if (value.length > 1) {
      const arrayCopy = value.map((element, index) => ({ ...element, order: index + 1 }))
      return onChange(null, arrayCopy)
    }
    if (value.length === 1) return onChange(null, [{ ...value[0], order: 1 }])
    return onChange(null, [])
  }

  return (
    <Autocomplete
      {...autocompleteProps}
      onChange={setNewValue}
      options={
        options.sort((first, second) => (second.element?.name < first.element?.name ? 1 : -1)) || []
      }
      open={open}
      loading={loading}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      onInputChange={handleInputChange}
      getOptionSelected={(option, value) => option.element.id === value.element.id}
      getOptionLabel={option => option?.element?.name}
      renderOption={option => option.element.name}
    />
  )
}

export default withFormikAutocomplete(HighlightAutoCompleteWithOrder)
