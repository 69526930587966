import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as signUpActions from '../slice/signUp'

const selectSignUpState = state => state.auth.signUp

const useSignUp = () => ({
  ...bindActionCreators(signUpActions, useDispatch()),
  ...useSelector(selectSignUpState),
})

export default useSignUp
