import { Link } from 'react-router-dom'
import DoneIcon from '@material-ui/icons/Done'
import ClearIcon from '@material-ui/icons/Clear'
import moment from 'moment'
import { ImageCell, TableProps } from 'modules/ui'
import { Table } from 'modules/ui'
import { Partner, QuoteRequestStatus } from 'modules/api'
import config from 'modules/config'

const imagesPath = config.app.IMAGES_URL

const createColumns = ({ RowActions }) => [
  {
    field: 'profileImage',
    headerName: 'Profile image',
    width: 150,
    renderCell: row => (
      <ImageCell src={`${imagesPath}/${row.profileImage?.name}`} alt="profile picture" />
    ),
  },
  {
    field: 'name',
    headerName: 'Name',
    width: 220,
    renderCell: row => <Link to={`/partners/${row.id}/edit`}>{row.name}</Link>,
    sortable: true,
  },
  {
    field: 'wallet',
    headerName: 'Tokens',
    renderCell: row => row?.wallet?.balance,
  },
  {
    field: 'quoteRequest',
    headerName: 'Quotes',
    renderCell: row => row?.quotes?.length,
  },
  {
    field: 'quoteRequest',
    headerName: 'Quotes Accepted',
    renderCell: row =>
      row?.quotes?.filter(({ status }) => status === QuoteRequestStatus.Accepted).length,
  },
  {
    field: 'quoteRequest',
    headerName: 'Quotes Rejected',
    renderCell: row =>
      row?.quotes?.filter(({ status }) => status === QuoteRequestStatus.Rejected).length,
  },
  {
    field: 'tags',
    headerName: 'Tags',
    width: 220,
    valueGetter: row => {
      const tags = row.tags.map(({ name }) => name).join(', ')
      return tags
    },
  },
  {
    field: 'email',
    headerName: 'Email address',
    width: 220,
    sortable: true,
  },
  {
    field: 'registered',
    headerName: 'Registered',
    width: 140,
    renderCell: row => (row.registered ? <DoneIcon /> : <ClearIcon />),
  },
  {
    field: 'categoryName',
    headerName: 'Category',
    width: 140,
    valueGetter: row => row.category?.name,
    sortable: true,
  },
  {
    field: 'website',
    headerName: 'Website',
    width: 140,
    sortable: true,
  },
  {
    /** TODO: bids */
  },
  {
    field: 'itemCnt',
    headerName: 'Item count',
    width: 140,
    valueGetter: row => row.items.length,
  },
  {
    field: 'contactPhone',
    headerName: 'Contact phone number',
    width: 140,
    valueGetter: row => row.contactPhone,
    sortable: true,
  },
  {
    field: 'verified',
    headerName: 'Verified',
    width: 140,
    renderCell: row => (row.verified ? <DoneIcon /> : <ClearIcon />),
  },
  {
    field: 'updatedAt',
    headerName: 'Last update',
    width: 200,
    sortable: true,
    renderCell: row => moment(row.updatedAt).format('YYYY-MM-DD HH:mm'),
  },
  {
    field: '',
    headerName: 'Actions',
    width: 140,
    sortable: false,
    disableClickEventBubbling: true,
    renderCell: RowActions,
  },
]

export interface PartnerListProps extends Omit<TableProps, 'columns'> {
  data: Partner[]
  rowActionsComponent: React.FC<{ row: Partner }>
}

const PartnerList = ({ rowActionsComponent: RowActions, ...props }: PartnerListProps) => {
  const columns = createColumns({ RowActions })

  return <Table columns={columns} {...props} />
}

export default PartnerList
