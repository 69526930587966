import { useState } from 'react'
import { useCategory } from 'modules/category'
import { Page } from 'modules/ui'
import { NeedDetail, NeedFormValues, useNeed } from 'modules/need'

export const Edit = () => {
  const { need, updateNeed } = useNeed()
  const { categories, loading: categoryLoading } = useCategory()
  const [submitting, setSubmitting] = useState(false)

  const handleSubmit = async (data: NeedFormValues) => {
    setSubmitting(true)
    await updateNeed({ ...need, ...data })
    setSubmitting(false)
  }

  if (!need) {
    return 'Loading...'
  }

  const initialValues: NeedFormValues = {
    name: need.name,
    category: need.category,
    hide: !!need.hide,
  }

  return (
    <Page title={`Edit "${need.name}"`} fullHeight>
      <NeedDetail
        initialValues={initialValues}
        categoryLoading={categoryLoading}
        categories={categories}
        handleSubmit={handleSubmit}
        submitting={submitting}
      />
    </Page>
  )
}
