import moment from 'moment'
import { Page, useTable } from 'modules/ui'
import { getPaginationParams } from 'modules/app'
import { useTransaction, TransactionList } from 'modules/transaction'

export const createColumns = () => [
  {
    field: 'name',
    headerName: 'Partner name',
    width: 250,
    valueGetter: row => row.wallet.partner.name,
  },
  {
    field: 'amount',
    headerName: 'Token amount',
    width: 50,
    valueGetter: row => (row.amount < 0 ? row.amount : '+' + row.amount),
  },
  {
    field: 'createdAt',
    headerName: 'Timestamp',
    width: 70,
    valueGetter: row => moment(row.createdAt).format('YYYY.MM.DD HH:mm'),
    sortable: true,
  },
  {
    field: 'remainingTokens',
    headerName: 'Remaining tokens',
    width: 50,
    valueGetter: row => row.details.remainingTokens,
    sortable: true,
  },
]

const TokenHistory = () => {
  const { pageSize, page, filter, sortBy, sortDirection } = getPaginationParams()
  const { handleChangePage, handleChangeRowsPerPage, handleSearch, handleSort } = useTable()
  const { transactions, total } = useTransaction()
  const columns = createColumns()

  return (
    <Page>
      <TransactionList
        transactions={transactions}
        columns={columns}
        totalElements={total}
        pageSize={pageSize}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleSearch={handleSearch}
        page={page}
        toggleSort={handleSort}
        sortDirection={sortDirection}
        sortBy={sortBy}
        filter={filter}
        title="Token history"
      />
    </Page>
  )
}

export default TokenHistory
