import { gql } from 'graphql-tag'

export const paginatedBlogs = gql`
  query SearchBlogs($input: BlogFilter!) {
    paginatedBlogs(input: $input) {
      elements {
        id
        name
        author
        ordering
        coverImage {
          name
        }
        content
      }
    }
  }
`
