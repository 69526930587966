import { takeLatest, put } from '@redux-saga/core/effects'
import { actions as trackingActions } from 'modules/tracking'
import { actions as inspirationActions } from 'modules/inspiration'
import { actions as partnerActions } from 'modules/partner'
import { actions as authActions } from 'modules/auth'
import { actions as quoteActions } from 'modules/quote'
import { actions as availabilityActions } from 'modules/availability'

function* trackCreateItem({ meta }) {
  const { active, coverImage, description, name, partner, status, tags } = meta.arg

  yield put(
    trackingActions.track('Create inspiration item', {
      'Active': active,
      'Cover Image': coverImage,
      'Description': description,
      'Name': name,
      'Partner': partner,
      'Status': status,
      'Tag names': tags.map(({ name }) => name).join(', '),
      'Tag IDs': tags.map(({ id }) => id).join(', '),
    }),
  )
}

function* trackUpdateItem({ meta }) {
  const { active, coverImage, description, name, partner, status, tags } = meta.arg

  yield put(
    trackingActions.track('Update inspiration item', {
      'Active': active,
      'Cover Image': coverImage,
      'Description': description,
      'Name': name,
      'Partner': partner,
      'Status': status,
      'Tag names': tags.map(({ name }) => name).join(', '),
      'Tag IDs': tags.map(({ id }) => id).join(', '),
    }),
  )
}

function* trackRemoveItem({ meta }) {
  const { id } = meta.arg

  yield put(
    trackingActions.track('Delete inspiration item', {
      Id: id,
    }),
  )
}

function* trackUpdatePartnerProfile() {
  yield put(trackingActions.track('Update profile'))
}

function* trackOpenAccount() {
  yield put(trackingActions.track('Open partner account'))
}

function* trackDeclineQuoteRequest({ meta }) {
  const { requestId, partnerId, coupleId } = meta.arg

  yield put(
    trackingActions.track('Decline quote request', {
      'Quote request ID': requestId,
      'Partner ID': partnerId,
      'Couple ID': coupleId,
    }),
  )
}

function* trackAcceptQuoteRequest({ meta }) {
  const { input } = meta.arg
  const { requestId, partnerId, coupleId, email, name, phone, text, attachments } = input

  yield put(
    trackingActions.track('Quote request accept', {
      'Quote request ID': requestId,
      'Partner ID': partnerId,
      'Couple ID': coupleId,
      'Email': email,
      'Name': name,
      'Phone': phone,
      'Text': text,
      'Attachments hashes': attachments.map(({ hash }) => hash).join(', '),
      'Attachments names': attachments.map(({ name }) => name).join(', '),
    }),
  )
}

function* trackAcceptAvailability({ meta }) {
  const { id } = meta.arg

  yield put(
    trackingActions.track('Availability accepted', {
      Id: id,
    }),
  )
}

function* trackRejectAvailability({ meta }) {
  const { id } = meta.arg

  yield put(
    trackingActions.track('Availability rejected', {
      Id: id,
    }),
  )
}

const trackingSagas = [
  takeLatest(inspirationActions.createItem.fulfilled, trackCreateItem),
  takeLatest(inspirationActions.updateItem.fulfilled, trackUpdateItem),
  takeLatest(inspirationActions.removeItem.fulfilled, trackRemoveItem),
  takeLatest(partnerActions.updatePartner.fulfilled, trackUpdatePartnerProfile),
  takeLatest(authActions.openAccount.fulfilled, trackOpenAccount),
  takeLatest(quoteActions.declineQuoteRequest.fulfilled, trackDeclineQuoteRequest),
  takeLatest(quoteActions.acceptQuoteRequest.fulfilled, trackAcceptQuoteRequest),
  takeLatest(availabilityActions.acceptAvailability.fulfilled, trackAcceptAvailability),
  takeLatest(availabilityActions.rejectAvailability.fulfilled, trackRejectAvailability),
]

export default trackingSagas
