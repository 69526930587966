import { Chip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { useT } from 'modules/i18n'
import { QuoteRequestStatus } from 'modules/api'

const useStyles = makeStyles(theme => ({
  quoteChip: {
    color: 'white',
    marginRight: theme.spacing(2),
  },
}))

interface Props {
  status: QuoteRequestStatus
  className?: string
  size?: 'small' | 'medium'
}

const STATUS_MAP = {
  [QuoteRequestStatus.Accepted]: { background: '#2e7d32', text: 'Quote accepted' },
  [QuoteRequestStatus.Rejected]: { background: '#d32f2f', text: 'Quote rejected' },
  [QuoteRequestStatus.Pending]: { background: '#FEC140', text: 'Quote pending' },
}

const QuoteRequestStatusChip = ({ status, className = '', size = 'small', ...props }: Props) => {
  const classes = useStyles()
  const t = useT()

  return (
    <Chip
      className={clsx(classes.quoteChip, className && className)}
      label={t(STATUS_MAP[status].text)}
      style={{ background: STATUS_MAP[status].background }}
      size={size}
      {...props}
    />
  )
}

export default QuoteRequestStatusChip
