import { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { unwrapResult } from '@reduxjs/toolkit'
import { useSignIn, SingUpContext, SignUpSuccess } from 'modules/auth'

let COUNTDOWN_SECONDS = 5

const SignIn = () => {
  const { signIn, error } = useSignIn()
  const history = useHistory()
  const { state } = useContext(SingUpContext)
  const { password, email } = state
  const [counter, setCounter] = useState(COUNTDOWN_SECONDS)
  const [timer] = useState(setInterval(() => setCounter(counter - 1), 1000))

  const handleSignIn = async () => {
    clearInterval(timer)

    try {
      unwrapResult(await signIn({ email, password }))
      history.push('/')
    } catch (e) {
      history.push('/sign-in')
    }
  }

  useEffect(async () => {
    if (counter < 1) {
      clearInterval(timer)
      handleSignIn()
    }

    return () => {
      clearInterval(timer)
    }
  }, [counter])

  return <SignUpSuccess onSignInClick={handleSignIn} counter={counter} error={error} />
}

export default SignIn
