import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'
import { createSagaAction } from 'saga-toolkit'

const name = 'couple'

export const coupleAdapter = createEntityAdapter()

const initialState = {
  loading: false,
  error: null,
  couples: coupleAdapter.getInitialState(),
  couple: null,
  total: 0,
}

export const fetchCouples = createSagaAction(`${name}/fetchCouples`)
export const fetchCouple = createSagaAction(`${name}/fetchCouple`)
export const exportCouples = createSagaAction(`${name}/exportCouples`)

const handlePending = state => ({
  ...state,
  loading: true,
})

const handleRejected = (state, { error }) => ({
  ...state,
  error,
  loading: false,
})

const slice = createSlice({
  name,
  initialState,
  reducers: {
    clearCouple: state => ({
      ...state,
      couple: null,
    }),
    clear: () => initialState,
  },
  extraReducers: {
    // fetchCouples
    [fetchCouples.pending]: handlePending,
    [fetchCouples.fulfilled]: (state, { payload }) => {
      coupleAdapter.setAll(state.couples, payload.elements)
      state.loading = false
      state.total = payload.total
    },
    [fetchCouples.rejected]: handleRejected,

    // fetchCouple
    [fetchCouple.pending]: handlePending,
    [fetchCouple.fulfilled]: (state, { payload }) => ({
      ...state,
      couple: payload,
      loading: false,
    }),
    [fetchCouple.rejected]: handleRejected,

    // exportCouples
    [exportCouples.pending]: handlePending,
    [exportCouples.fulfilled]: state => {
      state.loading = false
    },
    [exportCouples.rejected]: handleRejected,
  },
})

export const { clearCouple, clear } = slice.actions

export default slice.reducer
