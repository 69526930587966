import { forwardRef } from 'react'
import { NavLink } from 'react-router-dom'
import IconButton from '@material-ui/core/IconButton'
import { Tooltip } from 'modules/ui'

const NavLinkRef = forwardRef((props, ref) => (
  <div ref={ref}>
    <NavLink {...props} />
  </div>
))

const NavButton = ({ tooltip, tooltipPlacement = 'bottom', ...props }) => (
  <Tooltip title={tooltip} enterDelay={100} leaveDelay={200} placement={tooltipPlacement}>
    <IconButton aria-label={tooltip} color="inherit" component={NavLinkRef} {...props} />
  </Tooltip>
)

export default NavButton
