export const parseItem = item => ({
  ...item,
  coverImage: item.coverImage?.name,
  ...(item.gallery && {
    gallery: item.gallery.map(({ image, ...rest }) => ({
      ...rest,
      image: image.name,
    })),
  }),
})
