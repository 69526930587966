const {
  REACT_APP_STAGE: STAGE,
  REACT_APP_BUCKET: BUCKET,
  REACT_APP_API_GATEWAY_URL: API_GATEWAY_URL,
  REACT_APP_REGION: REGION,
  REACT_APP_USER_POOL_ID: USER_POOL_ID,
  REACT_APP_APP_CLIENT_ID: APP_CLIENT_ID,
  REACT_APP_IDENTITY_POOL_ID: IDENTITY_POOL_ID,
  REACT_APP_IMAGES_URL: IMAGES_URL,
  REACT_APP_MIXPANEL_TOKEN: MIXPANEL_TOKEN,
  REACT_APP_ENABLE_TRACKING: ENABLE_TRACKING,
  REACT_APP_GIT_VERSION: GIT_VERSION,
  REACT_APP_QA_TESTING: QA_TESTING,
  REACT_APP_API_VERSION: API_VERSION,
  REACT_APP_SENTRY_DSN: SENTRY_DSN,
} = process.env

export default {
  app: {
    STAGE: STAGE,
    MAX_ATTACHMENT_SIZE: 1048576 * 15,
    CONVERSATION_FETCH_PERIOD: 30000,
    IMAGES_URL: IMAGES_URL + '/public', // TODO refactor & remove public
    GIT_VERSION,
    QA_TESTING,
  },
  s3: {
    REGION,
    BUCKET,
  },
  apiGateway: {
    REGION,
    URL: API_GATEWAY_URL,
  },
  cognito: {
    REGION,
    USER_POOL_ID,
    APP_CLIENT_ID,
    IDENTITY_POOL_ID,
  },
  mixpanelToken: MIXPANEL_TOKEN,
  enableTracking: ENABLE_TRACKING,
  apiVersion: API_VERSION,
  sentryDsn: SENTRY_DSN,
}
