import { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import { Formik, Form, useFormikContext } from 'formik'
import * as yup from 'yup'
import { TextField, Select, FileUploaderField, RichTextEditor } from 'modules/formik'
import { TagAutocompleteFormik } from 'modules/tag'
import { PartnerAutocompleteFormik } from 'modules/partner'
import { GalleryUploaderField } from 'modules/item'
import { Checkbox, SubmitButton } from 'modules/formik'
import { Button, FormLabel } from 'modules/ui'
import { Status } from 'modules/api'
import config from 'modules/config'

const imagesPath = config.app.IMAGES_URL

const statuses = [
  { name: 'Pending', value: Status.Pending },
  { name: 'Active', value: Status.Active },
  { name: 'Rejected', value: Status.Rejected },
]

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  galleryWrapper: {
    width: '100%',
    marginBlock: 10,
  },
  addButtonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    backgroundColor: '#FAFAFAFA',
    border: 'solid',
    borderWidth: 0.1,
    borderColor: '#A9A9A9',
  },
  addButton: {
    width: '100%',
    height: '100%',
  },
  coverImageLabel: {
    marginBlock: 10,
  },
}))

const validationSchema = yup.object({
  name: yup
    .string('Enter name')
    .min(2, 'Should be of minimum 2 characters length')
    .required('Name is required'),
  partner: yup
    .object({
      id: yup.string(),
      name: yup.string(),
    })
    .nullable()
    .required('Partner is required'),
  status: yup.string('Add status').required('Status is required'),
})

const initialState = {
  name: '',
  active: true,
  status: '',
  partner: {},
  coverImage: '',
  tags: [],
  gallery: [],
  description: '',
}

const handleReplaceClick = setter => () => setter()

const TagAutocompleteWithContext = () => {
  const { values } = useFormikContext()

  return (
    <TagAutocompleteFormik
      categoryId={values?.partner?.category?.id || ''}
      id="tags"
      name="tags"
      label="Tags"
      labelId="partner-edit-form-tag-ids-select"
      margin="normal"
      fullWidth
      multiple
      filterSelectedOptions
      disableClearable
    />
  )
}

const FormBody = ({ partners }) => {
  const { initialValues } = useFormikContext()
  const [image, setImage] = useState(initialValues?.coverImage)
  const classes = useStyles()

  useEffect(() => {
    // TODO fix
    setImage(initialValues?.coverImage)
  }, [initialValues])

  return (
    <Form className={classes.root} noValidate autoComplete="off">
      <TextField id="name" name="name" label="Name" margin="normal" fullWidth />
      <RichTextEditor
        id="description"
        name="description"
        label="Description"
        toolbarOptions={[
          'inline',
          'blockType',
          'fontSize',
          'list',
          'textAlign',
          'emoji',
          'history',
          'link',
        ]}
        converterOptions={{
          entityToHTML: (entity, originalText) => {
            if (entity.type === 'LINK') {
              return <a href={entity.data.url}>{originalText}</a>
            }
            return originalText
          },
        }}
      />
      {image ? (
        <FormControl fullWidth margin="normal">
          <FormLabel className={classes.coverImageLabel}> Cover Image </FormLabel>
          <img width={'100%'} src={`${imagesPath}/${image}`} alt="Cover" />
          <Button size="small" onClick={handleReplaceClick(setImage)}>
            replace image
          </Button>
        </FormControl>
      ) : (
        <FileUploaderField
          margin="normal"
          label="Upload cover image"
          name="coverImage"
          fullWidth
          singleImage
          withIcon={false}
          withPreview
          buttonText="Choose image"
          imgExtension={['.jpg', '.png']}
          maxFileSize={config.app.MAX_ATTACHMENT_SIZE}
        />
      )}
      <Select
        name="status"
        label="Status"
        labelId="item-edit-form-status-select"
        margin="normal"
        fullWidth>
        {statuses.map(({ name, value }, i) => (
          <MenuItem key={i} value={value}>
            {name}
          </MenuItem>
        ))}
      </Select>
      <PartnerAutocompleteFormik
        id="partners"
        name="partner"
        label="Partner"
        labelId="item-edit-form-partner-ids-select"
        margin="normal"
        fullWidth
        filterSelectedOptions
      />
      <TagAutocompleteWithContext partners={partners} />
      <div className={classes.galleryWrapper}>
        <GalleryUploaderField name="gallery" label="Gallery" margin="normal" fullWidth multiple />
      </div>
      <Checkbox fullWidth label="Active" name="active" />
      <SubmitButton color="primary" variant="contained" size="small">
        save
      </SubmitButton>
    </Form>
  )
}

export default ({ categories, partners, initialValues = initialState, ...props }) => {
  const categoryId = initialValues.category ? initialValues.category.id : ''

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        ...initialValues,
        category: categoryId,
        description: initialValues.description || '',
      }}
      {...props}>
      <FormBody partners={partners} categories={categories} {...props} />
    </Formik>
  )
}
