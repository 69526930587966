import { ReactNode, useState } from 'react'
import moment from 'moment'
import { DataGrid } from '@material-ui/data-grid'

export type WeddingNeedsListProps = any & {
  weddings: any
  rowActionsComponent: ReactNode
  total: number
}

export const WeddingNeedsList = ({
  weddings,
  rowActionsComponent: RowActions = null,
  ...props
}) => {
  const columns = createColumns({ RowActions })
  const [pageSize, setPageSize] = useState(25)

  return (
    <DataGrid
      rows={weddings}
      columns={columns}
      pageSize={pageSize}
      onPageSizeChange={newPageSize => setPageSize(newPageSize)}
      rowsPerPageOptions={[25, 50, 100]}
      {...props}
    />
  )
}

const createColumns = ({ RowActions }) => [
  {
    field: 'coupleId',
    headerName: 'Couple ID',
    width: 220,
    renderCell: ({ row }) => row.couple.id,
  },
  {
    field: 'name',
    headerName: 'Name',
    width: 220,
    renderCell: ({ row }) => row.couple.name,
  },
  {
    field: 'email',
    headerName: 'Email',
    width: 220,
    renderCell: ({ row }) => row.couple.email,
  },
  {
    field: 'createdAt',
    headerName: 'Created at',
    width: 200,
    sortable: true,
    renderCell: ({ row }) => moment(row.createdAt).format('YYYY-MM-DD HH:mm'),
  },
  {
    field: 'date',
    headerName: 'Date',
    width: 200,
    sortable: true,
    renderCell: ({ row }) => moment(row.date).format('YYYY-MM-DD'),
  },
  // {
  //   field: '',
  //   headerName: 'Actions',
  //   width: 140,
  //   sortable: false,
  //   disableClickEventBubbling: true,
  //   renderCell: RowActions,
  // },
]
