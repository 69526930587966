import { useEffect, useRef } from 'react'
import { useRouteQueryParams } from '../../hooks'
import { SortDirection } from 'modules/api'

export const useIsMount = () => {
  const isMountRef = useRef(true)
  useEffect(() => {
    isMountRef.current = false
  }, [])
  return isMountRef.current
}

export const useTable = () => {
  const [setQueryParam] = useRouteQueryParams()

  function handleSort(newSortBy, sortBy, sortDirection) {
    let newSort = `${newSortBy},${SortDirection.Asc}`
    if (newSortBy === sortBy) {
      if (sortDirection === SortDirection.Desc) {
        // Already sorting by this field DESC -> disable sorting
        newSort = null
      } else {
        // Already sorting by this field ASC -> change to DESC
        newSort = `${newSortBy},${SortDirection.Desc}`
      }
    }
    setQueryParam('sort', newSort)
  }

  const handleChangePage = page => {
    setQueryParam('page', page)
  }

  const handleChangeRowsPerPage = rows => {
    setQueryParam('pageSize', rows)
  }

  const handleSearch = (search: string | { search: string; type: string }) => {
    if (typeof search === 'string') {
      setQueryParam('filter', search)
    } else {
      setQueryParam({
        search: search.search ?? '',
        filterType: search.type ?? '',
      })
    }
  }

  return { handleChangePage, handleChangeRowsPerPage, handleSearch, handleSort }
}
