import UIFormLabel, { FormLabelProps as UIFormLabelProps } from '@material-ui/core/FormLabel'
import { useT } from 'modules/i18n'

export interface FormLabelProps extends UIFormLabelProps {
  children: string | React.ReactNode
}

const isString = value => {
  return typeof value === 'string'
}

export const FormLabel = ({ children, ...props }) => {
  const t = useT()

  return (
    <UIFormLabel {...props}>{isString(children) ? t(children as string) : children}</UIFormLabel>
  )
}

export default FormLabel
