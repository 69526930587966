import { Route, useRouteMatch } from 'react-router-dom'
import List from './List'
import View from './View'

const Conversations = () => {
  const match = useRouteMatch()

  return (
    <>
      <Route path={`${match.url}`} exact component={List} />
      <Route path={`${match.url}/:id/view`} exact component={View} />
    </>
  )
}

export default Conversations
