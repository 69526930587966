import { Route, useRouteMatch } from 'react-router-dom'
import ChatPage from './Chat'
import BatchPage from './Batch'

const Chat = () => {
  const match = useRouteMatch()

  //TODO refactor routing
  return (
    <>
      <Route path={[`${match.url}`, `${match.url}/conversations/:id`]} exact component={ChatPage} />
      <Route path={`${match.url}/batch`} exact component={BatchPage} />
    </>
  )
}

export default Chat
