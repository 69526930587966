import Scrollbar from 'react-perfect-scrollbar'
import List from '@material-ui/core/List'
import ConversationsListItem from './ConversationsListItem'
import { Button } from '@material-ui/core'

const compareConversationsByLastMessage = (firstEl, secondEl) =>
  Date.parse(secondEl.messages[0].createdAt) - Date.parse(firstEl.messages[0].createdAt)

const ConversationsList = ({
  activeConversationId,
  conversations,
  onClick,
  userId,
  onClickMore,
  showMore = false,
}) => {
  const sortedArray = conversations
    .filter(conversation => conversation.messages.length > 0)
    .sort(compareConversationsByLastMessage)

  return (
    <Scrollbar>
      <List component="nav">
        {sortedArray.map((conversation, i) => (
          <ConversationsListItem
            key={i}
            {...conversation}
            lastMessage={conversation.messages[0]}
            userId={userId}
            participants={conversation.participants.filter(item => item.id !== userId)}
            selected={activeConversationId === conversation.id}
            onClick={() => onClick(conversation)}
          />
        ))}
        {showMore && <Button onClick={onClickMore}>More</Button>}
      </List>
    </Scrollbar>
  )
}

export default ConversationsList
