import { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Loader, Button, Typography } from 'modules/ui'
import { useQuote, RejectQuoteRequestForm } from 'modules/quote'
import { QuoteRequestStatus } from 'modules/api'

const getFormData = r => ({
  text: r?.text,
  reasons: r?.reasons,
})

const Reject = () => {
  const history = useHistory()
  const [open, setOpen] = useState(false)
  const { quoteRequest, declineQuoteRequest } = useQuote()
  const [loading, setLoading] = useState(false)
  const rejectForm = useRef()
  const readonly = quoteRequest?.status === QuoteRequestStatus.Rejected // TODO refactor !
  const quoteResponse = quoteRequest?.quoteResponse

  const goBack = () => {
    history.goBack()
  }

  const handleCancel = () => {
    setOpen(false)
  }

  useEffect(() => {
    setOpen(true)
  }, [])

  if (!quoteRequest) {
    return null
  }

  const handleSubmit = async data => {
    if (readonly) {
      return
    }
    setLoading(true)
    await declineQuoteRequest({
      ...data,
      requestId: quoteRequest.id,
      partnerId: quoteRequest.partnerId,
      coupleId: quoteRequest.coupleId,
    })
    setLoading(false)
    history.push('/quotes')
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={handleCancel}
      TransitionProps={{ onExited: goBack }}>
      <DialogTitle id="form-dialog-title">
        <Typography variant="inherit">Why do you want to reject this quote request?</Typography>
        <Typography>This couple specifically asked for an offer from you!</Typography>
      </DialogTitle>
      <DialogContent>
        <RejectQuoteRequestForm
          onSubmit={handleSubmit}
          innerRef={rejectForm}
          readonly={readonly}
          initialValues={readonly ? getFormData(quoteResponse) : undefined}
        />
        <Loader loading={loading} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>{readonly ? 'Close' : 'Cancel'}</Button>
        {!readonly && (
          <Button
            onClick={() => rejectForm.current.submitForm()}
            type="submit"
            variant="contained"
            color="primary"
            disabled={loading}>
            Reject quote
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default Reject
