import { useDispatch, useSelector } from 'modules/app'
import { bindActionCreators } from 'redux'
import * as actions from './slice'
import * as selectors from './selectors'

export const useConversation = () => {
  const conversations = useSelector(selectors.selectAllConversations)

  return {
    ...bindActionCreators(actions, useDispatch()),
    ...useSelector(selectors.selectRoot),
    conversations,
  }
}
