import { useHistory } from 'react-router'
import Box from '@material-ui/core/Box'
import { UserForm, useUser } from 'modules/user'
import { Page } from 'modules/ui'
import { processPartnerFormSubmit } from 'modules/partner'
import { processAdminFormSubmit } from 'modules/admin'

export default () => {
  const { loading, createUser } = useUser()
  const history = useHistory()

  const handleSubmit = async data => {
    switch (data.type) {
      case 'Partner':
        data.profile = await processPartnerFormSubmit(data.profile)

        break
      case 'Admin':
        data.profile = await processAdminFormSubmit(data.profile)
        break
      default:
        break
    }

    const { payload } = await createUser(data)
    history.push(`/users/${payload.id}/edit`)
  }

  if (loading) {
    return 'Loading...'
  }

  return (
    <Page title="New user">
      <Box mt={4}>
        <UserForm onSubmit={handleSubmit} />
      </Box>
    </Page>
  )
}
