import { Box, Grid } from '@material-ui/core'
import { Page, Typography, Button, Loader } from 'modules/ui'
import { usePayment } from 'modules/payment'
import simplepayLogo from '../../../images/simplepay-logo.svg'

const statusLabelMap = {
  SUCCESS: 'Sikeres',
  FAIL: 'Sikertelen',
  CANCEL: 'Megszakított',
}

function PurchaseTokenResult({ history, hiddenCloseButton }) {
  const { result, loading } = usePayment()

  const handleClickBack = async () => {
    history.replace('/wallet')
  }

  return (
    <Page title="Purchase token">
      <Grid spacing={2} container direction="row">
        <Grid item xs={12}>
          <Box mt={4} mr={4}>
            <Loader loading={loading} />
          </Box>
          {result && (
            <>
              <Typography variant="h6" gutterBottom>
                {statusLabelMap[result.status]} tranzakció
              </Typography>
              <Typography variant="body1" gutterBottom>
                SimplePay tranzakció azonosító: <strong>{result.transactionId}</strong>
              </Typography>
              {result.status === 'FAIL' && (
                <Typography variant="body1" gutterBottom>
                  Kérjük, ellenőrizze a tranzakció során megadott adatok helyességét. Amennyiben
                  minden adatot helyesen adott meg, a visszautasítás okának kivizsgálása kapcsán
                  kérjük, szíveskedjen kapcsolatba lépni kártyakibocsátó bankjával.
                </Typography>
              )}
              <Typography variant="body1" gutterBottom>
                Rendelés referencia: <strong>{result.orderRef}</strong>
              </Typography>
            </>
          )}
        </Grid>
        <Box display="flex" justifyContent="space-between" width="100%" mt={2}>
          <a
            href="http://simplepartner.hu/PaymentService/Fizetesi_tajekoztato.pdf"
            target="_blank"
            rel="noreferrer">
            <img
              height="32"
              src={simplepayLogo}
              title=" SimplePay - Online bankkártyás fizetés"
              alt=" SimplePay vásárlói tájékoztató"
            />
          </a>
          {!hiddenCloseButton && (
            <Button
              variant="contained"
              color="primary"
              disabled={loading}
              onClick={handleClickBack}>
              Close
            </Button>
          )}
        </Box>
      </Grid>
    </Page>
  )
}

export default PurchaseTokenResult
