import { gql } from 'graphql-tag'

export const paginatedTagGroups = gql`
  query SearchTagGroups($input: TagGroupFilter!) {
    paginatedTagGroups(input: $input) {
      elements {
        id
        name
        tags {
          id
          name
        }
        category {
          id
          name
        }
      }
    }
  }
`
