import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'
import { createSagaAction } from 'saga-toolkit'

const name = 'partner'

export const partnersAdapter = createEntityAdapter()

const initialState = {
  loading: false,
  error: null,
  partners: partnersAdapter.getInitialState(),
  partner: null,
  total: 0,
}

export const fetchPartners = createSagaAction(`${name}/fetchPartners`)
export const fetchPartner = createSagaAction(`${name}/fetchPartner`)
export const searchPartner = createSagaAction(`${name}/searchPartner`)
export const createPartner = createSagaAction(`${name}/createPartner`)
export const updatePartner = createSagaAction(`${name}/updatePartner`)
export const activatePartner = createSagaAction(`${name}/activatePartner`)
export const removePartner = createSagaAction(`${name}/removePartner`)
export const invitePartner = createSagaAction(`${name}/invitePartner`)
export const addTokenToPartner = createSagaAction(`${name}/addTokenToPartner`)

const handlePending = state => ({
  ...state,
  loading: true,
})

const handleRejected = (state, { error }) => ({
  ...state,
  error,
  loading: false,
})

const slice = createSlice({
  name,
  initialState,
  reducers: {
    clearPartner: state => ({
      ...state,
      partner: null,
    }),
    clear: () => initialState,
  },
  extraReducers: {
    // fetchPartners
    [fetchPartners.pending]: handlePending,
    [fetchPartners.fulfilled]: (state, { payload }) => {
      partnersAdapter.setAll(state.partners, payload.elements)
      state.loading = false
      state.total = payload.total
    },
    [fetchPartners.rejected]: handleRejected,

    // fetchPartner
    [fetchPartner.pending]: handlePending,
    [fetchPartner.fulfilled]: (state, { payload }) => ({
      ...state,
      partner: payload,
      loading: false,
    }),
    [fetchPartner.rejected]: handleRejected,

    // createPartner
    [createPartner.pending]: handlePending,
    [createPartner.fulfilled]: state => ({
      ...state,
      loading: false,
    }),
    [createPartner.rejected]: handleRejected,

    [addTokenToPartner.pending]: handlePending,
    [addTokenToPartner.fulfilled]: state => ({
      ...state,
      loading: false,
    }),
    [addTokenToPartner.rejected]: handleRejected,

    // updatePartner
    [updatePartner.pending]: handlePending,
    [updatePartner.fulfilled]: (state, { payload }) => {
      partnersAdapter.updateOne(state.partners, { id: payload.id, changes: payload })
      state.partner = { ...state.partner, ...payload }
      state.loading = false
    },
    [updatePartner.rejected]: handleRejected,

    // activatePartner
    [activatePartner.pending]: handlePending,
    [activatePartner.fulfilled]: (state, { payload }) => {
      partnersAdapter.updateOne(state.partners, { id: payload.id, changes: payload })
      state.partner = payload
      state.loading = false
    },
    [activatePartner.rejected]: handleRejected,

    // invitePartner
    [invitePartner.pending]: handlePending,
    [invitePartner.fulfilled]: state => ({
      ...state,
      loading: false,
    }),
    [invitePartner.rejected]: handleRejected,
  },
})

export const { clearPartner, clear, setOffset, setPage } = slice.actions

export default slice.reducer
