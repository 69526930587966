export const mapNavigationData = (path, location) => {
  switch (path) {
    default: {
      const { pathname } = location
      const id = pathname.split('/')[2]

      return {
        eventName: `Navigate to ${path}`,
        data: {
          Page: path,
          ...(id && !isNaN(parseInt(id)) && { Id: id }),
        },
      }
    }
  }
}
