import { useHistory, useRouteMatch } from 'react-router'
import { InspirationItemList, useInspiration } from 'modules/inspiration'
import { useProfile } from 'modules/profile'
import { Page } from 'modules/ui'

const List = () => {
  const match = useRouteMatch()
  const history = useHistory()
  const { items, loading } = useInspiration()

  const handleClickItem = ({ id }) => {
    history.push(`${match.url}/${id}/edit`)
  }

  const handleAddNewItem = () => {
    history.push(`${match.url}/new`)
  }

  return (
    <Page maxWidth="xl" disableBackground>
      <InspirationItemList
        handleAddNewItem={handleAddNewItem}
        items={items}
        handleClickItem={handleClickItem}
        loading={loading}
      />
    </Page>
  )
}

export default List
