import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core'
import { Typography, Button } from 'modules/ui'
import { useProfile } from 'modules/profile'

export interface WelcomeCardProps {
  handleEditProfile: () => void
  handleNewInspiration: () => void
}

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    backgroundColor: 'white',
    padding: '32px',
    borderRadius: '10px',
    overflow: 'hidden',
    boxShadow: '0px 17px 17px #EFF1F9BF',
    position: 'relative',
  },
  text: {
    // '& *': {
    //   fontFamily: 'Poppins',
    // },
    marginBottom: spacing(2),
  },
  buttonWrapper: {
    display: 'flex',
    gap: spacing(2),
  },
}))

const WelcomeCard = ({ handleEditProfile, handleNewInspiration }: WelcomeCardProps) => {
  const classes = useStyles()
  const { user } = useProfile()
  const buttons = []

  if (!user?.profileImage)
    buttons.push({
      text: 'Edit profile',
      handler: handleEditProfile,
    })

  if (user.items.length <= 0)
    buttons.push({
      text: 'Új inspirációs kép feltöltése',
      handler: handleNewInspiration,
    })

  return (
    <Box className={classes.root}>
      {user.status === 'PENDING' && (
        <Box className={classes.text}>
          <Typography gutterBottom variant="h6">
            Profilod jóváhagyás alatt
          </Typography>
          <Typography gutterBottom variant="body2">
            Ebben a státuszban, a rendszert még csak korlátozottan érheted el, az adminisztrátor
            hamarosan jóváhagyja a profilodat!
          </Typography>
        </Box>
      )}
      <Box className={classes.text}>
        <Typography gutterBottom variant="h6">
          Üdvözlünk a Wedding Manager adminisztrációs felületén! 😊
        </Typography>
        <Typography gutterBottom variant="body2">
          Ezen a felületen keresztül tudod irányítani profilodat, de néhány apró kivétellel a mobil
          alkalmazáson keresztül is teljeskörűen tudod adminisztrálni a fiókodat. A bejelentkezési
          adatok mind a két felületen megegyeznek.
        </Typography>
        <Typography gutterBottom variant="body1">
          <b>Ebben a szekcióban szeretnénk összefoglalni néhány gyakran ismételt kérdést:</b>
        </Typography>
        <Typography gutterBottom>
          <b>📢 Mi az az ESKÜVŐK menüpont?</b>
          <Typography gutterBottom variant="body2">
            Lényegében ez a legfontosabb funkció! Ezt megtalálod itt és az alkalmazásban is
            egyaránt. Itt találod kilistázva az összes olyan esküvőt, amire a párok jelenleg aktívan
            várnak árajánlatokat a megadott igényeik alapján. Ezek közül bármelyikre küldhetsz
            árajánlatot 10 Tokenért cserébe. Fontos, hogy ezeket az árajánlatokat nem konkrétan
            tőled kérik a párok, hanem általában a rendszerben lévő szolgáltatóktól, akik
            teljesíteni tudják a megadott igényeiket. Részletesebb infóért kattints a linkre:{' '}
            <a
              href={'https://www.weddingmanager.app/szolgaltatoknak'}
              target="_blank"
              rel="noreferrer">
              weddingmanager.app/szolgaltatoknak
            </a>
            .
          </Typography>
        </Typography>
        <Typography gutterBottom variant="body1">
          <b>💰 Mi az a Token?</b>
          <Typography gutterBottom variant="body2">
            A Token a Wedding Manager pénzneme. Ezt két dologra tudod felhasználni: Küldhetsz
            árajánlatot az Esküvők menüpontban lévő esküvőkre Küldhetsz árajánlatot azoknak a
            pároknak, akiknek külön ajánlottunk Téged és ők visszajeleztek, hogy szívesen várnának
            tőled árajánlatot.
          </Typography>
        </Typography>
        <Typography gutterBottom variant="body1">
          <b>🔔 Mi az a Direkt Árajánlat?</b>
          <Typography gutterBottom variant="body2">
            Ezek azok az organikus árajánlatok, amelyeket a párok a profilodon keresztül kértek
            tőled. Ezekre korlátok, és Token felhasználás nélkül tudsz reagálni.
          </Typography>
        </Typography>
        <Typography gutterBottom variant="body1">
          <b>📸 Mi az az Inspiration Scroll?</b>
          <Typography gutterBottom variant="body2">
            Az Inspiration Scroll Magyarország legnagyobb esküvői képes adatbázisa, amely
            összefoglalja a partnereink összes képét. A párok ezen keresztül inspirálódhatnak és
            fedezhetik fel a hazai kínálatot. Egy képeden keresztül a felhasználók egy kattintással
            eljuthatnak a profilodra! Minél többször, minél több képet töltesz fel, annál többször
            fogsz megjelenni a pároknak! Részletesebb infóért kattints a linkre:{' '}
            <a
              href={'https://www.weddingmanager.app/szolgaltatoknak'}
              target="_blank"
              rel="noreferrer">
              weddingmanager.app/szolgaltatoknak
            </a>
            .
          </Typography>
        </Typography>
        <Typography gutterBottom variant="body1">
          <b>🤔 Miért nem látom az ESKÜVŐK menüpontot?</b>
          <Typography gutterBottom variant="body2">
            Néhány kategória különlegesebb adatgyűjtést igényel, de már ezerrel dolgozunk azon, hogy
            minél előbb mindenki számára elérhető legyen!
          </Typography>
        </Typography>
        <Box className={classes.buttonWrapper}>
          {buttons.map(({ text, handler }, i) => (
            <Button key={i} variant="contained" color="primary" onClick={handler}>
              {text}
            </Button>
          ))}
        </Box>
      </Box>
    </Box>
  )
}

export default WelcomeCard
