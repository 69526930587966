import Storage from '@aws-amplify/storage'
import md5 from 'crypto-js/md5'

const readImage = async file => {
  if (!/^image\/(png|jpe?g|gif|webp)$/.test(file.type)) {
    return
  }

  const reader = new FileReader()

  return new Promise(resolve => {
    reader.addEventListener('load', () => {
      const img = new Image()

      img.addEventListener('load', () => {
        resolve(img)
      })
      img.src = reader.result
    })

    reader.readAsDataURL(file)
  })
}

export default async (file, onProgress) => {
  const event = await new Promise(resolve => {
    const reader = new FileReader()
    reader.onloadend = resolve
    reader.readAsBinaryString(file)
  })
  const ext = file.name.split('.').reverse()[0]
  const key = md5(event.target.result) + (ext !== file.name ? `.${ext}` : '')
  const { width, height } = (await readImage(file)) || {}

  await Storage.put(key, file, {
    level: 'public',
    contentType: file.type,
    ...(width && height && { metadata: { width: `${width}`, height: `${height}` } }),
    progressCallback: onProgress,
  })

  return key
}
