import DownloadIcon from '@material-ui/icons/CloudDownload'
import moment from 'moment'
import { Page, NavButton } from 'modules/ui'
import { WeddingNeedsList, useNeed } from 'modules/need'
import { Box } from '@material-ui/core'
import downloadFile from 'js-file-download'

const generateContent = weddingsByNeed =>
  weddingsByNeed
    .map(({ date, couple, createdAt }) =>
      [
        couple.id,
        couple.name,
        couple.email,
        moment(createdAt).format('YYYY-MM-DD'),
        moment(date).format('YYYY-MM-DD'),
      ].join(';'),
    )
    .join('\n')

export const WeddingsList = () => {
  const { need, weddingsByNeed } = useNeed()

  const handleDownloadClick = () => {
    const content = generateContent(weddingsByNeed)

    downloadFile(content, `user-needs-${need.id}.csv`)
  }

  if (!need) {
    return 'Loading...'
  }

  return (
    <Page title={`UserNeeds for "${need.name}"`} fullHeight>
      <WeddingNeedsList rowsPerPageOptions={[25, 50, 100]} weddings={weddingsByNeed} />
      <Box display="flex" justifyContent="flex-end" p={1}>
        <NavButton to="#" tooltip="Download CSV" onClick={handleDownloadClick}>
          <DownloadIcon />
        </NavButton>
      </Box>
    </Page>
  )
}
