import { MenuItem } from '@material-ui/core'
import BaseSelect, { SelectProps } from '@material-ui/core/Select'

interface PickerProps extends SelectProps {
  options: {
    name: string
    value: string
  }[]
}

function Picker({ options, ...props }: PickerProps) {
  return (
    <BaseSelect defaultValue="all" {...props}>
      {options.map(({ name, value }, i) => (
        <MenuItem key={i} value={value}>
          {name}
        </MenuItem>
      ))}
    </BaseSelect>
  )
}

export default Picker
