import i18n from 'i18next'
import { initReactI18next, useTranslation, UseTranslationOptions } from 'react-i18next'

// const withPrefix = (t, keyPrefix) => key => (keyPrefix ? t(`${keyPrefix}.${key}`) : t(key))

export const useT = (keyPrefix?: string, options?: UseTranslationOptions) => {
  const { t } = useTranslation('translation', { ...options, keyPrefix })

  return t
}

export const initTransalation = translations => {
  i18n.use(initReactI18next).init({
    resources: translations,
    fallbackLng: 'hu',
    ns: ['translation'],
    defaultNS: 'translation',
    nsSeparator: false,
    interpolation: {
      escapeValue: false,
    },
    compatibilityJSON: 'v3',
  })
}

export { useTranslation }

export default i18n

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
