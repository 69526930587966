import { takeLatestAsync } from 'saga-toolkit'
import { select } from '@redux-saga/core/effects'
import { requestApi, MutationUpdateUserNeedArgs, UserNeedStatus, SortDirection } from 'modules/api'
import * as actions from './slice'
import * as queries from './queries'
import * as selectors from './selectors'

function* fetchNeeds({ meta }) {
  const { offset, limit, filter, sortBy, sortDirection } = meta.arg

  const { paginatedNeeds } = yield requestApi(queries.paginatedNeedsQuery, {
    input: { offset, limit, filter, sortBy, sortDirection },
  })

  return paginatedNeeds
}

function* fetchAllNeeds() {
  const { needs } = yield requestApi(queries.needsQuery)

  return needs
}

function* fetchNeed({ meta }) {
  const { id } = meta.arg
  const { need } = yield requestApi(queries.needQuery, { id })

  return need
}

function* fetchUserNeedForPartner({ meta }) {
  const { id } = meta.arg
  const { userNeed } = yield requestApi(queries.userNeedQueryForPartner, {
    userNeedId: id,
  })
  return userNeed
}

function* fetchUserNeedForAdmin({ meta }) {
  const { id } = meta.arg
  const { userNeed } = yield requestApi(queries.userNeedQueryForAdmin, {
    userNeedId: id,
  })
  return userNeed
}

function* fetchUserNeeds({ meta }) {
  const { arg } = meta

  const limit = yield select(selectors.selectLimit)
  const offset = yield select(selectors.selectOffset)

  // TODO the category filtering is a backend job
  const { userNeeds } = yield requestApi(queries.userNeedsQueryForPartner, {
    filter: {
      limit: arg?.limit ?? limit,
      offset: arg?.offset ?? offset,
      status: UserNeedStatus.Accepted,
      needIds: arg?.needIds ?? [],

      // TODO make it not hardcoded here
      sortBy: 'approvedAt',
      sortDirection: SortDirection.Desc,
    },
  })

  return userNeeds
}

function* fetchUserNeedsForAdmin({ meta }) {
  const { offset, limit, filter, sortBy, sortDirection, status, complete, category, couple } =
    meta.arg

  const { userNeeds } = yield requestApi(queries.userNeedsQueryForAdmin, {
    filter: {
      offset,
      limit,
      filter,
      sortBy,
      sortDirection,
      ...(status && { status }),
      ...(complete && { complete }),
      ...((category || category !== 'all') && { category }),
      ...(couple && { couple }),
    },
  })

  return userNeeds
}

function* fetchUserNeedCategorySummary({ meta }) {
  const { status } = meta.arg

  const { userNeedCategorySummary } = yield requestApi(queries.userNeedsCategorySummary, {
    filter: {
      status,
    },
  })

  return userNeedCategorySummary
}

function* insertNeed({ meta }) {
  const { insertNeed } = yield requestApi(queries.insertNeedMutation, { input: meta.arg })

  return insertNeed
}

function* updateNeed({ meta }) {
  const { id, ...rest } = meta.arg
  const { updateNeed } = yield requestApi(queries.updateNeedMutation, {
    id,
    input: rest,
  })

  return updateNeed
}

function* removeNeed({ meta }) {
  const { deleteNeed } = yield requestApi(queries.removeNeedMutation, { id: meta.arg })

  return deleteNeed
}

function* fetchWeddingsByNeed({ meta }) {
  const { id } = meta.arg

  const { weddingsByNeed } = yield requestApi(queries.weddingsByNeedIdQuery, {
    weddingsByNeedId: id,
  })

  return weddingsByNeed
}

function* seenUserNeed({ meta }) {
  const { id: seenUserNeedId } = meta.arg

  const { seenUserNeed } = yield requestApi(queries.seenUserNeedMutation, {
    seenUserNeedId,
  })

  return seenUserNeed
}

function* updateUserNeed({ meta }: { meta: { arg: MutationUpdateUserNeedArgs } }) {
  const { id: updateUserNeedId, input } = meta.arg

  const { updateUserNeed } = yield requestApi(queries.updateUserNeedMutation, {
    updateUserNeedId,
    input,
  })

  return updateUserNeed
}

export default [
  takeLatestAsync(actions.fetchNeeds.type, fetchNeeds),
  takeLatestAsync(actions.fetchAllNeeds.type, fetchAllNeeds),
  takeLatestAsync(actions.fetchNeed.type, fetchNeed),
  takeLatestAsync(actions.fetchUserNeedForAdmin.type, fetchUserNeedForAdmin),
  takeLatestAsync(actions.fetchUserNeedCategorySummary.type, fetchUserNeedCategorySummary),
  takeLatestAsync(actions.fetchUserNeedForPartner.type, fetchUserNeedForPartner),
  takeLatestAsync(actions.fetchUserNeeds.type, fetchUserNeeds),
  takeLatestAsync(actions.fetchUserNeedsForAdmin.type, fetchUserNeedsForAdmin),
  takeLatestAsync(actions.insertNeed.type, insertNeed),
  takeLatestAsync(actions.updateNeed.type, updateNeed),
  takeLatestAsync(actions.removeNeed.type, removeNeed),
  takeLatestAsync(actions.fetchWeddingsByNeed.type, fetchWeddingsByNeed),
  takeLatestAsync(actions.seenUserNeed.type, seenUserNeed),
  takeLatestAsync(actions.updateUserNeed.type, updateUserNeed),
]
