import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper'
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect'
import connectedAuthWrapper from 'redux-auth-wrapper/connectedAuthWrapper'

export default ({
  redirectPath,
  isAuthenticated,
  isSignInLoading,
  getSignInData,
  isAdmin,
  isPartner,
  AuthenticatingComponent = () => <div>loading...</div>,
}) => {
  const locationHelper = locationHelperBuilder({})

  const userIsAuthenticatedDefaults = {
    authenticatedSelector: isAuthenticated,
    authenticatingSelector: isSignInLoading,
    wrapperDisplayName: 'UserIsAuthenticated',
  }

  const userIsAuthenticated = connectedAuthWrapper(userIsAuthenticatedDefaults)

  const userIsAdmin = connectedAuthWrapper({
    authenticatedSelector: state => getSignInData(state) !== null && isAdmin(state),
    predicate: isAdmin,
    wrapperDisplayName: 'UserIsAdmin',
  })

  const userIsPartner = connectedAuthWrapper({
    authenticatedSelector: state => getSignInData(state) !== null && isPartner(state),
    predicate: isPartner,
    wrapperDisplayName: 'UserIsPartner',
  })

  const userIsAuthenticatedRedir = connectedRouterRedirect({
    ...userIsAuthenticatedDefaults,
    AuthenticatingComponent,
    redirectPath,
  })

  const userIsAdminRedir = connectedRouterRedirect({
    redirectPath,
    allowRedirectBack: false,
    authenticatedSelector: state => getSignInData(state) !== null && isAdmin(state),
    predicate: isAdmin,
    wrapperDisplayName: 'UserIsAdmin',
  })

  const userIsPartnerRedir = connectedRouterRedirect({
    redirectPath,
    allowRedirectBack: false,
    authenticatedSelector: state => getSignInData(state) !== null && isPartner(state),
    predicate: isPartner,
    wrapperDisplayName: 'UserIsPartner',
  })

  const userIsNotAuthenticatedDefaults = {
    // Want to redirect the user when they are done loading and authenticated
    authenticatedSelector: state => !isAuthenticated(state) && !isSignInLoading(state),
    wrapperDisplayName: 'UserIsNotAuthenticated',
  }

  const userIsNotAuthenticated = connectedAuthWrapper(userIsNotAuthenticatedDefaults)

  const userIsNotAuthenticatedRedir = connectedRouterRedirect({
    ...userIsNotAuthenticatedDefaults,
    redirectPath: (state, ownProps) => locationHelper.getRedirectQueryParam(ownProps) || '/',
    allowRedirectBack: true,
  })

  return {
    userIsAuthenticated,
    userIsAdmin,
    userIsPartner,
    userIsAuthenticatedRedir,
    userIsAdminRedir,
    userIsPartnerRedir,
    userIsNotAuthenticated,
    userIsNotAuthenticatedRedir,
  }
}
