import { Route, useRouteMatch } from 'react-router-dom'
import { UserType } from 'modules/api'
import { useProfile } from 'modules/profile'
import View from './View'
import AdminView from './AdminView'
import NeedView from './NeedView'
import Reject from './Reject'
import Sent from './Sent'
import ViewResponse from './ViewResponse'
import List, { routes as listRoutes } from './List'
import AdminList from './AdminList'

const Quotes = () => {
  const match = useRouteMatch()
  const { user } = useProfile()

  return (
    <>
      <Route path={listRoutes} exact component={user.type === UserType.Admin ? AdminList : List} />
      <Route
        path={[
          `${match.url}/:id/view`,
          `${match.url}/:id/reject`,
          `${match.url}/:id/view-rejection`,
        ]}
        component={user.type === UserType.Admin ? AdminView : View}
      />
      <Route
        path={[`${match.url}/:id/reject`, `${match.url}/:id/view-rejection`]}
        component={Reject}
      />
      <Route path={`${match.url}/:id/sent`} component={Sent} />
      <Route path={`${match.url}/:id/view-response`} component={ViewResponse} />
      <Route path={`${match.url}/:userneedId/need`} component={NeedView} />
    </>
  )
}

export default Quotes
