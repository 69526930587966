import { useRouteMatch } from 'react-router'
import { NavButton } from 'modules/ui'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'

const RowActions = ({ row }) => {
  const match = useRouteMatch()

  return (
    <>
      <NavButton to={`${match.url}/${row.id}/edit`} tooltip="Edit">
        <EditIcon fontSize="small" />
      </NavButton>
      <NavButton to={`${match.url}/${row.id}/remove`} tooltip="Remove">
        <DeleteIcon fontSize="small" />
      </NavButton>
    </>
  )
}

export default RowActions
