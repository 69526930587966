import { useState, useRef } from 'react'
import { useField } from 'formik'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import IconButton from '@material-ui/core/IconButton'
import { Button, Typography } from 'modules/ui'
import { UploadImageForm } from 'modules/partner'
import ImageIcon from '@material-ui/icons/Image'

const ImageAttachmentPicker = ({ name, selected, onSubmit }) => {
  const [openDialog, setOpenDialog] = useState(false)
  const [, , helpers] = useField({
    name,
  })

  const handleProfileImageImageSubmit = data => {
    setValue(data.image)
    setOpenDialog(false)
    imageUploaderForm.current.resetForm()
    onSubmit(data.image)
  }

  const imageUploaderForm = useRef()
  const { setValue } = helpers

  return (
    <>
      <IconButton
        aria-label="image-upload"
        color={selected ? 'primary' : undefined}
        onClick={() => setOpenDialog(true)}>
        <ImageIcon />
      </IconButton>
      <Dialog fullWidth maxWidth="sm" open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle id="form-dialog-title">
          <Typography variant="inherit">Send image</Typography>
        </DialogTitle>
        <DialogContent>
          <UploadImageForm onSubmit={handleProfileImageImageSubmit} innerRef={imageUploaderForm} />
        </DialogContent>
        <DialogActions>
          <Button size="small" onClick={() => setOpenDialog(false)}>
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            size="small"
            onClick={() => imageUploaderForm.current.submitForm()}>
            Pick
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ImageAttachmentPicker
