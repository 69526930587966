import UIFormHelperText, {
  FormHelperTextProps as UIFormHelperTextProps,
} from '@material-ui/core/FormHelperText'
import { useT } from 'modules/i18n'

export interface FormHelperTextProps extends UIFormHelperTextProps {
  children: string | React.ReactNode
}

const isString = value => {
  return typeof value === 'string'
}

export const FormHelperText = ({ children, ...props }: FormHelperTextProps) => {
  const t = useT()

  return (
    <UIFormHelperText {...props}>
      {isString(children) ? t(children as string) : children}
    </UIFormHelperText>
  )
}

export default FormHelperText
