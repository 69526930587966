import { Grid, makeStyles, MenuItem } from '@material-ui/core'
import { Form, Formik } from 'formik'
import * as yup from 'yup'
import { pick } from 'rambda'
import { Select, TextField, SubmitButton, RichTextEditor } from 'modules/formik'
import { TagAutoCompleteSelector } from 'modules/tag'
import { validationSchemaObject } from 'modules/partner'

const useStyles = makeStyles(({ spacing }) => ({
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: spacing(4),
  },
}))

const validationSchema = yup.object(
  pick(['name', 'description', 'tags', 'category'], validationSchemaObject),
)

const CompanyForm = ({ state, submitForm, categories, categoryLoading }) => {
  const classes = useStyles()

  return (
    <Formik
      initialValues={{
        name: state.name || '',
        description: state.description || '',
        tags: state.tags || [],
        category: state.category || '',
      }}
      onSubmit={submitForm}
      validationSchema={validationSchema}>
      <Form>
        <Grid container justifyContent="center" alignContent="flex-end">
          <Grid item xs={12} sm={8} md={6}>
            <TextField id="name" name="name" label="Profile name" margin="normal" fullWidth />
            <RichTextEditor id="description" name="description" label="Description" />
            <Select
              id="category"
              name="category"
              label="Category"
              loading={categoryLoading}
              labelId="partner-edit-form-category-select"
              margin="normal"
              fullWidth>
              {categories.map(({ id, name }) => (
                <MenuItem key={id} value={id}>
                  {name}
                </MenuItem>
              ))}
            </Select>
            <TagAutoCompleteSelector />
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.footer}>
          <SubmitButton variant="contained" color="primary" margin="dense">
            Next
          </SubmitButton>
        </Grid>
      </Form>
    </Formik>
  )
}

export default CompanyForm
