import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(({ palette, spacing, breakpoints }) => ({
  root: {
    position: 'relative',
    width: '100%',
    maxWidth: 200,
    marginTop: spacing(2),
    margin: 'auto',
  },
  childrenContainer: {
    'width': '100%',
    'border': '1px solid grey',
    'padding': spacing(1),
    'backgroundColor': palette.background.default,
    'position': 'relative',
    '& img': {
      width: '100%',
    },
  },
  iconContainer: {
    top: 0,
    right: 0,
    backgroundColor: 'white',
    transform: 'translate(42%, -42%)',
    position: 'absolute',
    borderRadius: '100%',
    overflow: 'hidden',
    zIndex: 3000,
  },
}))

export interface BadgeProps {
  iconContent: React.ReactNode
  children: React.ReactNode
}

const Badge = ({ children, iconContent }: BadgeProps) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.iconContainer}>{iconContent}</div>
      <div className={classes.childrenContainer}>{children}</div>
    </div>
  )
}

export default Badge
