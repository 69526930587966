import { useField } from 'formik'
import BaseSlider from '@material-ui/core/Slider'
import FormControl from '@material-ui/core/FormControl'
import { FormLabel, FormHelperText } from 'modules/ui'

const RangeSlider = ({
  name,
  value,
  label,
  labelId,
  fullWidth,
  margin,
  onChange,
  onBlur,
  singleValue,
  disabled,
  ...props
}) => {
  const [field, meta, helper] = useField({ name, value, onChange, onBlur })

  return (
    <FormControl margin={margin} fullWidth={fullWidth}>
      {!!label && <FormLabel>{label}</FormLabel>}
      <BaseSlider
        defaultValue={singleValue ? 0 : [0, 1]}
        {...props}
        {...field}
        disabled={disabled}
        onChange={(e, value) => {
          helper.setValue(value)
        }}
        valueLabelDisplay="auto"
        aria-labelledby="range-slider"
      />
      {meta.error && (
        <FormHelperText error id={`${labelId}-helper`}>
          {meta.error}
        </FormHelperText>
      )}
    </FormControl>
  )
}

export default RangeSlider
