import { createSlice } from '@reduxjs/toolkit'
import { createSagaAction } from 'saga-toolkit'

const name = 'app'

export const initialState = {
  started: false,
  snackMessage: null,
}

export const start = createSagaAction(`${name}/start`)

const slice = createSlice({
  name,
  initialState,
  reducers: {
    showSnackMessage: (state, { payload }) => {
      state.snackMessage = payload
    },
    hideSnackMessage: state => {
      state.snackMessage = null
    },
  },
  extraReducers: {
    [start.fulfilled]: state => {
      state.started = true
    },
    [start.rejected]: () => ({
      started: true,
    }),
  },
})

export const { showSnackMessage, hideSnackMessage } = slice.actions

export default slice.reducer
