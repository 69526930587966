import { useState } from 'react'
import { DataGrid } from '@material-ui/data-grid'
import DoneIcon from '@material-ui/icons/Done'
import ClearIcon from '@material-ui/icons/Clear'
import { ImageCell } from 'modules/ui'
import config from 'modules/config'

const imagesPath = config.app.IMAGES_URL

const createColumns = ({ RowActions }) => [
  {
    field: 'image',
    headerName: 'Image',
    width: 150,
    renderCell: ({ row }) => <ImageCell src={`${imagesPath}/${row.image}`} alt="cover" />,
  },
  {
    field: 'name',
    headerName: 'Name',
    width: 220,
  },
  {
    field: 'hide',
    headerName: 'Hide',
    width: 220,
    renderCell: ({ row }) => (row.hide ? <DoneIcon /> : <ClearIcon />),
  },
  {
    field: 'ordering',
    headerName: 'Ordering',
    width: 220,
  },
  {
    field: '',
    headerName: 'Actions',
    width: 140,
    sortable: false,
    disableClickEventBubbling: true,
    renderCell: RowActions,
  },
]

export default ({ categories, rowActionsComponent: RowActions, ...props }) => {
  const columns = createColumns({ RowActions })
  const [pageSize, setPageSize] = useState(25)

  return (
    <DataGrid
      rows={categories}
      columns={columns}
      pageSize={pageSize}
      onPageSizeChange={newPageSize => setPageSize(newPageSize)}
      rowsPerPageOptions={[25, 50, 100]}
      rowHeight={90}
      pagination
      {...props}
    />
  )
}
