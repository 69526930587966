import { useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import Grid from '@material-ui/core/Grid'
import * as yup from 'yup'
import { Formik, Form, useFormikContext } from 'formik'
import { TextField, Select, SubmitButton } from 'modules/formik'
import { useCategory } from 'modules/category'
import { PartnerForm } from 'modules/partner'
import { AdminForm } from 'modules/admin'
import { Typography } from 'modules/ui'
import { UserType, UserRole, Status, CoupleRole } from 'modules/api'

const types = [
  { value: UserType.Admin, name: 'Admin' },
  { value: UserType.Partner, name: 'Partner' },
  { value: UserType.Couple, name: 'Couple' },
]

const roles = [
  { value: UserRole.Admin, name: 'Admin' },
  { value: UserRole.Partner, name: 'Partner' },
  { value: UserRole.User, name: 'User' },
]

const statuses = [
  { value: Status.Pending, name: 'Pending' },
  { value: Status.Active, name: 'Active' },
  { value: Status.Rejected, name: 'Rejected' },
]

const whoAreYouOptions = [
  { value: CoupleRole.Bride, name: 'Bride' },
  { value: CoupleRole.Fiance, name: 'Fiance' },
]

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  baseInfo: {
    [breakpoints.up('md')]: {
      paddingRight: spacing(1),
    },
    paddingRight: 0,
  },
  profile: {
    [breakpoints.up('md')]: {
      paddingLeft: spacing(1),
    },
    paddingLeft: 0,
  },
}))

const validationSchema = yup.object({
  /** TODO: validation */
})

const CoupleFields = () => {
  const { values } = useFormikContext()

  if (values.type !== 'COUPLE') {
    return null
  }

  // TODO livingLocation weddingLocation weddingDate exactWeddingDate guestRange budgetRange profileImage likedPartners likedItems

  return (
    <>
      <Select name="profile.whoAreYou" label="Who Are You" margin="normal" fullWidth>
        {whoAreYouOptions.map(({ value, name }, i) => (
          <MenuItem key={i} value={value}>
            {name}
          </MenuItem>
        ))}
      </Select>
      <TextField name="profile.myName" label="My Name" margin="normal" fullWidth />
      <TextField name="profile.partnersName" label="Partners Name" margin="normal" fullWidth />
    </>
  )
}

const PartnerFields = ({ ...props }) => {
  const { values, initialValues } = useFormikContext()

  if (values.type !== 'PARTNER') {
    return null
  }

  return <PartnerForm initialValues={initialValues} submitButton={false} {...props} />
}

const AdminFields = ({ ...props }) => {
  const { values, initialValues } = useFormikContext()

  if (values.type !== 'ADMIN') {
    return null
  }

  return <AdminForm initialValues={initialValues} submitButton={false} {...props} />
}

const initialState = {
  type: '',
  role: '',
  status: '',
}

export default ({ initialValues = initialState, onSubmit, ...props }) => {
  const classes = useStyles()
  const { categories, loading: categoryLoading } = useCategory()
  const profileForm = useRef()

  const handleProfileSubmit = async data => {
    return data
  }

  //TODO: Fix update
  const handleSubmit = async data => {
    const profile = await profileForm.current.submitForm()
    onSubmit(data)
  }

  return (
    <Formik
      {...props}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      initialValues={initialValues}>
      <Form className={classes.root} noValidate autoComplete="off">
        <Grid container>
          <Grid item xs={12} md={6} className={classes.baseInfo}>
            <Typography>Base info</Typography>
            <TextField
              id="cognitoId"
              name="cognitoId"
              label="Cognito Id"
              margin="normal"
              fullWidth
              disabled
            />
            <Select
              id="type"
              name="type"
              label="Type"
              margin="normal"
              fullWidth
              disabled={!!initialValues.type}>
              {types.map(({ value, name }, i) => (
                <MenuItem key={i} value={value}>
                  {name}
                </MenuItem>
              ))}
            </Select>
            <Select id="role" name="role" label="Role" margin="normal" fullWidth>
              {roles.map(({ value, name }, i) => (
                <MenuItem key={i} value={value}>
                  {name}
                </MenuItem>
              ))}
            </Select>
            <Select id="status" name="status" label="Status" margin="normal" fullWidth>
              {statuses.map(({ value, name }, i) => (
                <MenuItem key={i} value={value}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} md={6} className={classes.profile}>
            <Typography>Profile</Typography>
            <AdminFields innerRef={profileForm} onSubmit={handleProfileSubmit} />
            <CoupleFields />
            <PartnerFields
              categoryLoading={categoryLoading}
              onSubmit={handleProfileSubmit}
              categories={categories}
              innerRef={profileForm}
            />
          </Grid>
        </Grid>
        <SubmitButton color="primary" variant="contained" size="small">
          Save
        </SubmitButton>
      </Form>
    </Formik>
  )
}
