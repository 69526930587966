import List from './List'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import NeedView from '../Quotes/NeedView'

const Weddings = () => {
  const match = useRouteMatch()
  return (
    <Switch>
      <Route exact path={match.url} component={List} />
      <Route path={`${match.url}/:userneedId/need`}>
        <NeedView origin="weddings" />
      </Route>
    </Switch>
  )
}

export default Weddings
