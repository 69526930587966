import Box from '@material-ui/core/Box'
import { useProfile } from 'modules/profile'
import { Page, Typography } from 'modules/ui'

const AdminHome = () => {
  const { user } = useProfile()

  if (!user) {
    return null
  }

  return (
    <Page title="Admin home">
      <Typography gutterBottom variant="caption">
        Profile status: {user.status}
      </Typography>
      <Box mt={1}>
        <Typography>Yo {user.name}!</Typography>
      </Box>
    </Page>
  )
}

export default AdminHome
