import { useState } from 'react'
import moment from 'moment'
import { DataGrid } from '@material-ui/data-grid'

const createColumns = ({ RowActions, SenderColumn }) => [
  {
    field: 'title',
    headerName: 'Title',
    width: 220,
  },
  {
    field: 'sender',
    headerName: 'Sender',
    width: 220,
    renderCell: SenderColumn,
  },
  {
    field: 'createdAt',
    headerName: 'Created at',
    width: 220,
    valueGetter: ({ row }) => moment(row.createdAt).format('LLL'),
  },
  {
    field: '',
    headerName: 'Actions',
    width: 140,
    sortable: false,
    disableClickEventBubbling: true,
    renderCell: RowActions,
  },
]

export default ({
  notifications,
  rowActionsComponent: RowActions,
  senderColumnComponent: SenderColumn,
  ...props
}) => {
  const columns = createColumns({ RowActions, SenderColumn })
  const [pageSize, setPageSize] = useState(25)

  return (
    <DataGrid
      rows={notifications}
      columns={columns}
      pageSize={pageSize}
      onPageSizeChange={newPageSize => setPageSize(newPageSize)}
      rowsPerPageOptions={[25, 50, 100]}
      pagination
      {...props}
    />
  )
}
