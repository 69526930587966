import { Route, useRouteMatch } from 'react-router-dom'
import View from './View'
import PurchaseToken from './PurchaseToken'
import PurchaseTokenResult from './PurchaseTokenResult'

const Wallet = () => {
  const match = useRouteMatch()

  return (
    <>
      <Route path={match.url} exact component={View} />
      <Route path={`${match.url}/purchase-token`} exact component={PurchaseToken} />
      <Route path={`${match.url}/purchase-token/result`} exact component={PurchaseTokenResult} />
    </>
  )
}

export default Wallet
