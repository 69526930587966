import { gql } from 'graphql-tag'

export const searchPartnerQuery = gql`
  query SearchPartners($input: PartnerFilter!) {
    partners(input: $input) {
      elements {
        id
        name
        category {
          id
        }
      }
    }
  }
`
