import { useEffect } from 'react'
import { useFormikContext } from 'formik'
import TagAutocompleteFormik from './TagAutocompleteFormik'
const TagAutoCompleteSelector = () => {
  const { values, setFieldValue, touched } = useFormikContext()

  useEffect(() => {
    touched.category && setFieldValue('tags', [])
  }, [values.category])

  return (
    <TagAutocompleteFormik
      categoryId={values.category}
      id="tags"
      name="tags"
      label="Tags"
      labelId="partner-edit-form-tag-ids-select"
      margin="normal"
      fullWidth
      multiple
      filterSelectedOptions
      disableClearable
    />
  )
}

export default TagAutoCompleteSelector
