import { Route, useRouteMatch } from 'react-router-dom'
import List from './List'

const Payments = () => {
  const match = useRouteMatch()

  return (
    <>
      <Route path={[`${match.url}`, `${match.url}/:id/remove`]} exact component={List} />
    </>
  )
}

export default Payments
