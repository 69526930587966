import { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { CategoryForm, useCategory } from 'modules/category'
import { Page, Loader, Typography } from 'modules/ui'
import { uploadToS3 } from 'modules/storage'

const Edit = () => {
  const { category, updateCategory } = useCategory()
  const [submitting, setSubmitting] = useState(false)

  const handleSubmit = async data => {
    setSubmitting(true)
    const tagIdList = data.tags.map(item => item.id)
    const extraFields = data.extraFields.map(extraField => extraField?.field)
    await updateCategory({
      ...data,
      extraFields,
      tags: tagIdList,
      image: data.image !== category.image ? await uploadToS3(data.image) : data.image,
    })
    setSubmitting(false)
  }

  if (!category) {
    return 'Loading...'
  }

  return (
    <Page title={`Edit "${category.name}"`}>
      <Grid container>
        <Grid item xs={12} sm={8} md={6} lg={4}>
          <Typography variant="h5">Category details</Typography>
          <CategoryForm initialValues={category} onSubmit={handleSubmit} />
          <Loader loading={submitting} />
        </Grid>
      </Grid>
    </Page>
  )
}

export default Edit
