import { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { ItemForm, useItem } from 'modules/item'
import { Page, Typography, Loader } from 'modules/ui'
import { usePartner } from 'modules/partner'
import { uploadToS3 } from 'modules/storage'

export default () => {
  const { item, updateItem } = useItem()
  const [submitting, setSubmitting] = useState(false)
  const { partners } = usePartner()

  const handleSubmit = async data => {
    const { gallery, partner } = data

    const imagesToUpload = gallery.filter(({ image }) => image instanceof File)
    const tasks = imagesToUpload.map(async ({ image, name }) => {
      const _image = await uploadToS3(image)
      return {
        name,
        image: _image,
      }
    })
    const newImages = await Promise.all(tasks)

    await updateItem({
      ...data,
      partner: partner.id,
      coverImage:
        data.coverImage !== item.coverImage ? await uploadToS3(data.coverImage) : data.coverImage,
      gallery: [...gallery.filter(({ image }) => !(image instanceof File)), ...newImages],
    })

    setSubmitting(false)
  }

  if (!item) {
    return 'Loading...'
  }

  return (
    <Page title={`Edit "${item.name}"`}>
      <Loader loading={submitting} />
      <Grid container>
        <Grid item xs={12} sm={8} md={8} lg={8}>
          <Typography variant="h5">Item details</Typography>
          <ItemForm initialValues={item} partners={partners} onSubmit={handleSubmit} />
        </Grid>
      </Grid>
    </Page>
  )
}
