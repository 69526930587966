import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import { Typography, Button } from 'modules/ui'

export default function FinishForm({ title, classes, onSubmit }) {
  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12} sm={6} md={4}>
        <Typography variant="body1" gutterBottom>
          {title}
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.footer}>
        <Button variant="contained" color="primary" onClick={onSubmit}>
          Sign in
        </Button>
      </Grid>
    </Grid>
  )
}

FinishForm.propTypes = {
  title: PropTypes.string.isRequired,
}
