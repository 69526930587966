import { useParams, useHistory } from 'react-router-dom'
import { Dialog } from 'modules/ui'
import { useTag } from 'modules/tag'

const Remove = ({ match }) => {
  const { id } = useParams()
  const { replace } = useHistory()
  const { tag, removeTag } = useTag()

  const goBack = () => replace('/' + match.url.split('/')[1])

  const handleRemove = async () => {
    await removeTag({ id })
  }

  if (!tag) {
    return null
  }

  return (
    <Dialog
      title={tag.name}
      text="Are you sure you want to remove it?"
      closeButtonText="Cancel"
      confirmButtonText="Remove"
      onClose={goBack}
      onConfirm={handleRemove}
    />
  )
}

export default Remove
