import { select } from 'redux-saga/effects'
import { takeEveryAsync, takeLatestAsync } from 'saga-toolkit'
import { query } from 'modules/api'
import { fields } from 'modules/chat'
import * as selectors from './selectors'
import * as actions from './slice'

const initialMessagesLimit = 0

const processConversations = conversations => {
  const { elements } = conversations
  const convertedElements = elements.map(element => ({
    ...element,
    messages: element.messages.elements,
    totalMessages: element.messages.total,
  }))

  return { ...conversations, elements: convertedElements }
}

function* fetchConversations({ meta }) {
  const {
    limit = yield select(selectors.selectLimit),
    offset = 0,
    all = false,
    filter,
  } = meta.arg || {}
  const options = {
    operation: 'conversations',
    fields: fields.conversationFields,
    variables: {
      filter: {
        type: 'ConversationsFilter',
        value: {
          messagesLimit: initialMessagesLimit,
          searchByUserName: filter,
          limit,
          offset,
          all,
        },
      },
    },
  }

  const { conversations } = yield query(options, true)

  return processConversations(conversations)
}

function* fetchConversationMessages({ meta }) {
  const { id, limit = yield select(selectors.selectLimit), offset = 0 } = meta.arg || {}

  const result = yield query(
    {
      operation: 'messages',
      fields: fields.messages,
      variables: {
        filter: {
          type: 'MessagesFilter',
          value: {
            conversationId: id,
            limit,
            offset,
          },
        },
      },
    },
    true,
  )

  return result
}

export default [
  takeEveryAsync(actions.fetchConversations.type, fetchConversations),
  takeLatestAsync(actions.fetchConversationMessages.type, fetchConversationMessages),
]
