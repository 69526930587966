import { useState } from 'react'
import { Autocomplete } from 'modules/ui'
import { withFormikAutocomplete } from 'modules/formik'

const ExtraProfileFieldsAutoCompleteFormik = props => {
  const [open, setOpen] = useState(false)

  return (
    <Autocomplete
      {...props}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      getOptionLabel={option => option.label}
    />
  )
}

export default withFormikAutocomplete(ExtraProfileFieldsAutoCompleteFormik)
