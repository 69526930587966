import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import InputAdornment from '@material-ui/core/InputAdornment'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Select as UISelect, FormHelperText, FormLabel } from 'modules/ui'
import withFormikSelect from '../withFormikSelect'

const useStyles = makeStyles(theme => ({
  root: {},
  selectAdornment: {
    marginRight: 30,
  },
}))

const Select = ({
  label,
  loading,
  labelId,
  className,
  helperText,
  fullWidth,
  margin,
  ...props
}) => {
  const classes = useStyles()

  return (
    <FormControl className={clsx(classes.root, className)} fullWidth={fullWidth} margin={margin}>
      <FormLabel id={labelId}>{label}</FormLabel>
      <UISelect
        endAdornment={
          <>
            {loading && (
              <InputAdornment position="start" className={classes.selectAdornment}>
                <CircularProgress color="inherit" size={20} />
              </InputAdornment>
            )}
          </>
        }
        {...props}
        labelId={labelId}
      />
      {helperText && (
        <FormHelperText error id={`${labelId}-helper`}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  )
}

export default withFormikSelect(Select)
