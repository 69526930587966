import { Box } from '@material-ui/core'
import { Typography, Page } from 'modules/ui'

const Help = () => (
  <Page title="Kapcsolatfelvétel">
    <Typography>Kérdés esetén keress minket bátran az alábbi elérhetőségeken!</Typography>

    <Box mt={2}>
      <Typography variant="h6" bold>
        Kiss Boglárka
      </Typography>
      <Typography variant="body2">Telefonszám: +36 20 512 3918</Typography>
      <Typography variant="body2">Email: boglarka.kiss@weddingmanager.hu</Typography>
    </Box>

    <Box mt={2}>
      <Typography variant="body2">Központi email: hello@weddingmanager.hu</Typography>
    </Box>
  </Page>
)

export default Help
