export const messageFields = [
  'id',
  {
    participants: [
      {
        '...on Partner': [
          'name',
          'id',
          'type',
          { profileImage: ['name'] },
          {
            needs: [
              'id',
              'name',
              { category: ['id'] },
              {
                questions: [
                  'id',
                  'title',
                  'helperText',
                  {
                    fields: [
                      'name',
                      'label',
                      'type',
                      {
                        props: ['min', 'max', 'step', 'currency', { options: ['label', 'value'] }],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      { '...on Couple': ['name', 'id', 'type', { profileImage: ['name'] }] },
      { '...on Admin': ['name', 'id', 'type', { profileImage: ['name'] }] },
    ],
  },
  {
    messages: [
      'total',
      {
        elements: [
          'id',
          'text',
          'seenAt',
          'createdAt',
          { attachment: ['type', 'payload'] },
          {
            sender: [
              {
                '...on Partner': [
                  'name',
                  'id',
                  'type',
                  { profileImage: ['name'] },
                  {
                    needs: [
                      'id',
                      'name',
                      {
                        questions: [
                          'id',
                          'title',
                          'helperText',
                          {
                            fields: [
                              'name',
                              'label',
                              'type',
                              {
                                props: [
                                  'min',
                                  'max',
                                  'step',
                                  'currency',
                                  { options: ['label', 'value'] },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              { '...on Couple': ['name', 'id', 'type', { profileImage: ['name'] }] },
              { '...on Admin': ['name', 'id', 'type', { profileImage: ['name'] }] },
            ],
          },
          { conversation: ['id'] },
        ],
      },
    ],
  },
]

export const conversationFields = [
  'total',
  {
    elements: messageFields,
  },
]

export const message = [
  'id',
  'text',
  {
    sender: [
      {
        '...on Partner': [
          'name',
          'id',
          'type',
          { profileImage: ['name'] },
          {
            needs: [
              'id',
              'name',
              {
                questions: [
                  'id',
                  'title',
                  'helperText',
                  {
                    fields: [
                      'name',
                      'label',
                      'type',
                      {
                        props: ['min', 'max', 'step', 'currency', { options: ['label', 'value'] }],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      { '...on Couple': ['name', 'id', 'type', { profileImage: ['name'] }] },
      { '...on Admin': ['name', 'id', 'type', { profileImage: ['name'] }] },
    ],
  },
  'createdAt',
  { attachment: ['type', 'payload'] },
  'seenAt',
]

export const messages = [
  'total',
  {
    elements: message,
  },
]
