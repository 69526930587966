import { Box, CardMedia, makeStyles } from '@material-ui/core'
import { Button, Typography } from 'modules/ui'
import { Need, Wedding } from 'modules/api'
import { useT } from 'modules/i18n'
import config from 'modules/config'

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    columnGap: spacing(1),
  },
  leftContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: '17px',
  },
  image: {
    minWidth: '62px',
    width: '62px',
    height: '62px',
    borderRadius: '7px',
    border: '5px solid #ECEBEC',
  },
  coupleText: {
    fontSize: '12px',
    fontFamily: 'Poppins',
    fontWeight: 700,
    color: '#3F4254',
    marginBottom: spacing(0.5),
  },
  serviceText: {
    fontSize: '12px',
    fontFamily: 'Poppins',
    color: '#626679',
    lineHeight: '16px',
  },
  showButton: {
    color: '#455571',
    textTransform: 'none',
    fontSize: '12px',
    fontFamily: 'Poppins',
  },
}))

const imagesPath = config.app.IMAGES_URL

interface Props {
  wedding: Wedding
  need: Need
  onShowClick: () => void
}

const LatestWeddingsItem = ({ wedding, need, onShowClick }: Props) => {
  const classes = useStyles()
  const t = useT()

  return (
    <Box className={classes.root}>
      <Box className={classes.leftContainer}>
        <CardMedia className={classes.image} image={`${imagesPath}/${need.category.image.name}`} />
        <Box>
          <Typography className={classes.coupleText}>{wedding.couple.name}</Typography>
          <Typography className={classes.serviceText}>{`${need.name} ${t(
            'looking for service',
          )}`}</Typography>
        </Box>
      </Box>
      <Button variant="text" className={classes.showButton} onClick={onShowClick}>
        View
      </Button>
    </Box>
  )
}

export default LatestWeddingsItem
