import { takeLatestAsync } from 'saga-toolkit'
import moment from 'moment'
import Papa from 'papaparse'
import download from 'downloadjs'
import * as actions from './slice'
import { query } from 'modules/api'

const coupleFields = [
  'id',
  'name',
  'whoAreYou',
  'createdAt',
  'lastSeenAt',
  'email',
  'adminNote',
  {
    wedding: [
      'date',
      'possibleYear',
      'possibleMonths',
      'possibleDays',
      {
        address: ['name'],
      },
      { possibleAddresses: ['name'] },
    ],
  },
  { profileImage: ['name'] },
  { likedPartners: ['id', 'name'] },
  {
    likedItems: ['id', 'name'],
  },
  {
    userNeeds: [{ need: ['name'] }],
  },
]

const parseCouple = couple => ({
  ...couple,
  profileImage: couple.profileImage?.name,
})

function* fetchCouples({ meta }) {
  const { offset, limit, filter, sortBy, sortDirection, status } = meta.arg
  const options = {
    operation: 'couples',
    fields: [
      'total',
      {
        elements: coupleFields,
      },
    ],
    variables: {
      input: {
        value: {
          limit,
          offset,
          filter,
          sortBy,
          sortDirection,
          status,
        },
        type: 'CoupleFilter!',
      },
    },
  }

  const { couples } = yield query(options, true)

  return {
    ...couples,
    elements: couples.elements.map(parseCouple),
  }
}

function* fetchCouple({ meta }) {
  const { id } = meta.arg
  const options = {
    operation: 'couple',
    fields: coupleFields,
    variables: {
      id: {
        type: 'ID',
        value: id,
        required: true,
      },
    },
  }

  const { couple } = yield query(options, true)

  return parseCouple(couple)
}

function* exportCouples({ meta }) {
  const { filter, limit = 50 } = meta.arg
  let offset = 0
  let couples = []

  while (true) {
    console.log(`Downloading offset ${offset} ...`)

    const arg = { limit, offset, filter }
    const result = yield fetchCouples({ meta: { arg } })

    couples = [...couples, ...result.elements]
    offset += limit

    if (offset > result.total) {
      break
    }
  }

  const rows = couples.map(
    ({
      id,
      name,
      email,
      createdAt,
      lastSeenAt,
      wedding,
      likedItems,
      likedPartners,
      userNeeds,
    }) => ({
      id,
      name,
      email,
      createdAt: moment(createdAt).format('YYYY-MM-DD HH:mm'),
      lastSeenAt: lastSeenAt ? moment(lastSeenAt).format('YYYY-MM-DD HH:mm') : '-',
      weddingDate: wedding.date ? moment(wedding.date).format('YYYY-MM-DD') : '-',
      possibleWeddingDate: wedding.date
        ? '-'
        : `${wedding.possibleYear} / ${wedding.possibleMonths.join(', ')}`,
      weddingAddress:
        wedding?.address?.name ||
        wedding.possibleAddresses.map(({ name }) => name).join(', ') ||
        '-',
      likedItems: likedItems.length,
      likedPartners: likedPartners.length,
      numberOfUserNeeds: userNeeds.length,
    }),
  )
  const csv = Papa.unparse(rows)
  const data = new Blob([csv])

  download(data, 'couples.csv', 'text/csv')
}

export default [
  takeLatestAsync(actions.fetchCouples.type, fetchCouples),
  takeLatestAsync(actions.fetchCouple.type, fetchCouple),
  takeLatestAsync(actions.exportCouples.type, exportCouples),
]
