import { useState } from 'react'
import GoogleMapReact from 'google-map-react'
import Tooltip from '@material-ui/core/Tooltip'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import RoomIcon from '@material-ui/icons/Room'
import IconButton from '@material-ui/core/IconButton'

export interface MarkerProps {
  text: string
}

const Marker = ({ text }: MarkerProps) => {
  const [open, setOpen] = useState(false)

  const handleTooltipClose = () => {
    setOpen(false)
  }

  const handleTooltipToggle = () => {
    setOpen(!open)
  }

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={open}
          placement="top"
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={text}>
          <IconButton color="primary" onClick={handleTooltipToggle}>
            <RoomIcon fontSize="large" />
          </IconButton>
        </Tooltip>
      </div>
    </ClickAwayListener>
  )
}

export default ({ address, coordinate }) => {
  if (!coordinate || !address) return null

  const center = [coordinate.lat, coordinate.lng]
  return (
    <div style={{ height: '40vh', width: '100%' }}>
      <GoogleMapReact
        options={() => ({
          fullscreenControl: false,
          zoomControl: false,
          disableDoubleClickZoom: true,
          minZoom: 19,
        })}
        center={center}
        zoom={11}>
        {/* @ts-ignore */}
        <Marker lat={coordinate.lat} lng={coordinate.lng} text={address} />
      </GoogleMapReact>
    </div>
  )
}
