import { ReactNode } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { Paper, Container, ContainerProps } from '@material-ui/core'
import { Typography } from 'modules/ui'

const useStyles = makeStyles(theme => ({
  root: {
    'display': 'flex',
    'flexDirection': 'column',
    'padding': theme.spacing(5),
    'margin': 'auto',
    '&.fullHeight': {
      // width: '95%',
      height: 'calc(100vh - 100px)',
    },
    '&.disablePadding': {
      padding: 0,
    },
  },
  content: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    height: '100%',
  },
  paperRoot: {
    'backgroundColor': theme.palette.background.paper,
    '&.disableBackground': {
      backgroundColor: 'unset',
    },
  },
}))

export type PageProps = {
  title?: string
  maxWidth?: ContainerProps['maxWidth']
  fullHeight?: boolean
  disablePadding?: boolean
  disableBackground?: boolean
  children: ReactNode
}

const Page = ({
  children,
  title = '',
  maxWidth = 'xl',
  fullHeight = false,
  disablePadding = false,
  disableBackground = false,
}: PageProps) => {
  const classes = useStyles()

  return (
    <Container maxWidth={maxWidth}>
      <Paper
        elevation={0}
        className={clsx(classes.root, { fullHeight, disablePadding })}
        classes={{ root: clsx(classes.paperRoot, { disableBackground }) }}>
        {!!title && <Typography variant="h5">{title}</Typography>}
        <div className={classes.content}>{children}</div>
      </Paper>
    </Container>
  )
}

export default Page
