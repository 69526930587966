import UIStepLabel from '@material-ui/core/StepLabel'
import { useT } from 'modules/i18n'

export const StepLabel = ({ children, ...props }) => {
  const t = useT()

  return <UIStepLabel {...props}>{t(children)}</UIStepLabel>
}

export default StepLabel
