import { useCallback, useRef, useState } from 'react'
import { Box, Divider, Grid, makeStyles } from '@material-ui/core'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  CheckboxButton,
  CheckboxButtonProps,
  Page,
  Typography,
  Button,
  Link,
  Checkbox,
} from 'modules/ui'
import { usePayment } from 'modules/payment'
import { PaymentItem, StartTransactionResult } from 'modules/api'
import { InvoiceDetailsForm, InvoiceDetailsFormValues } from 'modules/wallet'
import simplepayLogo from '../../../images/simplepay-logo.svg'

const TOKEN_PRICE = 999 // TODO config or API
const TAX_KEY = 0.27 // TODO config or API

// TODO should come from API
const OPTIONS = [
  {
    item: PaymentItem.Token10,
    tokens: 100,
    price: 10 * TOKEN_PRICE,
    discount: 0,
  },
  {
    item: PaymentItem.Token25,
    tokens: 250,
    price: 25 * TOKEN_PRICE * 0.8,
    discount: 20,
  },
  {
    item: PaymentItem.Token50,
    tokens: 500,
    price: 50 * TOKEN_PRICE * 0.7,
    discount: 30,
  },
]

const useOptionStyles = makeStyles(() => ({
  tokens: {
    width: 100,
  },
  price: {
    width: 120,
  },
  discount: {
    width: 60,
  },
}))

interface OptionProps extends CheckboxButtonProps {
  tokens: number
  price: number
  discount: number
}

const Option = ({ tokens, price, discount, ...props }: OptionProps) => {
  const classes = useOptionStyles()

  return (
    <Box marginBottom={1}>
      <CheckboxButton
        {...props}
        fullWidth
        variant={props.checked ? 'contained' : undefined}
        color="primary">
        <span className={classes.tokens}>{tokens} Token</span>
        <span className={classes.price}>{price}.- Ft</span>
        <span className={classes.discount}>{discount ? ` -${discount}%` : ''}</span>
      </CheckboxButton>
    </Box>
  )
}

const CardListItem = ({ text1, text2 }) => (
  <Box py={2} display="flex" justifyContent="space-between">
    <Typography variant="body1">{text1}</Typography>
    <Typography variant="body1" style={{ whiteSpace: 'nowrap' }}>
      {text2}
    </Typography>
  </Box>
)

const PurchaseToken = () => {
  const { startTransaction, loading } = usePayment()
  const [selectedOptionIndex, setSelectedOptionIndex] = useState<undefined | number>(1)
  const [isFormValid, setFormValid] = useState(false)
  const [termsAccepted, setTermsAccepted] = useState(false)
  const form = useRef()

  const formRef = useCallback(node => {
    if (!node) return

    setFormValid(node.dirty && node.isValid)
    form.current = node
  }, [])

  const handleSelect = setSelectedOptionIndex

  const handleClickNext = async () => {
    if (!form?.current) {
      return
    }

    // @ts-ignore
    await form?.current?.submitForm()
  }

  const handleSubmit = async (data: InvoiceDetailsFormValues) => {
    try {
      const queryParams = new URLSearchParams(window.location.search)
      const authToken = queryParams.get('auth-token')
      const { paymentUrl }: StartTransactionResult = unwrapResult(
        await startTransaction({
          items: [OPTIONS[selectedOptionIndex].item],
          invoice: data,
          url:
            window.location.origin +
            window.location.pathname +
            '/result' +
            (authToken ? '?authToken=' + authToken : ''),
          authToken,
        }),
      )

      document.location.href = paymentUrl
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Page title="Purchase token">
      <Grid spacing={2} container direction="row">
        <Grid item xs={12} lg={4}>
          <Box mt={3} ml={-1} mr={1}>
            <Typography variant="body1">
              Töltsd fel a tárcádat és lépj kapcsolatba új ügyfelekkel!
              <br />
              <br />
              Válassz az alábbi csomagok közül:
            </Typography>
            <Box mt={4} mb={4}>
              {OPTIONS.map((option, i) => (
                <Option
                  {...option}
                  key={i}
                  checked={selectedOptionIndex === i}
                  onClick={() => handleSelect(i)}
                />
              ))}
            </Box>
            <Typography>
              Áfa-val fizetendő összesen:{' '}
              {Math.round(OPTIONS[selectedOptionIndex].price * (1 + TAX_KEY))}
              .- Ft.
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} lg={8}>
          <Box my={2}>
            <Box mb={2}>
              <Typography variant="h6">
                Milyen ügyfélszerzési folyamatokra mennyi Token szükséges?
              </Typography>
            </Box>
            <Divider />
            <CardListItem text1="Üzenetek küldése és fogadása" text2="Ingyenes" />
            <Divider />
            <CardListItem text1="Organikus megkeresésekre való reagálás" text2="Ingyenes" />
            <Divider />
            <CardListItem
              text1="Potenciális ügyfelek célzott megszólítása az Esküvők listából"
              text2="10 Token"
            />
            <Divider />
            <CardListItem
              text1="Ajánlórendszeren keresztül érkezett megkeresésekre való ajánlatküldés"
              text2="10 Token"
            />
          </Box>
        </Grid>

        <Grid xs={12} md={6}>
          <Box my={2} mt={4}>
            <Typography variant="h6">Invoice details</Typography>
            <InvoiceDetailsForm
              initialValues={InvoiceDetailsForm.initialValues}
              innerRef={formRef}
              onSubmit={handleSubmit}
            />
          </Box>
        </Grid>

        <Grid xs={12}>
          <Box my={2}>
            <Checkbox
              checked={termsAccepted}
              onChange={() => setTermsAccepted(!termsAccepted)}
              label={
                <Typography variant="caption">
                  Tudomásul veszem, hogy a(z) Wedding Manager Kft (Heves, 3360 Hősök tere 1.)
                  adatkezelő által a(z) weddingmanager.hu felhasználói adatbázisában tárolt alábbi
                  személyes adataim átadásra kerülnek az OTP Mobil Kft., mint adatfeldolgozó
                  részére. Az adatkezelő által továbbított adatok köre az alábbi: Számlázási adatok.
                  Az adatfeldolgozó által végzett adatfeldolgozási tevékenység jellege és célja a
                  SimplePay Adatkezelési tájékoztatóban, az alábbi linken tekinthető meg:&nbsp;
                  <Link target="_blank" href="http://simplepay.hu/vasarlo-aff">
                    http://simplepay.hu/vasarlo-aff
                  </Link>
                </Typography>
              }
            />
          </Box>
        </Grid>

        <Box display="flex" justifyContent="space-between" width="100%" mt={2}>
          <a
            href="http://simplepartner.hu/PaymentService/Fizetesi_tajekoztato.pdf"
            target="_blank"
            rel="noreferrer">
            <img
              height="32"
              src={simplepayLogo}
              title=" SimplePay - Online bankkártyás fizetés"
              alt=" SimplePay vásárlói tájékoztató"
            />
          </a>
          <Button
            variant="contained"
            color="primary"
            disabled={
              !isFormValid || !termsAccepted || loading || selectedOptionIndex === undefined
            }
            onClick={handleClickNext}>
            Pay
          </Button>
        </Box>
      </Grid>
    </Page>
  )
}

export default PurchaseToken
