import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useT } from 'modules/i18n'
import {
  AcceptQuoteRequestDialog,
  UserNeedPartnerView,
  useQuote,
  QuoteResponseContactView,
} from 'modules/quote'
import { Page, PageHeader } from 'modules/ui'
import { useProfile } from 'modules/profile'

const View = ({ match }) => {
  const { quoteRequest } = useQuote()
  const history = useHistory()
  const t = useT()
  const [formData, setFormData] = useState({})
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const {
    user: { wallet },
  } = useProfile()

  const handleAcceptQuoteRequest = data => {
    setFormData(data)
    setIsDialogOpen(true)
  }

  const handleAcceptQuoteRequestDialogSuccess = () => {
    history.push('/quotes/sent')
  }

  const handleAcceptQuoteRequestDialogCancel = () => {
    setIsDialogOpen(false)
  }

  const handleRejectQuoteRequest = () => {
    history.push(`/quotes/${quoteRequest.id}/reject`)
  }

  const handleClickBack = () => {
    history.goBack()
  }

  if (!quoteRequest || quoteRequest.id !== match.params.id) {
    return null
  }

  return (
    <>
      <PageHeader
        description="This couple found your profile and sent a quote request directly"
        onClickBack={handleClickBack}
      />

      <Page maxWidth="lg">
        <UserNeedPartnerView
          questionResponses={quoteRequest.userNeedCopy?.questionResponses}
          couple={quoteRequest.couple}
          needName={quoteRequest.userNeedCopy?.need?.name || ''}
          createdAt={quoteRequest.createdAt}
          wedding={quoteRequest.weddingCopy}
          text={quoteRequest.text}
          quoteType={quoteRequest.type}
          attachments={quoteRequest.attachments || []}
          onClickBack={handleClickBack}
        />

        <QuoteResponseContactView
          balance={wallet.balance}
          quoteType={quoteRequest.type}
          onAcceptQuoteRequest={handleAcceptQuoteRequest}
          onRejectQuoteRequest={handleRejectQuoteRequest}
        />
      </Page>

      <AcceptQuoteRequestDialog
        formData={formData}
        open={isDialogOpen}
        onSuccess={handleAcceptQuoteRequestDialogSuccess}
        onCancel={handleAcceptQuoteRequestDialogCancel}
      />
    </>
  )
}

export default View
