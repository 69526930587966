import { useState } from 'react'
import { DataGrid } from '@material-ui/data-grid'
import DoneIcon from '@material-ui/icons/Done'
import ClearIcon from '@material-ui/icons/Clear'

const createColumns = ({ RowActions }) => [
  {
    field: 'name',
    headerName: 'Name',
    width: 220,
  },
  {
    field: 'priority',
    headerName: 'priority',
    width: 220,
  },
  {
    field: 'hide',
    headerName: 'Hide',
    width: 220,
    renderCell: ({ row }) => (row.hide ? <DoneIcon /> : <ClearIcon />),
  },
  {
    field: '',
    headerName: 'Actions',
    width: 140,
    sortable: false,
    disableClickEventBubbling: true,
    renderCell: RowActions,
  },
]

export default ({ tags, rowActionsComponent: RowActions, ...props }) => {
  const columns = createColumns({ RowActions })
  const [pageSize, setPageSize] = useState(25)

  return (
    <DataGrid
      rows={tags}
      columns={columns}
      pageSize={pageSize}
      onPageSizeChange={newPageSize => setPageSize(newPageSize)}
      rowsPerPageOptions={[25, 50, 100]}
      pagination
      {...props}
    />
  )
}
