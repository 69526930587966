import { ButtonProps, makeStyles } from '@material-ui/core'
import { CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons'
import { Button } from 'modules/ui'

const useStyles = makeStyles(() => ({
  root: {
    fontSize: 16,
  },
  children: {
    display: 'flex',
  },
}))

export interface CheckboxButtonProps extends ButtonProps {
  checked?: boolean
  label?: string
  name?: string
  value?: string
}

const CheckboxButton = ({
  children,
  checked = false,
  label,
  name,
  value,
  classes,
  ...props
}: CheckboxButtonProps) => {
  const styles = useStyles()

  return (
    <Button {...props} classes={styles}>
      {checked ? <CheckBox /> : <CheckBoxOutlineBlank />}
      <div className={styles.children}>{children}</div>
    </Button>
  )
}

export default CheckboxButton
