import { useRouteMatch } from 'react-router'
import { Box } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import NotificationsIcon from '@material-ui/icons/Notifications'
import AddIcon from '@material-ui/icons/Add'
import { Page, NavButton, useTable } from 'modules/ui'
import { UsersList, useUser } from 'modules/user'
import { getPaginationParams } from 'modules/app'

const RowActions = row => {
  const match = useRouteMatch()

  return (
    <Box display="flex" flexDirection="row">
      <NavButton to={`${match.url}/${row.id}/edit`} tooltip="Edit">
        <EditIcon fontSize="small" />
      </NavButton>
      <NavButton to={`${match.url}/${row.id}/delete`} tooltip="Delete">
        <DeleteIcon fontSize="small" />
      </NavButton>
      <NavButton
        to={{
          pathname: 'notifications/send',
          state: {
            target: 'single-user',
            targetUser: { name: row.name, id: row.id },
          },
        }}
        tooltip="Send Notification">
        <NotificationsIcon fontSize="small" />
      </NavButton>
    </Box>
  )
}

const List = () => {
  const match = useRouteMatch()
  const { users, total } = useUser()
  const { handleChangePage, handleChangeRowsPerPage, handleSearch, handleSort } = useTable()
  const { pageSize, page, filter, sortBy, sortDirection } = getPaginationParams()

  return (
    <Page fullHeight>
      <UsersList
        title="Users"
        users={users}
        rowActionsComponent={RowActions}
        pageSize={pageSize}
        total={total}
        page={page}
        filter={filter}
        sortBy={sortBy}
        sortDirection={sortDirection}
        toggleSort={handleSort}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleSearch={handleSearch}
      />

      <Box display="flex" justifyContent="flex-end" p={1}>
        <NavButton to={`${match.url}/new`} tooltip="New">
          <AddIcon />
        </NavButton>
      </Box>
    </Page>
  )
}

export default List
