import { pick } from 'rambda'
import { takeLatestAsync } from 'saga-toolkit'
import * as actions from './slice'
import { put } from 'redux-saga/effects'
import { mutation, query } from 'modules/api'
import { actions as appActions } from 'modules/app'

const conversationIssues = [
  {
    conversation: ['id', 'name', { participants: ['id', 'name'] }, 'createdAt'],
  },
  'issues',
]

const parseAdmin = admin => ({
  ...admin,
  profileImage: admin.profileImage?.name,
})

function* fetchHealthCheck() {
  const { healthCheck } = yield query(
    {
      operation: 'healthCheck',
      fields: [
        {
          conversationIssues,
        },
      ],
    },
    true,
  )

  return healthCheck
}

function* updateAdmin({ meta }) {
  const { id } = meta.arg
  const body = pick(['name', 'profileImage', 'phone', 'email'], meta.arg)
  const options = {
    operation: 'updateAdmin',
    fields: ['id'],
    variables: {
      id: {
        value: id,
        type: 'ID',
      },
      input: {
        value: body,
        type: 'AdminInput',
      },
    },
  }

  const { updateAdmin } = yield mutation(options, true)

  yield put(appActions.showSnackMessage('Updated!'))

  return parseAdmin(updateAdmin)
}

function* fixConversationIssues() {
  const options = {
    operation: 'fixConversationIssues',
    fields: conversationIssues,
  }

  const { fixConversationIssues } = yield mutation(options, true)

  return fixConversationIssues
}

export default [
  takeLatestAsync(actions.updateAdmin.type, updateAdmin),
  takeLatestAsync(actions.fetchHealthCheck.type, fetchHealthCheck),
  takeLatestAsync(actions.fixConversationIssues.type, fixConversationIssues),
]
