import * as yup from 'yup'

const passwordValidationSchema = yup
  .string()
  .required('Password is required')
  .matches(
    /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
    'Your password must be at least 8 characters long, must contain one uppercase, one lowercase character and one number',
  )

export default passwordValidationSchema
