import { useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'
import { makeStyles } from '@material-ui/core'
import { Loader, Typography, Button } from 'modules/ui'
import { uploadToS3 } from 'modules/storage'
import { useProfile } from 'modules/profile'
import { useNeed } from 'modules/need'
import { useQuote } from 'modules/quote'
import { QuoteType } from 'modules/api'
import { unwrapResult } from '@reduxjs/toolkit'

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(1),
  },
  bold: {
    fontWeight: 800,
  },
  tokens: { display: 'flex', marginTop: theme.spacing(2), justifyContent: 'center' },
}))

const SendQuoteDialog = props => {
  const classes = useStyles()
  const { userNeed } = useNeed()
  const { partnerQuote } = useQuote()
  const {
    user: { id: partnerId, wallet },
  } = useProfile()
  const [loading, setLoading] = useState(false)

  const handleSend = async () => {
    setLoading(true)
    const { text, attachments, sendEmail } = props.formData
    const filesToUpload = attachments.filter(attachment => attachment instanceof File)
    const tasks = filesToUpload.map(async file => {
      return {
        hash: await uploadToS3(file),
        name: file.name,
      }
    })
    const newFiles = await Promise.all(tasks)
    const { couple, ...weddingCopy } = userNeed.wedding

    const quoteRequestInput = {
      text,
      partnerId,
      coupleId: userNeed.wedding.couple.id,
      userNeedCopy: {
        questionResponses: userNeed.questionResponses.map(qr =>
          qr.map(({ question, ...rest }) => rest),
        ),
        need: userNeed.need.id,
      },
      weddingCopy: {
        ...weddingCopy,
        locationTags: weddingCopy.locationTags.map(tag => tag.id),
      },
      type: QuoteType.Wall,
      category: userNeed.category,
      requestId: '',
      attachments: [...attachments.filter(attachment => !attachment instanceof File), ...newFiles],
      sendEmail: sendEmail || false,
    }

    unwrapResult(await partnerQuote({ quoteRequestInput }))

    setLoading(false)
    props.onSuccess()
  }

  const getDialogStatus = () => {
    return wallet.balance >= 10
      ? {
          text: (
            <>
              <Typography>
                <Typography display="inline" variant="body1">
                  {'This will cost you: '}
                </Typography>
                <Typography display="inline" className={classes.bold}>
                  10 tokens
                </Typography>
              </Typography>
            </>
          ),
          status: 'acceptable',
        }
      : {
          text: (
            <Typography tParams={{ couple: userNeed.wedding.couple.name }} variant="body1">
              {
                "You don't have enough tokens to accept quote from {{couple}}. You need at least 10 tokens to accept a quote."
              }
            </Typography>
          ),
          status: 'unacceptable',
        }
  }

  if (!userNeed) {
    return null
  }

  const { text, status } = getDialogStatus()
  return (
    <Dialog fullWidth maxWidth="sm" open={props.open} onClose={props.onCancel}>
      <DialogTitle id="form-dialog-title">
        <Typography
          tParams={{
            couple: userNeed.wedding.couple.name,
          }}>
          {'You are about to contact the following couple: {{couple}}!'}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {text}
          <Typography variant="body1" className={classes.tokens}>
            <Typography tParams={{ tokens: wallet.balance }} className={classes.bold}>
              {'Available tokens: {{tokens}}'}
            </Typography>
            <MonetizationOnIcon className={classes.icon} />
          </Typography>
        </DialogContentText>
        <Loader loading={loading} />
      </DialogContent>
      <DialogActions>
        <Button size="small" onClick={props.onCancel}>
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          disabled={loading || status !== 'acceptable'}
          size="small"
          onClick={handleSend}>
          Send
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SendQuoteDialog
