import { uploadToS3 } from 'modules/storage'

const processAdminFormSubmit = async profile => {
  const { profileImage } = profile
  const uploadProfilePictureIfNecessary = async dataProfileImage => {
    if (!dataProfileImage) return ''

    if (dataProfileImage instanceof File) {
      return await uploadToS3(dataProfileImage)
    }
  }

  const parsedProfile = {
    ...profile,
    profileImage: await uploadProfilePictureIfNecessary(profileImage),
  }

  return parsedProfile
}

export default processAdminFormSubmit
