import { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { Table } from 'modules/ui'

export type NeedsListProps = any & {
  needs: any
  imagesPath: string
  rowActionsComponent: ReactNode
  total: number
}

export const NeedsList = ({
  needs = [],
  imagesPath,
  rowActionsComponent: RowActions,
  total = 0,
  ...props
}: NeedsListProps) => {
  const columns = createColumns(RowActions)

  return <Table data={needs} columns={columns} totalElements={total} {...props} />
}

const createColumns = RowActions => [
  {
    field: 'name',
    headerName: 'Name',
    width: 220,
    renderCell: row => <Link to={`/needs/${row.id}/edit`}>{row.name}</Link>,
    sortable: true,
  },
  {
    field: 'updatedAt',
    headerName: 'Last update',
    width: 200,
    sortable: true,
    renderCell: row => moment(row.updatedAt).format('YYYY-MM-DD HH:mm'),
  },
  {
    field: '',
    headerName: 'Actions',
    width: 140,
    sortable: false,
    disableClickEventBubbling: true,
    renderCell: RowActions,
  },
]
