import { useRouteMatch } from 'react-router'
import AddIcon from '@material-ui/icons/Add'
import { Box } from '@material-ui/core'
import { NavButton } from 'modules/ui'
import { ItemsList, StatusPicker, useItem } from 'modules/item'
import { Page, RowActions } from 'modules/ui'

export default () => {
  const { items, loading, fetchItems, total, limit, offset, setOffset, setStatus, status } =
    useItem()
  const match = useRouteMatch()

  return (
    <Page title="Items" fullHeight>
      <StatusPicker
        onChange={e => {
          setStatus(e.target.value)
          setOffset(0)
          fetchItems()
        }}
        value={status}
      />
      <ItemsList
        items={items}
        loading={loading}
        rowActionsComponent={RowActions}
        fetchItems={fetchItems}
        total={total}
        setOffset={setOffset}
        offset={offset}
        limit={limit}
      />
      <Box display="flex" justifyContent="flex-end" p={1}>
        <NavButton to={`${match.url}/new`} tooltip="New">
          <AddIcon />
        </NavButton>
      </Box>
    </Page>
  )
}
