import clsx from 'clsx'
import moment from 'moment'
import { Box, Chip, makeStyles } from '@material-ui/core'
import { Pagination } from '@material-ui/lab'
import ConfirmationNumberOutlinedIcon from '@material-ui/icons/ConfirmationNumberOutlined'
import { useT } from 'modules/i18n'
import { Button, HelpField, Typography } from 'modules/ui'
import { QuoteType, QuoteRequestStatus, QuoteRequest } from 'modules/api'

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    minWidth: 1024,
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: spacing(3),
    marginBottom: spacing(4),
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: spacing(3),
    padding: `0 ${spacing(2)}px`,
    height: spacing(9),
    border: '1px solid lightGrey',
    borderRadius: spacing(2),
    backgroundColor: 'white',
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 36,
    height: 36,
    backgroundColor: palette.primary.main,
    borderRadius: '50%',
    marginRight: spacing(1),
  },
  icon: {
    color: 'white',
  },
  text: {
    width: '12%',
    color: 'grey',
    fontSize: 14,
  },
  buttonsContainer: {
    width: '30%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  button: {
    'height': 40,
    'borderRadius': 4,
    'fontSize': 16,
    'color': 'white',
    'backgroundColor': palette.primary.main,
    '&:hover': {
      backgroundColor: palette.primary.main,
    },
    'textTransform': 'none',
    'marginRight': spacing(1),
  },
  hidden: {
    visibility: 'hidden',
  },
  chipContainer: {
    width: '20%',
    textAlign: 'center',
  },
  chip: {
    color: 'white',
    fontWeight: 700,
    marginRight: spacing(1),
  },
  date: {
    width: '15%',
  },
}))

function formatRequestType(type) {
  switch (type) {
    case QuoteType.ForYou:
      return 'By recommendation'
    case QuoteType.Wall:
      return 'From all quote requests'
    case QuoteType.Direct:
      return 'Direct quote request'
  }
}

export interface SentQuoteRequestsProps {
  quoteRequests: QuoteRequest[]
  loading: boolean
  numberOfPages: number
  page: number
  onViewClick: (quoteRequestId: string) => void
  onConversationClick: (conversationId: string) => void
  onChangePage: (page: number) => void
}

const SentQuoteRequests = ({
  quoteRequests,
  loading,
  numberOfPages,
  page,
  onViewClick,
  onConversationClick,
  onChangePage,
}: SentQuoteRequestsProps) => {
  const t = useT()
  const classes = useStyles()

  const pagination = (
    <Box className={classes.paginationContainer}>
      <Pagination
        color="primary"
        page={page}
        count={numberOfPages}
        onChange={(_e, p) => onChangePage(p)}
      />
    </Box>
  )

  return (
    <Box className={classes.root}>
      <HelpField text="Here you can see the quotes sent out." />
      {pagination}
      {!loading &&
        quoteRequests.map(qr => (
          <Box key={qr.id} className={classes.listItem}>
            <Box className={classes.iconContainer}>
              <ConfirmationNumberOutlinedIcon className={classes.icon} />
            </Box>
            <Typography className={classes.text}>{qr.couple.name}</Typography>
            <Typography className={classes.text}>
              {qr.weddingCopy.date
                ? moment(qr.weddingCopy.date).format('YYYY.MM.DD.')
                : qr.weddingCopy.possibleYear}
            </Typography>
            <Typography className={classes.text}>{formatRequestType(qr.type)}</Typography>
            <Typography className={classes.text}>
              {qr.status === QuoteRequestStatus.Accepted ? 'Quote response is created' : 'Rejected'}
            </Typography>
            <Box className={classes.chipContainer}>
              <Chip
                className={classes.chip}
                label={t(qr.quoteResponse.seenAt ? 'Already seen' : 'Not yet seen')}
                size="small"
                style={{ backgroundColor: qr.quoteResponse.seenAt ? '#59de74' : '#dea01d' }}
              />
            </Box>
            <Typography className={clsx(classes.text, classes.date)}>
              {t('Sent at')}: {moment(qr.quoteResponse.createdAt).format('YYYY.MM.DD. HH:mm')}
            </Typography>
            <Box className={classes.buttonsContainer}>
              <Button
                className={clsx(
                  classes.button,
                  qr.status === QuoteRequestStatus.Rejected && classes.hidden,
                )}
                onClick={() => onViewClick(qr.id)}>
                View
              </Button>
              <Button
                className={clsx(
                  classes.button,
                  qr.status === QuoteRequestStatus.Rejected && classes.hidden,
                )}
                onClick={() => onConversationClick(qr.conversation.id)}>
                Conversation
              </Button>
            </Box>
          </Box>
        ))}
    </Box>
  )
}

export default SentQuoteRequests
