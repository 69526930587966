import { pick } from 'rambda'
import { takeLatestAsync } from 'saga-toolkit'
import { select } from '@redux-saga/core/effects'
import * as actions from './slice'
import { query, mutation } from 'modules/api'
import * as selectors from './selectors'
import { parseItem } from './parsers'

const itemField = [
  'id',
  'name',
  'status',
  'active',
  { coverImage: ['name'] },
  { partner: ['id', 'name'] },
  { tags: ['id', 'name'] },
  { gallery: ['name', { image: ['name'] }] },
  'likes',
  'views',
  'description',
]

function* fetchItems() {
  const limit = yield select(selectors.selectLimit)
  const offset = yield select(selectors.selectOffset)
  const partnerId = yield select(selectors.selectPartner)
  const status = yield select(selectors.selectStatus)

  const options = {
    operation: 'items',
    fields: [
      'total',
      {
        elements: [
          'id',
          { coverImage: ['name'] },
          'status',
          'name',
          'active',
          'likes',
          'views',
          'createdAt',
          { partner: ['id', 'name'] },
        ],
      },
    ],
    variables: {
      filter: {
        value: {
          limit,
          offset,
          partnerId,
          ...(status === 'all' ? {} : { status }),
        },
        type: 'ItemFilter!',
      },
    },
  }

  const { items } = yield query(options, true)

  return {
    ...items,
    items: items.elements.map(parseItem),
  }
}

function* fetchItem({ meta }) {
  const { id } = meta.arg
  const options = {
    operation: 'item',
    fields: itemField,
    variables: {
      id: {
        type: 'ID',
        value: id,
        required: true,
      },
    },
  }

  const { item } = yield query(options, true)

  return parseItem(item)
}

function* createItem({ meta }) {
  const tagIdList = meta.arg.tags.map(item => item.id)
  const data = { ...meta.arg, tags: tagIdList }
  const options = {
    operation: 'insertItem',
    fields: ['id'],
    variables: {
      input: {
        value: data,
        type: 'ItemInput!',
      },
    },
  }
  const result = yield mutation(options, true)

  return result.insertItem
}

function* updateItem({ meta }) {
  const tagIdList = meta.arg.tags.map(item => item.id)
  const data = { ...meta.arg, tags: tagIdList }
  const body = pick(
    ['name', 'status', 'active', 'partner', 'tags', 'gallery', 'coverImage', 'description', 'id'],
    data,
  )
  const options = {
    operation: 'updateItem',
    fields: itemField,
    variables: {
      input: {
        value: body,
        type: 'ItemInput!',
      },
    },
  }

  const { updateItem } = yield mutation(options, true)

  return parseItem(updateItem)
}

function* removeItem({ meta }) {
  const { id } = meta.arg
  const options = {
    operation: 'deleteItem',
    variables: {
      id: {
        value: id,
        type: 'ID!',
      },
    },
  }

  yield mutation(options, true)

  return id
}

export default [
  takeLatestAsync(actions.fetchItems.type, fetchItems),
  takeLatestAsync(actions.fetchItem.type, fetchItem),
  takeLatestAsync(actions.createItem.type, createItem),
  takeLatestAsync(actions.updateItem.type, updateItem),
  takeLatestAsync(actions.removeItem.type, removeItem),
]
