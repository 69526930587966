import { useHistory } from 'react-router'
import Grid from '@material-ui/core/Grid'
import { Page } from 'modules/ui'
import { useHighlight, HighlightForm } from 'modules/highlight'

const New = () => {
  const { highlights, createHighlight, loading } = useHighlight()
  const history = useHistory()

  const handleSubmit = async data => {
    const elementList = data.elements.map(element => ({
      id: element.element.id,
      order: element.order,
    }))
    const { payload } = await createHighlight({ ...data, elements: elementList })
    history.push(`${payload.id}/edit`)
  }

  if (loading) {
    return 'Loading...'
  }

  return (
    <Page title="New Highlight">
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={6} md={4}>
          <HighlightForm higlights={highlights} onSubmit={handleSubmit} />
        </Grid>
      </Grid>
    </Page>
  )
}

export default New
