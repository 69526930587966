import moment from 'moment'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import PeopleIcon from '@material-ui/icons/People'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import Tooltip from '@material-ui/core/Tooltip'
import Chip from '@material-ui/core/Chip'
import NoteIcon from '@material-ui/icons/Note'
import { Avatar, Typography } from 'modules/ui'
import ImageAttachment from './ImageAttachment'
import config from 'modules/config'

const imagesPath = config.app.IMAGES_URL

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    'backgroundColor': palette.background.paper,
    'display': 'flex',
    'margin': spacing(1),
    '& img': {
      borderRadius: spacing(1),
    },
  },
  chip: {
    padding: spacing(1),
  },
  primary: {
    justifyContent: 'flex-end',
  },
  secondary: {
    justifyContent: 'flex-start',
  },
  avatar: {
    marginRight: spacing(1),
  },

  messagePrimary: {
    backgroundColor: palette.primary.main,
    color: palette.primary.contrastText,
    textAlign: 'start',
    justifyContent: 'flex-start',
    whiteSpace: 'pre-line',
  },
  messageSecondary: {
    backgroundColor: palette.grey.main,
    color: 'black',
    textAlign: 'start',
    justifyContent: 'flex-end',
    whiteSpace: 'pre-line',
  },
  messageContainer: {
    display: 'inline-flex',
    flexDirection: 'column',
    borderRadius: '16px',
    minHeight: '32px',
    paddingLeft: '10px',
    paddingRight: '10px',
    paddingTop: '5px',
    paddingBottom: '5px',
    alignItems: 'center',
    fontSize: '0.825rem',
    verticalAlign: 'middle',
    justifyContent: 'center',
    width: 'auto',
    lineHeight: 1.25,
  },
  unsend: {
    opacity: 0.8,
  },
  errorIcon: {
    alignSelf: 'center',
  },
}))

const messageLinkRegex =
  /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/

const QuoteChip = ({ label, tParams, ...props }) => (
  <Chip
    icon={<NoteIcon />}
    label={<Typography tParams={tParams}>{label}</Typography>}
    clickable
    color="primary"
    {...props}
  />
)

const renderText = text => {
  return text?.split(' ').map((item, i) => {
    if (item.match(messageLinkRegex)) {
      const destination = item.startsWith('http') ? item : 'http://' + item

      return (
        <a key={i} href={destination} target="_blank" rel="noopener noreferrer">
          {item + ' '}
        </a>
      )
    }

    return item + ' '
  })
}

const Message = ({ text, attachment, sender, createdAt, userId, id, error, onClickAttachment }) => {
  const classes = useStyles()
  const isOwnMessage = sender.id === userId
  const unsend = !id
  const isQuote =
    attachment?.type === 'QUOTE_RESPONSE' ||
    attachment?.type === 'QUOTE_RESPONSE_FEEDBACK' ||
    attachment?.type === 'QUOTE_REQUEST' ||
    attachment?.type === 'QUOTE_SEEN'

  return (
    <div className={clsx(classes.root, isOwnMessage ? classes.primary : classes.secondary)}>
      {!isOwnMessage && (
        <div className={classes.avatar}>
          <Tooltip placement="left" title={sender.name}>
            <div>
              <Avatar src={imagesPath + '/' + sender.image.name}>
                <PeopleIcon />
              </Avatar>
            </div>
          </Tooltip>
        </div>
      )}
      {isOwnMessage && error && (
        <Tooltip title="Hiba történt!" placement="top">
          <ErrorOutlineIcon color="error" className={classes.errorIcon} />
        </Tooltip>
      )}
      <Tooltip title={moment(createdAt).format('LLL')} placement="right">
        <div>
          {!isQuote && (
            <div
              className={clsx(
                isOwnMessage ? classes.messagePrimary : classes.messageSecondary,
                classes.messageContainer,
                unsend && classes.unsend,
              )}>
              {!!text && (
                <Typography
                  display="inline"
                  className={isOwnMessage ? classes.messagePrimary : classes.messageSecondary}>
                  {renderText(text)}
                </Typography>
              )}
              {attachment?.type === 'image' && (
                <ImageAttachment
                  src={imagesPath + '/' + attachment.payload}
                  className={unsend && classes.unsend}
                />
              )}
              {attachment?.type === 'html' && (
                <div
                  className={unsend && classes.unsend}
                  dangerouslySetInnerHTML={{ __html: attachment.payload }}
                />
              )}
            </div>
          )}
          {isQuote && (
            <>
              {attachment?.type === 'QUOTE_RESPONSE' && (
                <QuoteChip
                  label="Quote response is created"
                  className={classes.chip}
                  onClick={() => onClickAttachment(attachment)}
                />
              )}
              {attachment?.type === 'QUOTE_RESPONSE_FEEDBACK' && (
                <QuoteChip
                  label="Feedback received: {{text}}"
                  tParams={{ text: JSON.parse(attachment.payload).feedback.option }}
                  className={classes.chip}
                  onClick={() => onClickAttachment(attachment)}
                />
              )}
              {attachment?.type === 'QUOTE_REQUEST' && (
                <QuoteChip
                  label="Quote request is received"
                  className={classes.chip}
                  onClick={() => onClickAttachment(attachment)}
                />
              )}
              {attachment?.type === 'QUOTE_SEEN' && (
                <QuoteChip
                  label="Response seen by couple"
                  className={classes.chip}
                  onClick={() => onClickAttachment(attachment)}
                />
              )}
            </>
          )}
        </div>
      </Tooltip>
    </div>
  )
}

export default Message
