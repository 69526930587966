import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'
import { createSagaAction } from 'saga-toolkit'

const name = 'item'

export const itemsAdapter = createEntityAdapter()

const initialState = {
  loading: false,
  error: null,
  items: itemsAdapter.getInitialState(),
  item: null,
  total: 0,
  limit: 50,
  offset: 0,
  partner: '',
  status: 'all',
}

export const fetchItems = createSagaAction(`${name}/fetchItems`)
export const fetchItem = createSagaAction(`${name}/fetchItem`)
export const createItem = createSagaAction(`${name}/createItem`)
export const updateItem = createSagaAction(`${name}/updateItem`)
export const removeItem = createSagaAction(`${name}/removeItem`)
export const removeGalleryImage = createSagaAction(`${name}/removeGalleryImage`)

const handlePending = state => ({
  ...state,
  loading: true,
})

const handleRejected = (state, { error }) => ({
  ...state,
  error,
  loading: false,
})

const slice = createSlice({
  name,
  initialState,
  reducers: {
    clearItem: () => initialState,
    setOffset: (state, { payload }) => ({
      ...state,
      offset: payload,
    }),
    setPartner: (state, { payload }) => ({
      ...state,
      partner: payload,
    }),
    setStatus: (state, { payload }) => ({
      ...state,
      status: payload,
    }),
  },
  extraReducers: {
    // items
    [fetchItems.pending]: handlePending,
    [fetchItems.fulfilled]: (state, { payload }) => {
      itemsAdapter.setAll(state.items, payload.items)
      state.loading = false
      state.total = payload.total
    },
    [fetchItems.rejected]: handleRejected,

    //item
    [fetchItem.pending]: handlePending,
    [fetchItem.fulfilled]: (state, { payload }) => ({
      ...state,
      item: payload,
      loading: false,
    }),
    [fetchItem.rejected]: handleRejected,

    // createItem
    [createItem.pending]: handlePending,
    [createItem.fulfilled]: state => ({
      ...state,
      loading: false,
    }),
    [createItem.rejected]: handleRejected,

    // updateItem
    [updateItem.pending]: handlePending,
    [updateItem.fulfilled]: (state, { payload }) => {
      itemsAdapter.updateOne(state.items, { id: payload.id, changes: payload })
      state.item = { ...state.item, ...payload }
      state.loading = false
    },
    [updateItem.rejected]: handleRejected,

    // removeGalleryImage
    [removeGalleryImage.pending]: handlePending,
    [removeGalleryImage.fulfilled]: (state, { meta }) => {
      const { imageId } = meta.arg

      delete state.item.galleries[0].images[imageId]
      state.loading = false

      return state
    },
    [removeGalleryImage.rejected]: handleRejected,
  },
})

export const { clearItem, setOffset, setPartner, clearPartner, setStatus } = slice.actions

export default slice.reducer
