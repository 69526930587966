import { createSlice } from '@reduxjs/toolkit'
import { createSagaAction } from 'saga-toolkit'

const name = 'profile'

const initialState = {
  loading: false,
  error: null,
  user: null,
  browserId: '',
}

export const fetchProfile = createSagaAction(`${name}/fetchProfile`)
export const updateProfile = createSagaAction(`${name}/updateProfile`)
export const deleteProfile = createSagaAction(`${name}/deleteProfile`)
export const registerDevice = createSagaAction(`${name}/registerDevice`)
export const signOut = createSagaAction(`${name}/signOut`)
export const createIdForBrowser = createSagaAction(`${name}/createIdForBrowser`)

const handlePending = state => ({
  ...state,
  loading: true,
})

const handleRejected = (state, { error }) => ({
  ...state,
  error,
  loading: false,
})

const slice = createSlice({
  name,
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: {
    // profile
    [fetchProfile.pending]: handlePending,
    [fetchProfile.fulfilled]: (state, { payload }) => ({
      ...state,
      user: payload,
      loading: false,
    }),
    [fetchProfile.rejected]: handleRejected,

    // update profile
    [updateProfile.pending]: handlePending,
    [updateProfile.fulfilled]: (state, { payload }) => ({
      ...state,
      user: {
        ...state.user,
        ...payload,
      },
      loading: false,
    }),
    [updateProfile.rejected]: handleRejected,

    // deleteProfile profile
    [deleteProfile.pending]: handlePending,
    [deleteProfile.fulfilled]: () => initialState,
    [deleteProfile.rejected]: handleRejected,

    // register device
    [registerDevice.pending]: handlePending,
    [registerDevice.fulfilled]: state => ({
      ...state,
      deviceRegistered: true,
      loading: false,
    }),
    [registerDevice.rejected]: handleRejected,
    // createIdForBrowser
    [createIdForBrowser.fulfilled]: (state, { payload }) => ({
      ...state,
      browserId: payload,
    }),
  },
})

export const { reset } = slice.actions

export default slice.reducer
