import { useHistory } from 'react-router-dom'
import { Button, Grid, withStyles, makeStyles } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import { Typography } from 'modules/ui'

const useStyles = makeStyles(theme => ({
  welcomeContainer: {
    position: 'fixed',
    left: 0,
    top: 0,
    zIndex: 10,
    background: 'linear-gradient(135deg, rgba(247,56,97,1) 0%, rgba(254,113,44,1) 100%)',
    width: '100vw',
    height: '100vh',
  },
  loaderImage: {
    width: 'auto',
    height: 'auto',
    maxWidth: '450px',
    maxHeight: '450px',
  },
  text: {
    color: theme.palette.background.paper,
  },
  spacing: {
    marginRight: theme.spacing(2),
  },
  partner: {
    marginBottom: theme.spacing(7),
    marginTop: '-20px',
  },
}))

const WhiteButton = withStyles(theme => ({
  root: {
    'color': theme.palette.getContrastText(grey[50]),
    'textTransform': 'none',
    'backgroundColor': grey[50],
    '&:hover': {
      backgroundColor: grey[200],
    },
  },
}))(Button)

const Welcome = () => {
  const classes = useStyles()
  const history = useHistory()
  return (
    <Grid
      container
      className={classes.welcomeContainer}
      justifyContent="center"
      alignItems="center">
      <Grid container justifyContent="center" alignItems="center" direction="column">
        <Grid container item justifyContent="center">
          <img className={classes.loaderImage} alt="logo" src="/wm-text.png" />
        </Grid>
        <Grid item className={classes.partner}>
          <Typography variant="h3" className={classes.text}>
            PARTNER
          </Typography>
        </Grid>
        <Grid container direction="row" alignItems="center" justifyContent="center">
          <Grid item>
            <WhiteButton onClick={() => history.push('/sign-in')} className={classes.spacing}>
              Bejelentkezés
            </WhiteButton>
          </Grid>
          <Grid item>
            <WhiteButton onClick={() => history.push('/sign-up')}>Regisztráció</WhiteButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid>
        <Typography variant="body1" className={classes.text}>
          Ⓒ {new Date().getFullYear()} Wedding Manager Kft.
        </Typography>
      </Grid>
    </Grid>
  )
}

export default Welcome
