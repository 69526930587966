import { useEffect, useState } from 'react'
import { useCategory } from 'modules/category'
import { Autocomplete /*, useIsMount*/ } from 'modules/ui'
import { useTag } from '../hooks'

const TagAutocomplete = ({ categoryId, ...props }) => {
  const { fetchCategory } = useCategory()
  const { searchTag } = useTag()
  const [open, setOpen] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState([])
  // const isMountRef = useIsMount()

  const handleInputChange = (e, value) => {
    setSearchTerm(value)
  }

  useEffect(() => {
    let mounted = true

    ;(async () => {
      setLoading(true)
      setOptions([])

      let options

      if (categoryId) {
        const { payload } = await fetchCategory({ id: categoryId })
        options = payload?.tags
      } else {
        const { payload } = await searchTag(searchTerm)
        options = payload
      }

      if (!mounted) {
        return
      }

      setOptions(options)
      setLoading(false)
    })()

    return () => {
      mounted = false
    }
  }, [categoryId])

  useEffect(() => {
    if (categoryId) {
      return // TODO
    }

    ;(async () => {
      setLoading(true)
      setOptions([])

      const { payload } = await searchTag(searchTerm)

      setOptions(payload)
      setLoading(false)
    })()
  }, [searchTerm])

  useEffect(() => {
    if (!open) {
      setSearchTerm('')
      return
    }
  }, [open])

  return (
    <Autocomplete
      {...props}
      options={options}
      open={open}
      loading={loading}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      onInputChange={handleInputChange}
      getOptionSelected={(option, value) => option.id === value.id}
      getOptionLabel={option => option.name}
    />
  )
}

export default TagAutocomplete
