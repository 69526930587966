import { DataGrid } from '@material-ui/data-grid'

const statusMap = {
  ACCEPTED: 'Accepted',
  PENDING: 'Pending',
  REJECTED: 'Rejected',
}

const createColumns = () => [
  {
    field: 'name',
    headerName: 'Name',
    width: 220,
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 140,
    valueGetter: ({ row }) => statusMap[row.status],
  },
]

export const RecommendedPartnersList = ({ items = [], ...props }) => {
  const columns = createColumns()

  return (
    <DataGrid
      rows={items}
      columns={columns}
      pagination
      pageSize={items.length}
      rowCount={items.length}
      rowHeight={90}
      {...props}
    />
  )
}
