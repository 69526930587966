import { useHistory } from 'react-router'
import { Container, Box } from '@material-ui/core'
import { ArrowBack } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Page, Typography } from 'modules/ui'
import { QuoteResponseForm, RejectQuoteRequestForm, useQuote } from 'modules/quote'
import QuoteResponseStatusChip from 'modules/quote/components/QuoteResponseStatusChip'
import QuoteTypeBadge, {
  getDefaultQuoteTypeBadgeProps,
} from 'modules/quote/components/QuoteTypeBadge'
import { QuoteRequestStatus } from 'modules/api'
import { useT } from 'modules/i18n'

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    padding: spacing(2),
    width: '100%',
  },
  backButton: {
    backgroundColor: 'white',
    borderRadius: spacing(4),
    border: '1px solid lightGrey',
    padding: spacing(1.5),
    paddingRight: spacing(3),
    paddingLeft: spacing(3),
    marginRight: spacing(3),
    alignContent: 'center',
    justifyContent: 'center',
    marginLeft: spacing(3),
    marginBottom: spacing(2),
  },
  arrowBack: {
    marginRight: spacing(1),
    color: palette.primary.main,
  },
  quoteTypeBadge: {
    marginRight: spacing(1),
  },
}))

const ViewResponse = ({ match }) => {
  const classes = useStyles()
  const t = useT()
  const history = useHistory()
  const { quoteRequest } = useQuote()

  if (!quoteRequest || !quoteRequest?.quoteResponse || quoteRequest.id !== match.params.id) {
    return null
  }

  const { quoteResponse } = quoteRequest
  const quoteTypeBadgeProps = getDefaultQuoteTypeBadgeProps(quoteRequest.type)

  return (
    <Container maxWidth="lg" className={classes.root}>
      <Button
        className={classes.backButton}
        onClick={() => {
          history.goBack()
        }}>
        <ArrowBack className={classes.arrowBack} /> {t('Back')}
      </Button>
      <Page>
        <Box display="flex" justifyContent="flex-start" mb={2}>
          <QuoteTypeBadge {...quoteTypeBadgeProps} className={classes.quoteTypeBadge} />
          {quoteResponse && <QuoteResponseStatusChip status={quoteResponse.status} />}
        </Box>

        <Typography variant="h6">Contact us</Typography>

        {quoteResponse?.status === QuoteRequestStatus.Accepted && (
          <QuoteResponseForm
            readonly
            initialValues={{
              text: quoteResponse.text,
              attachments: quoteResponse.attachments,
            }}
          />
        )}

        {quoteResponse?.feedback && (
          <Box mt={4}>
            <Typography variant="h6">Feedback from the couple</Typography>
            <Typography variant="body1">{quoteResponse?.feedback.option}</Typography>
          </Box>
        )}

        {quoteResponse?.status === QuoteRequestStatus.Rejected && (
          <RejectQuoteRequestForm
            readonly
            initialValues={{
              text: quoteResponse.text,
              reasons: quoteResponse.reasons,
            }}
          />
        )}
      </Page>
    </Container>
  )
}

export default ViewResponse
