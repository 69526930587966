import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { slice as app } from 'modules/app'
import { slice as auth } from 'modules/auth'
import { slice as category } from 'modules/category'
import { slice as chat } from 'modules/chat'
import { slice as tagGroup } from 'modules/tagGroup'
import { slice as item } from 'modules/item'
import { slice as notification } from 'modules/notification'
import { slice as tag } from 'modules/tag'
import { slice as user } from 'modules/user'
import { slice as partner } from 'modules/partner'
import { slice as blog } from 'modules/blog'
import { slice as filterGroup } from 'modules/filter-group'
import { slice as highlight } from 'modules/highlight'
import { slice as profile } from 'modules/profile'
import { slice as tracking } from 'modules/tracking'
import { slice as inspiration } from 'modules/inspiration'
import { slice as cover } from 'modules/cover'
import { slice as admin } from 'modules/admin'
import { slice as availability } from 'modules/availability'
import { slice as couple } from 'modules/couple'
import { slice as quote } from 'modules/quote'
import { slice as wedding } from 'modules/wedding'
import { slice as need } from 'modules/need'
import { slice as transaction } from 'modules/transaction'
import { slice as conversation } from 'modules/conversation'
import { slice as payment } from 'modules/payment'

const createRootReducer = ({ history, signOutAction, initialState }) => {
  const appReducer = combineReducers({
    router: connectRouter(history),
    app,
    auth,
    category,
    chat,
    tagGroup,
    item,
    notification,
    tag,
    user,
    partner,
    blog,
    filterGroup,
    highlight,
    profile,
    tracking,
    inspiration,
    cover,
    admin,
    availability,
    couple,
    quote,
    wedding,
    need,
    transaction,
    conversation,
    payment,
  })

  const rootReducer = (state, action) => {
    if (action.type === signOutAction) {
      return appReducer({ app: { ...initialState, started: true } }, action)
    }
    return appReducer(state, action)
  }

  return rootReducer
}

export default createRootReducer
