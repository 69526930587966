import React from 'react'
import clsx from 'clsx'
import { useLocation } from 'react-router'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import { Typography } from 'modules/ui'
import Loader from './Loader'
import { drawerWidth } from './SideBar'

const useStyles = makeStyles(theme => ({
  appBar: {
    color: 'white',
    boxShadow: 'none',
    background: theme.palette.linearPrimary.main,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  hide: {
    display: 'none',
  },
  menuButton: {
    marginRight: 36,
  },
  logo: {
    flex: 1,
    fontSize: '22px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '28px',
    },
  },
  weddingImage: {
    maxHeight: '55px',
  },
}))

export default ({ children, loading, authenticated, drawerOpen, onOpen }) => {
  const classes = useStyles()
  const location = useLocation()
  const hideActions = location.pathname.startsWith('/wedding/')

  if (['/welcome', '/sign-in'].includes(location.pathname)) {
    return null
  }

  return (
    <AppBar
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: authenticated & drawerOpen,
      })}>
      <Toolbar>
        <IconButton
          color="inherit"
          onClick={onOpen}
          edge="start"
          className={clsx(classes.menuButton, {
            [classes.hide]: !authenticated | drawerOpen,
          })}>
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" color="inherit" className={classes.logo} />
        {hideActions ? (
          <img className={classes.weddingImage} src="/wm-text-480.png" alt="Logo" />
        ) : (
          children
        )}
      </Toolbar>
      <Loader loading={loading} />
    </AppBar>
  )
}
