import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'
import { createSagaAction } from 'saga-toolkit'

const name = 'filterGroup'

export const filterGroupsAdapter = createEntityAdapter()

const initialState = {
  loading: false,
  error: null,
  filterGroups: filterGroupsAdapter.getInitialState(),
  filterGroup: null,
}

export const fetchFilterGroups = createSagaAction(`${name}/fetchFilterGroups`)
export const fetchFilterGroup = createSagaAction(`${name}/fetchFilterGroup`)
export const createFilterGroup = createSagaAction(`${name}/createFilterGroup`)
export const updateFilterGroup = createSagaAction(`${name}/updateFilterGroup`)
export const removeFilterGroup = createSagaAction(`${name}/removeFilterGroup`)

const handlePending = state => ({
  ...state,
  loading: true,
})

const handleRejected = (state, { error }) => ({
  ...state,
  error,
  loading: false,
})

const slice = createSlice({
  name,
  initialState,
  reducers: {
    clearFilterGroup: state => ({
      ...state,
      filterGroup: null,
    }),
  },
  extraReducers: {
    // filters
    [fetchFilterGroups.pending]: handlePending,
    [fetchFilterGroups.fulfilled]: (state, { payload }) => {
      filterGroupsAdapter.setAll(state.filterGroups, payload)
      state.loading = false
    },
    [fetchFilterGroups.rejected]: handleRejected,

    //filter
    [fetchFilterGroup.pending]: handlePending,
    [fetchFilterGroup.fulfilled]: (state, { payload }) => ({
      ...state,
      filterGroup: payload,
      loading: false,
    }),
    [fetchFilterGroup.rejected]: handleRejected,

    // createFilter
    [createFilterGroup.pending]: handlePending,
    [createFilterGroup.fulfilled]: state => ({
      ...state,
      loading: false,
    }),
    [createFilterGroup.rejected]: handleRejected,

    // updateFilter
    [updateFilterGroup.pending]: handlePending,
    [updateFilterGroup.fulfilled]: state => ({
      ...state,
      loading: false,
    }),
    [updateFilterGroup.rejected]: handleRejected,
  },
})

export const { clearFilterGroup } = slice.actions

export default slice.reducer
