import React from 'react'
import PropTypes from 'prop-types'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import { StepLabel } from 'modules/ui'

export default function StepperHeader({ steps, currentStep }) {
  return (
    <Stepper alternativeLabel activeStep={currentStep}>
      {steps.map(label => (
        <Step key={label}>
          <StepLabel completed={false}>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  )
}

StepperHeader.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.string).isRequired,
  currentStep: PropTypes.number.isRequired,
}
