import { useState } from 'react'
import { DataGrid } from '@material-ui/data-grid'

const createColumns = ({ RowActions }) => [
  {
    field: 'name',
    headerName: 'Name',
    width: 220,
  },
  {
    field: 'author',
    headerName: 'Author',
    width: 220,
  },
  {
    field: 'ordering',
    headerName: 'Ordering',
    width: 140,
  },
  {
    field: '',
    headerName: 'Actions',
    width: 140,
    sortable: false,
    disableClickEventBubbling: true,
    renderCell: RowActions,
  },
]

export default ({ blogs, rowActionsComponent: RowActions, ...props }) => {
  const columns = createColumns({ RowActions })
  const [pageSize, setPageSize] = useState(25)

  return (
    <DataGrid
      rows={blogs}
      columns={columns}
      pageSize={pageSize}
      onPageSizeChange={newPageSize => setPageSize(newPageSize)}
      rowsPerPageOptions={[25, 50, 100]}
      pagination
      {...props}
    />
  )
}
