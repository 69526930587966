import { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { SingUpContext, ContactForm } from 'modules/auth'

const Contact = () => {
  const history = useHistory()
  const { state, setState } = useContext(SingUpContext)

  const submitForm = values => {
    setState({ ...state, ...values })
    history.push('/sign-up/registration')
  }

  const goBack = () => {
    history.push('/sign-up/details')
  }

  return <ContactForm submitForm={submitForm} state={state} goBack={goBack} />
}

export default Contact
