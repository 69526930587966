import { inspirationAdapter } from './slice'

export const selectRoot = state => state.inspiration
const selectItems = state => selectRoot(state).items
export const selectItem = state => selectRoot(state).item
export const selectItemGallery =
  ({ galleryId }) =>
  state =>
    selectRoot(state).item.galleries.find(({ id }) => id === galleryId)

export const { selectIds, selectEntities, selectAll, selectTotal, selectById } =
  inspirationAdapter.getSelectors(selectItems)
