import { useState, useEffect } from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'

const SnackMessage = ({ error, snackMessage, hideSnackMessage }) => {
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState(null)
  const [severity, setSeverity] = useState()

  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    if (!error) {
      return
    }

    setMessage(error.message)
    setSeverity('error')
    setOpen(true)
  }, [error])

  useEffect(() => {
    if (!snackMessage) {
      return
    }

    setMessage(snackMessage)
    setSeverity('success')
    setOpen(true)
  }, [snackMessage])

  const handleExited = () => {
    snackMessage && hideSnackMessage()
    setMessage()
  }

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={open}
      autoHideDuration={4000}
      onClose={handleClose}
      TransitionProps={{ onExited: handleExited }}>
      <MuiAlert elevation={6} variant="filled" severity={severity}>
        {message}
      </MuiAlert>
    </Snackbar>
  )
}

export default SnackMessage
