import React from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import Grid from '@material-ui/core/Grid'
import { TextField, Button } from 'modules/ui'
import { useSignIn } from '../hooks'

const SignInSchema = Yup.object().shape({
  password: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
  email: Yup.string().email('Invalid email address').required('Required'),
})

export default function SignIn() {
  const { signIn } = useSignIn()

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: ({ email, password }) => {
      signIn({ email, password })
    },
    validationSchema: SignInSchema,
  })

  const { values, touched, errors, handleChange, handleSubmit } = formik
  const { email, password } = values

  return (
    <div>
      <form onSubmit={handleSubmit} noValidate autoComplete="off">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              autoFocus
              id="email"
              name="email"
              type="email"
              label="E-mail"
              onChange={handleChange}
              value={email}
            />
            {errors.email && touched.email ? <div>{errors.email}</div> : null}
          </Grid>
          <Grid item xs={12}>
            <TextField
              autoFocus
              id="password"
              name="password"
              type="password"
              label="Password"
              value={password}
              onChange={handleChange}
            />
            {errors.password && touched.password ? <div>{errors.password}</div> : null}
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="primary" type="submit">
              Sign in
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  )
}
