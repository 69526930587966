import { useParams, useHistory } from 'react-router-dom'
import { Dialog } from 'modules/ui'
import { useInspiration } from 'modules/inspiration'

const Remove = ({ match }) => {
  const { id } = useParams()
  const { replace } = useHistory()
  const { removeItem, item } = useInspiration()

  const goBack = () => replace('/' + match.url.split('/')[1])

  const handleRemove = async () => {
    await removeItem({ id })
  }

  if (!item) {
    return null
  }

  return (
    <Dialog
      title={item.name}
      text="Are you sure you want to remove it?"
      closeButtonText="Cancel"
      confirmButtonText="Remove"
      onClose={goBack}
      onConfirm={handleRemove}
    />
  )
}

export default Remove
