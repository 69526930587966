import moment from 'moment'
import { QuestionResponse } from 'modules/api'

const formatAnswer = (field: QuestionResponse): string => {
  // this operation removes every property from field object where the value is null
  // so type will be the name of the property of the answer
  // and answer will be the value of that property
  const [type, answer] = Object.entries(field)
    .filter(([property]) => property === property.toUpperCase()) // removes every property from field object where the property name is not uppercase
    .find(([, value]) => value !== null) || [null, null] // removes every property from field object where the value is null

  if (!type) return '-'

  // marked the unused types with a question mark
  switch (type) {
    case 'TEXT': //?
      return answer as string
    case 'TEXT_LONG': //?
      return answer as string
    case 'NUMBER':
      return answer as string
    case 'TIME':
      return moment(answer as string).format('HH:mm')
    case 'DATE':
      return moment(answer as string).format('YYYY.MM.DD')
    case 'CURRENCY':
      return (answer + ' HUF') as string
    case 'RANGE': //?
      return answer as string
    case 'RANGE_CURRENCY': //?
      return answer as string
    case 'CHECK_GROUP': //?
      return answer as string
    case 'CHECK_GROUP_INLINE':
      return (answer as string[]).join(', ')
    case 'CHECK': //?
      return answer as string
    case 'RADIO_GROUP':
      return answer as string
    default:
      return JSON.stringify(answer)
  }
}

const CustomNeedList = ({ questionResponses }) => {
  return (
    <ul>
      {questionResponses.map((questionResponse, questionResponseIndex) => (
        <li key={questionResponseIndex}>
          {questionResponse[0].question.title}

          <ul>
            {questionResponse.map((field, fieldIndex) => (
              <li key={fieldIndex}>
                {field.question.fields[fieldIndex].label + ' '}
                <strong>{formatAnswer(field)}</strong>
              </li>
            ))}
          </ul>
        </li>
      ))}
    </ul>
  )
}

export default CustomNeedList
