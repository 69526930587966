import moment from 'moment'
import { Table } from 'modules/ui'
import { Conversation, SortDirection } from 'modules/api'

type Props = {
  conversations: Conversation[]
  totalElements: number
  pageSize: number
  page: number
  handleChangePage(page: number): void
  handleChangeRowsPerPage(rows: number): void
  toggleSort(newSortBy: string, sortBy: string, sortDirection: SortDirection): void
  sortDirection: SortDirection
  sortBy: string
  filter: string
  title: string
  handleSearch(search: string): void
  loading: boolean
  rowActionsComponent?: any
}

export const createColumns = ({ RowActions }) => [
  {
    field: 'participant1',
    headerName: 'Participant #1',
    width: 250,
    valueGetter: row => row.participants[0]?.name || '-',
  },
  {
    field: 'participant2',
    headerName: 'Participant #2',
    width: 250,
    valueGetter: row => row.participants[1]?.name || '-',
  },
  {
    field: 'lastMessage',
    headerName: 'Last message',
    width: 70,
    valueGetter: row => moment(row.updatedAt).format('YYYY.MM.DD HH:mm'),
  },
  {
    field: 'createdAt',
    headerName: 'Létrehozás dátuma',
    width: 70,
    valueGetter: row => moment(row.createdAt).format('YYYY.MM.DD HH:mm'),
  },
  {
    field: '',
    headerName: 'Actions',
    width: 140,
    disableClickEventBubbling: true,
    renderCell: RowActions,
  },
]

const ConversationsTable = ({
  conversations,
  totalElements,
  pageSize,
  page,
  toggleSort,
  sortDirection,
  sortBy,
  filter,
  title,
  handleChangePage,
  handleChangeRowsPerPage,
  handleSearch,
  rowActionsComponent: RowActions,
}: Props) => {
  const columns = createColumns({ RowActions })

  return (
    <>
      <Table
        data={conversations || []}
        columns={columns}
        totalElements={totalElements}
        pageSize={pageSize}
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleSearch={handleSearch}
        toggleSort={toggleSort}
        sortDirection={sortDirection}
        sortBy={sortBy}
        filter={filter}
        title={title}
      />
    </>
  )
}

export default ConversationsTable
