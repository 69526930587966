import { ReactNode } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Box, BoxProps, Container } from '@material-ui/core'
import { ArrowBack } from '@material-ui/icons'
import { useT } from 'modules/i18n'
import HelpField from './HelpField'
import Button from './Button'

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    padding: spacing(1),
    paddingRight: spacing(3),
    paddingBottom: 0,
    width: '100%',
  },
  backButton: {
    backgroundColor: 'white',
    borderRadius: spacing(4),
    border: '1px solid lightGrey',
    padding: spacing(1.5),
    paddingRight: spacing(3),
    paddingLeft: spacing(3),
    marginRight: spacing(3),
    alignContent: 'center',
    justifyContent: 'center',
  },
  arrowBack: {
    marginRight: spacing(1),
    color: palette.primary.main,
  },
  description: {},
}))

export interface PageHeaderProps extends BoxProps {
  description?: string
  children?: ReactNode
  onClickBack: () => void
}

export const PageHeader = ({
  children,
  description = '',
  onClickBack,
  ...props
}: PageHeaderProps) => {
  const classes = useStyles()
  const t = useT()

  return (
    <Container maxWidth="lg" className={classes.root}>
      <Box display="flex" ml={3} alignItems="center" {...props}>
        <Button className={classes.backButton} onClick={onClickBack}>
          <ArrowBack className={classes.arrowBack} /> {t('Back')}
        </Button>
        {description && <HelpField className={classes.description} text={t(description)} />}
      </Box>
    </Container>
  )
}

export default PageHeader
