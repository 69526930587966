import { useHistory } from 'react-router-dom'
import { Page, PageHeader } from 'modules/ui'
import { useProfile } from 'modules/profile'
import { EmailPreferencesForm } from 'modules/user'
import { Partner, UserType } from 'modules/api'

const Notifications = () => {
  const { user, updateProfile }: { user: Partner; updateProfile: any } = useProfile()
  const history = useHistory()

  const handleSubmit = data => {
    return updateProfile({
      type: UserType.Partner,
      id: user.id,
      emailPreferences: data,
    })
  }

  const handleClickBack = () => {
    history.goBack()
  }

  if (user === null) {
    return null
  }

  return (
    <>
      <PageHeader
        description="Here you can customise which events you wish receive notifications about"
        onClickBack={handleClickBack}
      />

      <Page maxWidth="lg" title="Notifications">
        <EmailPreferencesForm initialValues={user.emailPreferences} onSubmit={handleSubmit} />
      </Page>
    </>
  )
}

export default Notifications
