import { Stepper, Step, makeStyles, Grid } from '@material-ui/core'
import { Page, Typography, StepLabel } from 'modules/ui'

const useStyles = makeStyles(({ spacing }) => ({
  infoText: {
    margin: spacing(3),
  },
}))

const SignUpStepper = ({ steps, activeStep, children }) => {
  const classes = useStyles()

  return (
    <Page title="Sign up" maxWidth="md">
      <Stepper alternativeLabel activeStep={activeStep}>
        {steps.map((step, index) => (
          <Step key={index}>
            <StepLabel completed={false}>{step.label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Grid container justifyContent="center" alignContent="center">
        <Grid item>
          <Typography className={classes.infoText} variant="body1">
            {steps[activeStep].infoText}
          </Typography>
        </Grid>
      </Grid>
      {children}
    </Page>
  )
}

export default SignUpStepper
