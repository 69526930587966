import Divider from '@material-ui/core/Divider'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from 'modules/ui'

const useStyles = makeStyles(theme => ({
  section: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
}))

const Section = ({ children, label, subLabel }) => {
  const classes = useStyles()
  return (
    <div className={classes.section}>
      <Typography variant="h6" color="primary">
        {label}
      </Typography>
      <Divider />
      <Typography className={classes.margin} variant="subtitle2" color="textSecondary">
        {subLabel}
      </Typography>

      <div className={classes.sectionContent}>{children}</div>
    </div>
  )
}

export default Section
