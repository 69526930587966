import { createSlice } from '@reduxjs/toolkit'
import { createSagaAction } from 'saga-toolkit'

const name = 'wedding'

const initialState = {
  loading: false,
  error: null,
  wedding: null,
  unseenWeddings: null,
}

export const fetchWedding = createSagaAction(`${name}/fetchWedding`)
export const fetchUnseenWeddings = createSagaAction(`${name}/fetchUnseenWeddings`)

const handlePending = state => ({
  ...state,
  loading: true,
})

const handleRejected = (state, { error }) => ({
  ...state,
  error,
  loading: false,
})

const slice = createSlice({
  name,
  initialState,
  reducers: {
    clearWedding: state => ({
      ...state,
      wedding: null,
    }),
  },
  extraReducers: {
    //wedding
    [fetchWedding.pending]: handlePending,
    [fetchWedding.fulfilled]: (state, { payload }) => ({
      ...state,
      wedding: payload,
      loading: false,
    }),
    [fetchWedding.rejected]: handleRejected,
    //unseen weddings
    [fetchUnseenWeddings.pending]: handlePending,
    [fetchUnseenWeddings.fulfilled]: (state, { payload }) => ({
      ...state,
      unseenWeddings: payload,
      loading: false,
    }),
    [fetchUnseenWeddings.rejected]: handleRejected,
  },
})

export const { clearWedding } = slice.actions

export default slice.reducer
