import { createSlice } from '@reduxjs/toolkit'
import { createSagaAction } from 'saga-toolkit'

const name = 'auth/signIn'

const initialState = {
  loading: false,
  error: null,
  data: null,
}

export const signIn = createSagaAction(`${name}/signIn`)
export const signOut = createSagaAction(`${name}/signOut`)
export const continueSession = createSagaAction(`${name}/continueSession`)

const slice = createSlice({
  name,
  initialState,
  reducers: {
    clearSignIn: () => initialState,
  },
  extraReducers: {
    [signIn.pending]: state => ({
      ...state,
      loading: true,
    }),
    [signIn.fulfilled]: (state, { payload }) => ({
      ...state,
      data: payload,
      loading: false,
    }),
    [signIn.rejected]: (state, { error }) => ({
      ...state,
      error,
      loading: false,
    }),
    [signOut.fulfilled]: () => initialState,
    [continueSession.pending]: state => ({
      ...state,
      loading: true,
    }),
    [continueSession.fulfilled]: (state, { payload }) => ({
      ...state,
      data: payload,
      loading: false,
    }),
    [continueSession.rejected]: state => ({
      ...state,
      loading: false,
    }),
  },
})

export const { clearSignIn } = slice.actions

export default slice.reducer
