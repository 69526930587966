import { useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { makeStyles, Box } from '@material-ui/core'
import { Container } from '@material-ui/core'
import { ArrowBack } from '@material-ui/icons'
import { Message as MessageType } from 'modules/api'
import { Page, Button, Typography } from 'modules/ui'
import { useT } from 'modules/i18n'
import { useConversation } from 'modules/conversation'
import moment from 'moment'

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    padding: spacing(2),
    width: '100%',
  },
  backButton: {
    backgroundColor: 'white',
    borderRadius: spacing(4),
    border: '1px solid lightGrey',
    padding: spacing(1.5),
    paddingRight: spacing(3),
    paddingLeft: spacing(3),
    marginRight: spacing(3),
    alignContent: 'center',
    justifyContent: 'center',
    marginLeft: spacing(3),
    marginBottom: spacing(2),
  },
  arrowBack: {
    marginRight: spacing(1),
    color: palette.primary.main,
  },
}))

const Conversations = () => {
  const classes = useStyles()
  const history = useHistory()
  const t = useT()
  const { messages, fetchConversationMessages, totalMessages } = useConversation()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const conversationId = location.pathname.split('/')[2]
  const [page, setPage] = useState(+queryParams.get('page') || messages.length)

  const loadMore = async () => {
    setPage(page + 10)
    fetchConversationMessages({ id: conversationId, offset: page, limit: 10, loadMore: true })
  }

  const canLoadMore = messages.length < totalMessages

  return (
    <Container maxWidth="lg" className={classes.root}>
      <Button
        className={classes.backButton}
        onClick={() => {
          history.goBack()
        }}>
        <ArrowBack className={classes.arrowBack} /> {t('Back')}
      </Button>
      <Page>
        <Typography variant="h5">{t('Conversation messages')}</Typography>
        {messages.map((message: MessageType) => (
          <Typography key={message.id}>
            <Message message={message} />
          </Typography>
        ))}

        {canLoadMore && <Button onClick={loadMore}>Load more</Button>}
      </Page>
    </Container>
  )
}

const Message = ({ message }: { message: MessageType }) => {
  const history = useHistory()
  const hasAttachment = !!message.attachment

  const handleClickAttachment = () => {
    if (!message.attachment) return

    const type = message.attachment?.type
    const { requestId } = JSON.parse(message.attachment?.payload)

    // convert to upper case because old messages have different casing
    switch (type.toUpperCase()) {
      case 'QUOTE_REQUEST': {
        history.push(`/quotes/${requestId}/view`)
        break
      }

      case 'QUOTE_SEEN':
      case 'QUOTE_RESPONSE': {
        history.push(`/quotes/${requestId}/view-response`)
        break
      }

      default:
        break
    }
  }

  return (
    <Box mb={1.5}>
      {moment(message.createdAt).format('YYYY. MM. DD. HH:mm') + ' - '}
      <strong>{message.sender.name}</strong>
      {': '}
      <Typography
        onClick={handleClickAttachment}
        color={hasAttachment ? 'secondary' : 'textPrimary'}>
        {hasAttachment ? 'Csatolmányt küldött.' : message.text}
      </Typography>
    </Box>
  )
}

export default Conversations
