import clsx from 'clsx'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import { IconButton, Drawer, makeStyles, List } from '@material-ui/core'
import AvatarWithLink from './AvatarWithLink'
import { Typography } from 'modules/ui'
import config from 'modules/config'

export const drawerWidth = 240

const version = config.app.GIT_VERSION

const useStyles = makeStyles(theme => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  listItems: {
    flexGrow: 1,
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  footerImage: {
    width: '85%',
    alignSelf: 'center',
  },
}))

export default ({ children, open, onClose, user }) => {
  const classes = useStyles()

  return (
    <Drawer
      anchor="left"
      variant="permanent"
      open={open}
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}>
      <div className={classes.content}>
        <div className={classes.toolbarIcon}>
          {open && (
            <>
              {user && <AvatarWithLink to="/profile" user={user} />}
              <IconButton onClick={onClose}>
                <ChevronLeftIcon />
              </IconButton>
            </>
          )}
        </div>
        <List className={classes.listItems}>{children}</List>
        <img className={classes.footerImage} src="/wm-text-gradient-new.png" alt="Logo" />
        <Typography align="center" variant="caption" color="textSecondary">
          {version}
        </Typography>
      </div>
    </Drawer>
  )
}
