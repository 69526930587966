import { Box, Grid } from '@material-ui/core'
import { NavLink } from 'react-router-dom'
import { Button, Page, Typography } from 'modules/ui'
import { TransactionList, TokenCounter } from 'modules/partner'
import { useProfile } from 'modules/profile'

function View({ location }) {
  const { user } = useProfile()
  const { wallet } = user

  if (!wallet) {
    return null
  }

  return (
    <Page title="Wallet">
      <Grid spacing={2} container direction="row">
        <Grid item xs={12} sm={12} md={8}>
          <TransactionList wallet={wallet} />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <TokenCounter wallet={wallet} />
          <Box mt={2}>
            <Typography align="center">Are you out of tokens?</Typography>
            <NavLink to={`${location.pathname}/purchase-token`}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                style={{
                  textDecoration: 'underline',
                }}>
                Buy tokens
              </Button>
            </NavLink>
          </Box>
        </Grid>
      </Grid>
    </Page>
  )
}

export default View
