import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { ItemsList } from 'modules/item'
import { PartnerForm } from 'modules/partner'
import { Loader, Typography } from 'modules/ui'

const PartnerDetail = ({
  categoryLoading,
  initialValues,
  categories,
  handleSubmit,
  submitting,
  items,
  loading,
  RowActions,
  actionButtons,
  fetchItems,
  total,
  setOffset,
  offset,
  limit,
}) => {
  return (
    <Grid direction="column" container>
      <Grid item xs={12} sm={8} md={6}>
        <PartnerForm
          categoryLoading={categoryLoading}
          initialValues={initialValues}
          categories={categories}
          onSubmit={handleSubmit}
          actionButtons={actionButtons}
        />
        <Loader loading={submitting} />
      </Grid>
      <Grid item>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header">
            <Typography>Items</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ minHeight: '800px' }}>
            <ItemsList
              items={items}
              loading={loading}
              rowActionsComponent={RowActions}
              fetchItems={fetchItems}
              total={total}
              setOffset={setOffset}
              offset={offset}
              limit={limit}
              style={{ minHeight: '800px' }}
            />
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  )
}

export default PartnerDetail
