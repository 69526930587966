import { useParams, useHistory } from 'react-router-dom'
import { Dialog } from 'modules/ui'
import { useCategory } from 'modules/category'

const Remove = ({ match }) => {
  const { id } = useParams()
  const { replace } = useHistory()
  const { category, removeCategory } = useCategory()

  const goBack = () => replace('/' + match.url.split('/')[1])

  const handleRemove = async () => {
    await removeCategory({ id })
  }

  if (!category) {
    return null
  }

  return (
    <Dialog
      title={category.name}
      text="Are you sure you want to remove it?"
      closeButtonText="Cancel"
      confirmButtonText="Remove"
      onClose={goBack}
      onConfirm={handleRemove}
    />
  )
}

export default Remove
