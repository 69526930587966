import Grid from '@material-ui/core/Grid'
import { Loader } from 'modules/ui'
import { Category } from 'modules/api'
import { NeedForm, NeedFormValues } from './NeedForm'

export type NeedDetailProps = {
  initialValues: NeedFormValues
  categories: Category[]
  categoryLoading: boolean
  handleSubmit: (data?: NeedFormValues) => void
  submitting: boolean
}

export const NeedDetail = ({
  initialValues,
  categories = [],
  categoryLoading = false,
  handleSubmit = () => {},
  submitting = false,
}: NeedDetailProps) => (
  <Grid direction="column" container>
    <Grid item xs={12} sm={8} md={6}>
      <NeedForm
        categoryLoading={categoryLoading}
        initialValues={initialValues}
        categories={categories}
        onSubmit={handleSubmit}
      />
      <Loader loading={submitting} />
    </Grid>
  </Grid>
)
