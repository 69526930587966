import { Route, useRouteMatch, NavLink, useHistory } from 'react-router-dom'
import { Grid, makeStyles } from '@material-ui/core'
import { useT } from 'modules/i18n'
import RecommendPartnersDialog from './RecommendPartnersDialog'
import EditAdminTextDialog from './EditAdminTextDialog'
import List from './List'
import View from './View'

const useStyles = makeStyles(theme => ({
  tabs: {
    marginBottom: theme.spacing(1),
    justifyContent: 'center',
  },
  tabLink: {
    'padding': theme.spacing(2),
    'textDecoration': 'none',
    'fontSize': 18,
    'fontWeight': 500,
    'color': theme.palette.grey[800],
    '&.active': {
      color: theme.palette.primary.main,
    },
  },
}))

const Routes = () => {
  const history = useHistory()
  const match = useRouteMatch()
  const classes = useStyles()
  const t = useT()

  const tabRoutes = [
    { path: match.url, title: t('Waiting for approval'), page: List },
    { path: `${match.url}/approved`, title: t('Approved'), page: List },
    { path: `${match.url}/archived`, title: t('Archived'), page: List },
    { path: `${match.url}/deleted`, title: t('Deleted'), page: List },
  ]

  const tabPaths = tabRoutes.map(tr => tr.path)

  return (
    <>
      {tabPaths.includes(history.location.pathname) && (
        <Grid container spacing={4} className={classes.tabs}>
          {tabRoutes.map(({ path, title }) => (
            <Grid key={path} item>
              <NavLink className={classes.tabLink} exact to={path}>
                {title}
              </NavLink>
            </Grid>
          ))}
        </Grid>
      )}
      {tabRoutes.map(({ path, page }) => (
        <Route key={path} path={path} exact component={page} />
      ))}
      <Route path={`${match.url}/:id/view`} component={View} />
      <Route
        path={`${match.url}/:id/view/recommend-partners`}
        component={RecommendPartnersDialog}
      />
      <Route path={`${match.url}/:id/view/edit-admin-text`} component={EditAdminTextDialog} />
    </>
  )
}

export default Routes
