import { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { HighlightForm, useHighlight } from 'modules/highlight'
import { Page, Loader, Typography } from 'modules/ui'

const Edit = () => {
  const { highlight, updateHighlight } = useHighlight()
  const [submitting, setSubmitting] = useState(false)

  const handleSubmit = async data => {
    setSubmitting(true)
    const elementList = data.elements.map(element => ({
      id: element.element.id,
      order: element.order,
    }))
    await updateHighlight({ ...data, elements: elementList })
    setSubmitting(false)
  }

  if (!highlight) {
    return 'Loading...'
  }

  return (
    <Page title={`Edit "${highlight.name}"`}>
      <Grid container>
        <Grid item xs={12} sm={8} md={6} lg={4}>
          <Typography variant="h5">Category details</Typography>
          <HighlightForm initialValues={highlight} onSubmit={handleSubmit} />
          <Loader loading={submitting} />
        </Grid>
      </Grid>
    </Page>
  )
}

export default Edit
