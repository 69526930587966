import { Route, useRouteMatch } from 'react-router-dom'
import New from './New'
import Edit from './Edit'
import List from './List'
import Remove from './Remove'

const Blogs = () => {
  const match = useRouteMatch()

  return (
    <>
      <Route path={[`${match.url}`, `${match.url}/:id/remove`]} exact component={List} />
      <Route path={`${match.url}/new`} exact component={New} />
      <Route path={`${match.url}/:id/edit`} exact component={Edit} />
      <Route path={`${match.url}/:id/remove`} component={Remove} />
    </>
  )
}

export default Blogs
