import { useState, useEffect } from 'react'
import * as yup from 'yup'
import { Formik, Form, useFormikContext } from 'formik'
import { makeStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import { TextField, FileUploaderField, Select, SubmitButton } from 'modules/formik'
import { TagAutocompleteFormik } from 'modules/tag'
import { Button } from 'modules/ui'
import config from 'modules/config'

const imagesPath = config.app.IMAGES_URL

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  image: {
    alignItems: 'flex-start',
  },
}))

const validationSchema = yup.object({
  name: yup
    .string('Enter name')
    .min(2, 'Should be of minimum 2 characters length')
    .required('Name is required'),
})

const initialState = {
  name: '',
  image: '',
  category: '',
  tags: [],
}

const TagAutocompleteWithContext = () => {
  const { values, setFieldValue, touched } = useFormikContext()

  useEffect(() => {
    touched.category && setFieldValue('tags', [])
  }, [values.category])

  return (
    <TagAutocompleteFormik
      categoryId={values.category}
      id="tags"
      name="tags"
      label="Tags"
      labelId="taggroup-edit-form-tag-ids-select"
      margin="normal"
      fullWidth
      multiple
      filterSelectedOptions
      disableClearable
    />
  )
}

export default ({ initialValues = initialState, categoryLoading, categories, ...props }) => {
  const classes = useStyles()
  const [image, setImage] = useState(initialValues?.image)

  useEffect(() => {
    setImage(initialValues?.image)
  }, [initialValues])

  const handleReplaceClick = () => {
    setImage()
  }

  const categoryId = initialValues.category?.id

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        ...initialValues,
        category: categoryId || '',
      }}
      {...props}>
      <Form className={classes.root} noValidate autoComplete="off">
        <TextField id="name" name="name" label="Name" margin="normal" fullWidth />
        {image ? (
          <FormControl className={classes.image} fullWidth>
            <img width={200} src={`${imagesPath}/${image}`} alt="Tag Group" />
            <Button size="small" onClick={handleReplaceClick}>
              replace image
            </Button>
          </FormControl>
        ) : (
          <FileUploaderField
            label="Max file size: 15mb, accepted: jpg|png"
            id="image"
            name="image"
            fullWidth
            singleImage
            withIcon={false}
            withPreview
            buttonText="Choose image"
            imgExtension={['.jpg', '.png']}
            maxFileSize={config.app.MAX_ATTACHMENT_SIZE}
          />
        )}
        <Select
          loading={categoryLoading}
          id="category"
          name="category"
          label="Category"
          labelId="tag-group-edit-form-category-select"
          margin="normal"
          fullWidth>
          {/** TODO: need to add a categoryName field */}
          <MenuItem value={''}>{'-'}</MenuItem>
          {categories.map(({ id, name }, i) => (
            <MenuItem key={i} value={id}>
              {name}
            </MenuItem>
          ))}
        </Select>
        <TagAutocompleteWithContext />
        <SubmitButton color="primary" variant="contained" size="small">
          Save
        </SubmitButton>
      </Form>
    </Formik>
  )
}
