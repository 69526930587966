import { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { unwrapResult } from '@reduxjs/toolkit'
import { Conversation, BatchUploadForm, useChat } from 'modules/chat'
import { Page } from 'modules/ui'
import { useProfile } from 'modules/profile'
import { uploadToS3 } from 'modules/storage'

const useStyles = makeStyles(theme => ({
  container: {
    flex: 1,
    height: '100%',
    display: 'flex',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  fillParent: {
    height: '100%',
  },
  conversationList: {
    borderBottom: `3px solid ${theme.palette.grey.main}`,
    width: 400,
    height: '100%',
    flexDirection: 'column',
    overflow: 'auto',

    [theme.breakpoints.down('md')]: {
      width: 250,
    },
    [theme.breakpoints.down('sm')]: {
      height: '50%',
      width: '100%',
      marginBottom: theme.spacing(4),
    },
  },
  conversationContainer: {
    display: 'flex',
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      flexGrow: 1,
      height: '50%',
    },
  },
  scrollableColumn: {
    display: 'flex',
    maxHeight: '100%',
  },
}))

const processAttachment = async ({ type, payload }, onProgress) => {
  const attachment = { type }

  switch (type) {
    case 'image':
      attachment.payload = await uploadToS3(payload, onProgress)
      break
    default:
      attachment.payload = payload
  }

  return attachment
}

const Batch = () => {
  const classes = useStyles()
  const [submitting, setSubmitting] = useState(false)
  const [uploading, setUploading] = useState(0)
  const [ids, setIds] = useState([])
  const { sendBatchMessage, loading } = useChat()
  const { user } = useProfile()

  const handleBatchUploadSubmit = ({ ids }) => setIds(ids)

  const handleProgress = ({ total, loaded }) => {
    setUploading((loaded / total) * 100)
  }

  const handleEnterMessage = async ({ text, attachment }) => {
    setSubmitting(true)

    if (!ids.length) {
      return
    }

    try {
      unwrapResult(
        await sendBatchMessage({
          ids,
          text,
          ...(attachment && { attachment: await processAttachment(attachment, handleProgress) }),
        }),
      )
      alert('Kiküldve!')
    } catch (error) {
      alert('Hiba: ' + error.message)
    }

    setSubmitting(false)
  }

  return (
    <Page maxWidth={false} fullHeight>
      <div className={classes.container}>
        <div className={clsx(classes.scrollableColumn, classes.conversationList)}>
          <BatchUploadForm onSubmit={handleBatchUploadSubmit} />
          <ul>
            {ids.map(id => (
              <li key={id}>{id}</li>
            ))}
          </ul>
        </div>
        <div className={clsx(classes.scrollableColumn, classes.conversationContainer)}>
          <Conversation
            userId={user?.id}
            loading={loading || submitting}
            noParticipants
            uploading={uploading}
            onEnterMessage={handleEnterMessage}
          />
        </div>
      </div>
    </Page>
  )
}

export default Batch
