import { createTheme } from '@material-ui/core/styles'
import shadows from '@material-ui/core/styles/shadows'

export default createTheme({
  palette: {
    primary: {
      main: '#FA709A',
      contrastText: '#FFFFFF',
    },
    linearPrimary: {
      main: 'linear-gradient(135deg, #FEC140 0%, #fa709a 100%)',
    },
    grey: {
      main: '#E4E4E4',
    },
  },
  overrides: {
    MuiButton: {
      containedPrimary: {
        'color': '#FFFFFF',
        'background': 'linear-gradient(135deg, #FEC140 0%, #fa709a 100%)',
        'border': 0,
        'borderRadius': 3,
        'boxShadow': 'none',
        '&:hover': {
          boxShadow: shadows[5],
        },
      },
      containedSecondary: {
        'color': 'white',
        'backgroundColor': '#FA709A',
        'border': 0,
        'borderRadius': 3,
        'boxShadow': 'none',
        '&:hover': {
          backgroundColor: '#FA709A',
          boxShadow: shadows[5],
        },
      },
    },
    MuiCssBaseline: {
      '@global': {
        body: {
          backgroundColor: '#f2f2f2',
        },
      },
    },
  },
})
