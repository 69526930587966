import { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { useForgotPassword, useSignIn } from 'modules/auth'
import { FinishForm, StepperHeader, PasswordConfirmForm, EmailForm } from 'modules/auth'
import { Page, Typography } from 'modules/ui'

const useStyles = makeStyles(({ spacing }) => ({
  footer: {
    'display': 'flex',
    'justifyContent': 'flex-end',
    'textDecoration': 'none',
    '& button': {
      marginLeft: spacing(1),
    },
  },
  verificationLink: {
    cursor: 'pointer',
  },
}))

//prettier-ignore
const getSteps = () => [
  'Enter your email address',
  'Enter verification code',
  'Sign in'
]

const ForgotPassword = () => {
  const {
    step,
    forgotPassword,
    confirmForgotPassword,
    error,
    loading,
    currentEmail,
    skipToVerification,
  } = useForgotPassword()
  const { signIn } = useSignIn()

  const classes = useStyles()
  const [creditentials, setCreditentials] = useState()

  const handleEmailFormSubmit = val => {
    forgotPassword(val)
  }

  const handleSkipToVerification = () => {
    skipToVerification()
  }

  const handleConfirmForgotPassword = ({ email, password, ...rest }) => {
    setCreditentials({ email, password })

    confirmForgotPassword({
      email,
      password,
      ...rest,
    })
  }

  const handleSignIn = async () => {
    await signIn(creditentials)
  }

  const renderStep = step => {
    switch (step) {
      case 0:
        return (
          <EmailForm
            renderError={renderError}
            classes={classes}
            onSubmit={handleEmailFormSubmit}
            skipToVerification={handleSkipToVerification}
            loading={loading}
          />
        )
      case 1:
        return (
          <PasswordConfirmForm
            renderError={renderError}
            classes={classes}
            onSubmit={handleConfirmForgotPassword}
            loading={loading}
            currentEmail={currentEmail}
          />
        )
      case 2:
        return (
          <FinishForm
            renderError={renderError}
            classes={classes}
            onSubmit={handleSignIn}
            title="Congratulations! You have successfully reset your password."
          />
        )
      default:
        return <div>Unknown step</div>
    }
  }

  const renderError = () => {
    return (
      error && (
        <Grid align="center" item xs={12}>
          <Typography color="error" variant="caption" gutterBottom>
            Error
          </Typography>
          <Typography color="error" variant="body2" gutterBottom>
            {error.message.toString()}
          </Typography>
        </Grid>
      )
    )
  }

  return (
    <Page title="Forgot Password" maxWidth="md">
      <StepperHeader steps={getSteps()} currentStep={step} />
      {renderStep(step)}
    </Page>
  )
}

export default ForgotPassword
