import BaseLink from '@material-ui/core/Link'
import { useT } from 'modules/i18n'

const isString = value => {
  return typeof value === 'string'
}

const Link = ({ children, ...props }) => {
  const t = useT()

  return <BaseLink {...props}>{isString(children) ? t(children) : children}</BaseLink>
}

export default Link
