const translations = {
  hu: {
    translation: {
      'Highlights': 'Kiemelések',
      'Notifications': 'Értesítések',
      'Covers': 'Borítók',
      'Services': 'Szolgáltatások',
      'Blogs': 'Blog',
      'Items': 'Tételek',
      'Days': 'Nap',
      'Months': 'Hónap',
      'Minutes': 'Perc',
      'Hours': 'Óra',
      'Seconds': 'Másodperc',
      'Categories': 'Kategóriák',
      'Couples': 'Párok',
      'Partners': 'Partnerek',
      'Users': 'Felhasználók',
      'Home': 'Kezdőoldal',
      'Upload profile picture': 'Profil kép feltöltése',
      'Forgot Password': 'Elfelejtett jelszó',
      'Quantity': 'Mennyiség',
      'Type': 'Típus',
      'Couple': 'Pár',
      'Status': 'Státusz',
      'Send image': 'Kép küldése',
      'Pick': 'Kiválaszt',
      'Number of tokens in the wallet': 'Tárcában lévő Tokenek száma',
      'Enter your email address': 'Add meg az email címedet',
      'Enter verification code': 'Add meg a hitelesítő kódod',
      'Skip to verification': 'Ugrás a hitelesítéshez',
      'skip to the verification page': 'ugorj a hitelesítés oldalra.',
      'To reset your password please enter your email address. If you have already started the password reset process and the authentication code has arrived at your address,':
        'Jelszavad helyreállításához add meg az email címed. Amennyiben már elindítottad a jelszóvisszaállítási folyamatot és megérkezett címedre a hitelesítési kód, ',
      'next': 'következő',
      'Profile name': 'Profil név',
      'Next': 'Következő',
      'Profile picture is required': 'A profil kép kötelező',
      'Too Short!': 'Túl rövid!',
      'Too Long!': 'Túl hosszú',
      'Help': 'Segítség',
      'Sign In': 'Jelentkezz be',
      'Signing in... {{counter}}': 'Bejelentkezés... {{counter}}',
      'User does not exist.': 'A felhasználó nem létezik',
      'Sign out': 'Kijelentkezés',
      'Your item is under review': 'Az inspirációd vizsgálat alatt van',
      'Your item is rejected': 'Az inspirációd el lett utasítva',
      'Chat': 'Chat',
      'password': 'jelszó',
      'Password': 'Jelszó',
      'Password *': 'Jelszó *',
      'Add new image': 'Új kép hozzáadása',
      'Inspiration Scroll': 'Inspirációs adatbázis',
      'Availabilities': 'Dátumlekérdezések',
      'Quote': 'Árajánlat',
      'Quotes': 'Ajánlatkérések',
      'Quote response': 'Válasz',
      'Accept': 'Elfogad',
      'Quote request': 'Ajánlatkérés',
      'Profile status: pending': 'Profilod státusza: feldolgozás alatt',
      'Profile status: rejected': 'Profilod státusza: elfogadva',
      'Profile status: fulfilled': 'Profilod státusza: felfüggesztve',
      'Reject availability from: {{user}}':
        'Időpontkérés elutasítása az alábbi felhasználótól: {{user}}',
      'Accept availability from: {{user}}':
        'Időpontkérés elfogadása az alábbi felhasználótól: {{user}}',
      'Reject quote from {{couple}}?':
        'Elutasítod az ajánlatkérést az alábbi felhasználótól: {{couple}}?',
      'Will you send your quote to {{couple}}?': 'Elküldöd az árajánlatodat {{couple}} részére?',
      'Are you sure you want to reject quote from?':
        'Biztos vagy benne, hogy elutasítod az ajánlatot tőlük: {{couple}}?',
      'Are you sure you want to accept quote from {{couple}}?':
        'Biztos vagy benne, hogy elfogadod az ajánlatot tőlük: {{couple}}?',
      'Profile': 'Profil',
      "You don't have enough tokens to accept quote from {{couple}}. You need at least 10 tokens to accept a quote.":
        'Nincs elég tokened ahhoz, hogy elfogadj egy ajánlatkérést tőlük: {{couple}}. Legalább 10 token szükséges ahhoz, hogy elfogadj egy ajánlatkérést.',
      '10 tokens': '10 token',
      'Available tokens: {{tokens}}': 'Elérhető tokenek: {{tokens}}',
      'This will cost you: ': 'Ennyibe fog kerülni: ',
      'Add new item': 'Új elem hozzáadása',
      'Most viewed': 'Legnézettebb',
      'Most liked': 'Legtöbbet kedvelt',
      'Oldest': 'Legrégebbi',
      'Newest': 'Legújabb',
      'Cover Image': 'Borítókép',
      'optional': 'opcionális',
      'Wallet': 'Tokentárca',
      'Upload cover image': 'Borítókép feltöltése',
      'Add other images to your inspiration': 'További képek hozzáadása az inspirációhoz',
      'Load more': 'Még több',
      'Availability': 'Időpontok',
      'User': 'Felhasználó',
      'Created at': 'Létrehozva',
      'Approved at': 'Jóváhagyva',
      'Address': 'Cím',
      'Radius': 'Hatókör',
      'Available nationwide': 'Országosan elérhető',
      'Description': 'Leírás',
      'Website': 'Weboldal',
      'Cover photos': 'Borítóképek',
      'Back': 'Vissza',
      'Delete profile': 'Profil törlése',
      'Date': 'Dátum',
      'Sign up': 'Regisztráció',
      'Note': 'Megjegyzés',
      'Vendor name': 'Vendor név',
      'Visible email': 'Látható email cím',
      'Contact name': 'Kapcsolattartó neve',
      'Contact email': 'Kapcsolattartó email címe',
      'Contact phone number': 'Kapcsolattartó telefonszáma',
      'Contact phone': 'Kapcsolattartó telefonszáma',
      'Actions': 'Műveletek',
      'Invalid phone!': 'Hibás telefonszám',
      'Requests for quotations': 'Ajánlatkérések',
      'Password reset': 'Új jelszó',
      'Password is required': 'A jelszó megadása kötelező',
      'Email is required': 'Az email cím megadása kötelező',
      'Invalid email address': 'Hibás email cím formátum',
      'Save': 'Mentés',
      'Choose': 'Kiválaszt',
      'Registration': 'Regisztráció',
      'Details': 'Részletek',
      'Category': 'Kategória',
      'Contact': 'Kapcsolat',
      'Verification': 'Hitelesítés',
      'Base information': 'Alapadatok',
      'Pending': 'Válaszra vár',
      'Rejected': 'Elutasítva',
      'Accepted': 'Elfogadva',
      'pending': 'válaszra vár',
      'rejected': 'elutasítva',
      'accepted': 'elfogadva',
      'Reject quote': 'Ajánlatkérés elutasítása',
      'Upload attachments (Excel, Word, PDF)': 'Csatolmány feltöltése (Excel, Word, PDF)',
      'Reject': 'Elutasít',
      'Cancel': 'Mégsem',
      'Close': 'Bezár',
      'Confirm': 'Megerősít',
      'Remove': 'Töröl',
      'Approve': 'Jóváhagy',
      'View': 'Megtekintés',
      'Accept quote': 'Ajánlatkérés elfogadása',
      'Image': 'Kép',
      'My name': 'Az én nevem',
      "Partner's name": 'A partnerem neve',
      'Wedding date': 'Az esküvő dátuma',
      'Wedding location': 'Az esküvőnk helye',
      'Email address': 'Email cím',
      'Attachments': 'Csatolmányok',
      "Quote's description": 'Az ajánlatkérés leírása',
      'Your profile is under review, please be patient!':
        'A profilod vizsgálat alatt van, kérlek légy türelemmel!',
      'Your profile has been successfully updated!': 'A profilod sikeresen frissítve lett!',
      'Guests': 'Létszám',
      'Profil image': 'Profilkép',
      'min': 'minimum',
      'max': 'maximum',
      'Names': 'Nevek',
      'Name': 'Név',
      'Remove Item': 'Elem törlése',
      'Are you sure you want to remove it?': 'Biztosan törölni szeretné?',
      'Update': 'Frissítés',
      'Create': 'Létrehoz',
      'Conversations': 'Beszélgetések',
      'Conversation': 'Beszélgetés',
      'New inspiration': 'Új inspiráció',
      'Tags': 'Címkék',
      'Tag Groups': 'Címkecsoportok',
      'Select your tags': 'Válaszd ki a tagjeidet',
      'Terms and conditions': 'Felhasználási feltételek',
      'You must accept terms and condition before create or update a new inspiration!':
        'El kell fogadnod a felhasználási feltételeket, mielőtt módosítod vagy létrehozol egy inspirációt!',
      'You must accept GDPR before create or update a new inspiration!':
        'El kell fogadnod a GDPR-t mielőtt készítesz vagy frissítesz egy inspirációt',
      'Confirm password': 'Jelszó megerősítése',
      'Confirm password *': 'Jelszó megerősítése *',
      '* The password must be at least 8 characters including a number, a lowercase letter, and an uppercase letter':
        '* A jelszónak minimum 8 karakteresnek kell lennie és tartalmaznia kell legalább egy számot, egy kis- illetve nagy betűt!',
      'Confirmation code': 'Megerősítő kód',
      'Check your mailbox for the 6 digit confirmation code.':
        'Nézd meg az email-jeidet a 6 számjegyű megerősítő kódért.',
      'Congratulations! You have successfully reset your password.':
        'Gratulálunk! Sikeresen helyreállítottad a jelszavadat.',
      'here': 'itt',
      'Cover image is required': 'A borítókép kötelező',
      'Image is required': 'Kép kiválasztása kötelező',
      'Name is required': 'A név megadása kötelező',
      'I accept the ': 'Elfogadom a ',
      'Should be at least 1 tag': 'Legalább egy taget meg kell adnod!',
      'You must accept GDPR before registration!':
        'El kell fogadnod a GDPR-t a regisztráció előtt!',
      'Incorrect username or password.': 'Hibás felhasználónév vagy jelszó',
      'You must accept the terms and condition before registration!':
        'El kell fogadnod a felhasználási feltételeket a regisztráció előtt!',
      'Passwords must match': 'A jelszavaknak egyezniük kell',
      'Your password must be at least 8 characters long, must contain one uppercase, one lowercase character and one number':
        'A jelszónak legalább 8 karakter hosszúnak kell lennie, tartalmaznia kell nagybetűt, kisbetűt és legalább 1 számot',
      'Confirm email': 'Email megerősítés',
      'Sign in': 'Bejelentkezés',
      'Required': 'Kötelező',
      'Reset': 'Visszaállítás',
      'Action': 'Művelet',
      'Amount': 'Mennyiség',
      'Submit': 'Végrehajt',
      'Search': 'Keresés',
      'Partner is required': 'A partner mező megadása kötelező',
      'Status is required': 'A státusz mező megadása kötelező',
      'Add a description to your inspiration (optional)':
        'Adj leírást az inspirációdhoz (opcionális)',
      'Search...': 'Keresés...',
      'Start to type...': 'Kezdj el gépelni...',
      'Phone number': 'Telefonszám',
      'Choose image': 'Válassz képet',
      'Phone': 'Telefonszám',
      'Accept?': 'Elfogadod?',
      'Reject?': 'Elutasítod?',
      'Address is required': 'A cím kitöltése kötelező',
      'City is required': 'A város megadása kötelező',
      'Street is required': 'Az utca megadása kötelező',
      'Street number is required': 'A házszám megadása kötelező',
      'ZIP Code is required': 'Az irányítószám megadása kötelező',
      'coverImages field must have less than or equal to 5 items':
        'Borítóképek száma maximum 5 lehet',
      'coverImages field must have at least 1 items':
        'Borítóképek számának minimum 1-nek kell lennie',
      'Upload cover picture': 'Borítókép feltöltése',
      'January': 'Január',
      'February': 'Február',
      'March': 'Március',
      'April': 'Április',
      'May': 'Május',
      'June': 'Június',
      'July': 'Július',
      'August': 'Augusztus',
      'September': 'Szeptember',
      'October': 'Október',
      'November': 'November',
      'December': 'December',
      'Monday': 'Hétfő',
      'Tuesday': 'Kedd',
      'Wednesday': 'Szerda',
      'Thursday': 'Csütörtök',
      'Friday': 'Péntek',
      'Saturday': 'Szombat',
      'Sunday': 'Vasárnap',
      'The uploaded content complies with the ': 'A feltöltött tartalom megfelel a következőnek: ',
      'On which days can the ceremony be held': 'Mely napokon tartható szertartás',
      'Please check your fields, because some of them are missing or maybe invalid':
        'Kérlek ellenőrizd a mezőket, mert van amit nem töltöttél ki, vagy talán hibásan',
      'Search in names only': 'Keresés a nevek között',
      'Search in the following options': 'Keresés az alábbi opciók között',
      'Quotes Rejected': 'Ajánlatok elutasítva',
      'Quotes Accepted': 'Ajánlatok elfogadva',
      'Needs': 'Szükségletek',
      'Needs list': 'Szükséglet lista',
      'Quote response is created': 'Megkeresés elküldve!',
      'Quote request is received': 'Árajánlatkérésed érkezett!',
      'Default params': 'Általános paraméterek',
      'Custom params': 'Egyedi paraméterek',
      'Textual addition': 'Szöveges kiegészítés',
      'The description which is given by couple:': 'A pár által adott leírás:',
      'and': 'és',
      'New': 'Új',
      'Be the first!': 'Légy az első!',
      'Be the first to send a quote to the couple!':
        'Légy az első, aki ajánlatot küld a pár számára!',
      'Quote request details': 'Ajánlatkérés részletei',
      'Here are the quote requests that were organically requested directly from you through your profile! You can send quotes for these without using Token!':
        'Itt találod azokat az ajánlatkéréseket, amelyeket organikusan a profilodon keresztül direkt tőled kértek! Ezekre Tokenfelhasználás nélkül küldhetsz árajánlatokat!',
      'Direct quote requests': 'Bejövő árajánlatkérések',
      'Direct quote request': 'Direkt ajánlatkérés',
      'Sent quotes': 'Elküldött megkeresések',
      'You can see the couples special needs about the service here':
        'Itt láthatod a pár egyedi elképzeléseit a szolgáltatásoddal kapcsolatban',
      'You can see the default informations about the wedding here':
        'Itt láthatod az esküvő általános információit',
      'Receive time:': 'Beérkezés ideje: ',
      'Location': 'Helyszín',
      'This couple directly asked you for a quote through your profile!':
        'Ez a pár direkt tőled kért árajánlatot a profilodon keresztül!',
      'This couple found your profile and sent a quote request directly':
        'Ez a pár direkt tőled kért árajánlatot a profilodon keresztül!',
      'Here you can create a quote for the couple! After sending the offer, you can already chat with the couple!':
        'Itt hozhatsz létre árajánlatot a pár számára! Az ajánlat kiküldése után, már chat-en is beszélhetsz a párral!',
      'If you want, you can write a few sentences for the quote request.':
        'Ha szeretnéd, írhatsz néhány mondatot az ajánlathoz.',
      'Send': 'Küldés',
      'Enter more days': 'Több napot adj meg',
      'Enter fewer days': 'Kevesebb napot adj meg',
      'Cannot be a negative value': 'Negatív érték nem lehet',
      'Reject direct quote request': 'Direkt ajánlatkérés elutasítása',
      'Why do you want to reject this quote request?':
        'Miért szeretnéd visszautasítani az ajánlatkérést?',
      'You need at select least one reason to reject this quote request':
        'Legalább egy indokot ki kell választanod ahhoz, hogy elutasítsd az ajánlatkérést!',
      'Send quote': 'Ajánlatot küldök',
      'guests': 'fő',
      'Planned year': 'Tervezett év',
      'Planned city': 'Tervezett város',
      'This couple specifically asked for an offer from you!':
        'Ez a pár konkrétan tőled kért ajánlatot!',
      'Here you find the details of the original quote and wedding, as well as your response':
        'Itt találod az eredeti árajánlatkérés/esküvő részleteit, illetve az arra adott válaszodat!',
      'Planned date (Suitable months and days)': 'Tervezett dátum (Tervezett hónapok és napok)',
      'Planned location(s) and favorite features':
        'Tervezett helyszín(ek) és kedvelt jellegzetességek',
      'Images attached by the couple': 'A pár által csatolt képek',
      'Here you can find the images attached by the couple':
        'Itt találod a pár által csatolt képeket',
      'Spent 10 tokens on quote request accept': 'Elköltött 10 tokent az ajánlatkérés elfogadására',
      'Recommendation system': 'Ajánló rendszer',
      'Here you can see the quotes sent out.': 'Itt láthatod listázva a kiküldött megkereséseket!',
      'For You': 'Neked',
      'Here you can find all the couples in the system who are looking for a quote in your category!':
        'Itt találod az összes párt a rendszerben, akik árajánlatot keresnek a te kategóriádban!',
      'Here you can find the quotes that were requested directly from you through our recommendation system!':
        'Itt találod azokat az ajánlatokat, amelyeket az ajánlórendszerünkön keresztül direkt tőled kértek!',
      'Inspiration pending': 'Ellenőrzés alatt',
      'Inspiration active': 'Aktív',
      'Inspiration rejected': 'Elutasított',
      'Inspiration hidden': 'Rejtve',
      'Here you can edit your inspiration scroll images!':
        'Itt tudod alakítani az inspiration scroll képeidet!',
      'Rules of uploading': 'Feltöltésre vonatkozó szabályzat',
      'soon': 'hamarosan',
      'Direct request': 'Direkt kérés',
      'This quote was requested directly from you!': 'Ezt az ajánlatot direkt tőled kérték!',
      'By recommendation': 'Ajánlás útján',
      'Weddings Wall': 'Esküvők oldalról',
      'Quote pending': 'Válaszra vár',
      'Quote rejected': 'Elutasított',
      'Quote accepted': 'Árajánlatot kapott',
      'requested a quote': 'árajánlatot kért tőle',
      'Weddings': 'Esküvők',
      'Here you can find all couples in the system who are looking for your service and are waiting to receive a quote from service providers like you!':
        'Itt találod az összes párt a rendszerben, akik a Te szolgáltatásodat keresik és várják, hogy árajánlatot kapjanak olyan szolgáltatóktól, mint Te!',
      'Number of quotes': 'Ajánlatok',
      'Origin': 'Keletkezés',
      'Attached images': 'Csatolt képek',
      'Quotes received': 'Beérkezett árajánlatok',
      'Settings': 'Beállítások',
      'Onboarding': 'Onboarding',
      'Recommended partners': 'Ajánlott partnerek',
      'Recommend partners': 'Partnerek ajánlása',
      'Recommend partners for user need': 'Partnerek ajánlása szükségletre',
      'Be the first': 'Légy az első',
      'First 3': 'Első három',
      'First 5': 'Első öt',
      'looking for service': 'szolgáltatást keresnek',
      'Contact us': 'Kapcsolatfelvétel',
      'Below, you can even compile a specific quote, attach documents, or simply write a message to the couple for further consultation!':
        'Az alábbiakban akár összeállíthatsz egy konkrét árajánlatot, csatolhatsz dokumentumokat, vagy szimplán írhatsz egy üzenetet a párnak a további egyeztetés végett!',
      'Attach documents': 'Dokumentum(ok) csatolása',
      'The couple expects to receive a concrete quote':
        'A pár arra számít, hogy konkrét árajánlatot, különböző ajánlatokat vagy kiajánlókat kap! A kapcsolatfelvételt követően, már chat üzenetben is folytathatjátok a beszélgetést',
      'Token history': 'Token történet',
      'Partner name': 'Partner neve',
      'Couple name': 'Pár neve',
      'Token amount': 'Tranzakció értéke',
      'Timestamp': 'Tranzakció dátuma',
      'Remaining tokens': 'Fennmaradó tokenek száma',
      'This couple requested a quote directly from you through the recommendation system':
        'Ez a pár direkt tőled kért árajánlatot az ajánlórendszeren keresztül',
      'They asked you for an offer through a recommendation system!':
        'Ajánlórendszeren keresztül kértek tőled ajánlatot!',
      'We have recommended you to a couple who would like to request a quote from you! You can contact them using tokens!':
        'Kiajánlottunk téged egy párnak, aki szeretne tőled árajánlatot kérni! Tokenek felhasználásával tudsz kapcsolatba lépni velük!',
      'Take the first step by contacting the couple! After that, you can chat with them!':
        'Tedd meg az első lépést azzal, hogy kapcsolatba lépsz a párral! Ezután már chat-en is beszélhetsz velük!',
      'Time of creation:': 'Létrehozás ideje:',
      'Last sign in date:': 'Utoljára bejelentkezve:',
      'This couple is waiting for quotes and enquiries with the details below, so you have the opportunity to contact them!':
        'Ez a pár árajánlatokat és megkereséseket vár az alábbi részletekkel, így neked is van lehetőséged kapcsolatba lépni velük!',
      'Be in the first three to send a quote to the couple!':
        'Légy az első három között, aki ajánlatot küld a pár számára!',
      'Be in the first five to send a quote to the couple!':
        'Légy az első öt között, aki ajánlatot küld a pár számára!',
      'You are about to contact the following couple: {{couple}}!':
        'Kapcsolatfelvételre készülsz a következő párral: {{couple}}!',
      'Latest weddings in {{categoryName}} category':
        'Legújabb esküvők {{categoryName}} kategóriában',
      'Send a quote and get new customers!': 'Küldj Te is árajánlatot és szerezz új ügyfeleket!',
      'Show all': 'Lássam az összeset',
      'Weddings wall': 'Esküvők fal',
      'Be the first': 'Légy az első',
      'Be in the first three': 'Légy az első három között',
      'Be in the first five': 'Légy az első öt között',
      'Waiting for approval': 'Jóváhagyásra várva',
      'Approved': 'Jóváhagyva',
      'Archived': 'Archiválva',
      'Edit': 'Szerkesztés',
      'Add': 'Hozzáadás',
      'Edit additional text': 'Kiegészítő szöveg szerkesztése',
      'From all quote requests': 'Esküvőkből',
      'Participant #1': 'Résztvevő #1',
      'Participant #2': 'Résztvevő #2',
      'Conversation messages': 'Beszélgetés üzenetei',
      'Filter list': 'Szűrés',
      'Partner category': 'Partnerkategória',
      'Partner categories': 'Partnerkategóriák',
      'Number of User Needs': 'User Needek száma',
      'Only show 100% User Needs': 'Csak 100%-os User Needek mutatása',
      'Deleted': 'Törölt',
      'Read': 'Olvasva',
      'Direct quotes': 'Direkt ajánlatok',
      'Wall quotes': '"Esküvők" ajánlatok',
      'ForYou quotes': '"Neked" ajánlatok',
      'Edit profile': 'Profil szerkesztése',
      'Last message': 'Utolsó üzenet',
      'Keyword': 'Kulcsszó',
      'Clear': 'Törlés',
      'Introduction': 'Bemutatkozás',
      'All': 'Összes',
      'Password must be at least 8 characters long':
        'A jelszónak legalább 8 karakter hosszúnak kell lennie',
      'Password must contain at least one number': 'A jelszónak legalább egy számnak kell lennie',
      'Password must contain at least one uppercase letter':
        'A jelszónak legalább egy nagybetűnek kell lennie',
      'Password must contain at least one lowercase letter':
        'A jelszónak legalább egy kisbetűnek kell lennie',
      'Password must contain at least one symbol':
        'A jelszónak legalább egy speciális karakternek kell lennie',
      'Username/client id combination not found.':
        'Ezzel az email címmel nem található felhasználó.',
      'Invalid verification code provided, please try again.':
        'Érvénytelen ellenőrző kód, kérlek próbáld újra.',
      'Error': 'Hiba',
      'Too Short!': 'Túl rövid',
      'tags field must have at least 1 items': 'Legalább 1 címkét meg kell adni',
      'City': 'Város',
      'Zip': 'Irányítószám',
      'Street': 'Utca',
      'Street Number': 'Házszám',
      'Your password must be at least 8 characters long, must contain one uppercase, one lowercase character and one number':
        'A jelszónak legalább 8 karakter hosszúnak kell lennie, tartalmaznia kell nagybetűt, kisbetűt és legalább 1 számot',
      'Already seen': 'Megnyitották',
      'Not yet seen': 'Még nem nyitották meg',
      'Response seen by couple': 'A pár megtekintette az ajánlatodat!',
      'I would like the couple to receive my offer by email!':
        'Szeretném, ha az ajánlatomat emailben is megkapná a pár!',
      'Type of quote': 'Ajánlat típusa',
      'Status': 'Állapot',
      'Couple name': 'Pár neve',
      'Couple email': 'Pár email címe',
      'Partner name': 'Partner neve',
      'Filter category': 'Kategória szűrés',
      'Sent at': 'Elküldve',
      'Edit': 'Szerkesztés',
      'Sent notification': 'Értesítés küldése',
      'Feedback from the couple': 'Visszajelzés a pártól',
      'Feedback received: {{text}}': 'Visszajelzés érkezett: {{text}}',
      'Buy tokens': 'Token vásárlás',
      'Purchase token': 'Token vásárlás',
      'Are you out of tokens?': 'Elfogytak a tokenenek?',
      'Randomized order': 'Véletlenszerű sorrend',
      'New weddings': 'Új esküvők',
      'Here you can customise which events you wish receive notifications about':
        'Személyre szabhatod, hogy milyen eseményekről szeretnél értesítést kapni',
      'Pay': 'Fizetés',
      'Invoice details': 'Számlázási adatok',
    },
  },
}

export default translations
