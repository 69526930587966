import Grid from '@material-ui/core/Grid'
import { useNotification, NotificationView } from 'modules/notification'
import { Page, Typography } from 'modules/ui'

export default () => {
  const { notification } = useNotification()

  if (!notification) {
    return 'Loading...'
  }

  return (
    <Page title={`View "${notification.title}"`}>
      <Grid container>
        <Grid item xs={12} sm={8} md={6} lg={4}>
          <Typography variant="h6" color="textPrimary">
            Notification details
          </Typography>
          <NotificationView {...notification} />
        </Grid>
      </Grid>
    </Page>
  )
}
