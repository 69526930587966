import * as yup from 'yup'
import { Form, Formik } from 'formik'
import { TextField, SubmitButton } from 'modules/formik'
import { Button } from 'modules/ui'
import { Grid, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(({ spacing }) => ({
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: spacing(4),
    gap: spacing(2),
  },
}))

const validationSchema = yup.object({
  contactEmail: yup.string().email('Invalid email address').required('Required'),
  contactPhone: yup.string().min(2, 'Invalid phone!').required('Required'),
  contactName: yup.string().min(2, 'Too short!').required('Required'),
})

const ContactForm = ({ submitForm, state, goBack }) => {
  const classes = useStyles()

  return (
    <Formik initialValues={state} onSubmit={submitForm} validationSchema={validationSchema}>
      <Form>
        <Grid container justifyContent="center" alignContent="flex-end">
          <Grid item xs={12} sm={8} md={6}>
            <TextField
              id="contactName"
              name="contactName"
              label="Contact name"
              margin="normal"
              fullWidth
            />
            <TextField
              id="contactPhone"
              name="contactPhone"
              label="Contact phone"
              margin="normal"
              fullWidth
            />
            <TextField
              id="contactEmail"
              name="contactEmail"
              label="Contact email"
              margin="normal"
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.footer}>
          <Button variant="outlined" color="primary" margin="dense" onClick={goBack}>
            Back
          </Button>
          <SubmitButton variant="contained" color="primary" margin="dense">
            next
          </SubmitButton>
        </Grid>
      </Form>
    </Formik>
  )
}

export default ContactForm
