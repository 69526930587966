import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'
import { createSagaAction } from 'saga-toolkit'

const name = 'inspiration'

export const inspirationAdapter = createEntityAdapter()

const initialState = {
  loading: false,
  error: null,
  items: inspirationAdapter.getInitialState(),
  item: null,
}

export const fetchItems = createSagaAction(`${name}/fetchItems`)
export const fetchItem = createSagaAction(`${name}/fetchItem`)
export const createItem = createSagaAction(`${name}/createItem`)
export const updateItem = createSagaAction(`${name}/updateItem`)
export const removeItem = createSagaAction(`${name}/removeItem`)

const handlePending = state => ({
  ...state,
  loading: true,
})

const handleRejected = (state, { error }) => ({
  ...state,
  error,
  loading: false,
})

const slice = createSlice({
  name,
  initialState,
  reducers: {
    clearItem: state => {
      state.item = null
    },
  },
  extraReducers: {
    // fetchItems
    [fetchItems.pending]: handlePending,
    [fetchItems.fulfilled]: (state, { payload }) => {
      try {
        inspirationAdapter.setAll(state.items, payload)
        state.loading = false
      } catch (e) {
        console.error('My error: ', e)
      }
    },
    [fetchItems.rejected]: handleRejected,

    // fetchItem
    [fetchItem.pending]: handlePending,
    [fetchItem.fulfilled]: (state, { payload }) => ({
      ...state,
      item: payload,
      loading: false,
    }),
    [fetchItem.rejected]: handleRejected,

    // createItem
    [createItem.pending]: handlePending,
    [createItem.fulfilled]: state => ({
      ...state,
      loading: false,
    }),
    [createItem.rejected]: handleRejected,

    // removeItem
    [removeItem.pending]: handlePending,
    [removeItem.fulfilled]: (state, { payload }) => {
      inspirationAdapter.removeOne(state.items, payload.id)
      state.loading = false
    },
    [removeItem.rejected]: handleRejected,

    // updateItem
    [updateItem.pending]: handlePending,
    [updateItem.fulfilled]: (state, { payload }) => {
      inspirationAdapter.updateOne(state.items, { id: payload.id, changes: payload })
      state.item = { ...state.item, ...payload }
      state.loading = false
    },
    [updateItem.rejected]: handleRejected,
  },
})

export const { clearItem } = slice.actions
export default slice.reducer
