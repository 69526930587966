import { useState } from 'react'
import { useHistory } from 'react-router'
import { Dialog, DialogContent, DialogTitle, Grid } from '@material-ui/core'
import { PartnerProfileForm, processPartnerFormSubmit } from 'modules/partner'
import { useProfile } from 'modules/profile'
import { AdminForm, processAdminFormSubmit } from 'modules/admin'
import { Loader, Page, Button } from 'modules/ui'

const Profile = () => {
  const history = useHistory()
  const { user, deleteProfile, loading, updateProfile } = useProfile()
  const [submitting, setSubmitting] = useState(false)
  const [deleteConfirmModalVisible, setDeleteConfirmModalVisible] = useState(false)
  const extraFields = user?.category?.extraFields
  const initialState = {
    ...user,
    id: user?.id,
    category: undefined, //TODO, use category to select tags and services
    ...(user?.type === 'PARTNER' && {
      guestRange: [
        user.guestRange ? user.guestRange.low : 0,
        user.guestRange ? user.guestRange.high : 1,
      ],
    }),
  }

  const onSubmit = async data => {
    setSubmitting(true)

    switch (user.type) {
      case 'PARTNER':
        const processedPartner = await processPartnerFormSubmit(data)
        await updateProfile(processedPartner)
        break
      case 'ADMIN':
        const processedAdmin = await processAdminFormSubmit(data)
        await updateProfile(processedAdmin)
        break
      default:
        break
    }

    setSubmitting(false)
  }

  const handleDeleteProfile = async () => {
    if (!loading) {
      await deleteProfile()
      setDeleteConfirmModalVisible(false)
      history.push('/welcome')
    }
  }

  return (
    <Page>
      <Loader loading={submitting} />
      {user?.type === 'ADMIN' && (
        <AdminForm
          onSubmit={onSubmit}
          initialValues={initialState}
          user={user}
          showDeleteConfirm={() => setDeleteConfirmModalVisible(true)}
        />
      )}
      {user?.type === 'PARTNER' && (
        <PartnerProfileForm
          onSubmit={onSubmit}
          initialState={initialState}
          user={user}
          extraFields={extraFields}
        />
      )}

      <Dialog
        open={deleteConfirmModalVisible}
        onClose={() => setDeleteConfirmModalVisible(false)}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="xs">
        <DialogTitle id="form-dialog-title">Do you really want to delete your profile?</DialogTitle>
        <Grid container justifyContent="space-around">
          <Grid item>
            <Button
              onClick={() => setDeleteConfirmModalVisible(false)}
              variant="contained"
              color="primary">
              Back
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={handleDeleteProfile} color="primary">
              Delete profile
            </Button>
          </Grid>
        </Grid>

        <DialogContent></DialogContent>
      </Dialog>
    </Page>
  )
}

export default Profile
