import { useHistory } from 'react-router'
import { useLocation } from 'react-router-dom'
import { Box, Checkbox, Grid, makeStyles } from '@material-ui/core'
import { QuoteRequest, QuoteRequestStatus, QuoteType } from 'modules/api'
import { QuoteAdminList, useQuote } from 'modules/quote'
import { useT } from 'modules/i18n'
import { getPaginationParams, useQueryState } from 'modules/app'
import { NavButton, Page, Picker, Typography, useTable } from 'modules/ui'

const statusOptions = [
  { name: 'All', value: 'all' },
  { name: 'Accepted', value: QuoteRequestStatus.Accepted },
  { name: 'Pending', value: QuoteRequestStatus.Pending },
  { name: 'Rejected', value: QuoteRequestStatus.Rejected },
]

const typeOptions = [
  { name: 'All', value: 'all' },
  { name: 'Direct', value: QuoteType.Direct },
  { name: 'For you', value: QuoteType.ForYou },
  { name: 'Wall', value: QuoteType.Wall },
]

const useStyles = makeStyles(({ spacing }) => ({
  navButton: {
    fontSize: '0.875rem',
    fontWeight: 500,
    textTransform: 'uppercase',
  },
}))

const AdminRowActionsComponent = (row: QuoteRequest) => {
  const classes = useStyles()
  const t = useT()

  return (
    <Grid container wrap="nowrap">
      <NavButton to={`/quotes/${row.id}/view`} tooltip="View" className={classes.navButton}>
        {t('View')}
      </NavButton>
      <NavButton
        to={`/quotes/${row.id}/view-response`}
        tooltip="View"
        className={classes.navButton}>
        {t('View response')}
      </NavButton>
      <NavButton to={`/couples/${row.couple.id}/view`} tooltip="CIC" className={classes.navButton}>
        {t('CIC')}
      </NavButton>
    </Grid>
  )
}

const List = () => {
  // todo add types for hooks like useQuote
  const { quoteRequests, total } = useQuote()
  const { handleChangePage, handleChangeRowsPerPage, handleSearch, handleSort } = useTable()
  const { pageSize, page, filter, sortBy, sortDirection } = getPaginationParams()
  const { handleChange: handleStatusChange, value: status } = useQueryState({
    defaultValue: 'all',
    queryParam: 'status',
  })
  const { handleChange: handleTypeChange, value: type } = useQueryState({
    defaultValue: 'all',
    queryParam: 'type',
  })
  const history = useHistory()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const shownFeedbackOnly = queryParams.get('hasFeedback') === 'true'

  const handleShownFeedbackOnlyChange = () => {
    shownFeedbackOnly ? queryParams.delete('hasFeedback') : queryParams.set('hasFeedback', 'true')
    history.replace(`${location.pathname}?${queryParams.toString()}`)
  }

  return (
    <Page fullHeight>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <Picker
            fullWidth
            label="Status"
            options={statusOptions}
            onChange={handleStatusChange}
            value={status}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Picker
            fullWidth
            label="Type"
            options={typeOptions}
            onChange={handleTypeChange}
            value={type}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="button">Has feedback</Typography>
          <Checkbox
            checked={shownFeedbackOnly}
            onChange={handleShownFeedbackOnlyChange}
            size="small"
          />
        </Grid>
      </Grid>

      <QuoteAdminList
        quoteRequests={quoteRequests}
        total={total}
        pageSize={pageSize}
        page={page}
        filter={filter}
        sortBy={sortBy}
        sortDirection={sortDirection}
        rowActionsComponent={AdminRowActionsComponent}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        onSearch={handleSearch}
        onSort={handleSort}
      />
    </Page>
  )
}

export default List
