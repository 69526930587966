import {
  Chip,
  FormControl,
  Input,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
} from '@material-ui/core'
import CancelIcon from '@material-ui/icons/Cancel'
import { useField } from 'formik'
import { useNeed } from '../hooks'

const useStyles = makeStyles(theme => ({
  formControl: {
    'margin': `${theme.spacing(1)}px 0`,
    'minWidth': 120,
    'width': '100%',
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
    '& .MuiSelect-root': {
      paddingTop: 0,
      paddingBottom: 1,
    },
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 3,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}))

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  getContentAnchorEl: null,
}

export const NeedMultiSelect = ({ name }) => {
  const classes = useStyles()
  const { needs } = useNeed()
  const [field, meta, helper] = useField({ name })
  const value = field.value || []

  const handleChange = event => {
    helper.setValue(event.target.value)
  }

  const handleDelete = id => {
    helper.setValue(field.value.filter(v => v.id !== id))
  }

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="demo-mutiple-chip-label">Needs</InputLabel>
      <Select
        labelId="demo-mutiple-chip-label"
        id="demo-mutiple-chip"
        multiple
        value={value}
        onChange={handleChange}
        input={<Input id="select-multiple-chip" />}
        renderValue={selected => (
          <div className={classes.chips}>
            {selected.map(({ id, name }) => (
              <Chip
                key={id}
                label={name}
                className={classes.chip}
                onDelete={() => handleDelete(id)}
                deleteIcon={<CancelIcon onMouseDown={e => e.stopPropagation()} />}
              />
            ))}
          </div>
        )}
        MenuProps={MenuProps}>
        {needs.map(need => (
          <MenuItem key={need.id} value={need}>
            {need.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
