import { makeStyles } from '@material-ui/core/styles'
import { Box, Grid } from '@material-ui/core'
import * as yup from 'yup'
import { Formik, Form, FormikConfig } from 'formik'
import { SubmitButton, Checkbox } from 'modules/formik'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  submit: {
    display: 'flex',
    justifyContent: 'space-around',
  },
}))

const validationSchema = yup.object({
  approveUserNeed: yup.boolean().required(),
})

const initialState = {
  approveUserNeed: false,
}

export interface EmailPreferencesFormValues {
  approveUserNeed: boolean
}

const EmailPreferencesForm = ({
  initialValues = initialState,
  ...props
}: FormikConfig<EmailPreferencesFormValues>) => {
  const classes = useStyles()

  return (
    <Formik {...props} validationSchema={validationSchema} initialValues={initialValues}>
      <Form className={classes.root} noValidate autoComplete="off">
        <Grid container>
          <Grid item xs={12} md={6}>
            <Checkbox label="New weddings" name="approveUserNeed" fullWidth />
          </Grid>
          <Grid xs={12} item className={classes.submit}>
            <SubmitButton color="primary" variant="contained" size="small">
              Save
            </SubmitButton>
          </Grid>
        </Grid>
      </Form>
    </Formik>
  )
}

export default EmailPreferencesForm
