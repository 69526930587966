import { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import * as yup from 'yup'
import { Formik, Form, FormikConfig, useFormikContext } from 'formik'
import { RadioGroup, TextField } from 'modules/formik'
import { InvoiceType } from '../../api'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
}))

const validationSchema = yup.object({
  type: yup.string().required('Kötelező'),
  name: yup.string().when('type', {
    is: InvoiceType.Individual,
    then: yup
      .string()
      .min(3, 'Minimum 3')
      .max(25, 'Maximum 25')
      .required('Kérjük, add meg a teljes neved!'),
    otherwise: yup.string(),
  }),
  company: yup.string().when('type', {
    is: InvoiceType.Company,
    then: yup
      .string()
      .min(3, 'Minimum 3')
      .max(25, 'Maximum 25')
      .required('Kérjük, add meg a cég nevét neved!'),
    otherwise: yup.string(),
  }),
  country: yup.string().required('Kötelező'),
  state: yup.string().required('Kötelező'),
  city: yup.string().required('Kötelező'),
  zip: yup.string().min(4, 'Minimum 4').required('Kötelező'),
  address: yup.string().required('Kötelező'),
  phone: yup.string(),
  taxNumber: yup.string().when('type', {
    is: InvoiceType.Company,
    then: yup.string().required('Kérjük, add meg az adószámát!'),
    otherwise: yup.string(),
  }),
})

const CUSTOMER_TYPES = [
  { label: 'Cég', value: InvoiceType.Company },
  { label: 'Magánszemély', value: InvoiceType.Individual },
]

const initialValues = {
  type: InvoiceType.Company,
  name: '',
  company: '',
  country: '',
  state: '',
  city: '',
  zip: '',
  address: '',
  phone: '',
  taxNumber: '',
}

export interface InvoiceDetailsFormValues {
  type: InvoiceType
  name: string
  company: string
  country: string
  state: string
  city: string
  zip: string
  address: string
  phone: string
  taxNumber: string
}

const InnterForm = () => {
  const classes = useStyles()
  const { values, setValues, resetForm } = useFormikContext<InvoiceDetailsFormValues>()

  useEffect(() => {
    resetForm()
    setValues({ ...initialValues, type: values.type })
  }, [values.type])

  return (
    <Form className={classes.root} noValidate autoComplete="off">
      <Grid container>
        <Grid item xs={12}>
          <RadioGroup name="type" options={CUSTOMER_TYPES} row />
          {values.type === InvoiceType.Company ? (
            <TextField label="Cégnév" name="company" fullWidth margin="dense" />
          ) : (
            <TextField label="Név" name="name" fullWidth margin="dense" />
          )}
          <TextField label="Ország" name="country" fullWidth margin="dense" />
          <TextField label="Megye" name="state" fullWidth margin="dense" />
          <TextField label="Város" name="city" fullWidth margin="dense" />
          <TextField label="Irányítószám" name="zip" fullWidth margin="dense" />
          <TextField label="Cím" name="address" fullWidth margin="dense" />
          <TextField label="Telefonszám" name="phone" fullWidth margin="dense" />
          {values.type === InvoiceType.Company && (
            <TextField label="Adószám" name="taxNumber" fullWidth margin="dense" />
          )}
        </Grid>
      </Grid>
    </Form>
  )
}

const InvoiceDetailsForm = ({
  initialValues: _initialValues = initialValues,
  ...props
}: FormikConfig<InvoiceDetailsFormValues>) => (
  <Formik {...props} validationSchema={validationSchema} initialValues={_initialValues}>
    <InnterForm />
  </Formik>
)

InvoiceDetailsForm.initialValues = initialValues

export default InvoiceDetailsForm
