import { takeAggregateAsync, takeLatestAsync, putAsync } from 'saga-toolkit'
import Auth from '@aws-amplify/auth'
import { select, put } from 'redux-saga/effects'
import { v4 as uuidv4 } from 'uuid'
import { query, mutation } from 'modules/api'
import { actions as authActions } from 'modules/auth'
import * as actions from './slice'
import * as selectors from './selectors'
import { createUpdateUserInput, partnerProfileFields } from './fields'

function* fetchProfile() {
  const { profile } = yield query(
    {
      operation: 'profile',
      fields: [
        { '...on Partner': partnerProfileFields },
        {
          '...on Admin': [
            'id',
            'type',
            'name',
            'role',
            'email',
            { profileImage: ['name'] },
            { devices: ['id', 'signedIn', { notificationPreferences: ['token', 'enabled'] }] },
            'role',
            'status',
            'active',
            'name',
            'phone',
          ],
        },
      ],
    },
    true,
  )

  return profile
}

function* updateProfile({ meta }) {
  const { partner } = createUpdateUserInput(meta.arg)

  const { updatePartner } = yield mutation(
    {
      operation: 'updatePartner',
      variables: {
        input: {
          value: partner,
          type: 'UpdatePartnerInput!',
        },
      },
      fields: partnerProfileFields,
    },
    true,
  )
  return updatePartner
}

function* deleteProfile() {
  const result = yield mutation({ operation: 'deleteProfile' })

  if (result?.deleteProfile) {
    yield put(authActions.signOut())
  }
}

function* registerDevice() {
  const browserId = yield putAsync(actions.createIdForBrowser())
  const result = yield mutation(
    {
      operation: 'signInToDevice',
      variables: { deviceId: { value: browserId, type: 'String', required: true } },
    },
    true,
  )

  return result
}

function* signOut() {
  const browserId = yield putAsync(actions.createIdForBrowser())
  try {
    yield mutation({
      operation: 'signOutFromDevice',
      variables: {
        deviceId: { value: browserId, type: 'String', required: true },
      },
    })
  } catch (error) {}
}

function createIdForBrowser() {
  const localStorage = window.localStorage
  const browserId = localStorage.getItem('BrowserId')

  if (browserId) {
    return browserId
  }
  const newBrowserId = uuidv4()

  localStorage.setItem('BrowserId', newBrowserId)
  return newBrowserId
}

export default [
  takeAggregateAsync(actions.fetchProfile.type, fetchProfile),
  takeLatestAsync(actions.updateProfile.type, updateProfile),
  takeLatestAsync(actions.deleteProfile.type, deleteProfile),
  takeLatestAsync(actions.registerDevice.type, registerDevice),
  takeLatestAsync(actions.signOut.type, signOut),
  takeLatestAsync(actions.createIdForBrowser.type, createIdForBrowser),
]
