import { gql } from 'graphql-tag'

export const weddingByIdQuery = gql`
  query WeddingById($weddingByIdId: ID!) {
    weddingById(id: $weddingByIdId) {
      id
      couple {
        name
      }
      date
      description
      coverImage {
        name
      }
      address {
        name
      }
      guestRange {
        low
        high
      }
      possibleYear
      possibleMonths
      possibleDays
      possibleAddresses {
        name
      }
      locationTags {
        id
        name
      }
    }
  }
`

export const unseenUserNeedsQuery = gql`
  query UnseenUserNeeds($filter: UnseenUserNeedsInput!) {
    unseenUserNeeds(filter: $filter)
  }
`
