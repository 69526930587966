import { Switch, Route, useRouteMatch } from 'react-router-dom'
import New from './New'
import Edit from './Edit'
import List from './List'

export default () => {
  const match = useRouteMatch()

  return (
    <Switch>
      <Route path={`${match.url}`} exact component={List} />
      <Route path={`${match.url}/new`} exact component={New} />
      <Route path={`${match.url}/:id/edit`} exact component={Edit} />
    </Switch>
  )
}
