import { makeStyles } from '@material-ui/core/styles'
import BaseRadioGroup, {
  RadioGroupProps as BaseRadioGroupProps,
} from '@material-ui/core/RadioGroup'
import FormControl, { FormControlProps } from '@material-ui/core/FormControl'
import Radio from '@material-ui/core/Radio'
import clsx from 'clsx'
import FormHelperText from './FormHelperText'
import FormControlLabel from './FormControlLabel'
import FormLabel from './FormLabel'

const useStyles = makeStyles(() => ({
  root: {},
}))

export interface Option {
  label: string
  value: string
}

export interface RadioGroupProps extends Omit<FormControlProps, 'onChange'> {
  label?: string
  name?: string
  value?: string
  options?: Array<Option>
  helperText?: string
  row?: BaseRadioGroupProps['row']
  defaultValue?: BaseRadioGroupProps['defaultValue']
  onChange?: BaseRadioGroupProps['onChange']
}

const RadioGroup = ({
  name,
  value,
  defaultValue,
  row,
  options = [],
  label,
  helperText,
  className,
  onChange,
  ...props
}: RadioGroupProps) => {
  const classes = useStyles()

  return (
    <FormControl {...props} className={clsx(classes.root, className)}>
      {label && <FormLabel>{label}</FormLabel>}
      <BaseRadioGroup
        name={name}
        row={row}
        defaultValue={defaultValue || value}
        value={value}
        onChange={onChange}>
        {options.map(({ label, value: _value }, i) => (
          <FormControlLabel key={i} label={label} value={_value} control={<Radio />} />
        ))}
      </BaseRadioGroup>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}

export default RadioGroup
