import { CheckboxSelect } from 'modules/formik'

const weekdaysArray = [
  {
    label: 'Monday',
    value: 'monday',
  },
  {
    label: 'Tuesday',
    value: 'tuesday',
  },
  {
    label: 'Wednesday',
    value: 'wednesday',
  },
  {
    label: 'Thursday',
    value: 'thursday',
  },
  {
    label: 'Friday',
    value: 'friday',
  },
  {
    label: 'Saturday',
    value: 'saturday',
  },
  {
    label: 'Sunday',
    value: 'sunday',
  },
]

const WeekdaySelect = () => (
  <CheckboxSelect
    label="On which days can the ceremony be held"
    options={weekdaysArray}
    name="availableWeekdays"
  />
)

export default WeekdaySelect
