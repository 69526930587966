import { createSlice } from '@reduxjs/toolkit'
import { createSagaAction } from 'saga-toolkit'

const name = 'transaction'

const initialState = {
  loading: false,
  error: null,
  transactions: null,
  total: 0,
}

export const fetchTransactions = createSagaAction(`${name}/fetchTransactions`)

const handlePending = state => ({
  ...state,
  loading: true,
})

const handleRejected = (state, { error }) => ({
  ...state,
  error,
  loading: false,
})

const slice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: {
    // transactions
    [fetchTransactions.pending]: handlePending,
    [fetchTransactions.fulfilled]: (state, { payload }) => {
      const { transactions } = payload

      state.transactions = transactions.elements
      state.total = transactions.total
      state.loading = false
    },
    [fetchTransactions.rejected]: handleRejected,
  },
})

export default slice.reducer
