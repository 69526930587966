import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: 'auto',
  },
}))

const ImageCell = ({ src, ...props }) => {
  const classes = useStyles()
  return <img className={classes.root} src={`${src}?width=200&height=240`} alt="" {...props} />
}

export default ImageCell
