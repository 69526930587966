import { Route, useRouteMatch } from 'react-router-dom'
import Home from './Home'
import ConversationIssuesList from './ConversationIssuesList'
import FixChatConversationIssues from './FixChatConversationIssues'

const Admin = () => {
  const match = useRouteMatch()

  return (
    <>
      <Route path={`${match.url}`} exact component={Home} />
      <Route
        path={[`${match.url}/conversation-issues`, `${match.url}/conversation-issues/fix`]}
        exact
        component={ConversationIssuesList}
      />
      <Route
        path={`${match.url}/conversation-issues/fix`}
        exact
        component={FixChatConversationIssues}
      />
    </>
  )
}

export default Admin
