import { query } from 'modules/api'
import { putAsync, takeLatestAsync } from 'saga-toolkit'
import { actions as itemActions, parseItem } from 'modules/item'
import * as actions from './slice'

function* fetchItems() {
  const { profile } = yield query(
    {
      operation: 'profile',
      fields: [
        {
          '...on Partner': [
            {
              items: [
                'id',
                'name',
                'active',
                'status',
                { coverImage: ['name'] },
                { tags: ['id', 'name'] },
                {
                  gallery: ['name', { image: ['name'] }],
                },
                'likes',
                'views',
              ],
            },
          ],
        },
      ],
    },
    true,
  )
  const parsedItems = profile.items.map(item => parseItem(item))

  return parsedItems || []
}

function* updateItem({ meta }) {
  return yield putAsync(itemActions.updateItem(meta.arg))
}

function* removeItem({ meta }) {
  const { id } = meta.arg
  yield putAsync(itemActions.removeItem(meta.arg))
  return { id }
}

function* fetchItem({ meta }) {
  return yield putAsync(itemActions.fetchItem(meta.arg))
}

function* createItem({ meta }) {
  return yield putAsync(itemActions.createItem(meta.arg))
}

const sagas = [
  takeLatestAsync(actions.fetchItems.type, fetchItems),
  takeLatestAsync(actions.updateItem.type, updateItem),
  takeLatestAsync(actions.removeItem.type, removeItem),
  takeLatestAsync(actions.fetchItem.type, fetchItem),
  takeLatestAsync(actions.createItem.type, createItem),
]

export default sagas
