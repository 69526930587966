import { takeLatestAsync } from 'saga-toolkit'
import {
  requestApi,
  StartTransactionInput,
  StartTransactionMutation,
  ValidateTransactionInput,
  ValidateTransactionMutation,
} from 'modules/api'
// import { selectPartner } from './selectors'
import * as actions from './slice'
import * as operations from './operations'

interface Arg<T> {
  meta: {
    arg: T
  }
}

function* fetchPayments({ meta }) {
  const { offset, limit, filter, sortBy, sortDirection, status } = meta.arg

  const { payments } = yield requestApi(operations.paymentsQuery, {
    filter: { offset, limit, filter, sortBy, sortDirection, status },
  })

  return payments
}

function* startTransaction({ meta }: Arg<StartTransactionInput & { authToken?: string }>) {
  const { authToken, ...input } = meta.arg
  const result = yield requestApi<StartTransactionMutation>(
    operations.startTransaction,
    {
      input,
    },
    authToken,
  )

  return result.startTransaction
}

function* validateTransaction({ meta }: Arg<ValidateTransactionInput & { authToken?: string }>) {
  const { authToken, ...input } = meta.arg
  const result = yield requestApi<ValidateTransactionMutation>(
    operations.validateTransaction,
    {
      input,
    },
    authToken,
  )

  return result.validateTransaction
}

export default [
  takeLatestAsync(actions.fetchPayments.type, fetchPayments),
  takeLatestAsync(actions.startTransaction.type, startTransaction),
  takeLatestAsync(actions.validateTransaction.type, validateTransaction),
]
