import React from 'react'
import ReactDOM from 'react-dom'
import { ConnectedRouter } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { Provider } from 'react-redux'
import { Amplify, Analytics } from 'aws-amplify'
import { ThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import { actions as authActions } from 'modules/auth'
import { appInitialState } from 'modules/app'
import moment from 'moment'
import theme from './theme'
import App from './components/App'
import * as serviceWorker from './serviceWorker'
import { createStore } from './store'
import config from 'modules/config'
import 'react-perfect-scrollbar/dist/css/styles.css'

if (!config.enableTracking) {
  Analytics.disable() // https://github.com/aws-amplify/amplify-js/issues/3484#issuecomment-505400743
}

Amplify.configure({
  Auth: {
    mandatorySignIn: false,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
  Storage: {
    region: config.s3.REGION,
    bucket: config.s3.BUCKET,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
  },
  API: {
    graphql_endpoint: `${config.apiGateway.URL}/graphql`,
    graphql_endpoint_iam_region: config.apiGateway.REGION,
    endpoints: [
      {
        name: 'default',
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION,
      },
    ],
  },
})

moment.locale('hu')

/*moment.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: 'a few sec',
    ss: '%d s',
    m: '1 m',
    mm: '%d m',
    h: '1 h',
    hh: '%d h',
    d: '1 d',
    dd: '%d d',
    M: '1 m',
    MM: '%d m',
    y: 'a year',
    yy: '%d y',
  },
})*/

const history = createBrowserHistory()
const store = createStore({
  history,
  signOutAction: authActions.signOut.fulfilled.type,
  initialState: appInitialState,
})

// https://stackoverflow.com/questions/61220424/material-ui-drawer-finddomnode-is-deprecated-in-strictmode
const StrictMode = false ? React.StrictMode : React.Fragment

ReactDOM.render(
  <StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <App />
        </ConnectedRouter>
      </Provider>
    </ThemeProvider>
  </StrictMode>,
  document.getElementById('root'),
)

serviceWorker.unregister()
