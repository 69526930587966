import { gql } from 'graphql-tag'

export const searchUserQuery = gql`
  query SearchUsers($input: UserFilter!) {
    users(input: $input) {
      elements {
        id
        name
        email
        profileImage {
          name
        }
      }
    }
  }
`

export const usersQuery = gql`
  query UsersQuery($input: UserFilter!) {
    users(input: $input) {
      elements {
        id
        name
        email
        type
        role
        status
        createdAt
      }
      total
    }
  }
`
