import { gql } from 'graphql-tag'

export const transactionsQuery = gql`
  query Transactions($filter: TransactionFilter!) {
    transactions(filter: $filter) {
      elements {
        id
        wallet {
          partner {
            name
          }
        }
        amount
        details {
          remainingTokens
        }
        createdAt
      }
      total
    }
  }
`
