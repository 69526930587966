import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'
import { createSagaAction } from 'saga-toolkit'

const name = 'tag'

export const tagsAdapter = createEntityAdapter()

const initialState = {
  loading: false,
  error: null,
  tags: tagsAdapter.getInitialState(),
  tag: null,
}

export const fetchTags = createSagaAction(`${name}/fetchTags`)
export const fetchTag = createSagaAction(`${name}/fetchTag`)
export const searchTag = createSagaAction(`${name}/searchTag`)
export const createTag = createSagaAction(`${name}/createTag`)
export const updateTag = createSagaAction(`${name}/updateTag`)
export const removeTag = createSagaAction(`${name}/removeTag`)

const handlePending = state => ({
  ...state,
  loading: true,
})

const handleRejected = (state, { error }) => ({
  ...state,
  error,
  loading: false,
})

const slice = createSlice({
  name,
  initialState,
  reducers: {
    clearTag: state => ({
      ...state,
      tag: null,
    }),
  },
  extraReducers: {
    // tags
    [fetchTags.pending]: handlePending,
    [fetchTags.fulfilled]: (state, { payload }) => {
      tagsAdapter.setAll(state.tags, payload)
      state.loading = false
    },
    [fetchTags.rejected]: handleRejected,

    //tag
    [fetchTag.pending]: handlePending,
    [fetchTag.fulfilled]: (state, { payload }) => ({
      ...state,
      tag: payload,
      loading: false,
    }),
    [fetchTag.rejected]: handleRejected,

    // createTag
    [createTag.pending]: handlePending,
    [createTag.fulfilled]: state => ({
      ...state,
      loading: false,
    }),
    [createTag.rejected]: handleRejected,

    // updateTag
    [updateTag.pending]: handlePending,
    [updateTag.fulfilled]: (state, { payload }) => {
      tagsAdapter.updateOne(state.tags, { id: payload.id, changes: payload })
      state.tag = { ...state.tag, ...payload }
      state.loading = false
    },
    [updateTag.rejected]: handleRejected,
  },
})

export const { clearTag } = slice.actions

export default slice.reducer
