import React from 'react'
import { useField } from 'formik'

interface FieldProps {
  name: string
  value?: any
  onChange?: (event: React.ChangeEvent<any>) => void
  onBlur?: (event: React.FocusEvent<any>) => void
  ref?: React.Ref<HTMLInputElement>
  // [key: string]: any
}

type FormikFieldProps<T> = T & FieldProps

const withFormikField = <
  T extends {
    error?: boolean
    label?: string
    helperText?: string
  },
>(
  Field: React.ComponentType<FieldProps>,
) => {
  const FormikField = (props: FormikFieldProps<T>) => {
    const { name, value, onChange, onBlur, ...rest } = props
    const [field, meta] = useField({ name, value, onChange, onBlur })

    return (
      <Field
        {...field}
        {...rest}
        error={meta.touched && !!meta.error}
        helperText={meta.error || props.helperText}
      />
    )
  }

  return FormikField
}

export default withFormikField
