import { Box, Container, makeStyles } from '@material-ui/core'
import { HelpField } from 'modules/ui'
import { Pagination } from '@material-ui/lab'
import { QuoteType, QuoteRequest } from 'modules/api'
import QuotesCardListItem from './QuotesCardListItem'

const useStyles = makeStyles(({ spacing }) => ({
  cardContainer: {
    display: 'grid',
    justifyContent: 'center',
    gridTemplateColumns: 'repeat(auto-fill, 256px)',
    gridGap: spacing(4),
    marginBottom: spacing(7),
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: spacing(3),
    marginBottom: spacing(4),
  },
}))

const getTypeBadgeData = (type: QuoteType) => {
  switch (type) {
    case QuoteType.Direct:
      return {
        label: 'Direct request',
        info: 'This quote was requested directly from you!',
      }
    case QuoteType.Wall:
      return {
        label: 'Weddings wall',
        info: '',
        chipBackground: '#a90ecc',
      }
    case QuoteType.ForYou:
      return {
        label: 'By recommendation',
        info: 'They asked you for an offer through a recommendation system!',
        chipBackground: '#00D3FF',
        infoColor: '#00D3FF',
      }
  }
}

const getStyle = (type: QuoteType) => {
  if (type === QuoteType.ForYou) {
    return { border: '1pt solid #00D3FF' }
  }
  return {}
}

export type QuotesCardListProps = {
  quoteRequests: QuoteRequest[]
  helpText?: string
  loading?: boolean
  withStatus?: boolean
  numberOfPages: number
  page: number
  onChangePage: (page: number) => void
  onQuoteRequestClick: (quoteRequest: QuoteRequest) => void
}

const QuotesCardList = ({
  quoteRequests,
  loading = false,
  helpText = null,
  withStatus = false,
  numberOfPages,
  page,
  onChangePage,
  onQuoteRequestClick,
}: QuotesCardListProps) => {
  const classes = useStyles()

  const pagination = (
    <Box className={classes.paginationContainer}>
      <Pagination
        color="primary"
        page={page}
        count={numberOfPages}
        onChange={(_e, p) => onChangePage(p)}
      />
    </Box>
  )

  return (
    <Container maxWidth="lg">
      {!!helpText && <HelpField text={helpText} />}
      {pagination}
      {!loading && (
        <>
          <Box className={classes.cardContainer}>
            {quoteRequests.map(item => (
              <QuotesCardListItem
                key={item.id}
                isNew={!item.seenAt}
                wedding={item.weddingCopy}
                couple={item.couple}
                need={item.userNeedCopy?.need}
                onDetailsClick={() => onQuoteRequestClick(item)}
                status={withStatus ? item.status : null}
                typeBadge={getTypeBadgeData(item.type)}
                style={getStyle(item.type)}
              />
            ))}
          </Box>
          {quoteRequests.length > 4 && pagination}
        </>
      )}
    </Container>
  )
}

export default QuotesCardList
